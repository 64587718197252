/* parser generated by jison 0.3.0 */
/**
 * Returns a Parser implementing JisonParserApi and a Lexer implementing JisonLexerApi.
 */
import { JisonParser, JisonParserApi, StateType, SymbolsType, TerminalsType, ProductionsType, o } from '@ts-jison/parser';const $V0=[1,3],$V1=[1,9],$V2=[14,17],$V3=[5,15];

export class JisonParserParser extends JisonParser implements JisonParserApi {
    $?: any;

    constructor (yy = {}, lexer = new JisonParserLexer(yy)) {
      super(yy, lexer);
    }

    symbols_: SymbolsType = {"error":2,"exp":3,"GROUP":4,"EOF":5,"JSONObjectEntry":6,"JSONObjectKey":7,"JSONValue":8,"VAR":9,"TRUE":10,"FALSE":11,"NUMBER":12,"JSONObjectProperties":13,"AND":14,"OR":15,"(":16,")":17,"$accept":0,"$end":1};
    terminals_: TerminalsType = {2:"error",5:"EOF",9:"VAR",10:"TRUE",11:"FALSE",12:"NUMBER",14:"AND",15:"OR",16:"(",17:")"};
    productions_: ProductionsType = [0,[3,2],[6,2],[7,1],[8,1],[8,1],[8,1],[13,1],[13,3],[4,3],[4,3]];
    table: Array<StateType> = [{3:1,4:2,16:$V0},{1:[3]},{5:[1,4],15:[1,5]},{6:7,7:8,9:$V1,13:6},{1:[2,1]},{4:10,16:$V0},{14:[1,12],17:[1,11]},o($V2,[2,7]),{8:13,10:[1,14],11:[1,15],12:[1,16]},o([10,11,12],[2,3]),o($V3,[2,9]),o($V3,[2,10]),{6:7,7:8,9:$V1,13:17},o($V2,[2,2]),o($V2,[2,4]),o($V2,[2,5]),o($V2,[2,6]),o($V2,[2,8])];
    defaultActions: {[key:number]: any} = {4:[2,1]};

    performAction (yytext:string, yyleng:number, yylineno:number, yy:any, yystate:number /* action[1] */, $$:any /* vstack */, _$:any /* lstack */): any {
/* this == yyval */
          var $0 = $$.length - 1;
        switch (yystate) {
case 1:
return '[' + $$[$0-1] + ']';
break;
case 2:
this.$ = $$[$0-1].concat(': ').concat($$[$0])
break;
case 3:
this.$ = '"'.concat($$[$0]).concat('"')
break;
case 4:
this.$ = true
break;
case 5:
this.$ = false
break;
case 6:
this.$ = $$[$0]
break;
case 8: case 9:
this.$ = $$[$0-2].concat(', ').concat($$[$0])
break;
case 10:
this.$ = '{'.concat($$[$0-1]).concat('}')
break;
        }
    }
}


/* generated by ts-jison-lex 0.3.0 */
import { JisonLexer, JisonLexerApi } from '@ts-jison/lexer';
export class JisonParserLexer extends JisonLexer implements JisonLexerApi {
    options: any = {"moduleName":"JisonParser"};
    constructor (yy = {}) {
        super(yy);
    }

    rules: RegExp[] = [/^(?: = 1\b)/,/^(?: != 1\b)/,/^(?:\s=\s\d\b)/,/^(?: OR )/,/^(?: or )/,/^(?: AND )/,/^(?: and )/,/^(?:\()/,/^(?:\))/,/^(?:[^ ]+)/,/^(?:\s+)/,/^(?:$)/];
    conditions: any = {"INITIAL":{"rules":[0,1,2,3,4,5,6,7,8,9,10,11],"inclusive":true}}
    performAction (yy:any,yy_:any,$avoiding_name_collisions:any,YY_START:any): any {
          var YYSTATE=YY_START;
        switch($avoiding_name_collisions) {
    case 0:return 10;
      break;
    case 1:return 11
      break;
    case 2:yy_.yytext = yy_.yytext.replace(/\s\=\s/g, ''); return 12;
      break;
    case 3:return 15;
      break;
    case 4:return 15;
      break;
    case 5:return 14;
      break;
    case 6:return 14;
      break;
    case 7:return 16;
      break;
    case 8:return 17;
      break;
    case 9:return 9;
      break;
    case 10:/* skip whitespace */
      break;
    case 11:return 5;
      break;
        }
    }
}

