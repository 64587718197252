import {NativeBanner} from './native-banner';
import {CampaignBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {ICampaignBanner} from '../banner';

class CampaignNativeBanner extends NativeBanner implements
    ICampaignBanner,
    IMergable<CampaignBanner>,
    ICloneable<CampaignNativeBanner> {

    private _campaignId: number;
    private _parentBannerId: number | null = null;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        title: string,
        clickUrl: string,
        campaignId: number,
    ) {
        super(id, networkId, name, landingPage, title, clickUrl);

        this._campaignId = campaignId;
    }

    get campaignId(): number {
        return this._campaignId;
    }

    set campaignId(value: number) {
        this._campaignId = value;
    }

    get parentBannerId(): number | null {
        return this._parentBannerId;
    }

    set parentBannerId(value: number | null) {
        this._parentBannerId = value;
    }

    public merge(source: CampaignBanner): void {
        super.merge(source);
        CampaignNativeBanner.fillAdditionalFields(this, source);
    }

    public clone(): CampaignNativeBanner {
        const newModel: CampaignNativeBanner = new CampaignNativeBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.title,
            this.clickUrl,
            this.campaignId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: CampaignNativeBanner, source: CampaignBanner): void {
        NativeBanner.fillAdditionalFields(target, source);

        target.campaignId = source.campaignId;
        target.parentBannerId = source.parentBannerId;
    }
}

export {CampaignNativeBanner};
