import {Observable, of} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IInterstitialFilterProperties extends IFilterProperties {
    isInterstitialEnabled: boolean;
}

class InterstitialFilter extends Filter<FilterTypes.INTERSTITIAL> {
    protected readonly _type: FilterTypes.INTERSTITIAL = FilterTypes.INTERSTITIAL;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IInterstitialFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    public get isInterstitialEnabled(): boolean {
        return this.props.isInterstitialEnabled;
    }

    public set isInterstitialEnabled(value: boolean) {
        this.props.isInterstitialEnabled = value;
    }

    protected getDefaultOptions(): IInterstitialFilterProperties {
        return {
            ...super.getDefaultOptions(),
            isInterstitialEnabled: false,
        };
    }
}

export {InterstitialFilter, IInterstitialFilterProperties};
