import {NgModule} from '@angular/core';
import {AdDynamicProgressBarComponent} from './dynamic-progress-bar.component';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        AdDynamicProgressBarComponent,
    ],
    declarations: [
        AdDynamicProgressBarComponent,
    ],
    providers: [],
})
class AdDynamicProgressBarModule {}

export {AdDynamicProgressBarModule};
