import {IVideoEncoding} from '../banner';
import {Encoding} from './encoding';

class VideoEncoding extends Encoding implements IVideoEncoding {
    private _width: number;
    private _height: number;

    constructor(type: string, bitRate: number, width: number, height: number) {
        super(type, bitRate);
        this._width = width;
        this._height = height;
    }

    get width(): number {
        return this._width;
    }

    set width(value: number) {
        this._width = value;
    }

    get height(): number {
        return this._height;
    }

    set height(value: number) {
        this._height = value;
    }

    public toObject(): IVideoEncoding {
        return {
            type: this.type,
            bitRate: this.bitRate,
            width: this.width,
            height: this.height,
        };
    }
}

export {VideoEncoding};
