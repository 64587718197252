enum FilterGroup {
    ADSQUARE = 'ADSQUARE',
    AUDIENCE = 'AUDIENCE',
    GEO = 'GEO',
    IAS = 'IAS',
    MOBILE = 'MOBILE',
    ODC = 'ODC',
    OTTO_PNTA = 'OTTO_PNTA',
    PLACEMENT = 'PLACEMENT',
    TECHNICAL = 'TECHNICAL',
    VIDEO = 'VIDEO',
    WEISCHER_TRACE = 'WEISCHER_TRACE',
}

export {FilterGroup};
