import {CampaignBanner} from '../base-banner';
import {VideoBanner} from './video-banner';
import {VideoVastSettings} from '../../vast-settings/video-vast-settings';
import {VastSettingsPreset} from '../../vast-settings/vast-settings.service';
import {VastVersion} from '../../vast-versions/vast-version';
import {ICloneable, IMergable} from '../../types';
import {ICampaignBanner} from '../banner';

class CampaignVideoBanner extends VideoBanner implements
    ICampaignBanner,
    ICloneable<CampaignVideoBanner>,
    IMergable<CampaignBanner> {

    private _campaignId: number;
    private _parentBannerId: number | null = null;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        assetId: number,
        vastSettings: VideoVastSettings,
        vastTemplateId: VastSettingsPreset,
        vastVersion: VastVersion | string,
        campaignId: number,
    ) {
        super(id, networkId, name, landingPage, clickUrl, assetId, vastSettings, vastTemplateId, vastVersion);
        this._campaignId = campaignId;
    }

    get campaignId(): number {
        return this._campaignId;
    }

    set campaignId(value: number) {
        this._campaignId = value;
    }

    get parentBannerId(): number | null {
        return this._parentBannerId;
    }

    set parentBannerId(value: number | null) {
        this._parentBannerId = value;
    }

    public merge(source: CampaignBanner): void {
        super.merge(source);
        CampaignVideoBanner.fillAdditionalFields(this, source);
    }

    public clone(): CampaignVideoBanner {
        const newModel: CampaignVideoBanner = new CampaignVideoBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.clickUrl,
            this.assetId,
            this.vastSettings,
            this.vastTemplateId,
            this.vastVersion,
            this.campaignId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: CampaignVideoBanner, source: CampaignBanner): void {
        VideoBanner.fillAdditionalFields(target, source);

        target.campaignId = source.campaignId;
        target.parentBannerId = source.parentBannerId;
    }
}

export {CampaignVideoBanner};
