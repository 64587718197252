import {Component} from '@angular/core';
const browserList: string = require('raw-loader!./browserslist').default;

@Component({
    selector: 'commons-outdated-browser',
    templateUrl: './outdated-browser.html',
    styleUrls: ['./outdated-browser.scss'],
})
class CommonsOutdatedBrowserComponent {
    public browserList: string = browserList;
}

export {CommonsOutdatedBrowserComponent};
