<libs-card-widget
    [title]="title"
    [footerDescription]="getFooterDescription()"
    [isLoading]="isLoading"
    class="list-widget"
>
    <ng-container libs-card-widget-content>
        <div class="vm-flex vm-fill vm-flex-direction-column">
            <commons-list-item
                *ngFor="let entry of entries | slice:0:getLimit()"
                [borderStyle]="borderStyle"
            >
                <div
                    class="
                        vm-display-flex
                        vm-flex-direction-row
                        vm-layout-justify-start vm-layout-align-center
                        list-widget__list-content
                    "
                    [class.list-widget__list-content--has-footer]="hasFooterDescription()"
                >
                    <mat-icon
                        class="list-widget__list-icon"
                        [fontSet]="entry.listIcon.font || ''"
                    >{{entry.listIcon.name}}</mat-icon>

                    <span class="list-widget__list-title">
                        {{entry.title}}
                        <span *ngIf="entry.subtitle" class="list-widget__list-subtitle">
                            {{entry.subtitle}}
                        </span>
                    </span>

                    <div class="vm-display-flex vm-flex vm-layout-justify-end vm-layout-align-center">
                        <mat-icon
                            class="list-widget__list-status-icon"
                            [fontSet]="entry.statusIcon.icon.font || ''"
                            [color]="entry.statusIcon.iconColor"
                        >{{entry.statusIcon.icon.name}}</mat-icon>
                    </div>
                </div>
            </commons-list-item>
        </div>
    </ng-container>
</libs-card-widget>
