import {ICloneable, IMergable} from '../types';

class SegmentRelation implements ICloneable<SegmentRelation>, IMergable<SegmentRelation> {
    private readonly _id: number | null;
    private _segmentId: number;
    private _userNetworkId: number;
    private _cpm: number | null = null;
    private _cpmBilledByPartner: number | null = null;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        segmentId: number,
        userNetworkId: number,
    ) {
        this._id = id;
        this._segmentId = segmentId;
        this._userNetworkId = userNetworkId;
    }

    get id(): number | null {
        return this._id;
    }

    get cpm(): number | null {
        return this._cpm;
    }

    set cpm(value: number | null) {
        this._cpm = value;
    }

    get cpmBilledByPartner(): number | null {
        return this._cpmBilledByPartner;
    }

    set cpmBilledByPartner(value: number | null) {
        this._cpmBilledByPartner = value;
    }

    get segmentId(): number {
        return this._segmentId;
    }

    set segmentId(value: number) {
        this._segmentId = value;
    }

    get userNetworkId(): number {
        return this._userNetworkId;
    }

    set userNetworkId(value: number) {
        this._userNetworkId = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public clone(): SegmentRelation {
        const newModel: SegmentRelation = new SegmentRelation(
            this.id,
            this.segmentId,
            this.userNetworkId,
        );
        newModel.merge(this);

        return newModel;
    }

    public merge(source: SegmentRelation): void {
        this.segmentId = source.segmentId;
        this.userNetworkId = source.userNetworkId;
        this.cpm = source.cpm;
        this.cpmBilledByPartner = source.cpmBilledByPartner;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }
}

function calculateCpmBilledByPartner(cpm: number): number {
    return Math.round(cpm * 0.85);
}

export {SegmentRelation, calculateCpmBilledByPartner};
