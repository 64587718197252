import {Filter, IFilterProperties} from './filter';
import {FilterTypes} from './filter.types';

interface IContextualFilterProperties extends IFilterProperties {
    expression: string;
    referencedSegmentIds: Array<number>;
}

abstract class ContextualFilter<TFilterType extends FilterTypes = FilterTypes> extends Filter<TFilterType> {
    protected abstract props: IContextualFilterProperties;

    public get referencedSegmentIds(): Array<number> {
        return this.props.referencedSegmentIds;
    }

    public set referencedSegmentIds(value: Array<number>) {
        this.props.referencedSegmentIds = value;
    }

    public get expression(): string {
        return this.props.expression;
    }

    public set expression(value: string) {
        this.props.expression = value;
    }

    protected getDefaultOptions(): IContextualFilterProperties {
        return {
            ...super.getDefaultOptions(),
            expression: '',
            referencedSegmentIds: [],
        };
    }
}

export {ContextualFilter, IContextualFilterProperties};
