import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DateFormat, Order, CommonsError, IGenericObject, IRequestBuilderResult} from '@active-agent/types';
import {OrderApiRepresentation} from './order-api-representation';
import {format} from 'date-fns';
import {ICommonsErrorHandler} from '@active-agent/error';
import {ApiResource, DspApiGetRequestBuilder} from '@active-agent/api';

class GetOrderBuilder extends DspApiGetRequestBuilder {
    constructor(
        baseUrl: string,
        http: HttpClient,
        networkIds: Array<number>,
        private errorHandler: ICommonsErrorHandler | null,
    ) {
        super(http, networkIds, ApiResource.Orders, baseUrl);
    }

    public build(): Observable<IRequestBuilderResult<Array<Order>>> {
        return super.build()
            .pipe(
                map((result: IGenericObject): IRequestBuilderResult<Array<Order>> => {
                    return {
                        data: this.parseApiOrderObjects(result.data),
                        requestIds: result.requestIds,
                    };
                }),
            );
    }

    public fetchDeleted(): this {
        super.addQueryParam({key: 'fetchDeleted', value: true});

        return this;
    }

    public addFromDate(date: Date): this {
        this.addQueryParam({key: 'from', value: format(date, DateFormat.ApiRequestDateTime)});

        return this;
    }

    public addToDate(date: Date): this {
        this.addQueryParam({key: 'to', value: format(date, DateFormat.ApiRequestDateTime)});

        return this;
    }

    public addName(name: string): this {
        this.addQueryParam({key: 'name', value: name});

        return this;
    }

    public addAdvertiserIds(ids: Array<number>): this {
        this.addQueryParam({key: 'advertiserIds', value: ids});

        return this;
    }

    private parseApiOrderObjects(orders: Array<IGenericObject>): Array<Order> {
        return orders.reduce((orderList: Array<Order>, current: IGenericObject): Array<Order> => {
            try {
                orderList.push(OrderApiRepresentation.fromApi(current));
            } catch (error) {
                if (this.errorHandler) {
                    void this.errorHandler.handle(
                        new CommonsError('failed to parse order api object', {
                            data: {order: current},
                            cause: error,
                        }),
                        false,
                    );
                }
            }

            return orderList;
        }, []);
    }
}

export {GetOrderBuilder};
