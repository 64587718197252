import {OdcFilter, OdcFilterProperties} from '../odc-filter';
import {FilterTypes} from '../../types/filter.types';

type OdcViewabilityContextualFilterProperties = OdcFilterProperties;

class OdcViewabilityContextualFilter extends OdcFilter<FilterTypes.ODC_VIEWABILITY_CONTEXTUAL> {
    protected readonly _type: FilterTypes.ODC_VIEWABILITY_CONTEXTUAL = FilterTypes.ODC_VIEWABILITY_CONTEXTUAL;
    protected readonly props: OdcViewabilityContextualFilterProperties = this.getDefaultOptions();
}

export {OdcViewabilityContextualFilter, OdcViewabilityContextualFilterProperties};
