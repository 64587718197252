import {SwitchInXxlBanner} from './switch-in-xxl-banner';
import {BannerPoolBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {BannerFileOrUrl, IBannerPoolBanner} from '../banner';

class BannerPoolSwitchInXxlBanner extends SwitchInXxlBanner implements
    IBannerPoolBanner,
    ICloneable<BannerPoolSwitchInXxlBanner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        bottomFileOrUrl: BannerFileOrUrl,
        leftFileOrUrl: BannerFileOrUrl,
        bannerPoolId: number,
    ) {
        super(id, networkId, name, landingPage, bottomFileOrUrl, leftFileOrUrl);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolSwitchInXxlBanner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolSwitchInXxlBanner {
        let bottomFileOrUrl: BannerFileOrUrl = '';
        if (this.bottomFile) {
            bottomFileOrUrl = this.bottomFile;
        } else if (this.bottomUrl) {
            bottomFileOrUrl = this.bottomUrl;
        }
        let leftFileOrUrl: BannerFileOrUrl = '';
        if (this.leftFile) {
            leftFileOrUrl = this.leftFile;
        } else if (this.leftUrl) {
            leftFileOrUrl = this.leftUrl;
        }

        const newModel: BannerPoolSwitchInXxlBanner = new BannerPoolSwitchInXxlBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            bottomFileOrUrl,
            leftFileOrUrl,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolSwitchInXxlBanner, source: BannerPoolBanner): void {
        SwitchInXxlBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolSwitchInXxlBanner};
