import {LibsAppDataService} from '@active-agent/app-data';
import {assert} from '@active-agent/std';
import {CoreCookieService} from '@angular-clan/core';
import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';

export function LibsLogoutResolverFn(defaultRoute: string): ResolveFn<Promise<boolean>> {
    return (route: ActivatedRouteSnapshot): Promise<boolean> => {
        assert(defaultRoute, 'missing default route for logout resolver');

        inject(CoreCookieService).remove('token');
        inject(LibsAppDataService).setData({user: undefined, network: undefined});

        return inject(Router).navigate([defaultRoute], {
            queryParams: {
                redirectTo: route.queryParams.redirectTo,
            },
        });
    };
}
