import {Component, Inject, Input, Optional} from '@angular/core';
import {SERVICES_URL} from '@active-agent/types';
import {CommonModule, NgOptimizedImage} from '@angular/common';

@Component({
    selector: 'libs-favicon',
    templateUrl: './favicon.html',
    styleUrl: './favicon.scss',
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
    ],
})
class LibsFaviconComponent {
    public faviconUrl: string = !this.servicesUrl ? '' : `${this.servicesUrl}/favicon?url=no-domain`;
    @Input() public description: string = '';
    @Input() public set domain(value: string) {
        if (value) {
            this.faviconUrl = `${this.servicesUrl}/favicon?url=${value}`;
        }
    }

    constructor(
        @Optional() @Inject(SERVICES_URL) private servicesUrl: string | null,
    ) {}
}

export {LibsFaviconComponent};
