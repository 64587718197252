import {UnauthorizedRequestError} from './error';

class RequestQueue {

    private _isSynchronous: boolean = false;
    private _requestQueue: any;

    constructor() {
        this._requestQueue = Promise.resolve([]);
    }

    get isSynchronous(): boolean {
        return this._isSynchronous;
    }

    set isSynchronous(value: boolean) {
        this._isSynchronous = value;
    }

    public add(promise: any): Promise<any> {
        if (this.isSynchronous) {
            this._requestQueue = this._requestQueue
                .catch((error: any): any => {
                    /**
                     * We specifically want to catch this error. On 401 Unauthorized we want to stop the queue to
                     * not spam the api with a log of following failing requests
                     */
                    if (error instanceof UnauthorizedRequestError) {
                        throw error;
                    }

                    /**
                     * Do nothing with the previous error in the queue. You can catch it from the previous return and
                     * react on it.
                     * This one needs to be caught to be able to continue the queue.
                     */
                })
                .then((): any => {
                    return promise();
                });

            return this._requestQueue;
        }

        return promise();
    }

    public get requestQueue(): any {
        return this._requestQueue;
    }

}

export {RequestQueue};
