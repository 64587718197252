import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
    selector: 'libs-notification',
    templateUrl: 'notification.html',
    styleUrls: ['notification.scss'],
})
class LibsNotificationComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<LibsNotificationComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: INotificationData,
    ) {}
}

interface INotificationData {
    message: string;
    icon: string;
}

export {LibsNotificationComponent, INotificationData};
