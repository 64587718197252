import {Banner, BaseBanner} from '../base-banner';
import {Ssps} from '../../ssp/ssps';
import {SwitchInBannerTargetSsp} from '../switch-in/switch-in-banner';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {BannerFileOrUrl, IBannerFile, ISwitchInXxlBanner} from '../banner';
import {convertApiObjectToBase64, replaceAditionPlaceholders} from '../../file';

class SwitchInXxlBanner extends BaseBanner implements ISwitchInXxlBanner, ICloneable<SwitchInXxlBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.SWITCH_IN_XXL;
    private _ssl: boolean = false;
    private _clickUrl: string | null = null;
    private _trackingUrls: Array<string> = [];
    private _bottomFile: IBannerFile | null = null;
    private _bottomUrl: string | null = null;
    private _leftFile: IBannerFile | null = null;
    private _leftUrl: string | null = null;
    private _startBarFile: IBannerFile | null = null;
    private _startBarUrl: string | null = null;
    /**
     * @deprecated
     */
    private _targetSsp?: SwitchInBannerTargetSsp;
    private _compatibleSsps: Array<Ssps> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        bottomFileOrUrl: BannerFileOrUrl,
        leftFileOrUrl: BannerFileOrUrl,
    ) {
        super(id, networkId, name, landingPage);

        if (typeof bottomFileOrUrl === 'string') {
            this._bottomUrl = bottomFileOrUrl;
        } else {
            this._bottomFile = bottomFileOrUrl;
        }

        if (typeof leftFileOrUrl === 'string') {
            this._leftUrl = leftFileOrUrl;
        } else {
            this._leftFile = leftFileOrUrl;
        }
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string | null {
        return this._clickUrl;
    }

    set clickUrl(value: string | null) {
        this._clickUrl = value;
    }

    get trackingUrls(): Array<string> {
        return this._trackingUrls;
    }

    set trackingUrls(value: Array<string>) {
        this._trackingUrls = value;
    }

    get bottomFile(): IBannerFile | null {
        return this._bottomFile;
    }

    set bottomFile(value: IBannerFile | null) {
        this._bottomFile = value;
        this._bottomUrl = null;
    }

    get bottomUrl(): string | null {
        return this._bottomUrl;
    }

    set bottomUrl(value: string | null) {
        this._bottomUrl = value;
        this._bottomFile = null;
    }

    get leftFile(): IBannerFile | null {
        return this._leftFile;
    }

    set leftFile(value: IBannerFile | null) {
        this._leftFile = value;
        this._leftUrl = null;
    }

    get leftUrl(): string | null {
        return this._leftUrl;
    }

    set leftUrl(value: string | null) {
        this._leftUrl = value;
        this._leftFile = null;
    }

    get startBarFile(): IBannerFile | null {
        return this._startBarFile;
    }

    set startBarFile(value: IBannerFile | null) {
        this._startBarFile = value;
        this._startBarUrl = null;
    }

    get startBarUrl(): string | null {
        return this._startBarUrl;
    }

    set startBarUrl(value: string | null) {
        this._startBarUrl = value;
        this._startBarFile = null;
    }

    /**
     * @deprecated
     */
    get targetSsp(): SwitchInBannerTargetSsp | undefined {
        return this._targetSsp;
    }

    /**
     * @deprecated
     */
    set targetSsp(value: SwitchInBannerTargetSsp | undefined) {
        this._targetSsp = value;
    }

    get compatibleSsps(): Array<Ssps> {
        return this._compatibleSsps;
    }

    set compatibleSsps(value: Array<Ssps>) {
        this._compatibleSsps = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        SwitchInXxlBanner.fillAdditionalFields(this, source);
    }

    public clone(): SwitchInXxlBanner {
        let bottomFileOrUrl: BannerFileOrUrl = '';
        if (this.bottomFile) {
            bottomFileOrUrl = this.bottomFile;
        } else if (this.bottomUrl) {
            bottomFileOrUrl = this.bottomUrl;
        }
        let leftFileOrUrl: BannerFileOrUrl = '';
        if (this.leftFile) {
            leftFileOrUrl = this.leftFile;
        } else if (this.leftUrl) {
            leftFileOrUrl = this.leftUrl;
        }
        const newModel: SwitchInXxlBanner = new SwitchInXxlBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            bottomFileOrUrl,
            leftFileOrUrl,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: SwitchInXxlBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('clickUrl' in source) {
            target.clickUrl = source.clickUrl;
        }
        if ('trackingUrls' in source) {
            target.trackingUrls = source.trackingUrls;
        }
        if ('targetSsp' in source) {
            target.targetSsp = source.targetSsp;
        }
        if ('startBarFile' in source && source.startBarFile) {
            target.startBarFile = source.startBarFile;
        } else if ('startBarUrl' in source && source.startBarUrl) {
            target.startBarUrl = source.startBarUrl;
        }
        if ('bottomFile' in source && source.bottomFile) {
            target.bottomFile = source.bottomFile;
        } else if ('bottomUrl' in source && source.bottomUrl) {
            target.bottomUrl = source.bottomUrl;
        }
        if ('leftFile' in source && source.leftFile) {
            target.leftFile = source.leftFile;
        } else if ('leftUrl' in source && source.leftUrl) {
            target.leftUrl = source.leftUrl;
        }
        if ('compatibleSsps' in source && Array.isArray(source.compatibleSsps)) {
            target.compatibleSsps = source.compatibleSsps.map((ssp: Ssps) => ssp);
        }
    }

    public static getSwitchInImageSource(value: string | null | IBannerFile): string | null {
        // Image is a URL
        if (typeof value === 'string' || value === null) {
            return value;
        }
        // Newly uploaded image from the input
        if (value.data && value.type) {
            return convertApiObjectToBase64(value);
        }
        // Existing Image from the API
        if (value.path) {
            return replaceAditionPlaceholders(value.path);
        }

        return null;
    }
}

export {SwitchInXxlBanner};
