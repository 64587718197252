import {Seat} from '../seats';
import {ThirdPartyFeatureType} from '../third-party-feature';
import {IMergable} from '../types';
import {ICompany} from './company.types';

abstract class Company implements ICompany, IMergable<Company> {
    protected _isAgency: boolean = false;
    protected _isClient: boolean = false;
    private readonly _id: number | null;
    private _networkId: number;
    private _agencyIds: Array<number> = [];
    private _clientIds: Array<number> = [];
    private _advertiserMarginId: number | undefined;
    private _agencyOrderIds: Array<number> = [];
    private _clientOrderIds: Array<number> = [];
    private _bannerPoolIds: Array<number> = [];
    private _externalDealIds: Array<number> = [];
    private _name: string;
    private _domain: string;
    private _seats: Array<Seat> = [];
    private _restrictedDmpAttributeIds: Array<number> = [];
    private _restrictedTaggingAttributeIds: Array<number> = [];
    private _trackingPageIds: Array<number> = [];
    private _dsaPaid: string | null = null;
    private _dsaBehalf: string | null = null;
    private _activeThirdPartyFeatures: Array<ThirdPartyFeatureType> = [];
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        domain: string,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._name = name;
        this._domain = domain;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get agencyIds(): Array<number> {
        return this._agencyIds;
    }

    set agencyIds(value: Array<number>) {
        this._agencyIds = value;
    }

    get clientIds(): Array<number> {
        return this._clientIds;
    }

    set clientIds(value: Array<number>) {
        this._clientIds = value;
    }

    get advertiserMarginId(): number | undefined {
        return this._advertiserMarginId;
    }

    set advertiserMarginId(value: number | undefined) {
        this._advertiserMarginId = value;
    }

    get agencyOrderIds(): Array<number> {
        return this._agencyOrderIds;
    }

    set agencyOrderIds(value: Array<number>) {
        this._agencyOrderIds = value;
    }

    get clientOrderIds(): Array<number> {
        return this._clientOrderIds;
    }

    set clientOrderIds(value: Array<number>) {
        this._clientOrderIds = value;
    }

    get bannerPoolIds(): Array<number> {
        return this._bannerPoolIds;
    }

    set bannerPoolIds(value: Array<number>) {
        this._bannerPoolIds = value;
    }

    get externalDealIds(): Array<number> {
        return this._externalDealIds;
    }

    set externalDealIds(value: Array<number>) {
        this._externalDealIds = value;
    }

    get isAgency(): boolean {
        return this._isAgency;
    }

    get isClient(): boolean {
        return this._isClient;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get domain(): string {
        return this._domain;
    }

    set domain(value: string) {
        this._domain = value;
    }

    get seats(): Array<Seat> {
        return this._seats;
    }

    set seats(value: Array<Seat>) {
        this._seats = value;
    }

    get restrictedDmpAttributeIds(): Array<number> {
        return this._restrictedDmpAttributeIds;
    }

    set restrictedDmpAttributeIds(value: Array<number>) {
        this._restrictedDmpAttributeIds = value;
    }

    get restrictedTaggingAttributeIds(): Array<number> {
        return this._restrictedTaggingAttributeIds;
    }

    set restrictedTaggingAttributeIds(value: Array<number>) {
        this._restrictedTaggingAttributeIds = value;
    }

    get trackingPageIds(): Array<number> {
        return this._trackingPageIds;
    }

    set trackingPageIds(value: Array<number>) {
        this._trackingPageIds = value;
    }

    get dsaPaid(): string | null {
        return this._dsaPaid;
    }

    set dsaPaid(value: string | null) {
        this._dsaPaid = value;
    }

    get dsaBehalf(): string | null {
        return this._dsaBehalf;
    }

    set dsaBehalf(value: string | null) {
        this._dsaBehalf = value;
    }

    get activeThirdPartyFeatures(): Array<ThirdPartyFeatureType> {
        return this._activeThirdPartyFeatures;
    }

    set activeThirdPartyFeatures(thirdPartyFeatures: Array<ThirdPartyFeatureType>) {
        this._activeThirdPartyFeatures = thirdPartyFeatures;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: Company): void {
        this.networkId = source.networkId;
        this.agencyIds = source.agencyIds.map((current: number) => current);
        this.clientIds = source.clientIds.map((current: number) => current);
        this.advertiserMarginId = source.advertiserMarginId;
        this.agencyOrderIds = source.agencyOrderIds.map((current: number) => current);
        this.clientOrderIds = source.clientOrderIds.map((current: number) => current);
        this.bannerPoolIds = source.bannerPoolIds.map((current: number) => current);
        this.externalDealIds = source.externalDealIds.map((current: number) => current);
        this.name = source.name;
        this.domain = source.domain;
        this.seats = source.seats.map((seat: Seat) => {
            return seat.clone();
        });
        this.restrictedDmpAttributeIds = source.restrictedDmpAttributeIds.map((current: number) => current);
        this.restrictedTaggingAttributeIds = source.restrictedTaggingAttributeIds.map((current: number) => current);
        this.trackingPageIds = source.trackingPageIds.map((current: number) => current);
        this.dsaPaid = source.dsaPaid;
        this.dsaBehalf = source.dsaBehalf;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
        this.activeThirdPartyFeatures = [...source.activeThirdPartyFeatures];
    }
}

export {Company};
