import {ChangeDetectionStrategy, Component, input, InputSignal} from '@angular/core';
import {CommonModule, CurrencyPipe, NgClass} from '@angular/common';
import {TransformHelper} from '@active-agent/std';

@Component({
    selector: 'libs-filter-price',
    templateUrl: './filter-price.html',
    styleUrl: './filter-price.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        CommonModule,
    ],
    providers: [
        CurrencyPipe,
    ],
})
class LibsFilterPriceComponent {
    public price: InputSignal<number> = input(0);
    public disabled: InputSignal<boolean> = input(false);
    public prefix: InputSignal<string> = input('');
    public styles: InputSignal<Partial<Record<string, string>>> = input<Partial<Record<string, string>>>({});

    constructor(
        private currencyPipe: CurrencyPipe,
    ) {}

    public transformPrice(): string {
        return this.currencyPipe.transform(TransformHelper.centToEuro(this.price()), 'EUR') || '';
    }
}

export {LibsFilterPriceComponent};
