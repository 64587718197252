import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {parseISO} from 'date-fns';
import {getTimeZonedMaxDate, getTimeZonedMinDate} from '@active-agent/std';

@Injectable({
    providedIn: 'root',
})
class StartTimeValidator {
    public static readonly minDate: Date = getTimeZonedMinDate();
    public static readonly maxDate: Date = getTimeZonedMaxDate();

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const startTime: string = control.value;

        if (startTime === undefined || startTime === null) {
            return {[StartTimeErrorCodes.Required]: this.getTranslationForErrorCode(StartTimeErrorCodes.Required)};
        }

        const date: Date = parseISO(startTime);
        if (isNaN(date.getTime())) {
            return {[StartTimeErrorCodes.InvalidFormat]: this.getTranslationForErrorCode(StartTimeErrorCodes.InvalidFormat)};
        }

        if (date.valueOf() < StartTimeValidator.minDate.valueOf()) {
            return {[StartTimeErrorCodes.ExceedMinRange]: this.getTranslationForErrorCode(StartTimeErrorCodes.ExceedMinRange)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: StartTimeErrorCodes): string {
        switch (code) {
            case StartTimeErrorCodes.Required:
                return $localize`:@@VALIDATOR_START_TIME_REQUIRED_ERROR_MESSAGE:VALIDATOR_START_TIME_REQUIRED_ERROR_MESSAGE`;
            case StartTimeErrorCodes.InvalidFormat:
                return $localize`:@@VALIDATOR_START_TIME_INVALID_FORMAT_ERROR_MESSAGE:VALIDATOR_START_TIME_INVALID_FORMAT_ERROR_MESSAGE`;
            case StartTimeErrorCodes.ExceedMinRange:
                return $localize`:@@VALIDATOR_START_TIME_EXCEED_MIN_RANGE_ERROR_MESSAGE:VALIDATOR_START_TIME_EXCEED_MIN_RANGE_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum StartTimeErrorCodes {
    Required = '537c313e-ec54-449f-92da-88cfce5c29b9',
    InvalidFormat = '85ce99f3-7d02-4c28-a761-ddd2e6bbc3e6',
    ExceedMinRange = 'ae877efa-52c3-4490-bf5c-45bd74b6024b',
}

export {StartTimeValidator};
