import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'ad-cropped-preview-dialog',
    templateUrl: 'cropped-preview-dialog.html',
    styleUrls: ['./cropped-preview-dialog.scss'],
})
class AdCroppedPreviewDialogComponent {
    public image: string | null = null;
    constructor(
        private dialogRef: MatDialogRef<AdCroppedPreviewDialogComponent, Array<any>>,
        @Inject(MAT_DIALOG_DATA) private data: ICroppedPreviewDialogData,
    ) {
        this.dialogRef.addPanelClass(['dialog-container']);
        this.dialogRef.backdropClick().subscribe(() => {
            this.close();
        });
        this.image = this.data.image;
    }

    public close(): void {
        this.dialogRef.close();
    }
}

interface ICroppedPreviewDialogData {
    image: string;
}

export {AdCroppedPreviewDialogComponent, ICroppedPreviewDialogData};
