import {
    CommonsSegment,
    ISegmentExpression,
    ISegmentExpressionBoolExpression,
    ISegmentExpressionPartOperandChild,
    ISegmentExpressionPartOperandNegation,
    ISegmentExpressionPartOperandVariable,
    SegmentExpressionOperator,
} from '../types';
import {CommonsError} from '../../error';

class SegmentHelper {
    public static getExternalSegmentIdsFromExpression(
        expression: ISegmentExpression,
        validExpressionOperator: SegmentExpressionOperator | undefined = undefined,
    ): Array<string> {
        switch (expression.type) {
            case 'bool_expression':
                const boolExpression: ISegmentExpressionBoolExpression = expression as ISegmentExpressionBoolExpression;

                if (validExpressionOperator !== undefined && boolExpression.operator !== validExpressionOperator) {
                    throw new CommonsError(
                        'Invalid filter expression operator',
                        {data: {expression, validExpressionOperator}},
                    );
                }

                return [
                    ...this.getExternalSegmentIdsFromExpression(boolExpression.left, validExpressionOperator),
                    ...this.getExternalSegmentIdsFromExpression(boolExpression.right, validExpressionOperator),
                ];

            case 'group':
                const operandChild: ISegmentExpressionPartOperandChild = expression as ISegmentExpressionPartOperandChild;

                return [...this.getExternalSegmentIdsFromExpression(operandChild.child, validExpressionOperator)];

            case 'variable':
                const variable: ISegmentExpressionPartOperandVariable = expression as ISegmentExpressionPartOperandVariable;

                return [variable.value];

            case 'negation':
                const operandNegation: ISegmentExpressionPartOperandNegation = expression as ISegmentExpressionPartOperandNegation;

                return this.getExternalSegmentIdsFromExpression(operandNegation.right, validExpressionOperator);

            default:
                throw new CommonsError('unknown expression type', {data: {expression}});
        }
    }

    public static compareExternalSegmentIdsWithSegments(externalSegmentIds: Array<string>, segments: Array<CommonsSegment>): boolean {
        const availableSegments: Array<CommonsSegment> = this.findSegmentsFromExternalSegmentIds(externalSegmentIds, segments);

        // If there is not a segment for each available segment id, the filter contains deleted/invalid segment ids
        return availableSegments.length === externalSegmentIds.length;
    }

    public static findSegmentsFromExternalSegmentIds(externalSegmentIds: Array<string>, segments: Array<CommonsSegment>):
    Array<CommonsSegment> {
        return segments
            .filter((segment: CommonsSegment): boolean => externalSegmentIds.includes(segment.externalSegmentId))
            .sort((a: CommonsSegment, b: CommonsSegment) => a.name.localeCompare(b.name));
    }
}

export {SegmentHelper};
