import {Component, InputSignal, input} from '@angular/core';
import {LibsCardComponent} from '../../card/card.component';
import {CommonModule} from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
    selector: 'libs-card-widget',
    templateUrl: './card-widget.html',
    styleUrl: './card-widget.scss',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        LibsCardComponent,
    ],
})
class LibsCardWidgetComponent {
    public title: InputSignal<string | null> = input<string | null>(null);
    public footerDescription: InputSignal<string | null> = input<string | null>(null);
    public isLoading: InputSignal<boolean> = input<boolean>(false);
}

export {LibsCardWidgetComponent};
