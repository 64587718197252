import {CommonsError} from '../../error/error';
import {CommonsSegment} from '../types/segment';
import {
    ICustomSiteContextualValues,
    ICustomSiteContextualViewPartnerGroup,
    ICustomSiteContextualViewValue,
    ISegmentExpression,
    ISegmentExpressionBoolExpression,
    ISegmentExpressionPartOperandChild,
    ISegmentExpressionPartOperandNegation,
    ISegmentExpressionPartOperandVariable,
    SegmentExpressionOperator,
} from '../types/targeting-segment';

class CustomSiteContextualHelper {
    public static getCustomSiteContextualValues(expression: ISegmentExpression): ICustomSiteContextualValues {
        switch (expression.type) {
            case 'negation':
                const operandNegation: ISegmentExpressionPartOperandNegation = expression as ISegmentExpressionPartOperandNegation;
                const childExpressionResult: ICustomSiteContextualValues = this.getCustomSiteContextualValues(operandNegation.right);

                return {
                    negated: true,
                    operator: childExpressionResult.operator,
                    externalSegmentIds: childExpressionResult.externalSegmentIds,
                };

            case 'bool_expression':
                const boolExpression: ISegmentExpressionBoolExpression = expression as ISegmentExpressionBoolExpression;
                const leftExpressionResult: ICustomSiteContextualValues = this.getCustomSiteContextualValues(boolExpression.left);
                const rightExpressionResult: ICustomSiteContextualValues = this.getCustomSiteContextualValues(boolExpression.right);

                if (
                    (rightExpressionResult.operator !== undefined && boolExpression.operator !== rightExpressionResult.operator) ||
                    (leftExpressionResult.operator !== undefined && boolExpression.operator  !== leftExpressionResult.operator)
                ) {
                    throw new CommonsError(
                        'the filter expression has mixed operators',
                        {data: {expression}},
                    );
                }

                return {
                    operator: boolExpression.operator,
                    externalSegmentIds: leftExpressionResult.externalSegmentIds.concat(rightExpressionResult.externalSegmentIds),
                };

            case 'group':
                const operandChild: ISegmentExpressionPartOperandChild = expression as ISegmentExpressionPartOperandChild;

                return this.getCustomSiteContextualValues(operandChild.child);

            case 'variable':
                const variable: ISegmentExpressionPartOperandVariable = expression as ISegmentExpressionPartOperandVariable;

                return {
                    externalSegmentIds: [variable.value],
                };

            default:
                throw new CommonsError(
                    'unknown expression type',
                    {data: {expression}},
                );
        }
    }

    public static getPartnerFromExternalSegmentId(externalSegmentId: string): string {
        return externalSegmentId.split('-')[0];
    }

    public static getCustomSiteContextualPartnerGroups(
        segments: Array<CommonsSegment>,
        activeExternalSegmentIds: Array<string>,
    ): Array<ICustomSiteContextualViewPartnerGroup> {

        const partnerGroups: Map<string, ICustomSiteContextualViewPartnerGroup> = new Map();
        segments
            .sort((a: CommonsSegment, b: CommonsSegment) => {
                return a.name.localeCompare(b.name);
            })
            .forEach((segment: CommonsSegment) => {
                const partner: string = CustomSiteContextualHelper.getPartnerFromExternalSegmentId(segment.externalSegmentId);

                let partnerGroup: ICustomSiteContextualViewPartnerGroup | undefined = partnerGroups.get(partner);
                if (partnerGroup === undefined) {
                    partnerGroup = {
                        name: partner,
                        expanded: false,
                        items: [],
                        disabled: false,
                    };
                    partnerGroups.set(partner, partnerGroup);
                }

                partnerGroup.items.push({
                    id: segment.id || 0,
                    name: segment.name,
                    active: activeExternalSegmentIds.includes(segment.externalSegmentId),
                    cpm: segment.cpm,
                    externalSegmentId: segment.externalSegmentId,
                });
            });

        return Array.from(partnerGroups.values());
    }

    public static getPartnerGroupActiveSegmentsCount(partnerGroup: ICustomSiteContextualViewPartnerGroup): number {
        return partnerGroup.items.reduce((result: number, item: ICustomSiteContextualViewValue) => {
            result += item.active ? 1 : 0;

            return result;
        }, 0);
    }

    public static getCustomSiteContextualExpression(
        externalSegmentIds: Array<string>,
        operator: SegmentExpressionOperator,
        negated: boolean,
    ): string {
        let expression: string = externalSegmentIds.join(` ${operator} `);

        if (negated) {
            expression = `NOT(${expression})`;
        }

        return expression;
    }
}

export {CustomSiteContextualHelper};
