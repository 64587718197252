import {of, Observable} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IPlatformFilterProperties extends IFilterProperties {
    profile: string;
}

class PlatformFilter extends Filter<FilterTypes.PLATFORM> {
    protected readonly _type: FilterTypes.PLATFORM = FilterTypes.PLATFORM;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IPlatformFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        if (!this.profile.length) {
            return of(false);
        }

        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    protected getDefaultOptions(): IPlatformFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

export {PlatformFilter, IPlatformFilterProperties};
