import {
    endOfDay,
    endOfMonth,
    startOfDay,
    startOfMonth,
    subDays,
    subMonths,
} from 'date-fns';
import {getTimeZonedNewDate} from '../timezone/time-zone-store';

function getDefaultPresets(): Array<IDateRangePickerPreset> {
    return [{
        name: $localize`:@@DATE_RANGE_PICKER_TODAY_PRESET_LABEL:DATE_RANGE_PICKER_TODAY_PRESET_LABEL`,
        startDate: startOfDay(getTimeZonedNewDate()),
        endDate: endOfDay(getTimeZonedNewDate()),
    }, {
        name: $localize`:@@DATE_RANGE_PICKER_YESTERDAY_PRESET_LABEL:DATE_RANGE_PICKER_YESTERDAY_PRESET_LABEL`,
        startDate: startOfDay(subDays(getTimeZonedNewDate(), 1)),
        endDate: endOfDay(subDays(getTimeZonedNewDate(), 1)),
    }, {
        name: $localize`:@@DATE_RANGE_PICKER_LAST_7_DAYS_PRESET_LABEL:DATE_RANGE_PICKER_LAST_7_DAYS_PRESET_LABEL`,
        startDate: startOfDay(subDays(getTimeZonedNewDate(), 6)),
        endDate: endOfDay(getTimeZonedNewDate()),
    }, {
        name: $localize`:@@DATE_RANGE_PICKER_LAST_30_DAYS_PRESET_LABEL:DATE_RANGE_PICKER_LAST_30_DAYS_PRESET_LABEL`,
        startDate: startOfDay(subDays(getTimeZonedNewDate(), 30)),
        endDate: endOfDay(getTimeZonedNewDate()),
    }, {
        name: $localize`:@@DATE_RANGE_PICKER_CURRENT_MONTH_PRESET_LABEL:DATE_RANGE_PICKER_CURRENT_MONTH_PRESET_LABEL`,
        startDate: startOfMonth(getTimeZonedNewDate()),
        endDate: endOfDay(getTimeZonedNewDate()),
    }, {
        name: $localize`:@@DATE_RANGE_PICKER_LAST_MONTH_PRESET_LABEL:DATE_RANGE_PICKER_LAST_MONTH_PRESET_LABEL`,
        startDate: startOfMonth(subMonths(getTimeZonedNewDate(), 1)),
        endDate: endOfMonth(subMonths(getTimeZonedNewDate(), 1)),
    }];
}

type DateRangePickerPresetState = Record<string, unknown> | void;
interface IDateRangePickerPreset<S extends DateRangePickerPresetState = void> {
    name: string;
    startDate: Date;
    endDate: Date;
    state?: S;
}

export {getDefaultPresets, DateRangePickerPresetState, IDateRangePickerPreset};
