import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdSelectListItemComponent} from './select-list-item.component';
import {MatSelectModule} from '@angular/material/select';
import {CommonsTextListItemModule} from '../text-list-item/text-list-item.module';

@NgModule({
    imports: [
        CommonModule,
        MatSelectModule,
        CommonsTextListItemModule,
    ],
    exports: [
        AdSelectListItemComponent,
    ],
    declarations: [
        AdSelectListItemComponent,
    ],
})
class AdSelectListItemModule {}

export {AdSelectListItemModule};
