import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import {CommonsSegment} from '@active-agent/types';
import {Subject} from 'rxjs';
import {CurrencyPipe} from '@angular/common';
import {TransformHelper} from '@active-agent/std';

@Component({
    selector: 'libs-segment-list',
    templateUrl: './segment-list.html',
    styleUrls: ['./segment-list.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
class LibsSegmentListComponent implements OnInit, OnChanges {
    @Input() public segments: Array<CommonsSegment> = [];
    @Input() public isDisabled: boolean = false;
    @Input() public isReadOnly: boolean = false;
    @Input() public isSelectable: boolean = true;
    @Input() public showSearchToolbar: boolean = true;
    @Input() public activeExternalSegmentIdsSubject: Subject<Set<string>> = new Subject();
    public queryString: string = '';
    public filteredSegments: Array<CommonsSegment> = [];
    private activeExternalSegmentIds: Set<string> = new Set();
    private readonly virtualScrollThreshold: number = 6;

    constructor(private detectorRef: ChangeDetectorRef, private currencyPipe: CurrencyPipe){}

    public ngOnInit(): void {
        this.filteredSegments = this.segments;

        this.activeExternalSegmentIdsSubject.subscribe((activeExternalSegmentIds: Set<string>) => {
            this.activeExternalSegmentIds = activeExternalSegmentIds;
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.options || changes.options.currentValue === undefined) {
            return;
        }
        this.filteredSegments = this.segments;
        this.detectorRef.detectChanges();
    }

    public onSearchChange(query: string): void {
        this.queryString = query;

        if (query) {
            this.filteredSegments = this.segments.filter((segment: CommonsSegment) =>{
                return this.queryString.length ? segment.name.toLowerCase().includes(this.queryString.toLowerCase()) : true;
            });
        } else {
            this.filteredSegments = this.segments;
        }

        this.detectorRef.detectChanges();
    }

    public isSegmentActive(segment: CommonsSegment): boolean {
        return isSegmentActive(segment.externalSegmentId, this.activeExternalSegmentIds);
    }

    public onSwitchChange(segment: CommonsSegment, isActive: boolean): void {
        if (isActive) {
            this.activeExternalSegmentIds.add(segment.externalSegmentId);
        } else {
            this.activeExternalSegmentIds.delete(segment.externalSegmentId);
        }

        this.activeExternalSegmentIdsSubject.next(this.activeExternalSegmentIds);
        this.detectorRef.detectChanges();
    }

    public useVirtualScroll(): boolean {
        return this.segments.length > this.virtualScrollThreshold;
    }

    public cpmToCurrency(cpm: number | undefined): string {
        if (!cpm) {
            return '';
        }

        return this.currencyPipe.transform(TransformHelper.centToEuro(cpm), 'EUR') || '';
    }
}

function isSegmentActive(externalSegmentId: string, activeExternalSegmentIds: Set<string>): boolean {
    return activeExternalSegmentIds.has(externalSegmentId);
}

export {LibsSegmentListComponent, isSegmentActive};
