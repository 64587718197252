enum AppPath {
    Login = 'login',
    Logout = 'logout',
    Dashboard = 'dashboard',
    Search = 'search',
    Account = 'account',
    Advertisers = 'advertisers',
    Advertiser = 'advertiser',
    CreateAdvertiser = 'create-advertiser',
    TargetingAttributes = 'targeting-attributes',
    TargetingAttribute = 'targeting-attribute',
    CreateAttribute = 'create-attribute',
    Permissions = 'permissions',
    Orders = 'orders',
    Order = 'order',
    CreateOrder = 'create-order',
    CreateCampaign = 'create-campaign',
    Campaigns = 'campaigns',
    Campaign = 'campaign',
    Seats = 'seats',
    List = 'list',
    Create = 'create',
    Settings = 'settings',
    Reporting = 'reporting',
    Inventory = 'inventory',
    BidFactors = 'bid-factors',
    TrackingPages = 'tracking-pages',
    TrackingPage = 'tracking-page',
    RedirectTag = 'redirect-tag',
    CreateTrackingPage = 'create-tracking-page',
    CreateBanner = 'create-banner',
    TrackingSpots = 'tracking-spots',
    TrackingSpot = 'tracking-spot',
    FootfallStore = 'footfall-store',
    FootfallScreen = 'footfall-screen',
    Banners = 'banners',
    Copy = 'copy',
    Banner = 'banner',
    BannerPreview = 'banner-preview',
    BannerPool = 'banner-pool',
    BannerPools = 'banner-pools',
    UploadBanners = 'upload-banners',
    CreateBannerPool = 'create-banner-pool',
    DealManagement = 'deal-management',
    CreateDeal = 'create-deal',
    UploadDeal = 'upload-deal',
    TargetingPeculiarities = 'targeting-peculiarities',
    TargetingPeculiarity = 'targeting-peculiarity',
    Targeting = 'targeting',
    CampaignControl = 'campaigncontrol',
    Status = 'status',
    VastWrapper = 'vast-wrapper',
    CreateTargetingPeculiarity = 'create-targeting-peculiarity',
    ThirdPartyCost = 'third-party-cost',
    Deals = 'deals',
    Add = 'add',
    Links = 'links',
    CreateTrackingSpot = 'create-tracking-spot',
    BulkEdit = 'bulk-edit',
    General = 'general',
    Runtime = 'runtime',
    Budget = 'budget',
    Pacing = 'pacing',
    Optimization = 'optimization',
    Deal = 'deal',
    Result = 'result',
    Filter = 'filter',
    PreHtmlCode = 'pre-html-code',
    PostHtmlCode = 'post-html-code',
    Footfall = 'footfall',
    Templates = 'templates',
    AutoReport = 'autoreport',
    AutoReports = 'autoreports',
    CreateAutoReport = 'create-autoreport',
    /**
     * @deprecated This is just for a redirect in the reporting page
     * The menu has problems if the urls of the side navigation match for multiple
     * similar urls, e.g. /reporting and /reporting/templates.
     */
    ReportingConfiguration = 'configuration',
    Viewability = 'viewability',
    Manual = 'manual',
    RedirectById = 'redirect-by-id',
    Glossary = 'glossary',
}

export {AppPath};
