import {FilterTypes} from '../types/filter.types';
import {OttoPntaFilter, IOttoPntaFilterProperties} from './otto-pnta-filter';

type OttoPntaSozioFilterProperties = IOttoPntaFilterProperties;

class OttoPntaSozioFilter extends OttoPntaFilter<FilterTypes.OTTO_PNTA_SOZIO> {
    protected readonly _type: FilterTypes.OTTO_PNTA_SOZIO = FilterTypes.OTTO_PNTA_SOZIO;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly props: OttoPntaSozioFilterProperties = this.getDefaultOptions();
}

export {OttoPntaSozioFilter, OttoPntaSozioFilterProperties};
