import {DoohLocationFileType} from './dooh-location-file-type';
import {IDoohLocation} from './dooh-location.types';
import {ICloneable, IMergable} from '../../types';
import {Ssps} from '../../ssp';
import {DoohLocationCountryCode} from './dooh-location-country-code';

class DoohLocation implements IDoohLocation, IMergable<DoohLocation>, ICloneable<DoohLocation> {
    private readonly _id: number;
    private _externalLocationId: string;
    private _partnerId: number;
    private _latitude: number;
    private _longitude: number;
    private _name: string;
    private _publisher: string;
    private _ssp: Ssps;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;
    private _address: string | null = null;
    private _allowedDealType: string | null = null;
    private _allowedFileType: DoohLocationFileType | null = null;
    private _city: string | null = null;
    private _countryCode: DoohLocationCountryCode | null = null;
    private _dailyImpressions: number | null = null;
    private _isAudioAvailable: boolean = false;
    private _maxDuration: number | null = null;
    private _minDuration: number | null = null;
    private _monthlyImpressions: number | null = null;
    private _network: string | null = null;
    private _operationHours: string | null = null;
    private _postCode: string | null = null;
    private _referrer: string | null = null;
    private _screenCount: number | null = null;
    private _screenHeight: number | null = null;
    private _screenWidth: number | null = null;
    private _specialInfo: string | null = null;
    private _state: string | null = null;
    private _venueType: string | null = null;
    private _weeklyImpressions: number | null = null;

    constructor(
        id: number,
        externalLocationId: string,
        partnerId: number,
        latitude: number,
        longitude: number,
        name: string,
        publisher: string,
        ssp: Ssps,
    ) {
        this._id = id;
        this._externalLocationId = externalLocationId;
        this._partnerId = partnerId;
        this._latitude = latitude;
        this._longitude = longitude;
        this._name = name;
        this._publisher = publisher;
        this._ssp = ssp;
    }

    get id(): number {
        return this._id;
    }

    get externalLocationId(): string {
        return this._externalLocationId;
    }

    set externalLocationId(value: string) {
        this._externalLocationId = value;
    }

    get partnerId(): number {
        return this._partnerId;
    }

    set partnerId(value: number) {
        this._partnerId = value;
    }

    get latitude(): number {
        return this._latitude;
    }

    set latitude(value: number) {
        this._latitude = value;
    }

    get longitude(): number {
        return this._longitude;
    }

    set longitude(value: number) {
        this._longitude = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get publisher(): string {
        return this._publisher;
    }

    set publisher(value: string) {
        this._publisher = value;
    }

    get ssp(): Ssps {
        return this._ssp;
    }

    set ssp(value: Ssps) {
        this._ssp = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get address(): string | null {
        return this._address;
    }

    set address(value: string | null) {
        this._address = value;
    }

    get allowedDealType(): string | null {
        return this._allowedDealType;
    }

    set allowedDealType(value: string | null) {
        this._allowedDealType = value;
    }

    get allowedFileType(): DoohLocationFileType | null {
        return this._allowedFileType;
    }

    set allowedFileType(value: DoohLocationFileType | null) {
        this._allowedFileType = value;
    }

    get city(): string | null {
        return this._city;
    }

    set city(value: string | null) {
        this._city = value;
    }

    get countryCode(): DoohLocationCountryCode | null {
        return this._countryCode;
    }

    set countryCode(value: DoohLocationCountryCode | null) {
        this._countryCode = value;
    }

    get dailyImpressions(): number | null {
        return this._dailyImpressions;
    }

    set dailyImpressions(value: number | null) {
        this._dailyImpressions = value;
    }

    get isAudioAvailable(): boolean {
        return this._isAudioAvailable;
    }

    set isAudioAvailable(value: boolean) {
        this._isAudioAvailable = value;
    }

    get maxDuration(): number | null {
        return this._maxDuration;
    }

    set maxDuration(value: number | null) {
        this._maxDuration = value;
    }

    get minDuration(): number | null {
        return this._minDuration;
    }

    set minDuration(value: number | null) {
        this._minDuration = value;
    }

    get monthlyImpressions(): number | null {
        return this._monthlyImpressions;
    }

    set monthlyImpressions(value: number | null) {
        this._monthlyImpressions = value;
    }

    get network(): string | null {
        return this._network;
    }

    set network(value: string | null) {
        this._network = value;
    }

    get operationHours(): string | null {
        return this._operationHours;
    }

    set operationHours(value: string | null) {
        this._operationHours = value;
    }

    get postCode(): string | null {
        return this._postCode;
    }

    set postCode(value: string | null) {
        this._postCode = value;
    }

    get referrer(): string | null {
        return this._referrer;
    }

    set referrer(value: string | null) {
        this._referrer = value;
    }

    get screenCount(): number | null {
        return this._screenCount;
    }

    set screenCount(value: number | null) {
        this._screenCount = value;
    }

    get screenHeight(): number | null {
        return this._screenHeight;
    }

    set screenHeight(value: number | null) {
        this._screenHeight = value;
    }

    get screenWidth(): number | null {
        return this._screenWidth;
    }

    set screenWidth(value: number | null) {
        this._screenWidth = value;
    }

    get specialInfo(): string | null {
        return this._specialInfo;
    }

    set specialInfo(value: string | null) {
        this._specialInfo = value;
    }

    get state(): string | null {
        return this._state;
    }

    set state(value: string | null) {
        this._state = value;
    }

    get venueType(): string | null {
        return this._venueType;
    }

    set venueType(value: string | null) {
        this._venueType = value;
    }

    get weeklyImpressions(): number | null {
        return this._weeklyImpressions;
    }

    set weeklyImpressions(value: number | null) {
        this._weeklyImpressions = value;
    }

    public merge(source: this): void {
        this.externalLocationId = source.externalLocationId;
        this.partnerId = source.partnerId;
        this.latitude = source.latitude;
        this.longitude = source.longitude;
        this.name = source.name;
        this.publisher = source.publisher;
        this.ssp = source.ssp;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
        this.address = source.address;
        this.allowedDealType = source.allowedDealType;
        this.allowedFileType = source.allowedFileType;
        this.city = source.city;
        this.countryCode = source.countryCode;
        this.dailyImpressions = source.dailyImpressions;
        this.isAudioAvailable = source.isAudioAvailable;
        this.maxDuration = source.maxDuration;
        this.minDuration = source.minDuration;
        this.monthlyImpressions = source.monthlyImpressions;
        this.network = source.network;
        this.operationHours = source.operationHours;
        this.postCode = source.postCode;
        this.referrer = source.referrer;
        this.screenCount = source.screenCount;
        this.screenHeight = source.screenHeight;
        this.screenWidth = source.screenWidth;
        this.specialInfo = source.specialInfo;
        this.state = source.state;
        this.venueType = source.venueType;
        this.weeklyImpressions = source.weeklyImpressions;
    }

    public clone(): DoohLocation {
        const newModel: DoohLocation = new DoohLocation(
            this.id,
            this.externalLocationId,
            this.partnerId,
            this.latitude,
            this.longitude,
            this.name,
            this.publisher,
            this.ssp,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {DoohLocation};
