<div class="segment-list">
    <core-search-toolbar
        *ngIf="showSearchToolbar && segments.length"
        (onChange)="onSearchChange($event)"
        [alwaysOpen]="true"
    ></core-search-toolbar>

    <div class="segment-list__content">
        <!-- Uses normal loop instead of virtual scroll if size of segments is smaller than the threshold to avoid whitespace -->
        <ng-template [ngIf]="!useVirtualScroll() && filteredSegments.length">
            <ng-container
                *ngFor="let segment of filteredSegments"
                [ngTemplateOutlet]="switchListItemTemplate"
                [ngTemplateOutletContext]="{segment: segment}"
            >
            </ng-container>
        </ng-template>

        <!-- Virtual Scroll -->
        <cdk-virtual-scroll-viewport
            *ngIf="useVirtualScroll() && filteredSegments.length"
            itemSize="50"
            minBufferPx="400"
            maxBufferPx="800"
            class="segment-list__segments-container"
        >
            <!-- Uses virtual scroll if size of segments is greater than the threshold -->
            <!-- Virtual Scroll Item -->
            <ng-container
                *cdkVirtualFor="let segment of filteredSegments"
                [ngTemplateOutlet]="switchListItemTemplate"
                [ngTemplateOutletContext]="{segment: segment}"
            >
            </ng-container>
        </cdk-virtual-scroll-viewport>

        <!-- No entries found -->
        <ad-no-items-available-item
            *ngIf="!queryString.length && !this.filteredSegments.length"
            message="NO_ENTRIES_FOUND"
            i18n-message
        ></ad-no-items-available-item>

        <!-- No items match search query -->
        <ad-no-items-available-item
            *ngIf="queryString.length && !this.filteredSegments.length"
            message="NO_ITEMS_MATCH_SEARCH_QUERY_LIST_ITEM_TEXT"
            i18n-message
        ></ad-no-items-available-item>
    </div>
</div>

<ng-template #switchListItemTemplate let-segment="segment">
    <div class="vm-flex-direction-row">
        <commons-switch-list-item
            class="vm-flex"
            [isActive]="isSegmentActive(segment)"
            [isDisabled]="isDisabled || isReadOnly"
            [isSelectable]="isSelectable"
            [title]="segment.name"
            [subtitle]="segment.description"
            [label]="cpmToCurrency(segment.cpm)"
            (onChange)="onSwitchChange(segment, $event)"
        ></commons-switch-list-item>
    </div>
</ng-template>
