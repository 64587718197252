import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[libsInstanceControl]',
    standalone: true,
    exportAs: 'instanceControl',
})
class LibsInstanceControlDirective {
    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
    ) {
        this.create();
    }

    public reinstance(): void {
        this.clear();
        this.create();
    }

    private clear(): void {
        this.viewContainer.clear();
    }

    private create(): void {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
}

export {LibsInstanceControlDirective};
