import {Banner, BaseBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {BannerTypes} from '../banner-types';
import {IBumperAdBanner} from '../banner';
import {Ssps} from '../../ssp/ssps';

class BumperAdBanner extends BaseBanner implements IBumperAdBanner, ICloneable<BumperAdBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.BUMPER_AD;
    private _ssl: boolean = false;
    private _clickUrl: string | null = null;
    private _trackingUrls: Array<string> = [];
    private _bumperAdType: BumperAdTypes = BumperAdTypes.SWITCH_IN_BUMPER;
    private _videoUrl: string = '';
    private _compatibleSsps: Array<Ssps> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
    ) {
        super(id, networkId, name, landingPage);
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string | null {
        return this._clickUrl;
    }

    set clickUrl(value: string | null) {
        this._clickUrl = value;
    }

    get trackingUrls(): Array<string> {
        return this._trackingUrls;
    }

    set trackingUrls(value: Array<string>) {
        this._trackingUrls = value;
    }

    get bumperAdType(): BumperAdTypes {
        return this._bumperAdType;
    }

    set bumperAdType(value: BumperAdTypes) {
        this._bumperAdType = value;
    }

    get videoUrl(): string {
        return this._videoUrl;
    }

    set videoUrl(value: string) {
        this._videoUrl = value;
    }

    get compatibleSsps(): Array<Ssps> {
        return this._compatibleSsps;
    }

    set compatibleSsps(value: Array<Ssps>) {
        this._compatibleSsps = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        BumperAdBanner.fillAdditionalFields(this, source);
    }

    public clone(): BumperAdBanner {
        const newModel: BumperAdBanner = new BumperAdBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BumperAdBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('trackingUrls' in source && source.trackingUrls !== null) {
            target.trackingUrls = source.trackingUrls;
        }
        if ('bumperAdType' in source && source.bumperAdType !== null) {
            target.bumperAdType = source.bumperAdType;
        }
        if ('videoUrl' in source && source.videoUrl !== null) {
            target.videoUrl = source.videoUrl;
        }
        if ('compatibleSsps' in source && Array.isArray(source.compatibleSsps)) {
            target.compatibleSsps = source.compatibleSsps.map((ssp: Ssps) => ssp);
        }
    }
}

enum BumperAdTypes {
    SWITCH_IN_BUMPER = 'SWITCH_IN_BUMPER',
    SWITCH_IN_SPOT = 'SWITCH_IN_SPOT',
    /**
     * @deprecated The option 15 seconds isn't supported anymore. Only for old banner they have that options selected.
     */
    SWITCH_IN_SPOT_LONG = 'SWITCH_IN_SPOT_LONG',
}

export {BumperAdBanner, BumperAdTypes};
