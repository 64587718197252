import {Asset, AssetType, BaseAsset} from './base-asset';
import {IAsset} from './asset.types';
import {ICloneable, IMergable} from '../types';

class AudioAsset extends BaseAsset implements IAsset, IMergable<Asset>, ICloneable<AudioAsset> {
    private readonly _type: AssetType = AssetType.Audio;

    public merge(source: Asset): void {
        BaseAsset.fillAdditionalFields(this, source);
    }

    public get type(): AssetType {
        return this._type;
    }

    public clone(): AudioAsset {
        const newModel: AudioAsset = new AudioAsset(
            this.id,
            this.networkId,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {AudioAsset};
