import {HttpClient} from '@angular/common/http';
import {CommonsGetRequestBuilder} from './get-request-builder';
import {IGenericObject} from '@active-agent/types';

class DspApiGetRequestBuilder extends CommonsGetRequestBuilder {
    public static apiRequestChunkSize: number = 800;

    public constructor(
        http: HttpClient,
        networkIds: Array<number>,
        resourcePath: string,
        baseUrl: string,
    ) {
        super(http, resourcePath, baseUrl);

        this.addQueryParam({key: 'networkIds', value: networkIds});
        this.setChunkSize(DspApiGetRequestBuilder.apiRequestChunkSize);
        this.setChunkAggregator(defaultChunkAggregator);
    }
}

function defaultChunkAggregator(chunkResults: Array<IGenericObject>): IGenericObject {
    /**
     * This is a workaround for resources that don't return an array but instead an object
     * (for example campaign-publisher-tags).
     * In this case we can't do any aggregation but requests also won't be split up beforehand, since this never
     * happens for resources that you provide chunkable IDs to
     */
    if (chunkResults.length === 1 && !Array.isArray(chunkResults[0].data)) {
        const result: IGenericObject = {
            data: chunkResults[0].data,
            requestIds: [chunkResults[0].requestId],
        };
        if (chunkResults[0].lastProcessedTime) {
            result.lastProcessedTime = chunkResults[0].lastProcessedTime;
        }

        return result;
    }

    let responseData: Array<IGenericObject> = [];
    const requestIds: Array<number> = [];
    let lastProcessedTime: string | undefined;
    // Stay with a simple for-loop, to be performant enough to concat HUGE arrays.
    for (let i: number = 0; i < chunkResults.length; i++) {
        responseData = responseData.concat(chunkResults[i].data);
        requestIds.push(chunkResults[i].requestId);
        if (chunkResults[i].lastProcessedTime) {
            lastProcessedTime = chunkResults[i].lastProcessedTime;
        }
    }

    const result: IGenericObject = {
        data: responseData,
        requestIds,
        lastProcessedTime,
    };
    if (lastProcessedTime) {
        result.lastProcessedTime = lastProcessedTime;
    }

    return result;
}

export {DspApiGetRequestBuilder, defaultChunkAggregator};
