import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class RedirectCampaignBannerWidthValidator {
    public static readonly maxValue: number = 65535;
    public static readonly minValue: number = 1;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const width: number | undefined = control.value;

        if (width === undefined || width === null) {
            return {[WidthErrorCodes.Required]: getTranslationForErrorCode(WidthErrorCodes.Required)};
        }

        if (isNaN(width)) {
            return {[WidthErrorCodes.NaN]: getTranslationForErrorCode(WidthErrorCodes.NaN)};
        }

        if (width < RedirectCampaignBannerWidthValidator.minValue) {
            return {[WidthErrorCodes.MinValue]: getTranslationForErrorCode(WidthErrorCodes.MinValue)};
        }

        if (width > RedirectCampaignBannerWidthValidator.maxValue) {
            return {[WidthErrorCodes.MaxValue]: getTranslationForErrorCode(WidthErrorCodes.MaxValue)};
        }

        return null;
    }
}

function getTranslationForErrorCode(code: WidthErrorCodes): string {
    switch (code) {
        case WidthErrorCodes.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        case WidthErrorCodes.MinValue:
            return $localize`:@@VALIDATOR_WIDTH_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_WIDTH_MIN_VALUE_ERROR_MESSAGE ${RedirectCampaignBannerWidthValidator.minValue}:INTERPOLATION:`;
        case WidthErrorCodes.MaxValue:
            return $localize`:@@VALIDATOR_WIDTH_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_WIDTH_MAX_VALUE_ERROR_MESSAGE ${RedirectCampaignBannerWidthValidator.maxValue}:INTERPOLATION:`;
        case WidthErrorCodes.NaN:
            return $localize`:@@VALIDATOR_WIDTH_NOT_A_NUMBER_ERROR_MESSAGE:VALIDATOR_WIDTH_NOT_A_NUMBER_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum WidthErrorCodes {
    Required = '9d34c99b-faa2-465a-86b3-facda0cc2775',
    MinValue = 'fa4cb0d0-dd1e-4ded-aaf8-609f350b891d',
    MaxValue = '9ab78b1c-5ff9-42cd-8a69-ecc12ed95c20',
    NaN = '5ae5b1b3-b91f-4efd-a619-13b2f3558f1e',
}

export {RedirectCampaignBannerWidthValidator};
