import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class RequiredMaxCpcValidator implements Validator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0.01;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const maxCpc: number | undefined | null = control.value;

        if (maxCpc === undefined || maxCpc === null) {
            return {[MaxCpcErrorCodes.Required]: this.getTranslationForErrorCode(MaxCpcErrorCodes.Required)};
        }

        if (maxCpc < RequiredMaxCpcValidator.minValue) {
            return {[MaxCpcErrorCodes.MinValue]: this.getTranslationForErrorCode(MaxCpcErrorCodes.MinValue)};
        }

        if (maxCpc > RequiredMaxCpcValidator.maxValue) {
            return {[MaxCpcErrorCodes.MaxValue]: this.getTranslationForErrorCode(MaxCpcErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: MaxCpcErrorCodes): string {
        switch (code) {
            case MaxCpcErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case MaxCpcErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_MAX_CPC_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_CPC_MIN_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(RequiredMaxCpcValidator.minValue, 'EUR')}:INTERPOLATION:`;
            case MaxCpcErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_MAX_CPC_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_CPC_MAX_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(RequiredMaxCpcValidator.maxValue, 'EUR')}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum MaxCpcErrorCodes {
    Required = 'cb694f3a-871e-4e48-aaf4-efd73c0d7de7',
    MinValue = '3158f580-131f-4fd3-b199-8007e5e88b37',
    MaxValue = '312f6874-e93e-4b4b-8197-337db6ff4605',
}

export {RequiredMaxCpcValidator, MaxCpcErrorCodes};
