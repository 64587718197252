<core-content-box
    class="static-information"
    [class.static-information--no-margin]="!addMargin()"
    [addContentPadding]="false"
>
    <ng-container core-content-box-icon>description</ng-container>
    <ng-container core-content-box-title i18n="@@BASE_DATA_HEADLINE">BASE_DATA_HEADLINE</ng-container>
    <ng-container core-content-box-content>
        <section class="static-information__wrapper">
            @for (field of fields; track getFieldId(field)) {
                <div
                    class="static-information__item"
                    [class.static-information__item--clickable]="field.hasTemplateClickEvent"
                >
                    @if (field.template) {
                        <!-- with template -->
                        <ng-container *ngTemplateOutlet="field.template"></ng-container>
                    } @else {
                        <!-- without template -->
                        <div class="static-information__template">
                            <div class="static-information__template-text">
                                <div class="static-information__title">{{field.header}}</div>
                                <div class="static-information__description">{{field.description}}</div>
                            </div>
                            @if (field.tooltip) {
                                <commons-status-icon
                                    [status]="infoStatus"
                                    [tooltip]="field.tooltip"
                                ></commons-status-icon>
                            }
                        </div>
                    }
                </div>
            }
        </section>
    </ng-container>
</core-content-box>
