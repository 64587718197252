import {of, Observable} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IDoohLocationFilterProperties extends IFilterProperties {
    doohLocationIds: Array<number>;
}

class DoohLocationFilter extends Filter<FilterTypes.DOOH_LOCATION> {
    protected readonly _type: FilterTypes.DOOH_LOCATION = FilterTypes.DOOH_LOCATION;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IDoohLocationFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        if (!this.doohLocationIds.length) {
            return of(false);
        }

        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    public isFullScreenFormAvailable(): boolean {
        return true;
    }

    get doohLocationIds(): Array<number> {
        return this.props.doohLocationIds;
    }

    set doohLocationIds(value: Array<number>) {
        this.props.doohLocationIds = value;
    }

    protected getDefaultOptions(): IDoohLocationFilterProperties {
        return {
            ...super.getDefaultOptions(),
            doohLocationIds: [],
        };
    }
}

export {DoohLocationFilter};
