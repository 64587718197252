import {SwitchInBanner} from './switch-in-banner';
import {BannerPoolBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {BannerFileOrUrl, IBannerPoolBanner} from '../banner';

class BannerPoolSwitchInBanner extends SwitchInBanner implements
    IBannerPoolBanner,
    ICloneable<BannerPoolSwitchInBanner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        switchInFileOrUrl: BannerFileOrUrl,
        bannerPoolId: number,
    ) {
        super(id, networkId, name, landingPage, switchInFileOrUrl);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolSwitchInBanner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolSwitchInBanner {
        let switchInFileOrUrl: BannerFileOrUrl = '';
        if (this.switchInFile) {
            switchInFileOrUrl = this.switchInFile;
        } else if (this.switchInUrl) {
            switchInFileOrUrl = this.switchInUrl;
        }

        const newModel: BannerPoolSwitchInBanner = new BannerPoolSwitchInBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            switchInFileOrUrl,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolSwitchInBanner, source: BannerPoolBanner): void {
        SwitchInBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolSwitchInBanner};
