import {NgModule} from '@angular/core';
import {ErrorHandlerService} from './error-handler.service';
import {LibsNotificationModule} from '@active-agent/notification';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [],
    imports: [
        RouterModule,
        LibsNotificationModule,
        MatSnackBarModule,
    ],
    providers: [
        ErrorHandlerService,
    ],
})
class AaErrorHandlerModule {}

export {AaErrorHandlerModule};
