import {Inject, Injectable, Optional} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {CommonsFileService, FileTypes} from '@active-agent/file';
import {CommonsEventLog, ICommonsEventLogger, EVENT_LOGGER} from '@active-agent/event';
import {InfoBoxLevel} from '@angular-clan/core/info-box';

@Injectable({
    providedIn: 'root',
})
class FileUploadValidator {
    constructor(@Optional() @Inject(EVENT_LOGGER) private eventLogger: ICommonsEventLogger | null) {}
    public validate(
        file: File,
        type: FileTypes,
        eventPrefix: string,
        maxFileSizeInMegaByte: number = maxFileSizeDefault,
    ): IFileValidationErrors | null {
        const fileSizeError: IFileValidationErrors | null = this.isFileSizeOk(file, eventPrefix, maxFileSizeInMegaByte);
        if (fileSizeError) {
            return fileSizeError;
        }
        const fileTypeError: IFileValidationErrors | null = this.isType(file, type, eventPrefix);
        if (fileTypeError) {
            return fileTypeError;
        }

        return null;
    }

    public isType(file: File, type: FileTypes, eventPrefix: string): IFileValidationErrors | null {
        if (!fileMimeTypesByExtension[type].includes(file.type)) {
            if (this.eventLogger) {
                void this.eventLogger.log(
                    new CommonsEventLog(`${eventPrefix}.file-upload.invalid-file-type`, {
                        file,
                    }),
                );
            }

            return {
                type: FileUploadErrorTypes.UnsupportedFileFormat,
                level: InfoBoxLevel.Error,
                message: $localize`:@@UPLOAD_ERROR_UNSUPPORTED_FILE_FORMAT:UPLOAD_ERROR_UNSUPPORTED_FILE_FORMAT`,
            };
        }

        return null;
    }

    public isFileSizeOk(file: File, eventPrefix: string, maxFileSizeInMegaByte: number): IFileValidationErrors | null {
        if (
            file.size > CommonsFileService.convertMegaBytesToBytes(maxFileSizeInMegaByte)
        ) {
            if (this.eventLogger) {
                void this.eventLogger.log(
                    new CommonsEventLog(`${eventPrefix}.file-upload.invalid-size`, {file}),
                );
            }

            return {
                type: FileUploadErrorTypes.MaxFileSize,
                level: InfoBoxLevel.Error,
                message: $localize`:@@UPLOAD_ERROR_MAX_SIZE:UPLOAD_ERROR_MAX_SIZE ${maxFileSizeInMegaByte}:INTERPOLATION:`,
            };
        }

        return null;
    }
}

type IFileValidationErrors<
    AdditionalData extends {[name: string]: any} = {},
    AlternateType = never,
> = ValidationErrors & IUploadError<AlternateType> & Partial<AdditionalData>;

enum FileUploadErrorTypes {
    MaxFileSize = 'MaxFileSize',
    UnsupportedFileFormat = 'UnsupportedFileFormat',
    UnknownEntries = 'UnknownEntries',
}

interface IUploadError<AlternateType = never> {
    type: FileUploadErrorTypes | AlternateType;
    level: InfoBoxLevel;
    message: string;
}
interface IFileExtensionsMimeType {
    [key: string]: Array<string>;
}

const fileMimeTypesByExtension: IFileExtensionsMimeType = {
    [FileTypes.Csv]: ['text/csv', 'application/vnd.ms-excel'],
};

const maxFileSizeDefault: number = 10;

export {
    FileUploadValidator,
    FileUploadErrorTypes,
    IFileValidationErrors,
    fileMimeTypesByExtension,
};
