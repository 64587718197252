/* parser generated by jison 0.3.0 */
/**
 * Returns a Parser implementing JisonParserApi and a Lexer implementing JisonLexerApi.
 */
import { JisonParser, JisonParserApi, StateType, SymbolsType, TerminalsType, ProductionsType, o } from '@ts-jison/parser';const $V0=[1,10],$V1=[1,11],$V2=[1,12],$V3=[5,9],$V4=[1,18],$V5=[1,19],$V6=[13,15];

export class JisonParserParser extends JisonParser implements JisonParserApi {
    $?: any;

    constructor (yy = {}, lexer = new JisonParserLexer(yy)) {
      super(yy, lexer);
    }

    symbols_: SymbolsType = {"error":2,"exp":3,"contents":4,"EOF":5,"ACTIVE_GROUP":6,"INACTIVE_GROUP":7,"OPEN_GROUP":8,"CLOSE_GROUP":9,"AND":10,"ACTIVE_VALUE":11,"VIDEO_PLAYER_KEY":12,"VIDEO_PLAYER_ACTIVE":13,"INACTIVE_VALUE":14,"VIDEO_PLAYER_INACTIVE":15,"SMALL_VIDEO_PLAYER":16,"MEDIUM_VIDEO_PLAYER":17,"LARGE_VIDEO_PLAYER":18,"OR":19,"$accept":0,"$end":1};
    terminals_: TerminalsType = {2:"error",5:"EOF",8:"OPEN_GROUP",9:"CLOSE_GROUP",10:"AND",13:"VIDEO_PLAYER_ACTIVE",15:"VIDEO_PLAYER_INACTIVE",16:"SMALL_VIDEO_PLAYER",17:"MEDIUM_VIDEO_PLAYER",18:"LARGE_VIDEO_PLAYER",19:"OR"};
    productions_: ProductionsType = [0,[3,2],[3,1],[4,1],[4,1],[4,7],[11,2],[14,2],[12,1],[12,1],[12,1],[6,1],[6,3],[7,1],[7,3]];
    table: Array<StateType> = [{3:1,4:2,5:[1,3],6:4,7:5,8:[1,6],11:7,12:9,14:8,16:$V0,17:$V1,18:$V2},{1:[3]},{5:[1,13]},{1:[2,2]},{5:[2,3]},{5:[2,4]},{6:14,11:7,12:15,16:$V0,17:$V1,18:$V2},o($V3,[2,11],{19:[1,16]}),o($V3,[2,13],{10:[1,17]}),{13:$V4,15:$V5},o($V6,[2,8]),o($V6,[2,9]),o($V6,[2,10]),{1:[2,1]},{9:[1,20]},{13:$V4},{6:21,11:7,12:15,16:$V0,17:$V1,18:$V2},{7:22,12:23,14:8,16:$V0,17:$V1,18:$V2},o([5,9,19],[2,6]),o([5,9,10],[2,7]),{10:[1,24]},o($V3,[2,12]),o($V3,[2,14]),{15:$V5},{8:[1,25]},{7:26,12:23,14:8,16:$V0,17:$V1,18:$V2},{9:[1,27]},{5:[2,5]}];
    defaultActions: {[key:number]: any} = {3:[2,2],4:[2,3],5:[2,4],13:[2,1],27:[2,5]};

    performAction (yytext:string, yyleng:number, yylineno:number, yy:any, yystate:number /* action[1] */, $$:any /* vstack */, _$:any /* lstack */): any {
/* this == yyval */
          var $0 = $$.length - 1;
        switch (yystate) {
case 1:
return '{' + $$[$0-1] + '}';
break;
case 2:
return '{}';
break;
case 5:
this.$ = $$[$0-5].concat(',').concat($$[$0-1])
break;
case 6:
this.$ = '"'.concat($$[$0-1]).concat('": ').concat('true')
break;
case 7:
this.$ = '"'.concat($$[$0-1]).concat('": ').concat('false')
break;
case 12: case 14:
this.$ = $$[$0-2].concat(',').concat($$[$0])
break;
        }
    }
}


/* generated by ts-jison-lex 0.3.0 */
import { JisonLexer, JisonLexerApi } from '@ts-jison/lexer';
export class JisonParserLexer extends JisonLexer implements JisonLexerApi {
    options: any = {"moduleName":"JisonParser"};
    constructor (yy = {}) {
        super(yy);
    }

    rules: RegExp[] = [/^(?:\s+)/,/^(?:isSmallVideoPlayer\b)/,/^(?:isMediumVideoPlayer\b)/,/^(?:isLargeVideoPlayer\b)/,/^(?:[aA][nN][dD])/,/^(?:[oO][rR])/,/^(?:= 1\b)/,/^(?:!= 1\b)/,/^(?:\()/,/^(?:\))/,/^(?:$)/];
    conditions: any = {"INITIAL":{"rules":[0,1,2,3,4,5,6,7,8,9,10],"inclusive":true}}
    performAction (yy:any,yy_:any,$avoiding_name_collisions:any,YY_START:any): any {
          var YYSTATE=YY_START;
        switch($avoiding_name_collisions) {
    case 0:/* skip whitespace */
      break;
    case 1:return 16;
      break;
    case 2:return 17;
      break;
    case 3:return 18;
      break;
    case 4:return 10;
      break;
    case 5:return 19;
      break;
    case 6:return 13;
      break;
    case 7:return 15;
      break;
    case 8:return 8;
      break;
    case 9:return 9;
      break;
    case 10:return 5;
      break;
        }
    }
}

