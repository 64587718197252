import {Banner, Campaign, FilterTypes, IRouterHandlerRouteConfiguration} from '@active-agent/types';
import {InjectionToken} from '@angular/core';

const FILTER_ROUTES_CONFIGURATION: InjectionToken<IFilterRoutesConfiguration>
    = new InjectionToken<IFilterRoutesConfiguration>('filter-routes.config');

interface IFilterRoutesConfiguration {
    dashboard: IRouterHandlerRouteConfiguration<IFilterDashboardRouteData>;
    settingsFilter: IRouterHandlerRouteConfiguration<IFilterSettingsRouteData>;
    createFilter: IRouterHandlerRouteConfiguration<IFilterSettingsRouteData>;
}

interface IFilterDashboardRouteData {
    banner?: Banner;
    campaign: Campaign;
}

interface IFilterSettingsRouteData {
    type: FilterTypes;
    banner?: Banner;
    campaign: Campaign;
}

export {
    FILTER_ROUTES_CONFIGURATION,
    type IFilterSettingsRouteData,
    type IFilterDashboardRouteData,
    type IFilterRoutesConfiguration,
};
