import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {parseISO} from 'date-fns';

function StartTimeEndTimeComparisonValidator(
    startTimeControl: AbstractControl<string | null>,
    endTimeControl: AbstractControl<string | null>,
): ValidatorFn {
    let thisControl: AbstractControl;

    return (control: AbstractControl): ValidationErrors | null => {
        /**
         * On the first call it starts listen to the dealId and ssp
         * On every change of dealId and ssp thisControl will be validated
         */
        if (!thisControl) {
            thisControl = control;

            // Listen to the value changes and trigger the validation on change
            startTimeControl.valueChanges.subscribe(() => {
                thisControl.updateValueAndValidity();
            });
            endTimeControl.valueChanges.subscribe(() => {
                thisControl.updateValueAndValidity();
            });
        }

        if (!startTimeControl.value || startTimeControl.value === '') {
            return null;
        }
        if (!endTimeControl.value || endTimeControl.value === '') {
            return null;
        }

        const startTime: Date = parseISO(startTimeControl.value);
        const endTime: Date = parseISO(endTimeControl.value);
        if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
            if (startTime.valueOf() >= endTime.valueOf()) {
                return {
                    [StartTimeEndTimeComparisonErrorCodes.BeforeStartTime]: getTranslationForErrorCode(
                        StartTimeEndTimeComparisonErrorCodes.BeforeStartTime,
                    ),
                };
            }
        }

        return null;
    };

    function getTranslationForErrorCode(code: StartTimeEndTimeComparisonErrorCodes): string {
        switch (code) {
            case StartTimeEndTimeComparisonErrorCodes.BeforeStartTime:
                return $localize`:@@RUNTIME_VALIDATOR_END_TIME_BEFORE_START_TIME_ERROR_MESSAGE:RUNTIME_VALIDATOR_END_TIME_BEFORE_START_TIME_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum StartTimeEndTimeComparisonErrorCodes {
    BeforeStartTime = 'a606d924-582a-4f88-99a9-f04811d90521',
}

export {StartTimeEndTimeComparisonValidator, StartTimeEndTimeComparisonErrorCodes};
