import {CommonsRequestBuilder} from './request-builder';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CommonsError} from '@active-agent/types';

class CommonsBlobRequestBuilder extends CommonsRequestBuilder {

    public constructor(
        private http: HttpClient,
        resourcePath: string,
        baseUrl: string,
    ) {
        super(resourcePath, baseUrl);
    }

    /**
     * Creates a valid api blob request and fetches the result from the api
     */
    public build(): Observable<Blob> {
        const baseUrl: string = this.getApiBaseUrl();
        const queryIds: Array<number | string> | null = this.getQueryIds();
        let url: string = queryIds && queryIds.length > 0 ? `${baseUrl}/${queryIds.join(',')}` : baseUrl;
        if (this.getSubResourcePath()) {
            url += `/${this.getSubResourcePath()}`;
        }

        if ((!queryIds || queryIds.length === 0)) {
            return throwError(new CommonsError(
                'No query Ids are provided',
                {data: {url}},
            ));
        }

        if (queryIds) {
            const hasInvalidValues: boolean = queryIds.some((id: number | string): boolean => {
                return typeof id !== 'string' && !Number.isFinite(id);
            });
            if (hasInvalidValues) {
                return throwError(new CommonsError(
                    'Invalid data format. Array of numbers or strings expected',
                    {data: {queryIds}},
                ));
            }
        }

        return this.http
            .get(url, {
                params: this.getQueryParamsForRequest(),
                headers: this.getHeadersForRequest(),
                responseType: 'blob',
            });
    }
}

export {CommonsBlobRequestBuilder};
