import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {format, isAfter} from 'date-fns';
import {FootfallRuntimeValidator} from './footfall-runtime-validator';
import {DateFormat, IRuntime} from '@active-agent/types';
import {differenceInHoursIgnoringDST, saveParseISO} from '@active-agent/std';

@Injectable({
    providedIn: 'root',
})
class FootfallConversionRuntimeValidator implements Validator {
    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const startTimeControl: AbstractControl = control.get('startTime') as AbstractControl;
        const endTimeControl: AbstractControl = control.get('endTime') as AbstractControl;

        if (startTimeControl.valid && endTimeControl.valid) {
            const conversionRuntime: IRuntime = {
                startTime: format(
                    saveParseISO(startTimeControl.value),
                    DateFormat.ApiRequestDateTime,
                ),
                endTime: format(
                    saveParseISO(endTimeControl.value),
                    DateFormat.ApiRequestDateTime,
                ),
            };

            if (isAfter(saveParseISO(conversionRuntime.startTime), saveParseISO(conversionRuntime.endTime))) {
                return getValidationError(FootfallConversionRuntimeErrorCode.StartTimeAfterEndTime);
            }
            const differenceInHours: number =
                differenceInHoursIgnoringDST(saveParseISO(conversionRuntime.endTime), saveParseISO(conversionRuntime.startTime));

            if (differenceInHours > (FootfallRuntimeValidator.maxTotalRangeDays * 24)) {
                return getValidationError(FootfallConversionRuntimeErrorCode.MaxRuntime);
            }
        }

        return null;
    }
}

function getValidationError(code: FootfallConversionRuntimeErrorCode): ValidationErrors {
    return {[code]: getTranslationForErrorCode(code)};
}

function getTranslationForErrorCode(code: FootfallConversionRuntimeErrorCode): string {
    switch (code) {
        case FootfallConversionRuntimeErrorCode.StartTimeAfterEndTime:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_CONVERSION_RUNTIME_START_TIME_AFTER_END_TIME_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_CONVERSION_RUNTIME_START_TIME_AFTER_END_TIME_ERROR_MESSAGE`;
        case FootfallConversionRuntimeErrorCode.MaxRuntime:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_RUNTIME_EXCEEDS_MAX_RANGE_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_RUNTIME_EXCEEDS_MAX_RANGE_ERROR_MESSAGE ${FootfallRuntimeValidator.maxTotalRangeDays}:INTERPOLATION:`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

const enum FootfallConversionRuntimeErrorCode {
    StartTimeAfterEndTime = '4ba30ee2-45aa-4df5-9e79-5575c2338de4',
    MaxRuntime = '7d12d570-03fd-4f72-81c6-2faf9675415c',
}

export {FootfallConversionRuntimeValidator};
