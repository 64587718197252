import {NgModule, ErrorHandler as AngularErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonsAppDataModule} from '@active-agent/app-data';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AaGuardsModule} from './guards/guards.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler} from './commons/error/error-handler';
import {API_URL} from '@active-agent/api';
import {
    SERVICES_URL,
    WEB_SOCKET_URL,
    ROUTE_HANDLER,
    LEAFLET_MAP_PROVIDER,
} from '@active-agent/types';
import {CommonsOutdatedBrowserModule} from '@active-agent/outdated-browser';
import {EVENT_LOGGER} from '@active-agent/event';
import {environment} from '../environments/environment';
import {AaApiModule} from './commons/api/api.module';
import {ErrorHandlerService} from './commons/error/error-handler.service';
import {EventLoggerService} from './commons/event/event-logger.service';
import {DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule} from '@angular/material/core';
import {LibsRouteHandlerService} from '@active-agent/route-handler';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe} from '@angular/common';
import {ServiceWorkerModule} from '@angular/service-worker';
import {ERROR_HANDLER} from '@active-agent/error';
import {DATEPICKER_DATE_FORMATS} from '@active-agent/std';
import {LibsAnnouncementHeaderModule, LibsAdBlockerDetectorModule, CommonsNewVersionToolbarModule} from '@active-agent/ui-components';
import {LibsLoadingDataModule} from '@active-agent/global-loading-indicator';
import {MAT_SELECT_CONFIG} from '@angular/material/select';
import {MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS} from '@angular/material/slide-toggle';
import {FILTER_ROUTES_CONFIGURATION, FilterRouteHandler} from '@active-agent/filter-routes';
import {filterRoutesConfiguration} from './pages/shared/filters/filter-routes-configuration';
import {FOOTFALL_ROUTES_CONFIGURATION} from '@active-agent/footfall-routes';
import {footfallRoutesConfiguration} from './pages/shared/bulk-edit/footfall-bulk-edit/footfall-routes-configuration';
import {SYNC_BANNER_POOL_ROUTER_CONFIG} from '@active-agent/sync-banner-routes';
import {syncBannerPoolRouterConfig} from './pages/advertisers/advertiser/banner-pool/banners/sync-banner-pool-router-config';
import {CustomDateAdapter} from '@active-agent/date';
import {orderRoutesConfiguration} from './pages/advertisers/advertiser/order/order-routes-configuration';
import {ORDER_ROUTES_CONFIGURATION} from '@active-agent/order';
import {AppPath} from './commons/enums';
import {MANUAL_APP_PATH, MANUAL_APP_PATH_REDIRECT_FOR_ID} from '@active-agent/manual';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppRoutingModule,
        AaGuardsModule,
        MatNativeDateModule,
        AaApiModule,
        CommonsOutdatedBrowserModule,
        CommonsNewVersionToolbarModule,
        LibsAdBlockerDetectorModule,
        LibsAnnouncementHeaderModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        CommonsAppDataModule.forRoot(),
        LibsLoadingDataModule.forRoot(),
    ],
    providers: [
        {provide: AngularErrorHandler, useClass: ErrorHandler},
        {provide: API_URL, useValue: environment.apiUrl},
        {provide: SERVICES_URL, useValue: environment.servicesUrl},
        {provide: WEB_SOCKET_URL, useValue: environment.websocketUrl},
        {provide: LEAFLET_MAP_PROVIDER, useValue: environment.leafletMapProvider},
        {provide: ERROR_HANDLER, useExisting: ErrorHandlerService},
        {provide: EVENT_LOGGER, useExisting: EventLoggerService},
        {provide: ROUTE_HANDLER, useExisting: LibsRouteHandlerService},
        {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_DATE_FORMATS},
        {provide: DateAdapter, useClass: CustomDateAdapter},
        {provide: MAT_SELECT_CONFIG, useValue: {overlayPanelClass: 'expandable-overlay-panel'}},
        {provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: {hideIcon: true, color: 'primary'}},
        {provide: FILTER_ROUTES_CONFIGURATION, useValue: filterRoutesConfiguration},
        {provide: FOOTFALL_ROUTES_CONFIGURATION, useValue: footfallRoutesConfiguration},
        {provide: ORDER_ROUTES_CONFIGURATION, useValue: orderRoutesConfiguration},
        {provide: SYNC_BANNER_POOL_ROUTER_CONFIG, useValue: syncBannerPoolRouterConfig},
        {provide: MANUAL_APP_PATH, useValue: AppPath.Manual},
        {provide: MANUAL_APP_PATH_REDIRECT_FOR_ID, useValue: `${AppPath.Manual}/${AppPath.RedirectById}`},
        FilterRouteHandler,
        CurrencyPipe,
        DecimalPipe,
        PercentPipe,
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
class AppModule { }

export {AppModule};
