import {cloneDeep} from 'lodash-es';
import {ICloneable, IMergable} from '../types';
import {IFootfallPoi, IFootfallStoreConfig} from './footfall-poi';
import {IRuntime} from '../runtime';
import {CountryCode} from '../country';

class FootfallStoreConfig implements IFootfallStoreConfig, IMergable<IFootfallStoreConfig>, ICloneable<IFootfallStoreConfig>  {
    private readonly _id: number | null;
    private _networkId: number;
    private _externalId: string | undefined;
    private _orderId: number;
    private _footfallScreenConfigIds: Array<number> = [];
    private _conversionRuntime: IRuntime;
    private _controlGroupRuntime: IRuntime | null = null;
    private _conversionTime: number;
    private _countryCode: CountryCode;
    private _footfallPois: Array<IFootfallPoi> = [];
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        orderId: number,
        conversionRuntime: IRuntime,
        conversionTime: number,
        countryCode: CountryCode,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._orderId = orderId;
        this._conversionRuntime = conversionRuntime;
        this._conversionTime = conversionTime;
        this._countryCode = countryCode;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get externalId(): string | undefined {
        return this._externalId;
    }

    set externalId(value: string | undefined) {
        this._externalId = value;
    }

    get orderId(): number {
        return this._orderId;
    }

    set orderId(value: number) {
        this._orderId = value;
    }

    get footfallScreenConfigIds(): Array<number> {
        return this._footfallScreenConfigIds;
    }

    set footfallScreenConfigIds(value: Array<number>) {
        this._footfallScreenConfigIds = value;
    }

    get conversionRuntime(): IRuntime {
        return this._conversionRuntime;
    }

    set conversionRuntime(value: IRuntime) {
        this._conversionRuntime = value;
    }

    get controlGroupRuntime(): IRuntime | null {
        return this._controlGroupRuntime;
    }

    set controlGroupRuntime(value: IRuntime | null) {
        this._controlGroupRuntime = value;
    }

    get conversionTime(): number {
        return this._conversionTime;
    }

    set conversionTime(value: number) {
        this._conversionTime = value;
    }

    get countryCode(): CountryCode {
        return this._countryCode;
    }

    set countryCode(value: CountryCode) {
        this._countryCode = value;
    }

    get footfallPois(): Array<IFootfallPoi> {
        return this._footfallPois;
    }

    set footfallPois(value: Array<IFootfallPoi>) {
        this._footfallPois = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: FootfallStoreConfig): void {
        this.conversionTime = source.conversionTime;
        this.countryCode = source.countryCode;
        this.conversionRuntime = cloneDeep(source.conversionRuntime);
        this.controlGroupRuntime = cloneDeep(source.controlGroupRuntime);
        this.footfallScreenConfigIds = Array.from(source.footfallScreenConfigIds);
        this.footfallPois = cloneDeep(source.footfallPois);
        this.externalId = source.externalId;
        this.deleted = source.deleted;
        this.lastUpdate = source.lastUpdate;
    }

    public clone(): FootfallStoreConfig {
        const newModel: FootfallStoreConfig = new FootfallStoreConfig(
            this.id,
            this.networkId,
            this.orderId,
            this.conversionRuntime,
            this.conversionTime,
            this.countryCode,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {FootfallStoreConfig};
