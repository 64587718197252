import {Ssps} from '../../ssp/ssps';

function getNativeBannerAvailableImageSizes(sspService: {getTranslation(value: string): string}): Array<IAvailableSizesGroup> {
    return [
        {
            title: sspService.getTranslation(Ssps.YIELDLAB),
            sizes: [
                {dimensions: {width: 300, height: 200}, selected: false},
                {dimensions: {width: 400, height: 200}, selected: false},
                {dimensions: {width: 400, height: 209}, selected: false},
                {dimensions: {width: 400, height: 400}, selected: false},
            ],
        },
        {
            title: sspService.getTranslation(Ssps.APPNEXUS),
            sizes: [
                {dimensions: {width: 1200, height: 627}, selected: false},
                {dimensions: {width: 1200, height: 638}, selected: false},
                {dimensions: {width: 1200, height: 628}, selected: false},
                {dimensions: {width: 1200, height: 1200}, selected: false},
                {dimensions: {width: 1200, height: 800}, selected: false},
                {dimensions: {width: 1000, height: 600}, selected: false},
                {dimensions: {width: 712, height: 400}, selected: false},
                {dimensions: {width: 640, height: 480}, selected: false},
                {dimensions: {width: 640, height: 360}, selected: false},
                {dimensions: {width: 620, height: 375}, selected: false},
                {dimensions: {width: 600, height: 500}, selected: false},
                {dimensions: {width: 600, height: 600}, selected: false},
                {dimensions: {width: 600, height: 315}, selected: false},
                {dimensions: {width: 500, height: 500}, selected: false},
                {dimensions: {width: 375, height: 300}, selected: false},
                {dimensions: {width: 350, height: 350}, selected: false},
                {dimensions: {width: 320, height: 180}, selected: false},
                {dimensions: {width: 300, height: 250}, selected: false},
                {dimensions: {width: 300, height: 160}, selected: false},
            ],
        },
        {
            title: sspService.getTranslation(Ssps.GOOGLE),
            sizes: [
                {dimensions: {width: 1200, height: 627}, selected: false},
                {dimensions: {width: 600, height: 600}, selected: false},
            ],
        },
        {
            title: sspService.getTranslation(Ssps.OUTBRAIN),
            sizes: [
                {dimensions: {width: 1200, height: 800}, selected: false},
                {dimensions: {width: 600, height: 600}, selected: false},
            ],
        },
        {
            title: sspService.getTranslation(Ssps.ADSCALE),
            sizes: [
                {dimensions: {width: 1200, height: 600}, selected: false},
                {dimensions: {width: 900, height: 600}, selected: false},
                {dimensions: {width: 800, height: 600}, selected: false},
                {dimensions: {width: 800, height: 450}, selected: false},
                {dimensions: {width: 720, height: 600}, selected: false},
                {dimensions: {width: 600, height: 600}, selected: false},
                {dimensions: {width: 600, height: 500}, selected: false},
                {dimensions: {width: 600, height: 450}, selected: false},
                {dimensions: {width: 600, height: 400}, selected: false},
                {dimensions: {width: 600, height: 300}, selected: false},
            ],
        },
    ];
}

function getNativeBannerAvailableIconSizes(sspService: {getTranslation(value: string): string}): Array<IAvailableSizesGroup> {
    return [
        {
            title: sspService.getTranslation(Ssps.GOOGLE),
            sizes: [
                {dimensions: {width: 128, height: 128}, selected: false},
                {dimensions: {width: 16, height: 16}, selected: false},
            ],
        },
        {
            title: sspService.getTranslation(Ssps.APPNEXUS),
            sizes: [
                {dimensions: {width: 300, height: 300}, selected: false},
                {dimensions: {width: 150, height: 150}, selected: false},
                {dimensions: {width: 80, height: 80}, selected: false},
            ],
        },
    ];
}
interface IAvailableSizesGroup {
    sizes: Array<IAvailableSize>;
    title: string;
    expanded?: boolean;
}

interface IAvailableSize {
    dimensions: IImageSize;
    selected: boolean;
    translation?: string;
}

interface IImageSize {
    width: number;
    height: number;
}

export {getNativeBannerAvailableImageSizes, getNativeBannerAvailableIconSizes};
