import {FilterTypes} from './filter.types';

/**
 * The API filter type (technically a FilterTypes) of the GEO filter that is just used internally.
 */
type GeoFilterType = 'GEO';

/**
 * The API type of the GEO filter that is just used internally.
 */
const GEO_TYPE_KEY: GeoFilterType = 'GEO';

enum GeoType {
    COUNTRY = 'COUNTRY',
    CITY = 'CITY',
    STATE = 'STATE',
}

function getAvailableGeoTypes(): Array<FilterTypes | string> {
    return [
        FilterTypes.COUNTRY,
        FilterTypes.STATE,
        FilterTypes.CITY,
    ];
}

export {GeoType, GeoFilterType, getAvailableGeoTypes, GEO_TYPE_KEY};
