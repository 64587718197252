import {Input, Component, OnInit} from '@angular/core';
import {CommonsBorderStyle} from '../list-item/list-item.component';
import {CommonsError} from '@active-agent/types';

@Component({
    selector: 'ad-no-items-available-item',
    templateUrl: './no-items-available-item.html',
    styleUrls: ['./no-items-available-item.scss'],
})
class CommonsNoItemsAvailableItemComponent implements OnInit {
    @Input() public borderStyle: CommonsBorderStyle = 'dotted';
    @Input() public textAlign: TextAlign = TextAlign.Left;
    @Input() public message!: string;
    public readonly TextAlign: typeof TextAlign = TextAlign;

    public ngOnInit(): void {
        if (!this.message) {
            throw new CommonsError('Filter component binding has to be provided');
        }
    }
}

enum TextAlign {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export {CommonsNoItemsAvailableItemComponent, TextAlign};
