import {NgModule} from '@angular/core';
import {CommonsFileService} from './file.service';
import {DecimalPipe} from '@angular/common';

@NgModule({
    providers: [
        DecimalPipe,
        CommonsFileService,
    ],
})
class CommonsFileModule {}

export {CommonsFileModule};
