type PromiseStatus = 'success' | 'error' | undefined;

class CopyEvent {
    private running: boolean = false;
    private status: PromiseStatus;
    private promise: any;

    constructor(promise: any) {
        this.setPromise(promise);
    }

    public setPromise(promise: any): void {
        this.running = true;

        this.promise = promise
            .then((data: any): any => {
                this.status = 'success';
                this.running = false;

                return data;
            })
            .catch((error: any): any => {
                this.status = 'error';
                this.running = false;

                throw error;
            });
    }

    public getPromise(): any {
        return this.promise;
    }

    public inProgress(): boolean {
        return this.running;
    }

    public isSuccessful(): boolean {
        return this.status === 'success';
    }

    public hasError(): boolean {
        return this.status === 'error';
    }

}

function getSortValueForEntryEvent(event: CopyEvent): number {
    if (event.hasError()) {
        return 3;
    } else if (event.inProgress()) {
        return 2;
    } else if (event.isSuccessful()) {
        return 1;
    }

    return 0;
}

export {CopyEvent, getSortValueForEntryEvent};
