import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonsInfoContainerComponent} from './info-container.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        CommonsInfoContainerComponent,
    ],
    imports: [
        MatIconModule,
        CommonModule,
    ],
    exports: [
        CommonsInfoContainerComponent,
        MatIconModule,
        CommonModule,
    ],
})
class CommonsInfoContainerModule {}

export {CommonsInfoContainerModule};
