import {NgModule} from '@angular/core';
import {LibsManualComponent} from './manual.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CoreCookieModule} from '@angular-clan/core';
import {CoreManualButtonLinkComponent, CoreManualDialogService} from '@angular-clan/core/manual';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    declarations: [
        LibsManualComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        CoreCookieModule,
        MatDialogModule,
        CoreManualButtonLinkComponent,
    ],
    exports: [
        LibsManualComponent,
    ],
    providers: [
        CoreManualDialogService,
    ],
})
class LibsManualModule {}

export {LibsManualModule};
