import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {parseISO} from 'date-fns';
import {getTimeZonedMaxDate} from '@active-agent/std';

@Injectable({
    providedIn: 'root',
})
class EndTimeValidator {
    public static readonly maxDate: Date = getTimeZonedMaxDate();

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const endTime: string = control.value;

        if (endTime === undefined || endTime === null) {
            return {[EndTimeErrorCodes.Required]: this.getTranslationForErrorCode(EndTimeErrorCodes.Required)};
        }

        const date: Date = parseISO(endTime);
        if (isNaN(date.getTime())) {
            return {[EndTimeErrorCodes.InvalidFormat]: this.getTranslationForErrorCode(EndTimeErrorCodes.InvalidFormat)};
        }

        if (date.valueOf() > EndTimeValidator.maxDate.valueOf()) {
            return {[EndTimeErrorCodes.ExceedMaxRange]: this.getTranslationForErrorCode(EndTimeErrorCodes.ExceedMaxRange)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: EndTimeErrorCodes): string {
        switch (code) {
            case EndTimeErrorCodes.Required:
                return $localize`:@@VALIDATOR_END_TIME_REQUIRED_ERROR_MESSAGE:VALIDATOR_END_TIME_REQUIRED_ERROR_MESSAGE`;
            case EndTimeErrorCodes.InvalidFormat:
                return $localize`:@@VALIDATOR_END_TIME_INVALID_FORMAT_ERROR_MESSAGE:VALIDATOR_END_TIME_INVALID_FORMAT_ERROR_MESSAGE`;
            case EndTimeErrorCodes.ExceedMaxRange:
                return $localize`:@@VALIDATOR_END_TIME_EXCEED_MAX_RANGE_ERROR_MESSAGE:VALIDATOR_END_TIME_EXCEED_MAX_RANGE_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum EndTimeErrorCodes {
    Required = 'b53f0f2b-39bc-45eb-9e16-0a8fa96b77bc',
    InvalidFormat = '0d9a18f1-f27a-44c4-b6c4-a813c1fbe308',
    ExceedMaxRange = 'd02628ca-df95-465a-9ee4-5808876ab9b1',
}

export {EndTimeValidator};
