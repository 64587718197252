import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Order, IGenericObject, IRequestBuilderResult} from '@active-agent/types';
import {OrderApiRepresentation} from './order-api-representation';
import {ApiResource, CommonsPutRequestBuilder} from '@active-agent/api';

class UpdateOrderBuilder extends CommonsPutRequestBuilder {
    constructor(
        baseUrl: string,
        private httpClient: HttpClient,
        private originalOrder: Order,
        private modifiedOrder: Order,
    ) {
        super(
            httpClient,
            ApiResource.Orders,
            baseUrl,
            OrderApiRepresentation.toApi(originalOrder),
            OrderApiRepresentation.toApi(modifiedOrder),
        );
    }

    public build(): Observable<IRequestBuilderResult<Order>> {
        return super.build().pipe(
            map((result: IGenericObject) => {
                return {
                    data: OrderApiRepresentation.fromApi(result.data),
                    requestIds: result.requestIds,
                };
            }),
        );
    }
}

export {UpdateOrderBuilder};
