import {ICloneable, IMergable} from '../types';
interface ITrackingSpot {
    id: number | null;
    trackingPageId: number;
    networkId: number;
    isContainer: boolean;
    isSuccessSpot: boolean;
    name: string;
    ssl: boolean;
    code: string | null;
}

class TrackingSpot implements ITrackingSpot, ICloneable<TrackingSpot>, IMergable<TrackingSpot> {
    private readonly _id: number | null;
    private _networkId: number;
    private _trackingPageId: number;
    private _isContainer: boolean;
    private _isSuccessSpot: boolean;
    private _ssl: boolean;
    private _name: string;
    private _code: string | null = null;
    private _lastUpdate: number | null = null;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        name: string,
        isContainer: boolean,
        isSuccessSpot: boolean,
        ssl: boolean,
        networkId: number,
        trackingPageId: number,
    ) {
        this._id = id;
        this._name = name;
        this._isContainer = isContainer;
        this._isSuccessSpot = isSuccessSpot;
        this._ssl = ssl;
        this._networkId = networkId;
        this._trackingPageId = trackingPageId;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get trackingPageId(): number {
        return this._trackingPageId;
    }

    set trackingPageId(value: number) {
        this._trackingPageId = value;
    }

    get isContainer(): boolean {
        return this._isContainer;
    }

    set isContainer(value: boolean) {
        this._isContainer = value;
    }

    get isSuccessSpot(): boolean {
        return this._isSuccessSpot;
    }

    set isSuccessSpot(value: boolean) {
        this._isSuccessSpot = value;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get code(): string | null {
        return this._code;
    }

    set code(value: string | null) {
        this._code = value;
    }

    get lastUpdate(): number | null {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | null) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: TrackingSpot): void {
        this.networkId = source.networkId;
        this.trackingPageId = source.trackingPageId;
        this.isContainer = source.isContainer;
        this.isSuccessSpot = source.isSuccessSpot;
        this.ssl = source.ssl;
        this.name = source.name;
        this.code = source.code;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }

    public clone(): TrackingSpot {
        const newModel: TrackingSpot = new TrackingSpot(
            this.id,
            this.name,
            this.isContainer,
            this.isSuccessSpot,
            this.ssl,
            this.networkId,
            this.trackingPageId,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {TrackingSpot};
