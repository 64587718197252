import {Network} from '../network';

class BannerPool {
    private readonly _id: any = null;
    private _active: boolean = false;
    private _name: any = null;
    private _companyId: any = null;
    private _network: Network | undefined = undefined;
    private _bannerIds: any = [];
    private _lastUpdated: any = null;
    private _deleted: boolean = false;

    constructor(id: any = null) {
        this._id = id;
    }

    get id(): any {
        return this._id;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get name(): any {
        return this._name;
    }

    set name(value: any) {
        this._name = value;
    }

    get companyId(): any {
        return this._companyId;
    }

    set companyId(value: any) {
        this._companyId = value;
    }

    get network(): Network | undefined {
        return this._network;
    }

    set network(value: Network | undefined) {
        this._network = value;
    }

    get bannerIds(): any {
        return this._bannerIds;
    }

    set bannerIds(value: any) {
        this._bannerIds = value;
    }

    get lastUpdated(): any {
        return this._lastUpdated;
    }

    set lastUpdated(value: any) {
        this._lastUpdated = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public static merge(modelOne: BannerPool, modelTwo: BannerPool): BannerPool {
        modelOne.active = modelTwo.active;
        modelOne.network = modelTwo.network ? Network.clone(modelTwo.network) : undefined;
        modelOne.name = modelTwo.name;
        modelOne.companyId = modelTwo.companyId;
        modelOne.bannerIds = [...modelTwo.bannerIds];
        modelOne.lastUpdated = modelTwo.lastUpdated;
        modelOne.deleted = modelTwo.deleted;

        return modelOne;
    }

    public static clone(model: BannerPool): BannerPool {
        const newModel: BannerPool = new BannerPool(model.id);
        BannerPool.merge(newModel, model);

        return newModel;
    }
}

export {BannerPool};
