import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class WidthValidator {
    public static readonly maxValue: number = 65535;
    public static readonly minValue: number = 0;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl, customWidth?: number): ValidationErrors | null {
        const width: number | undefined = control.value;

        if (width === undefined || width === null) {
            return {[WidthErrorCodes.Required]: getTranslationForErrorCode(WidthErrorCodes.Required)};
        }

        if (isNaN(width)) {
            return {[WidthErrorCodes.NaN]: getTranslationForErrorCode(WidthErrorCodes.NaN)};
        }

        if (width < WidthValidator.minValue) {
            return {[WidthErrorCodes.MinValue]: getTranslationForErrorCode(WidthErrorCodes.MinValue)};
        }

        if (width > (customWidth || WidthValidator.maxValue)) {
            return {[WidthErrorCodes.MaxValue]: getTranslationForErrorCode(WidthErrorCodes.MaxValue, customWidth)};
        }

        return null;
    }

    public validateWithCustomWidth(width: number): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.validate(control, width);
        };
    }
}

function getTranslationForErrorCode(code: WidthErrorCodes, dynamicValue?: number): string {
    switch (code) {
        case WidthErrorCodes.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        case WidthErrorCodes.MinValue:
            return $localize`:@@VALIDATOR_WIDTH_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_WIDTH_MIN_VALUE_ERROR_MESSAGE ${(dynamicValue || WidthValidator.minValue)}:INTERPOLATION:`;
        case WidthErrorCodes.MaxValue:
            return $localize`:@@VALIDATOR_WIDTH_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_WIDTH_MAX_VALUE_ERROR_MESSAGE ${(dynamicValue || WidthValidator.maxValue)}:INTERPOLATION:`;
        case WidthErrorCodes.NaN:
            return $localize`:@@VALIDATOR_WIDTH_NOT_A_NUMBER_ERROR_MESSAGE:VALIDATOR_WIDTH_NOT_A_NUMBER_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum WidthErrorCodes {
    Required = '6a9a5c25-2438-4ca1-8b3c-aa3be7e080a8',
    MinValue = '9c6232a2-aecc-44d6-9ccf-6b516008b8ca',
    MaxValue = '2a9f7ecb-e730-46d6-b142-0d4b429eb0ae',
    NaN = '83ec6ec4-88da-442a-aa86-7deda48427c9',
}

export {WidthValidator};
