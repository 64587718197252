<libs-card-widget
    class="amount-widget"
    [title]="title()"
    [isLoading]="isLoading()"
>
    <ng-container libs-card-widget-content>
        <div class="vm-fill vm-flex-direction-column vm-layout-justify-center vm-layout-align-center">
            <span class="mat-subtitle-2 amount-widget__value">{{amount() | number}}</span>
            <span class="amount-widget__description">{{amountDescription()}}</span>
        </div>
    </ng-container>
</libs-card-widget>
