import {IBreadcrumb, ILevelUpLink, INavItem, ISectionInfo, ITitleData} from '@angular-clan/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injector} from '@angular/core';

class RouteConfig<T = undefined> implements IRouteConfig<T> {
    protected injector!: Injector;

    public initInjector(injector: Injector): void {
        this.injector = injector;
    }

    public getTitleData(_data: T, _route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): ITitleData {
        return {
            title: '',
        };
    }

    public getSectionInfo(_data: T): ISectionInfo | null {
        return null;
    }

    public getSubPageConfig(_data: T): ISubPageConfig | null {
        return null;
    }

    public getBreadcrumbsConfig(_data: T): Array<IBreadcrumb> | null {
        return null;
    }

    public getLevelUpLink(_data: T): ILevelUpLink | null {
        return null;
    }
}

interface ISubPageConfig {
    sectionId: string;
    subPages: Array<INavItem>;
}

interface IRouteConfig<T = undefined> {
    getTitleData(data: T, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ITitleData;
    getSectionInfo(data: T): ISectionInfo | null;
    getSubPageConfig(data: T): ISubPageConfig | null;
    getBreadcrumbsConfig(data: T): Array<IBreadcrumb> | null;
    getLevelUpLink(data: T): ILevelUpLink | null;
    initInjector(injector: Injector): void;
}

export {RouteConfig, IRouteConfig, ISubPageConfig};
