import {ICloneable, IMergable} from '../types';
import {CommonsError} from '../error/error';
import {AdvertiserMarginComponent, IAdvertiserMargin} from './advertister-margin.types';

abstract class AdvertiserMargin implements IAdvertiserMargin, IMergable<AdvertiserMargin>, ICloneable<AdvertiserMargin> {
    private readonly _id: number | null;
    private _networkId: number;
    private _components: Array<AdvertiserMarginComponent>;
    private _margin: number;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        components: Array<AdvertiserMarginComponent>,
        margin: number,
    ) {
        if (components.length === 0) {
            throw new CommonsError('can not create AdvertiserMargin with empty components');
        }

        this._id = id;
        this._networkId = networkId;
        this._components = components;
        this._margin = margin;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get components(): Array<AdvertiserMarginComponent> {
        return this._components;
    }

    set components(value: Array<AdvertiserMarginComponent>) {
        this._components = value;
    }

    get margin(): number {
        return this._margin;
    }

    set margin(value: number) {
        this._margin = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public abstract clone(): AdvertiserMargin;

    public merge(source: AdvertiserMargin): void {
        this.networkId = source.networkId;
        this.components = source.components;
        this.margin = source.margin;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }
}

export {AdvertiserMargin};
