import {FilterTypes} from '../types/filter.types';
import {OttoPntaFilter, IOttoPntaFilterProperties} from './otto-pnta-filter';

type OttoPntaPurchaseFilterProperties = IOttoPntaFilterProperties;

class OttoPntaPurchaseFilter extends OttoPntaFilter<FilterTypes.OTTO_PNTA_PURCHASE> {
    protected readonly _type: FilterTypes.OTTO_PNTA_PURCHASE = FilterTypes.OTTO_PNTA_PURCHASE;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly props: OttoPntaPurchaseFilterProperties = this.getDefaultOptions();
}

export {OttoPntaPurchaseFilter, OttoPntaPurchaseFilterProperties};
