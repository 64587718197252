import {VastWrapperBanner} from './vast-wrapper-banner';
import {BannerPoolBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {IBannerPoolBanner} from '../banner';

class BannerPoolVastWrapperBanner extends VastWrapperBanner implements
    IBannerPoolBanner,
    ICloneable<BannerPoolVastWrapperBanner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(id: number | null, networkId: number, name: string, landingPage: string, wrapperUrl: string, bannerPoolId: number) {
        super(id, networkId, name, landingPage, wrapperUrl);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolVastWrapperBanner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolVastWrapperBanner {
        const newModel: BannerPoolVastWrapperBanner = new BannerPoolVastWrapperBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.wrapperUrl,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolVastWrapperBanner, source: BannerPoolBanner): void {
        VastWrapperBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolVastWrapperBanner};
