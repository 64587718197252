<div
    class="commons-list-item"
    [ngClass]="{
        'commons-list-item--border-dotted': borderStyle === 'dotted',
        'commons-list-item--border-solid': borderStyle === 'solid',
        'commons-list-item--border-none': borderStyle === 'none',
        'commons-list-item--no-padding': !hasPadding
    }"
>
    <ng-content></ng-content>
</div>
