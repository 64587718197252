import {Observable, shareReplay} from 'rxjs';

/**
 * Returns a given observable with cached enabled
 */
function addCaching<TData = Record<string, unknown>>(observable: Observable<TData>): Observable<TData> {
    return observable
        .pipe(
            shareReplay({bufferSize: 1, refCount: true}),
        );
}

export {addCaching};
