import {IBannerFile} from '../banner';
import {CommonsError} from '../error';
import {API_FILE_TYPES} from './api-file-types';

function convertApiObjectToBase64(apiObject: IBannerFile): string {
    if (apiObject.data === undefined || apiObject.type === undefined) {
        throw new CommonsError('not a valid api object');
    }
    const mimeTypes: {[fileType: string]: string} = {};
    mimeTypes[API_FILE_TYPES.JPEG] = 'image/jpeg';
    mimeTypes[API_FILE_TYPES.PNG] = 'image/png';
    mimeTypes[API_FILE_TYPES.GIF] = 'image/gif';
    mimeTypes[API_FILE_TYPES.FLASH] = 'application/x-shockwave-flash';
    mimeTypes[API_FILE_TYPES.MISC] = '';

    const prefix: string = `data:${mimeTypes[apiObject.type]};base64,`;

    return `${prefix}${apiObject.data}`;
}

export {convertApiObjectToBase64};
