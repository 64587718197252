/* parser generated by jison 0.3.0 */
/**
 * Returns a Parser implementing JisonParserApi and a Lexer implementing JisonLexerApi.
 */
import { JisonParser, JisonParserApi, StateType, SymbolsType, TerminalsType, ProductionsType, o } from '@ts-jison/parser';const $V0=[1,4];

export class JisonParserParser extends JisonParser implements JisonParserApi {
    $?: any;

    constructor (yy = {}, lexer = new JisonParserLexer(yy)) {
      super(yy, lexer);
    }

    symbols_: SymbolsType = {"error":2,"exp":3,"ENTRY":4,"EOF":5,"VISIBILITY_VALUE":6,"KEY":7,"=":8,"VALUE":9,"OR":10,"$accept":0,"$end":1};
    terminals_: TerminalsType = {2:"error",5:"EOF",7:"KEY",8:"=",9:"VALUE",10:"OR"};
    productions_: ProductionsType = [0,[3,2],[6,3],[4,3],[4,1]];
    table: Array<StateType> = [{3:1,4:2,6:3,7:$V0},{1:[3]},{5:[1,5]},{5:[2,4],10:[1,6]},{8:[1,7]},{1:[2,1]},{4:8,6:3,7:$V0},{9:[1,9]},{5:[2,3]},o([5,10],[2,2])];
    defaultActions: {[key:number]: any} = {5:[2,1],8:[2,3]};

    performAction (yytext:string, yyleng:number, yylineno:number, yy:any, yystate:number /* action[1] */, $$:any /* vstack */, _$:any /* lstack */): any {
/* this == yyval */
          var $0 = $$.length - 1;
        switch (yystate) {
case 1:
return [...$$[$0-1]];
break;
case 2:
this.$ = Number($$[$0])
break;
case 3:
this.$ = [$$[$0-2]].concat($$[$0])
break;
case 4:
this.$ = [$$[$0]]
break;
        }
    }
}


/* generated by ts-jison-lex 0.3.0 */
import { JisonLexer, JisonLexerApi } from '@ts-jison/lexer';
export class JisonParserLexer extends JisonLexer implements JisonLexerApi {
    options: any = {"moduleName":"JisonParser"};
    constructor (yy = {}) {
        super(yy);
    }

    rules: RegExp[] = [/^(?:\s+)/,/^(?:visibility\b)/,/^(?:=)/,/^(?:\d\b)/,/^(?:[oO][rR])/,/^(?:$)/];
    conditions: any = {"INITIAL":{"rules":[0,1,2,3,4,5],"inclusive":true}}
    performAction (yy:any,yy_:any,$avoiding_name_collisions:any,YY_START:any): any {
          var YYSTATE=YY_START;
        switch($avoiding_name_collisions) {
    case 0:/* skip whitespace */
      break;
    case 1:return 7;
      break;
    case 2:return 8
      break;
    case 3:return 9;
      break;
    case 4:return 10;
      break;
    case 5:return 5;
      break;
        }
    }
}

