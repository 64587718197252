import {cloneDeep} from 'lodash-es';
import {ThirdPartyFeatureType} from '../third-party-feature/third-party-feature-type';
import {RedirectCampaign} from './redirect-campaign';
import {CampaignTypes} from './campaign-types';
import {AddressableTvCampaign} from './addressable-tv-campaign';
import {DigitalOutOfHomeCampaign} from './digital-out-of-home-campaign';
import {DisplayCampaign} from './display-campaign';
import {PepCampaign} from './pep-campaign';
import {RadioCampaign} from './radio-campaign';
import {ProgrammaticTvCampaign} from './programmatic-tv-campaign';
import {BiddingStrategy, DeliveryTechnique, IRtbCampaign} from './campaign';
import {ICloneable} from '../types';
import {IRuntime} from '../runtime/runtime';
import {ICampaignDeal} from './campaign-deals.types';

class RtbCampaign implements IRtbCampaign, ICloneable<RtbCampaign> {
    private readonly _id: number | null;
    private _active: boolean = false;
    private _bannerIds: Array<number> = [];
    private _bidFactorIds: Array<number> = [];
    private _biddingStrategy: BiddingStrategy;
    private _calculatedDailyBudget: number | undefined;
    private _calculatedDailyImpressions: number | undefined;
    private _calculatedDailyClicks: number | undefined;
    private _dailyBudget: number;
    private _dailyImpressions: number | null = null;
    private _dailyClicks: number | null = null;
    private _dailyPacing: boolean | undefined = false;
    private _deals: Array<ICampaignDeal> = [];
    private _deleted: boolean = false;
    private _deliveryTechnique: DeliveryTechnique;
    private _filterIds: Array<string> = [];
    private _lastUpdate: number | undefined;
    private _maxCpm: number;
    private _maxCombinedBidFactor: number | null = null;
    private _name: string;
    private readonly _networkId: number;
    private _orderId: number;
    private _pace: number | undefined;
    private _runtimePacing: boolean | undefined = false;
    private _runtimePacingFactor: number | null = null;
    private _runtimes: Array<IRuntime> = [];
    private _infiniteRuntime: boolean = false;
    private _remainingRuntimeDays: number | undefined;
    private _runtimePercentage: number | undefined;
    private _thirdPartyCostIds: Array<number> = [];
    private _totalBudget: number;
    private _totalImpressions: number | null = null;
    private _totalClicks: number | null = null;
    private _trackingPageIds: Array<number> = [];
    private _turnoverPerClick: number | undefined;
    private _dsaPaid: string | null = null;
    private _dsaBehalf: string | null = null;
    private _activeThirdPartyFeatures: Array<ThirdPartyFeatureType> = [];

    constructor(
        id: number | null,
        orderId: number,
        networkId: number,
        biddingStrategy: BiddingStrategy,
        dailyBudget: number,
        deliveryTechnique: DeliveryTechnique,
        maxCpm: number,
        name: string,
        totalBudget: number,
    ) {
        this._id = id;
        this._orderId = orderId;
        this._networkId = networkId;
        this._biddingStrategy = biddingStrategy;
        this._dailyBudget = dailyBudget;
        this._deliveryTechnique = deliveryTechnique;
        this._maxCpm = maxCpm;
        this._name = name;
        this._totalBudget = totalBudget;
    }

    get id(): number | null {
        return this._id;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get bannerIds(): Array<number> {
        return this._bannerIds;
    }

    set bannerIds(value: Array<number>) {
        this._bannerIds = value;
    }

    get bidFactorIds(): Array<number> {
        return this._bidFactorIds;
    }

    set bidFactorIds(value: Array<number>) {
        this._bidFactorIds = value;
    }

    get biddingStrategy(): BiddingStrategy {
        return this._biddingStrategy;
    }

    set biddingStrategy(value: BiddingStrategy) {
        this._biddingStrategy = value;
    }

    get calculatedDailyBudget(): number | undefined {
        return this._calculatedDailyBudget;
    }

    set calculatedDailyBudget(value: number | undefined) {
        this._calculatedDailyBudget = value;
    }

    get calculatedDailyImpressions(): number | undefined {
        return this._calculatedDailyImpressions;
    }

    set calculatedDailyImpressions(value: number | undefined) {
        this._calculatedDailyImpressions = value;
    }

    get calculatedDailyClicks(): number | undefined {
        return this._calculatedDailyClicks;
    }

    set calculatedDailyClicks(value: number | undefined) {
        this._calculatedDailyClicks = value;
    }

    get dailyBudget(): number {
        return this._dailyBudget;
    }

    set dailyBudget(value: number) {
        this._dailyBudget = value;
    }

    get dailyImpressions(): number | null {
        return this._dailyImpressions;
    }

    set dailyImpressions(value: number | null) {
        this._dailyImpressions = value;
    }

    get dailyClicks(): number | null {
        return this._dailyClicks;
    }

    set dailyClicks(value: number | null) {
        this._dailyClicks = value;
    }

    get dailyPacing(): boolean | undefined {
        return this._dailyPacing;
    }

    set dailyPacing(value: boolean | undefined) {
        this._dailyPacing = value;
    }

    get deals(): Array<ICampaignDeal> {
        return this._deals;
    }

    set deals(value: Array<ICampaignDeal>) {
        this._deals = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get deliveryTechnique(): DeliveryTechnique {
        return this._deliveryTechnique;
    }

    set deliveryTechnique(value: DeliveryTechnique) {
        this._deliveryTechnique = value;
    }

    get filterIds(): Array<string> {
        return this._filterIds;
    }

    set filterIds(value: Array<string>) {
        this._filterIds = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get maxCpm(): number {
        return this._maxCpm;
    }

    set maxCpm(value: number) {
        this._maxCpm = value;
    }

    get maxCombinedBidFactor(): number | null {
        return this._maxCombinedBidFactor;
    }

    set maxCombinedBidFactor(value: number | null) {
        this._maxCombinedBidFactor = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get networkId(): number {
        return this._networkId;
    }

    get orderId(): number {
        return this._orderId;
    }

    set orderId(value: number) {
        this._orderId = value;
    }

    get pace(): number | undefined {
        return this._pace;
    }

    set pace(value: number | undefined) {
        this._pace = value;
    }

    get runtimePacing(): boolean | undefined {
        return this._runtimePacing;
    }

    set runtimePacing(value: boolean | undefined) {
        this._runtimePacing = value;
    }

    get runtimePacingFactor(): number | null {
        return this._runtimePacingFactor;
    }

    set runtimePacingFactor(value: number | null) {
        this._runtimePacingFactor = value;
    }

    get runtimes(): Array<IRuntime> {
        return this._runtimes;
    }

    set runtimes(value: Array<IRuntime>) {
        this._runtimes = value;
    }

    get infiniteRuntime(): boolean {
        return this._infiniteRuntime;
    }

    set infiniteRuntime(value: boolean) {
        this._infiniteRuntime = value;
    }

    get remainingRuntimeDays(): number | undefined {
        return this._remainingRuntimeDays;
    }

    set remainingRuntimeDays(value: number | undefined) {
        this._remainingRuntimeDays = value;
    }

    get runtimePercentage(): number | undefined {
        return this._runtimePercentage;
    }

    set runtimePercentage(value: number | undefined) {
        this._runtimePercentage = value;
    }

    get thirdPartyCostIds(): Array<number> {
        return this._thirdPartyCostIds;
    }

    set thirdPartyCostIds(value: Array<number>) {
        this._thirdPartyCostIds = value;
    }

    get totalBudget(): number {
        return this._totalBudget;
    }

    set totalBudget(value: number) {
        this._totalBudget = value;
    }

    get totalImpressions(): number | null {
        return this._totalImpressions;
    }

    set totalImpressions(value: number | null) {
        this._totalImpressions = value;
    }

    get totalClicks(): number | null {
        return this._totalClicks;
    }

    set totalClicks(value: number | null) {
        this._totalClicks = value;
    }

    get trackingPageIds(): Array<number> {
        return this._trackingPageIds;
    }

    set trackingPageIds(value: Array<number>) {
        this._trackingPageIds = value;
    }

    get turnoverPerClick(): number | undefined {
        return this._turnoverPerClick;
    }

    set turnoverPerClick(value: number | undefined) {
        this._turnoverPerClick = value;
    }

    get dsaPaid(): string | null {
        return this._dsaPaid;
    }

    set dsaPaid(value: string | null) {
        this._dsaPaid = value;
    }

    get dsaBehalf(): string | null {
        return this._dsaBehalf;
    }

    set dsaBehalf(value: string | null) {
        this._dsaBehalf = value;
    }

    get activeThirdPartyFeatures(): Array<ThirdPartyFeatureType> {
        return this._activeThirdPartyFeatures;
    }

    set activeThirdPartyFeatures(thirdPartyFeatures: Array<ThirdPartyFeatureType>) {
        this._activeThirdPartyFeatures = thirdPartyFeatures;
    }

    get type(): CampaignTypes {
        throw new Error(`Type property is not available on rtb campaign, ${JSON.stringify(this)}`);
    }

    public merge(source: Campaign): void {
        RtbCampaign.fillAdditionalFields(this, source);
    }

    public clone(): RtbCampaign {
        const newModel: RtbCampaign = new RtbCampaign(
            this.id,
            this.orderId,
            this.networkId,
            this.biddingStrategy,
            this.dailyBudget,
            this.deliveryTechnique,
            this.maxCpm,
            this.name,
            this.totalBudget,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: RtbCampaign, source: Campaign): void {
        target.active = source.active;
        target.bannerIds = source.bannerIds;
        if (isRtbCampaign(source)) {
            target.bidFactorIds = source.bidFactorIds;
            target.calculatedDailyBudget = source.calculatedDailyBudget;
            target.calculatedDailyImpressions = source.calculatedDailyImpressions;
            target.calculatedDailyClicks = source.calculatedDailyClicks;
            target.dailyImpressions = source.dailyImpressions;
            target.dailyClicks = source.dailyClicks;
            target.dailyPacing = source.dailyPacing;
            target.deals = cloneDeep(source.deals);
            target.pace = source.pace;
            target.runtimePacing = source.runtimePacing;
            target.runtimePacingFactor = source.runtimePacingFactor;
            target.runtimes = cloneDeep(source.runtimes);
            target.infiniteRuntime = source.infiniteRuntime;
            target.remainingRuntimeDays = source.remainingRuntimeDays;
            target.runtimePercentage = source.runtimePercentage;
            target.thirdPartyCostIds = source.thirdPartyCostIds;
            target.totalBudget = source.totalBudget;
            target.totalImpressions = source.totalImpressions;
            target.totalClicks = source.totalClicks;
            target.turnoverPerClick = source.turnoverPerClick;
            target.maxCombinedBidFactor = source.maxCombinedBidFactor;
            target.biddingStrategy = source.biddingStrategy;
            target.dailyBudget = source.dailyBudget;
            target.deliveryTechnique = source.deliveryTechnique;
            target.maxCpm = source.maxCpm;
        }
        target.deleted = source.deleted;
        target.filterIds = source.filterIds.map((id: string) => id);
        target.lastUpdate = source.lastUpdate;
        target.trackingPageIds = source.trackingPageIds;
        target.name = source.name;
        target.dsaPaid = source.dsaPaid;
        target.dsaBehalf = source.dsaBehalf;
        target.activeThirdPartyFeatures = source.activeThirdPartyFeatures;
    }

    public isThirdPartyFeatureActive(thirdPartyFeature: ThirdPartyFeatureType): boolean {
        return this.activeThirdPartyFeatures.includes(thirdPartyFeature);
    }
}

function isRtbCampaign(campaign: unknown): campaign is RtbCampaign {
    return campaign instanceof RtbCampaign;
}

function isCampaign(object: unknown): object is RtbCampaign | RedirectCampaign {
    return object instanceof RtbCampaign || object instanceof RedirectCampaign;
}

type Campaign = AddressableTvCampaign
| DigitalOutOfHomeCampaign
| DisplayCampaign
| PepCampaign
| RadioCampaign
| RedirectCampaign
| RtbCampaign
| ProgrammaticTvCampaign;

export {RtbCampaign, Campaign, isRtbCampaign, isCampaign};
