import {BannerPoolBanner} from '../base-banner';
import {DeprecatedAtvSpotBanner} from './deprecated-atv-spot-banner';
import {ICloneable, IMergable} from '../../types';
import {IBannerPoolBanner} from '../banner';

class DeprecatedBannerPoolAtvSpotBanner extends DeprecatedAtvSpotBanner implements
    IBannerPoolBanner,
    ICloneable<DeprecatedBannerPoolAtvSpotBanner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        bannerPoolId: number,
    ) {
        super(id, networkId, name, landingPage);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        DeprecatedBannerPoolAtvSpotBanner.fillAdditionalFields(this, source);
    }

    public clone(): DeprecatedBannerPoolAtvSpotBanner {
        const newModel: DeprecatedBannerPoolAtvSpotBanner = new DeprecatedBannerPoolAtvSpotBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: DeprecatedBannerPoolAtvSpotBanner, source: BannerPoolBanner): void {
        DeprecatedAtvSpotBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {DeprecatedBannerPoolAtvSpotBanner};
