import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {IFootfallPoi} from '@active-agent/types';

function FootfallUniquePoiValidator(
    latitudeControl: AbstractControl,
    longitudeControl: AbstractControl,
    footfallPois: Array<IFootfallPoi>,
): ValidatorFn {
    let thisControl: AbstractControl;

    return (control: AbstractControl): ValidationErrors | null => {
        /**
         * On the first call it starts listen to the latitude and longitude
         * On every change of latitude and longitude those controls will be validated
         */
        if (!thisControl) {
            thisControl = control;

            // Listen to the value changes and trigger the validation on change
            latitudeControl.valueChanges.subscribe(() => thisControl.updateValueAndValidity());
            longitudeControl.valueChanges.subscribe(() => thisControl.updateValueAndValidity());
        }

        if (hasDuplicatedFootfallPois(footfallPois, latitudeControl, longitudeControl)) {
            return {[UniquePoiErrorCodes.Unique]: getTranslationForErrorCode(UniquePoiErrorCodes.Unique)};
        }

        return null;
    };
}

function hasDuplicatedFootfallPois(
    footfallPois: Array<IFootfallPoi>,
    latitudeControl: AbstractControl,
    longitudeControl: AbstractControl,
): boolean {
    let countDuplicatePois: number = 0;
    for (const footfallPoi of footfallPois) {
        if (footfallPoi.latitude === latitudeControl.value && footfallPoi.longitude === longitudeControl.value) {
            countDuplicatePois++;
        }
    }

    return countDuplicatePois > 1;
}

function getTranslationForErrorCode(code: UniquePoiErrorCodes): string {
    switch (code) {
        case UniquePoiErrorCodes.Unique:
            return $localize`:@@FOOTFALL_UNIQUE_POI_VALIDATOR_ERROR_MESSAGE:FOOTFALL_UNIQUE_POI_VALIDATOR_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum UniquePoiErrorCodes {
    Unique = '72c9e34f-6d81-4d8f-9094-a5b671fa89ba',
}

export {FootfallUniquePoiValidator};
