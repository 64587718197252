import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class FootfallConversionTimeValueValidator implements Validator {
    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || typeof control.value === 'undefined') {
            return getValidationError(FootfallConversionTimeValueErrorCode.Required);
        }

        if (!Number.isFinite(control.value)) {
            return getValidationError(FootfallConversionTimeValueErrorCode.InvalidValue);
        }

        return null;
    }
}

function getValidationError(code: FootfallConversionTimeValueErrorCode): ValidationErrors {
    return {[code]: getTranslationForErrorCode(code)};
}

function getTranslationForErrorCode(code: FootfallConversionTimeValueErrorCode): string {
    switch (code) {
        case FootfallConversionTimeValueErrorCode.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        case FootfallConversionTimeValueErrorCode.InvalidValue:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_CONVERSION_TIME_INVALID_VALUE_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_CONVERSION_TIME_INVALID_VALUE_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum FootfallConversionTimeValueErrorCode {
    Required = '50b7d826-296e-46b1-b33e-192d3923477c',
    InvalidValue = '1ecbc96f-fe4d-4539-be20-40ab05008e0b',
}

export {FootfallConversionTimeValueValidator};
