import {NgModule} from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LibsExpansionPanelComponent} from './expansion-panel.component';
import {CommonModule} from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';

@NgModule({
    imports: [
        CommonModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatTooltipModule,
        CommonsStatusIconModule,
    ],
    exports: [
        LibsExpansionPanelComponent,
    ],
    declarations: [
        LibsExpansionPanelComponent,
    ],
})
class LibsExpansionPanelModule {}

export {LibsExpansionPanelModule};
