import {Observable} from 'rxjs';
import {DspReportMetrics} from './dsp-reporting.types';
import {FootfallReportMetrics} from './footfall-reporting.types';
import {UniqueImpressionsReportMetrics} from './unique-impressions-reporting.types';
import {PTVReportMetrics} from './ptv-reporting.types';
import {ViewabilityReportMetrics} from './viewability-reporting.types';

enum ReportBusinessObjectType {
    Networks = 'Networks',
    Advertiser = 'Advertiser',
    Orders = 'Orders',
    Campaigns = 'Campaigns',
    Banners = 'Banners',
    Deals = 'Deals',
}

enum ReportingTabTypes {
    DspReporting,
    FootfallReporting,
    UniqueImpressionsReporting,
    PtvReporting,
    ViewabilityReporting,
}

type ReportMetrics =
    | DspReportMetrics
    | UniqueImpressionsReportMetrics
    | FootfallReportMetrics
    | PTVReportMetrics
    | ViewabilityReportMetrics;

interface IDeliveryGoalData {
    daily?: IDeliveryGoal;
    currentRuntime?: IDeliveryGoal;
    total?: IDeliveryGoal;
}

interface IDeliveryGoal {
    impressions: Observable<number>;
    budget: Observable<number>;
    clicks: Observable<number>;
}

export {
    ReportBusinessObjectType,
    ReportMetrics,
    ReportingTabTypes,
    IDeliveryGoalData,
    IDeliveryGoal,
};
