import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class FootfallRadiusValidator implements Validator {
    public static readonly min: number = 30;
    public static readonly max: number = 1_000;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const radius: number | undefined = control.value;

        if (radius === undefined || radius === null) {
            return null;
        }

        if (!Number.isFinite(radius)) {
            return {[RadiusErrorCodes.InvalidFormat]: this.getTranslationForErrorCode(RadiusErrorCodes.InvalidFormat)};
        }

        if (radius < FootfallRadiusValidator.min) {
            return {[RadiusErrorCodes.Min]: this.getTranslationForErrorCode(RadiusErrorCodes.Min)};
        }

        if (radius > FootfallRadiusValidator.max) {
            return {[RadiusErrorCodes.Max]: this.getTranslationForErrorCode(RadiusErrorCodes.Max)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: RadiusErrorCodes): string {
        switch (code) {
            case RadiusErrorCodes.InvalidFormat:
                return $localize`:@@FOOTFALL_RADIUS_VALIDATOR_INVALID_FORMAT_ERROR_MESSAGE:FOOTFALL_RADIUS_VALIDATOR_INVALID_FORMAT_ERROR_MESSAGE`;
            case RadiusErrorCodes.Min:
                return $localize`:@@FOOTFALL_RADIUS_VALIDATOR_MIN_ERROR_MESSAGE:FOOTFALL_RADIUS_VALIDATOR_MIN_ERROR_MESSAGE ${this.decimalPipe.transform(FootfallRadiusValidator.min)}:INTERPOLATION:`;
            case RadiusErrorCodes.Max:
                return $localize`:@@FOOTFALL_RADIUS_VALIDATOR_MAX_ERROR_MESSAGE:FOOTFALL_RADIUS_VALIDATOR_MAX_ERROR_MESSAGE ${this.decimalPipe.transform(FootfallRadiusValidator.max)}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum RadiusErrorCodes {
    Min = '88ba7b35-0b4a-45e9-a2eb-3b24acaa5337',
    Max = '163e3a2d-d743-4bab-bbc5-9fe1a93790ce',
    InvalidFormat = 'c59b4adb-ccbd-47df-b047-9b8d2358f8f6',
}

export {FootfallRadiusValidator};
