import {CommonsError} from '@active-agent/types';
import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class FootfallConversionTimeValidator implements Validator {
    public static readonly minMinutes: number = 1;
    public static readonly maxDays: number = 30;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const valueControl: AbstractControl = control.get('value') as AbstractControl;
        const unitControl: AbstractControl = control.get('unit') as AbstractControl;

        if (Number.isFinite(valueControl.value) && unitControl.value) {
            if (
                FootfallConversionTimeValidator
                    .minutes(valueControl.value, unitControl.value) < FootfallConversionTimeValidator.minMinutes
            ) {
                return getValidationError(FootfallConversionTimeErrorCode.MinRuntime);
            }

            if (FootfallConversionTimeValidator.days(valueControl.value, unitControl.value) > FootfallConversionTimeValidator.maxDays) {
                return getValidationError(FootfallConversionTimeErrorCode.MaxRuntime);
            }
        }

        return null;
    }

    public static minutes(value: number, unit: TimeUnit): number {
        switch (unit) {
            case TimeUnit.Minute:
                return value;
            case TimeUnit.Hour:
                return value * 60;
            case TimeUnit.Day:
                return value * 24 * 60;
            default:
                throw new CommonsError('Invalid time unit in minutes', {data: {unit}});
        }
    }

    public static days(value: number, unit: TimeUnit): number {
        switch (unit) {
            case TimeUnit.Minute:
                return value / (60 * 24);
            case TimeUnit.Hour:
                return value / 24;
            case TimeUnit.Day:
                return value;
            default:
                throw new CommonsError('Invalid time unit in days', {data: {unit}});
        }
    }
}

function getValidationError(code: FootfallConversionTimeErrorCode): ValidationErrors {
    return {[code]: getTranslationForErrorCode(code)};
}

function getTranslationForErrorCode(code: FootfallConversionTimeErrorCode): string {
    switch (code) {
        case FootfallConversionTimeErrorCode.MinRuntime:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_CONVERSION_TIME_MIN_RUNTIME_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_CONVERSION_TIME_MIN_RUNTIME_ERROR_MESSAGE`;
        case FootfallConversionTimeErrorCode.MaxRuntime:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_CONVERSION_TIME_MAX_RUNTIME_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_CONVERSION_TIME_MAX_RUNTIME_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

const enum TimeUnit {
    Minute = 'Minute',
    Hour = 'Hour',
    Day = 'Day',
}

const enum FootfallConversionTimeErrorCode {
    MinRuntime = 'd4b8e5b1-8c37-4b15-afe4-0232d5d217d2',
    MaxRuntime = 'ec749161-d30f-4a20-aabf-c7da36053cae',
}

export {FootfallConversionTimeValidator, TimeUnit};
