import {BannerTypes} from '../banner/banner-types';

enum FootfallReportDimensions {
    AgencyId = 'AGENCY_ID',
    AgencyName = 'AGENCY_NAME',
    AdvertiserId = 'ADVERTISER_ID',
    AdvertiserName = 'ADVERTISER_NAME',
    NetworkId = 'NETWORK_ID',
    NetworkName = 'NETWORK_NAME',
    OrderId = 'ORDER_ID',
    OrderName = 'ORDER_NAME',
    CampaignId = 'CAMPAIGN_ID',
    CampaignName = 'CAMPAIGN_NAME',
    BannerId = 'BANNER_ID',
    BannerName = 'BANNER_NAME',
    BannerSize = 'BANNER_SIZE',
    BannerType = 'BANNER_TYPE',
    Date = 'DATE',
    Year = 'YEAR',
    Month = 'MONTH',
    Day = 'DAY',
    Hour = 'HOUR',
    StorePoiId = 'STORE_POI_ID',
    StorePoiName = 'STORE_POI_NAME',
    StorePoiLatitude = 'STORE_POI_LATITUDE',
    StorePoiLongitude = 'STORE_POI_LONGITUDE',
    BannerTemplateId = 'BANNER_TEMPLATE_ID',
    BannerWidth = 'BANNER_WIDTH',
    BannerHeight = 'BANNER_HEIGHT',
}

enum FootfallReportMetrics {
    ControlConversionCount = 'CONTROL_CONVERSION_COUNT',
    VisitationUplift = 'VISITATION_UPLIFT',
    ExposureCount = 'EXPOSURE_COUNT',
    ConversionCount = 'CONVERSION_COUNT',
    ControlExposureCount = 'CONTROL_EXPOSURE_COUNT',
    VisitationRate = 'VISITATION_RATE',
    Exposures = 'EXPOSURES',
    ConvertedExposures = 'CONVERTED_EXPOSURES',
}

// eslint-disable-next-line @typescript-eslint/typedef
const FootfallReportField = {...FootfallReportDimensions, ...FootfallReportMetrics};
// eslint-disable-next-line no-redeclare
type FootfallReportField = FootfallReportDimensions | FootfallReportMetrics;

interface IFootfallReportResult {
    // dimensions
    [FootfallReportField.NetworkId]?: number;
    [FootfallReportField.AdvertiserId]?: number;
    [FootfallReportField.AgencyId]?: number;
    [FootfallReportField.OrderId]?: number;
    [FootfallReportField.CampaignId]?: number;
    [FootfallReportField.BannerId]?: number;
    [FootfallReportField.StorePoiId]?: number;
    [FootfallReportField.Date]?: string;
    [FootfallReportField.Year]?: number;
    [FootfallReportField.Month]?: number;
    [FootfallReportField.Day]?: number;
    [FootfallReportField.Hour]?: number;

    // fields
    [FootfallReportField.ControlConversionCount]?: number;
    [FootfallReportField.ControlExposureCount]?: number;
    [FootfallReportField.ConversionCount]?: number;
    [FootfallReportField.ExposureCount]?: number;

    // additional fields
    [FootfallReportField.NetworkName]?: string;
    [FootfallReportField.AdvertiserName]?: string;
    [FootfallReportField.AgencyName]?: string;
    [FootfallReportField.OrderName]?: string;
    [FootfallReportField.CampaignName]?: string;
    [FootfallReportField.BannerName]?: string;
    [FootfallReportField.BannerTemplateId]?: number;
    [FootfallReportField.BannerType]?: BannerTypes;
    [FootfallReportField.BannerWidth]?: number;
    [FootfallReportField.BannerHeight]?: number;
    [FootfallReportField.StorePoiName]?: string;
    [FootfallReportField.StorePoiLatitude]?: number;
    [FootfallReportField.StorePoiLongitude]?: number;
}

export {
    IFootfallReportResult,
    FootfallReportField,
    FootfallReportMetrics,
    FootfallReportDimensions,
};
