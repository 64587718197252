import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IDomainFilterProperties extends IFilterProperties {
    domains: Array<string>;
    inverted: boolean;
}

class DomainFilter extends Filter<FilterTypes.DOMAIN> {
    protected readonly _type: FilterTypes.DOMAIN = FilterTypes.DOMAIN;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = true;
    protected readonly props: IDomainFilterProperties = this.getDefaultOptions();
    private _isValid: boolean = true;

    public updateValidStatus(newStatus: boolean): void {
        this._isValid = newStatus;
    }

    public isValid(): Observable<boolean> {
        return of(this._isValid);
    }

    public setIsValid(value: boolean): void{
        this._isValid = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return this.isInverted().pipe(map((inverted: boolean) => !inverted));
    }

    public isInverted(): Observable<boolean> {
        return of(this.props.inverted);
    }

    get inverted(): boolean {
        return this.props.inverted;
    }

    set inverted(value: boolean) {
        this.props.inverted = value;
    }

    get domains(): Array<string> {
        return this.props.domains;
    }

    set domains(value: Array<string>) {
        this.props.domains = value;
    }

    protected getDefaultOptions(): IDomainFilterProperties {
        return {
            ...super.getDefaultOptions(),
            domains: [],
            inverted: false,
        };
    }
}

export {DomainFilter, IDomainFilterProperties};
