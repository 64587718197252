import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonsListItemModule} from '../list-item/list-item.module';
import {MatIconModule} from '@angular/material/icon';
import {CommonsTextListItemComponent} from './text-list-item.component';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';

@NgModule({
    imports: [
        CommonModule,
        CommonsStatusIconModule,
        CommonsListItemModule,
        MatIconModule,
    ],
    exports: [
        CommonsTextListItemComponent,
    ],
    declarations: [
        CommonsTextListItemComponent,
    ],
})
class CommonsTextListItemModule {}

export {CommonsTextListItemModule};
