import {AbstractControl, ValidationErrors} from '@angular/forms';
import {getUrlAditionPattern} from '@active-agent/pattern';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';
@Injectable({
    providedIn: 'root',
})
class WrapperUrlValidator {
    public static readonly maxLength: number = 1000;
    public static readonly pattern: RegExp = getUrlAditionPattern();

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const wrapperUrl: string = control.value;
        if (wrapperUrl === undefined || wrapperUrl === null || !wrapperUrl.length) {
            return {[WrapperUrlErrorCodes.Required]: this.getTranslationForErrorCode(WrapperUrlErrorCodes.Required)};
        }

        if (wrapperUrl.length > WrapperUrlValidator.maxLength) {
            return {[WrapperUrlErrorCodes.MaxLength]: this.getTranslationForErrorCode(WrapperUrlErrorCodes.MaxLength)};
        }

        if (!WrapperUrlValidator.pattern.test(wrapperUrl)) {
            return {[WrapperUrlErrorCodes.Pattern]: this.getTranslationForErrorCode(WrapperUrlErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: WrapperUrlErrorCodes): string {
        switch (code) {
            case WrapperUrlErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case WrapperUrlErrorCodes.MaxLength:
                const max: string | null = this.decimalPipe.transform(WrapperUrlValidator.maxLength);

                return $localize`:@@VALIDATOR_WRAPPER_URL_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_WRAPPER_URL_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            case WrapperUrlErrorCodes.Pattern:
                return $localize`:@@VALIDATOR_WRAPPER_URL_PATTERN_ERROR_MESSAGE:VALIDATOR_WRAPPER_URL_PATTERN_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum WrapperUrlErrorCodes {
    Required = 'cc03f2a6-692f-49cb-a6a3-a31a2b93619d',
    MaxLength = '19efa1e1-6541-4a3f-a133-c44fb218dfe4',
    Pattern = 'b662273c-63e8-4d93-ba48-62030660b89e',
}

export {WrapperUrlValidator, WrapperUrlErrorCodes};
