import {pluralize} from '@active-agent/pluralize';

function getRequestsTranslation(requests: number): string {
    return pluralize(
        {
            1: $localize`:@@REQUESTS_SINGULAR:REQUESTS_SINGULAR ${requests}:INTERPOLATION:`,
            other: $localize`:@@REQUESTS_PLURAL:REQUESTS_PLURAL ${requests}:INTERPOLATION:`,
        },
        requests,
    );
}

export {getRequestsTranslation};
