import {Banner, BaseBanner} from '../base-banner';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {IPtvAdSpotBanner} from '../banner';

class PtvAdSpotBanner extends BaseBanner implements IPtvAdSpotBanner, ICloneable<PtvAdSpotBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes.PTV_AD_SPOT = BannerTypes.PTV_AD_SPOT;
    private _adSpotId: string;
    private _duration: number;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        adSpotId: string,
        duration: number,
    ) {
        super(id, networkId, name, '');
        this._adSpotId = adSpotId;
        this._duration = duration;
    }

    get type(): BannerTypes.PTV_AD_SPOT {
        return this._type;
    }

    get adSpotId(): string {
        return this._adSpotId;
    }

    set adSpotId(adSpotId: string) {
        this._adSpotId = adSpotId;
    }

    get duration(): number {
        return this._duration;
    }

    set duration(duration: number) {
        this._duration = duration;
    }

    public merge(source: Banner): void {
        super.merge(source);
        PtvAdSpotBanner.fillAdditionalFields(this, source);
    }

    public clone(): PtvAdSpotBanner {
        const newModel: PtvAdSpotBanner = new PtvAdSpotBanner(
            this.id,
            this.networkId,
            this.name,
            this.adSpotId,
            this.duration,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: PtvAdSpotBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('adSpotId' in source) {
            target.adSpotId = source.adSpotId;
        }

        if ('duration' in source && typeof source.duration === 'number') {
            target.duration = source.duration;
        }
    }
}

function isPtvAdSpotBanner(banner: unknown): banner is PtvAdSpotBanner {
    return banner instanceof PtvAdSpotBanner;
}

export {PtvAdSpotBanner, isPtvAdSpotBanner};
