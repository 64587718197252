import {Input, Component, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {CommonsError, IIcon} from '@active-agent/types';
import {CommonsBorderStyle} from '../list-item/list-item.component';
import {IconSize, Status} from '../status-icon/status-icon.component';

@Component({
    selector: 'commons-text-list-item',
    templateUrl: './text-list-item.html',
    styleUrls: ['./text-list-item.scss'],
})
class CommonsTextListItemComponent implements OnInit {
    @Input() public title!: string;
    public readonly titleTooltipIconSize: IconSize = IconSize.Small;
    public readonly titleTooltipInfoStatus: Status = Status.Info;
    @Input() public titleTooltip: string = '';
    @Input() public subtitle?: string;
    @Input() public isActive: boolean | null = null;
    @Input() public hasStatusIcon: boolean = true;
    @Input() public iconColor: ThemePalette | null = null;
    @Input() public customStatusIcon: IIcon | null = null;
    @Input() public borderStyle: CommonsBorderStyle = 'dotted';
    @Input() public hasPadding: boolean = true;
    @Input() public enableEllipsis: boolean = true;
    @Input() public reverseOrder: boolean = false;
    public successStatus: Status = Status.Success;

    public ngOnInit(): void {
        if (!this.title) {
            throw new CommonsError('title component binding has to be provided');
        }
    }
}

export {CommonsTextListItemComponent};
