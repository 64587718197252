import {CommonsError} from '../../error/error';
import {AuctionType} from './auction-type-filter-import';

const auctionTypeFilterOptions: Array<IAuctionTypeFilterOption> = [
    {
        id: AuctionType.Unknown,
        translation: $localize`:@@AUCTION_TYPE_FILTER_OPTION_UNKNOWN_NAME:AUCTION_TYPE_FILTER_OPTION_UNKNOWN_NAME`,
        description: $localize`:@@AUCTION_TYPE_FILTER_OPTION_UNKNOWN_DESCRIPTION:AUCTION_TYPE_FILTER_OPTION_UNKNOWN_DESCRIPTION`,
        active: true,
    },
    {
        id: AuctionType.FirstPrice,
        translation: $localize`:@@AUCTION_TYPE_FILTER_OPTION_FIRST_PRICE_NAME:AUCTION_TYPE_FILTER_OPTION_FIRST_PRICE_NAME`,
        description: $localize`:@@AUCTION_TYPE_FILTER_OPTION_FIRST_PRICE_DESCRIPTION:AUCTION_TYPE_FILTER_OPTION_FIRST_PRICE_DESCRIPTION`,
        active: true,
    },
    {
        id: AuctionType.SecondPrice,
        translation: $localize`:@@AUCTION_TYPE_FILTER_OPTION_SECOND_PRICE_NAME:AUCTION_TYPE_FILTER_OPTION_SECOND_PRICE_NAME`,
        description: $localize`:@@AUCTION_TYPE_FILTER_OPTION_SECOND_PRICE_DESCRIPTION:AUCTION_TYPE_FILTER_OPTION_SECOND_PRICE_DESCRIPTION`,
        active: true,
    },
];

interface IAuctionTypeFilterOption {
    id: AuctionType;
    active: boolean;
    translation: string;
    description: string;
}

function getAuctionTypeFilterOptionTranslation(auctionType: AuctionType): string {
    const auctionTypeFilterOption: IAuctionTypeFilterOption | undefined = auctionTypeFilterOptions
        .find((option: IAuctionTypeFilterOption) => option.id === auctionType);

    if (!auctionTypeFilterOption) {
        throw new CommonsError('Translation no found');
    }

    return auctionTypeFilterOption.translation;
}

export {auctionTypeFilterOptions, IAuctionTypeFilterOption, getAuctionTypeFilterOptionTranslation};
