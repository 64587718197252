import {ICampaignDeal} from '../campaign/campaign-deals.types';
import {ICloneable, IMergable} from '../types';

class CampaignDeal implements ICampaignDeal, IMergable<CampaignDeal>, ICloneable<CampaignDeal> {
    private readonly _id: number | null;
    private readonly _networkId: number;
    private _campaignId: number;
    private _dealId: string;
    private _name: string;
    private _ssp: string;
    private _maxCpm: number | null = null;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;
    private _externalDealId: number | undefined;

    constructor(
        id: number | null,
        networkId: number,
        campaignId: number,
        dealId: string,
        name: string,
        ssp: string,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._campaignId = campaignId;
        this._dealId = dealId;
        this._name = name;
        this._ssp = ssp;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    get campaignId(): number {
        return this._campaignId;
    }

    set campaignId(value: number) {
        this._campaignId = value;
    }

    get dealId(): string {
        return this._dealId;
    }

    set dealId(value: string) {
        this._dealId = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get ssp(): string {
        return this._ssp;
    }

    set ssp(value: string) {
        this._ssp = value;
    }

    get maxCpm(): number | null {
        return this._maxCpm;
    }

    set maxCpm(value: number | null) {
        this._maxCpm = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get externalDealId(): number | undefined {
        return this._externalDealId;
    }

    set externalDealId(value: number | undefined) {
        this._externalDealId = value;
    }

    public merge(source: CampaignDeal): void {
        this.dealId = source.dealId;
        this.name = source.name;
        this.ssp = source.ssp;
        this.maxCpm = source.maxCpm;
        this.externalDealId = source.externalDealId;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }

    public clone(): CampaignDeal {
        const newModel: CampaignDeal = new CampaignDeal(
            this.id,
            this.networkId,
            this.campaignId,
            this.dealId,
            this.name,
            this.ssp,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {CampaignDeal};
