import {Observable} from 'rxjs';
import {IHttpHeader, IHttpParam} from './api';
import {IGenericObject} from '@active-agent/types';
import {chunkArray} from '@active-agent/std';
import {isDevMode} from '@angular/core';

abstract class CommonsRequestBuilder {
    protected readonly baseUrl: string;
    private resourcePath: string;
    private subResourcePath: string | undefined;
    private params: Map<string, IHttpParam> = new Map<string, IHttpParam>();
    private headers: Map<string, IHttpHeader> = new Map<string, IHttpHeader>();
    private queryIds: Set<number | string> | null = null;

    protected constructor(
        resourcePath: string,
        baseUrl: string,
    ) {
        this.resourcePath = resourcePath;
        this.baseUrl = baseUrl;

        if (!isDevMode()) {
            this.bypassServiceWorker(true);
        }
    }

    public bypassServiceWorker(bypass: boolean): this {
        if (bypass) {
            this.addHeader({key: 'ngsw-bypass', value: 'true'});
        } else {
            this.removeHeader('ngsw-bypass');
        }

        return this;
    }

    /**
     * Specify a set of ids that should be requested.
     *
     */
    public addQueryIds(ids: Array<number | string>): this {
        if (!this.queryIds) {
            this.queryIds = new Set();
        }
        for (const id of ids) {
            this.queryIds.add(id);
        }

        return this;
    }

    /**
     * Adds a query param to the request
     *
     */
    public addQueryParam(param: IHttpParam): this {
        this.params.set(param.key, param);

        return this;
    }

    /**
     * Removes the given query param.
     *
     * @param key of the param that should be removed
     */
    public removeQueryParam(key: string): this {
        this.params.delete(key);

        return this;
    }

    /**
     * Adds a header to the request.
     */
    public addHeader(header: IHttpHeader): this {
        this.headers.set(header.key, header);

        return this;
    }

    /**
     * Removes the header with the given key from the request.
     */
    public removeHeader(key: string): this {
        this.headers.delete(key);

        return this;
    }

    /**
     * Returns the base url that can be used for an api request.
     * Note: Does not include subResource path. Please implement this manually
     *
     */
    public getApiBaseUrl(): string {
        return `${this.baseUrl}/${this.resourcePath}`;
    }

    /**
     * Sets the sub resource path.
     * Note: You still need to implement the logic for the proper url in your class that extends this abstract class. The base url can only
     * define the url up to the resource path.
     * Afterwards it can differ, e.g. resourcePath/someIds/subResource or resourcePath/subResourcePath
     *
     * @param subResourcePath name of the subResourcePath
     */
    public setSubResourcePath(subResourcePath: string): this {
        this.subResourcePath = subResourcePath;

        return this;
    }

    /**
     * abstract method to create a valid api request and fetches the result from the api
     *
     */
    public abstract build(): Observable<IGenericObject | void>;

    /**
     * Prepares the query params to be usable for the HttpClient. Array values will be joined with a comma and each value will be casted as
     * string.
     *
     * @returns prepared params
     */
    protected getQueryParamsForRequest(): {[param: string]: string} {
        return Array.from(this.params.values())
            .reduce((result: {[param: string]: string}, current: IHttpParam): {[param: string]: string} => {
                const queryParamValue: string | null = getQueryParamValue(current);
                if (queryParamValue === null) {
                    return result;
                }

                result[current.key] = queryParamValue;

                return result;
            }, {});
    }

    /**
     * In contrast to the `getQueryParamsForRequest` this method splits up the requests based on the given query
     * parameter and chunk size
     * Only works if the value of the chunkQueryParam is an array
     *
     * Prepares the query params to be usable for the HttpClient. Array values will be joined with a comma and each value will be casted as
     * string.
     *
     * @returns prepared params
     */
    protected getChunkedQueryParamsForRequest(chunkSize: number, queryParam: string): Array<{[param: string]: string}> {
        const chunkableParam: IHttpParam | undefined = this.params.get(queryParam);
        if (!chunkableParam || !Array.isArray(chunkableParam.value)) {
            return [this.getQueryParamsForRequest()];
        }
        const values: Array<string | number> = chunkableParam.value;

        const defaultParams: {[param: string]: string} = Array.from(this.params.values())
            .reduce((result: {[param: string]: string}, current: IHttpParam): {[param: string]: string} => {
                if (current.key === queryParam) {
                    return result;
                }

                const queryParamValue: string | null = getQueryParamValue(current);
                if (queryParamValue === null) {
                    return result;
                }

                result[current.key] = queryParamValue;

                return result;
            }, {});

        return chunkArray<string | number>(values, chunkSize).map((chunk: Array<number | string>): {[param: string]: string} => {
            return {
                ...defaultParams,
                [queryParam]: chunk.join(','),
            };
        });
    }

    protected getHeadersForRequest(): { [header: string]: string } {
        return Array.from(this.headers.values())
            .reduce((result: { [header: string]: string }, current: IHttpHeader): { [header: string]: string } => {
                result[current.key] = current.value;

                return result;
            }, {});
    }

    protected getSubResourcePath(): string | undefined {
        return this.subResourcePath;
    }

    protected getQueryIds(): Array<number | string> | null {
        return this.queryIds ? Array.from(this.queryIds.values()) : null;
    }

    protected getResourcePath(): string {
        return this.resourcePath;
    }

    protected setResourcePath(resourcePath: string): this {
        this.resourcePath = resourcePath;

        return this;
    }
}

function getQueryParamValue(param: IHttpParam): string | null {
    let value: string | number | boolean | Array<string | number> = param.value;
    if (Array.isArray(value)) {
        if (value.length) {
            value = value.join(',');
        } else {
            return null;
        }
    } else {
        value = String(value);
    }
    if (value.length === 0) {
        return null;
    }

    return value;
}

export {CommonsRequestBuilder};
