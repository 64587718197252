import {VastBanner} from './vast-banner';
import {BannerPoolBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {IBannerPoolBanner} from '../banner';

class BannerPoolVastBanner extends VastBanner implements
    IBannerPoolBanner,
    ICloneable<BannerPoolVastBanner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(id: number | null, networkId: number, name: string, landingPage: string, vastXml: string, bannerPoolId: number) {
        super(id, networkId, name, landingPage, vastXml);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolVastBanner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolVastBanner {
        const newModel: BannerPoolVastBanner = new BannerPoolVastBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.vastXml,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolVastBanner, source: BannerPoolBanner): void {
        VastBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolVastBanner};
