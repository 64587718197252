import {BiddingStrategy} from './campaign';

const biddingStrategies: Map<string, IBiddingStrategy> = new Map([
    ['MAXPRICE', {
        id: 'MAXPRICE',
        translation: $localize`:@@BIDDING_STRATEGY_MAX_PRICE:BIDDING_STRATEGY_MAX_PRICE`,
        description: $localize`:@@BIDDING_STRATEGY_MAX_PRICE_DESCRIPTION:BIDDING_STRATEGY_MAX_PRICE_DESCRIPTION`,
    }],
    ['FLOORPRICE', {
        id: 'FLOORPRICE',
        translation: $localize`:@@BIDDING_STRATEGY_FLOOR_PRICE:BIDDING_STRATEGY_FLOOR_PRICE`,
        description: $localize`:@@BIDDING_STRATEGY_FLOOR_PRICE_DESCRIPTION:BIDDING_STRATEGY_FLOOR_PRICE_DESCRIPTION`,
    }],
    ['INTERVAL_BUDGET_OPTIMIZED', {
        id: 'INTERVAL_BUDGET_OPTIMIZED',
        translation: $localize`:@@BIDDING_STRATEGY_BEST_PRICE:BIDDING_STRATEGY_BEST_PRICE`,
        description: $localize`:@@BIDDING_STRATEGY_BEST_PRICE_DESCRIPTION:BIDDING_STRATEGY_BEST_PRICE_DESCRIPTION`,
    }],
]);

function getBiddingStrategyTranslation(value: BiddingStrategy): string {
    const biddingStrategy: IBiddingStrategy | undefined = biddingStrategies.get(value);

    return biddingStrategy ? biddingStrategy.translation : '';
}

interface IBiddingStrategy {
    id: BiddingStrategy;
    translation: string;
    description: string;
}

export {biddingStrategies, IBiddingStrategy, getBiddingStrategyTranslation};
