import {IBannerPublisherTag} from './banner-publisher-tag.types';
import {ICloneable, IMergable} from '../types';

class BannerPublisherTag implements IBannerPublisherTag, IMergable<BannerPublisherTag>, ICloneable<BannerPublisherTag> {
    private _bannerId: number;
    private _tag: string;

    constructor(
        bannerId: number,
        tag: string,
    ) {
        this._bannerId = bannerId;
        this._tag = tag;
    }

    public get bannerId(): number {
        return this._bannerId;
    }

    public set bannerId(value: number) {
        this._bannerId = value;
    }

    public get tag(): string {
        return this._tag;
    }

    public set tag(value: string) {
        this._tag = value;
    }

    public merge(source: BannerPublisherTag): void {
        this.bannerId = source.bannerId;
        this.tag = source.tag;
    }

    public clone(): BannerPublisherTag {
        const newModel: BannerPublisherTag = new BannerPublisherTag(
            this.bannerId,
            this.tag,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {BannerPublisherTag};
