import {OttoPntaFilter, IOttoPntaFilterProperties} from './otto-pnta-filter';
import {FilterTypes} from '../types/filter.types';

type OttoPntaGeoFilterProperties = IOttoPntaFilterProperties;

class OttoPntaGeoFilter extends OttoPntaFilter<FilterTypes.OTTO_PNTA_GEO> {
    protected readonly _type: FilterTypes.OTTO_PNTA_GEO = FilterTypes.OTTO_PNTA_GEO;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly props: OttoPntaGeoFilterProperties = this.getDefaultOptions();
}

export {OttoPntaGeoFilter, OttoPntaGeoFilterProperties};
