/* eslint-disable @typescript-eslint/ban-types */

import {CommonsError} from '@active-agent/types';
import {Id} from '../record';
import {Observable, OperatorFunction, switchMap, map, of} from 'rxjs';

type SequenceItem<TAggregateValue extends AggregateValue, TItemValue extends ItemValue> =
    | Observable<TItemValue>
    | ((data: Id<TAggregateValue>) => Observable<TItemValue>);

type InitialValue = Record<string, never>;
type AggregateValue = Record<string, unknown>;
type ItemValue = Record<string, unknown>;

function sequenceSwitchMap<
    A extends ItemValue,
    B extends ItemValue,
    C extends ItemValue = {},
    D extends ItemValue = {},
    E extends ItemValue = {},
    F extends ItemValue = {},
    G extends ItemValue = {},
>(
    ...sequenceItems: [
        SequenceItem<InitialValue, A>,
        SequenceItem<A, B>,
        SequenceItem<Id<A & B>, C>?,
        SequenceItem<Id<A & B & C>, D>?,
        SequenceItem<Id<A & B & C & D>, E>?,
        SequenceItem<Id<A & B & C & D & E>, F>?,
        SequenceItem<Id<A & B & C & D & E & F>, G>?,
    ]
): Observable<Id<A & B & C & D & E & F & G>> {
    const ops: Array<OperatorFunction<AggregateValue, AggregateValue>> = sequenceItems
        // eslint-disable-next-line @typescript-eslint/typedef
        .filter(item => item)
        // eslint-disable-next-line @typescript-eslint/typedef
        .map(item => createOperator(item as SequenceItem<AggregateValue, AggregateValue>));

    return of({}).pipe(
        ...ops as [OperatorFunction<AggregateValue, AggregateValue>],
    ) as Observable<Id<A & B & C & D & E & F & G>>;
}

function createOperator(item: SequenceItem<AggregateValue, ItemValue>): OperatorFunction<AggregateValue, AggregateValue> {
    return switchMap((previousAggregatedValue: AggregateValue) => {
        let observable: Observable<ItemValue>;
        if (typeof item === 'function') {
            observable = item(previousAggregatedValue as InitialValue);
        } else {
            observable = item;
        }

        return observable.pipe(
            map((itemValue: ItemValue) => {
                const aggregateKeys: Array<string> = Object.keys(previousAggregatedValue);
                const itemKeys: Array<string> = Object.keys(itemValue);

                const duplicateKeys: Array<string> = itemKeys.filter((itemKey: string) =>
                    aggregateKeys.indexOf(itemKey) !== -1,
                );
                if (duplicateKeys.length) {
                    throw new CommonsError(
                        'Same property detected in multiple objects.',
                        {data: {duplicateKeys}},
                    );
                }

                return {...previousAggregatedValue, ...itemValue};
            }),
        );
    });
}

export {sequenceSwitchMap};
