import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IBrowserFilterProperties extends IFilterProperties {
    browserIds: Array<number>;
    inverted: boolean;
}

class BrowserFilter extends Filter<FilterTypes.BROWSER> {
    protected readonly _type: FilterTypes.BROWSER = FilterTypes.BROWSER;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = true;
    protected readonly props: IBrowserFilterProperties = this.getDefaultOptions();
    private _isValid: boolean= true;

    get inverted(): boolean {
        return this.props.inverted;
    }

    set inverted(value: boolean) {
        this.props.inverted = value;
    }

    get browserIds(): Array<number> {
        return this.props.browserIds;
    }

    set browserIds(value: Array<number>) {
        this.props.browserIds = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return this.isInverted().pipe(map((inverted: boolean) => !inverted));
    }

    public isInverted(): Observable<boolean> {
        return of(this.props.inverted);
    }

    public isValid(): Observable<boolean> {
        return of(this._isValid);
    }

    public setIsValid(value: boolean): void{
        this._isValid = value;
    }

    protected getDefaultOptions(): IBrowserFilterProperties {
        return {
            ...super.getDefaultOptions(),
            browserIds: [],
            inverted: false,
        };
    }
}

export {BrowserFilter, IBrowserFilterProperties};
