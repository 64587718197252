import {Injectable} from '@angular/core';
import {Network, User} from '@active-agent/types';
import {DataService} from './data-service';

@Injectable({
    providedIn: 'root',
})
class LibsAppDataService extends DataService<IAppData> {
    constructor() {
        super({});
    }

    /**
     * @deprecated please try to use the regular `getData` instead.
     */
    public getCurrentNetwork(): Network {
        const network: Network | undefined = this.getLastValue().network;

        if (network) {
            return network;
        }

        throw new Error('no network provided');
    }

    /**
     * @deprecated please try to use the regular `getData` instead.
     */
    public getCurrentUser(): User {
        const user: User | undefined = this.getLastValue().user;

        if (user) {
            return user;
        }

        throw new Error('no user provided');
    }
}

interface IAppData {
    network?: Network;
    user?: User;
}

export {LibsAppDataService, IAppData};
