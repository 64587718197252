<div class="cropped-preview">
    <div [hidden]="true" *ngIf="image">
        <img
            #cropper
            [src]="image"
            class="cropped-preview__cropper-instance"
            alt="cropper preview"
        />
    </div>
    <div class="cropped-preview__preview-container">
        <div #preview class="cropped-preview__preview"></div>
        <div
            class="cropped-preview__overlay vm-flex-direction-row vm-layout-align-center vm-layout-justify-center"
            (click)="openCroppedPreview()"
        >
            <div i18n="@@CROPPED_PREVIEW_SHOW_PREVIEW_OVERLAY_LABEL">CROPPED_PREVIEW_SHOW_PREVIEW_OVERLAY_LABEL</div>
        </div>
    </div>
    <ng-template
        let-width="width"
        let-height="height"
        [ngTemplateOutletContext]="{ width: cropWidth, height: cropHeight }"
        [ngTemplateOutlet]="translation"
        #translation
    >
        <div
            i18n="@@WIDTH_BY_HEIGHT_IN_PIXEL"
            class="cropped-preview__size-label vm-flex-direction-row vm-layout-align-center vm-layout-justify-center"
        >WIDTH_BY_HEIGHT_IN_PIXEL {{width}} {{height}}</div>
    </ng-template>
</div>
