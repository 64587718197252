import {NgModule} from '@angular/core';
import {LibsCodeMirrorComponent} from './code-mirror.component';

@NgModule({
    declarations: [
        LibsCodeMirrorComponent,
    ],
    exports: [
        LibsCodeMirrorComponent,
    ],
})
class LibsCodeMirrorModule {}

export {LibsCodeMirrorModule};
