import {Input, Component, Output, EventEmitter} from '@angular/core';
import {Status} from '../status-icon/status-icon.component';
import {Campaign, isAddressableTvCampaign} from '@active-agent/types';

@Component({
    selector: 'libs-ssl-switch-list-item',
    templateUrl: './ssl-switch-list-item.html',
})
class LibsSslSwitchListItemComponent {
    @Input() public campaign?: Campaign;
    public title: string = $localize`:@@BANNER_FORM_SSL_STATUS_LABEL:BANNER_FORM_SSL_STATUS_LABEL`;
    @Input() public isDisabled: boolean = false;
    @Input() public isActive: boolean | null = null;
    public readonly warningStatus: Status = Status.Warning;
    @Output() private onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public onSwitchChange(isActive: boolean): void {
        if (this.isDisabled) {
            return;
        }

        setTimeout(() => {
            this.isActive = isActive;
            this.onChange.emit(isActive);
        });
    }

    public isAddressableTvCampaign(): boolean {
        return isAddressableTvCampaign(this.campaign);
    }

    public getWarningIconTooltip(): string {
        if (this.isAddressableTvCampaign()) {
            return $localize`:@@BANNER_FORM_ADDRESSABLE_TV_CAMPAIGN_BANNER_SSL_ACTIVE_WARNING_MESSAGE:BANNER_FORM_ADDRESSABLE_TV_CAMPAIGN_BANNER_SSL_ACTIVE_WARNING_MESSAGE`;
        }

        return $localize`:@@BANNER_FORM_SSL_INACTIVE_WARNING_MESSAGE:BANNER_FORM_SSL_INACTIVE_WARNING_MESSAGE`;
    }

    public isWarningIconVisible(): boolean {
        if (this.isActive) {
            return this.isAddressableTvCampaign();
        }

        return !this.isAddressableTvCampaign();
    }
}

export {LibsSslSwitchListItemComponent};
