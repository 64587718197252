<commons-content [fillHeight]="true" [minHeight]="500">
    <libs-card class="not-found-page">
        <div class="not-found-page__wrapper">
            <div class="not-found-page__watermark"></div>
            <span class="not-found-page__title" i18n="@@NOT_FOUND_PAGE_CODE_TITLE">NOT_FOUND_PAGE_CODE_TITLE</span>
            <span class="not-found-page__subtitle" i18n="@@NOT_FOUND_PAGE_CODE_SUBTITLE">NOT_FOUND_PAGE_CODE_SUBTITLE</span>
            <span class="not-found-page__description" i18n="@@NOT_FOUND_PAGE_DESCRIPTION">NOT_FOUND_PAGE_DESCRIPTION</span>
            <span class="not-found-page__countdown-text">{{this.getCountdownTranslation()}}</span>

            <button
                class="not-found-page__button"
                mat-raised-button
                color="primary"
                (click)="goToDefaultPage()"
            >{{getButtonTranslation()}}</button>
        </div>
    </libs-card>
</commons-content>
