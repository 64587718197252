enum PeculiarityTypes {
    Default = 'DEFAULT',
    /**
     * @deprecated Will be replaced with FIRST_PARTY_PERCENTAGE_BILLING
     */
    SomCrossDevice = 'SOM_CROSS_DEVICE',
    FirstPartyFlatBilling = 'FIRST_PARTY_FLAT_BILLING',
    FirstPartyPercentageBilling = 'FIRST_PARTY_PERCENTAGE_BILLING',
}

function getTranslationForPeculiarityType(type: PeculiarityTypes): string {
    const translations: Record<PeculiarityTypes, string> = {
        [PeculiarityTypes.Default]: $localize`:@@PECULIARITY_TYPE_DEFAULT:PECULIARITY_TYPE_DEFAULT`,
        [PeculiarityTypes.FirstPartyFlatBilling]: $localize`:@@PECULIARITY_TYPE_FLAT_BILLING:PECULIARITY_TYPE_FLAT_BILLING`,
        [PeculiarityTypes.FirstPartyPercentageBilling]: $localize`:@@PECULIARITY_TYPE_PERCENTAGE_BILLING:PECULIARITY_TYPE_PERCENTAGE_BILLING`,
        [PeculiarityTypes.SomCrossDevice]: $localize`:@@PECULIARITY_TYPE_PERCENTAGE_BILLING:PECULIARITY_TYPE_PERCENTAGE_BILLING`,
    };

    return String(translations[type]);
}

export {PeculiarityTypes, getTranslationForPeculiarityType};
