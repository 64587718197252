import {Banner, BaseBanner} from '../base-banner';
import {cloneDeep} from 'lodash-es';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {IBannerFile, IBannerSize, IRichMediaBanner} from '../banner';

class RichMediaBanner extends BaseBanner implements IRichMediaBanner, ICloneable<RichMediaBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.RICH_MEDIA;
    private _ssl: boolean = false;
    private _parse: boolean = true;
    private _clickUrl: string | null = null;
    private _size: IBannerSize;
    private _files: Array<IBannerFile> = [];
    private _code: string = '';
    private _preHtmlCode: string = '';
    private _postHtmlCode: string = '';

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        size: IBannerSize,
    ) {
        super(id, networkId, name, landingPage);

        this._size = size;
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get parse(): boolean {
        return this._parse;
    }

    set parse(value: boolean) {
        this._parse = value;
    }

    get clickUrl(): string | null {
        return this._clickUrl;
    }

    set clickUrl(value: string | null) {
        this._clickUrl = value;
    }

    get size(): IBannerSize {
        return this._size;
    }

    set size(value: IBannerSize) {
        this._size = value;
    }

    get files(): Array<IBannerFile> {
        return this._files;
    }

    set files(value: Array<IBannerFile>) {
        this._files = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get preHtmlCode(): string {
        return this._preHtmlCode;
    }

    set preHtmlCode(value: string) {
        this._preHtmlCode = value;
    }

    get postHtmlCode(): string {
        return this._postHtmlCode;
    }

    set postHtmlCode(value: string) {
        this._postHtmlCode = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        RichMediaBanner.fillAdditionalFields(this, source);
    }

    public clone(): RichMediaBanner {
        const newModel: RichMediaBanner = new RichMediaBanner(this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.size,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: RichMediaBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('parse' in source) {
            target.parse = source.parse;
        }
        if ('clickUrl' in source) {
            target.clickUrl = source.clickUrl;
        }
        if ('size' in source) {
            target.size = cloneDeep(source.size);
        }
        if ('files' in source) {
            target.files = cloneDeep(source.files);
        }
        if ('code' in source) {
            target.code = source.code;
        }
        if ('preHtmlCode' in source) {
            target.preHtmlCode = source.preHtmlCode;
        }
        if ('postHtmlCode' in source) {
            target.postHtmlCode = source.postHtmlCode;
        }
    }
}

export {RichMediaBanner};
