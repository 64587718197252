import {CommonsError} from '../../error/error';
import {
    parseVideoPositionProfile,
    VideoStartPosition,
    VideoPlacement,
    IVideoPositionProfile,
} from './video-start-position-filter-import';

class VideoPositionFilterParser {
    public static getPlacements(): Array<IVideoPlacement> {
        return [
            {
                name: VideoPlacement.InStream,
                active: true,
                translation: $localize`:@@VIDEO_POSITION_FILTER_INSTREAM_LABEL:VIDEO_POSITION_FILTER_INSTREAM_LABEL`,
                previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_INSTREAM_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_INSTREAM_PREVIEW_IMAGE_DESCRIPTION`,
                startPositions: [
                    {
                        name: VideoStartPosition.PreRoll,
                        active: true,
                        translation: $localize`:@@VIDEO_POSITION_FILTER_INSTREAM_PRE_ROLL_LABEL:VIDEO_POSITION_FILTER_INSTREAM_PRE_ROLL_LABEL`,
                        previewImageFilename: 'start-position-pre-roll.svg',
                        previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_INSTREAM_PRE_ROLL_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_INSTREAM_PRE_ROLL_PREVIEW_IMAGE_DESCRIPTION`,
                    },
                    {
                        name: VideoStartPosition.MidRoll,
                        active: true,
                        translation: $localize`:@@VIDEO_POSITION_FILTER_INSTREAM_MID_ROLL_LABEL:VIDEO_POSITION_FILTER_INSTREAM_MID_ROLL_LABEL`,
                        previewImageFilename: 'start-position-mid-roll.svg',
                        previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_INSTREAM_MID_ROLL_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_INSTREAM_MID_ROLL_PREVIEW_IMAGE_DESCRIPTION`,
                    },
                    {
                        name: VideoStartPosition.PostRoll,
                        active: true,
                        translation: $localize`:@@VIDEO_POSITION_FILTER_INSTREAM_POST_ROLL_LABEL:VIDEO_POSITION_FILTER_INSTREAM_POST_ROLL_LABEL`,
                        previewImageFilename: 'start-position-post-roll.svg',
                        previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_INSTREAM_POST_ROLL_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_INSTREAM_POST_ROLL_PREVIEW_IMAGE_DESCRIPTION`,
                    },
                ],
            },
            {
                name: VideoPlacement.OutStream,
                active: true,
                translation: $localize`:@@VIDEO_POSITION_FILTER_OUT_STREAM_LABEL:VIDEO_POSITION_FILTER_OUT_STREAM_LABEL`,
                previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_OUT_STREAM_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_OUT_STREAM_PREVIEW_IMAGE_DESCRIPTION`,
                startPositions: [
                    {
                        name: VideoStartPosition.InArticle,
                        active: true,
                        translation: $localize`:@@VIDEO_POSITION_FILTER_OUT_STREAM_IN_ARTICLE_LABEL:VIDEO_POSITION_FILTER_OUT_STREAM_IN_ARTICLE_LABEL`,
                        previewImageFilename: 'start-position-in-article.svg',
                        previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_OUT_STREAM_IN_ARTICLE_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_OUT_STREAM_IN_ARTICLE_PREVIEW_IMAGE_DESCRIPTION`,
                    },
                    {
                        name: VideoStartPosition.InFeed,
                        active: true,
                        translation: $localize`:@@VIDEO_POSITION_FILTER_OUT_STREAM_IN_FEED_LABEL:VIDEO_POSITION_FILTER_OUT_STREAM_IN_FEED_LABEL`,
                        previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_OUT_STREAM_IN_FEED_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_OUT_STREAM_IN_FEED_PREVIEW_IMAGE_DESCRIPTION`,
                        previewImageFilename: 'start-position-in-feed.svg',
                    },
                    {
                        name: VideoStartPosition.InBanner,
                        active: true,
                        translation: $localize`:@@VIDEO_POSITION_FILTER_OUT_STREAM_IN_BANNER_LABEL:VIDEO_POSITION_FILTER_OUT_STREAM_IN_BANNER_LABEL`,
                        previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_OUT_STREAM_IN_BANNER_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_OUT_STREAM_IN_BANNER_PREVIEW_IMAGE_DESCRIPTION`,
                        previewImageFilename: 'start-position-in-banner.svg',
                    },
                ],
            },
            {
                name: VideoPlacement.Interstitial,
                active: true,
                translation: $localize`:@@VIDEO_POSITION_FILTER_INTERSTITIAL_LABEL:VIDEO_POSITION_FILTER_INTERSTITIAL_LABEL`,
                previewTranslation: $localize`:@@VIDEO_POSITION_FILTER_INTERSTITIAL_PREVIEW_IMAGE_DESCRIPTION:VIDEO_POSITION_FILTER_INTERSTITIAL_PREVIEW_IMAGE_DESCRIPTION`,
                previewImageFilename: 'video-placement-interstitial.svg',
            },
        ];
    }

    /**
     * Converts the targeting profile string for adition to internal configuration objects.
     *
     * @param profileExpression Filters that should be parsed to adition format.
     * @returns String that is sent to the adition profile targeting
     */
    public static fromAdition(profileExpression: string): Array<IVideoPlacement> {
        const profile: Array<IVideoPositionProfile> = parseVideoPositionProfile(profileExpression);
        const placements: Array<IVideoPlacement> = this.getPlacements();

        for (const placement of placements) {
            placement.active = profile.some((data: IVideoPositionProfile) => data.videoPlacement === placement.name);

            if (placement.startPositions) {
                for (const startPosition of placement.startPositions) {
                    startPosition.active = profile.some((data: IVideoPositionProfile) =>
                        data.videoPlacement === placement.name && data.startPositions.includes(startPosition.name),
                    );
                }
            }
        }

        this.validateProfile(profile, placements, profileExpression);

        return placements;
    }

    /**
     * Converts the internal configuration objects to a targeting profile string for adition.
     *
     * @param placements Filters that should be parsed to adition format.
     * @returns String that is sent to the adition profile targeting
     */
    public static toAdition(placements: Array<IVideoPlacement>): string {
        placements = placements.filter((placement: IVideoPlacement) => placement.active && (
            !placement.startPositions || placement.startPositions.some((pos: IVideoStartPosition) => pos.active)
        ));

        return placements
            .map((placement: IVideoPlacement) => {
                const placementExpression: string = `videoPlacement = '${placement.name}'`;

                if (!placement.startPositions) {
                    return placementExpression;
                }

                const startPositions: Array<IVideoStartPosition> = placement.startPositions.filter(
                    (startPosition: IVideoStartPosition) => startPosition.active,
                );

                let startPositionExpression: string = startPositions
                    .map((startPosition: IVideoStartPosition) => `startPosition = '${startPosition.name}'`)
                    .join(' OR ');

                if (startPositions.length > 1) {
                    startPositionExpression = `(${startPositionExpression})`;
                }

                const expression: string = `${placementExpression} AND ${startPositionExpression}`;

                return placements.length > 1 ? `(${expression})` : expression;
            })
            .join(' OR ');
    }

    public static getPreviewImage(event: IVideoPlacement | IVideoStartPosition): string | null {
        return event && event.previewImageFilename
            ? `assets/video-position-filter/${event.previewImageFilename}`
            : null;
    }

    public static getPreviewTranslation(videoPlacement: IVideoPlacement, startPositions: Array<IVideoStartPosition> = []): string {
        const startPositionTranslations: Array<string> = startPositions
            .filter((startPosition: IVideoStartPosition) => startPosition.active)
            .map((startPosition: IVideoStartPosition) => startPosition.translation);

        return `${videoPlacement.translation}${startPositionTranslations.length ? `: ${startPositionTranslations.join(', ')}` : ''}`;
    }

    public static isVideoPlacementWithStartPositions(event: IVideoPlacement | IVideoStartPosition): event is IVideoPlacement {
        return 'startPositions' in event
            ? !!(event.startPositions && event.startPositions.length)
            : false;
    }

    private static validateProfile(
        profile: Array<IVideoPositionProfile>,
        placements: Array<IVideoPlacement>,
        profileExpression: string,
    ) {
        const invalidFilters: Array<IVideoPositionProfile> = [];

        for (const parsedFilter of profile) {
            const isInvalid: boolean = !placements.some((placement: IVideoPlacement) =>
                placement.name === parsedFilter.videoPlacement &&
                (
                    !parsedFilter.startPositions.length ||
                    (
                        placement.startPositions &&
                        placement.startPositions.some(
                            (startPosition: IVideoStartPosition) => parsedFilter.startPositions.includes(startPosition.name),
                        )
                    )
                ),
            );
            if (isInvalid) {
                invalidFilters.push(parsedFilter);
            }
        }

        if (invalidFilters.length) {
            throw new CommonsError(
                'video position profile filter contains invalid options',
                {data: {rawData: profileExpression, invalidFilters}},
            );
        }
    }
}

interface IVideoStartPosition {
    name: VideoStartPosition;
    active: boolean;
    translation: string;
    previewImageFilename: string;
    previewTranslation: string;
}

interface IVideoPlacement {
    name: VideoPlacement;
    active: boolean;
    translation: string;
    startPositions?: Array<IVideoStartPosition>;
    previewImageFilename?: string;
    previewTranslation?: string;
}

export {IVideoStartPosition, IVideoPlacement, VideoPositionFilterParser};
