import {NgModule} from '@angular/core';
import {CommonsStatusIconComponent} from './status-icon.component';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        CommonsStatusIconComponent,
    ],
    imports: [
        MatIconModule,
        CommonModule,
        MatTooltipModule,
    ],
    exports: [
        CommonsStatusIconComponent,
    ],
})
class CommonsStatusIconModule {}

export {CommonsStatusIconModule};
