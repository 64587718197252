import {CommonsError} from '../error/error';

enum AttributeType {
    Tagging = 'TAGGING',
    Dmp = 'DMP',
}

const translations: Record<AttributeType, string> = {
    [AttributeType.Tagging]: $localize`:@@ATTRIBUTE_TYPE_TAGGING:ATTRIBUTE_TYPE_TAGGING`,
    [AttributeType.Dmp]: $localize`:@@ATTRIBUTE_TYPE_DMP:ATTRIBUTE_TYPE_DMP`,
};

function getAttributeTypeTranslation(type: AttributeType | string): string {
    if (!translations[type]) {
        throw new CommonsError(
            'type translation for requested attribute type does not exist',
            {data: {type}},
        );
    }

    return translations[type];
}

export {AttributeType, getAttributeTypeTranslation};
