import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class OptionalDailyBudgetValidator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number>): ValidationErrors | null {
        const dailyBudget: number = control.value;

        if (dailyBudget < OptionalDailyBudgetValidator.minValue) {
            return {[DailyBudgetErrorCodes.MinValue]: this.getTranslationForErrorCode(DailyBudgetErrorCodes.MinValue)};
        }

        if (dailyBudget > OptionalDailyBudgetValidator.maxValue) {
            return {[DailyBudgetErrorCodes.MaxValue]: this.getTranslationForErrorCode(DailyBudgetErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: DailyBudgetErrorCodes): string {
        const min: string = this.currencyPipe.transform(OptionalDailyBudgetValidator.minValue, 'EUR') || '';
        const minMessage: string = $localize`:@@VALIDATOR_DAILY_BUDGET_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_DAILY_BUDGET_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
        const max: string = this.currencyPipe.transform(OptionalDailyBudgetValidator.maxValue, 'EUR') || '';
        const maxMessage: string = $localize`:@@VALIDATOR_DAILY_BUDGET_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_DAILY_BUDGET_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;

        switch (code) {
            case DailyBudgetErrorCodes.MinValue:
                return minMessage;
            case DailyBudgetErrorCodes.MaxValue:
                return maxMessage;
            default:
                return defaultMessage;
        }
    }
}

enum DailyBudgetErrorCodes {
    MinValue = '87e86430-5c56-4567-aac6-78cdc22481ad',
    MaxValue = 'bedc7f66-1a5c-4e23-bb38-382c24652df9',
}

export {OptionalDailyBudgetValidator, DailyBudgetErrorCodes};
