<div class="empty-page-info">
    <div *ngIf="icon" class="empty-page-info__icon">
        <mat-icon
            [fontSet]="icon.font || ''"
        >{{icon.name}}</mat-icon>
    </div>
    <div class="empty-page-info__header">{{header}}</div>
    <div class="empty-page-info__message">{{description}}</div>
    <div class="empty-page-info__content"><ng-content></ng-content></div>
</div>
