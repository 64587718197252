import {Component, Input, OnInit} from '@angular/core';
import {LibsManualService} from './manual.service';
import {ManualLinkKey} from './manual-link-keys.config';
import {Observable} from 'rxjs';
import {IManualPageContent, ManualColor} from '@angular-clan/core/manual';

@Component({
    selector: 'libs-manual',
    templateUrl: './manual.html',
    styleUrls: ['./manual.scss'],
})
class LibsManualComponent implements OnInit {
    @Input() public key: ManualLinkKey = ManualLinkKey.HomePage;
    @Input() public color: ManualColor = 'grey';
    public pageContent?: Observable<IManualPageContent>;
    public pageLink: string = '';

    public constructor(
        private manualService: LibsManualService,
    ) {}

    public ngOnInit(): void {
        this.pageLink = this.manualService.getExternalLink(this.key);
        this.pageContent = this.manualService.getManualContentByKey(this.key);
    }
}

export {LibsManualComponent, ManualColor};
