class DilNetwork implements IDilNetwork {
    private _id: number;
    private _ownerNetworkId: number | undefined;
    private _ownerNetworkName: string | undefined;
    private _ownerNetworkShortName: string | undefined;
    private _userNetworkId: number | undefined;
    private _userNetworkName: string | undefined;

    constructor(id: number) {
        this._id = id;
    }

    get id(): number {
        return this._id;
    }

    get ownerNetworkId(): number | undefined {
        return this._ownerNetworkId;
    }

    set ownerNetworkId(value: number | undefined) {
        this._ownerNetworkId = value;
    }

    get ownerNetworkName(): string | undefined {
        return this._ownerNetworkName;
    }

    set ownerNetworkName(value: string | undefined) {
        this._ownerNetworkName = value;
    }

    get ownerNetworkShortName(): string | undefined {
        return this._ownerNetworkShortName;
    }

    set ownerNetworkShortName(value: string | undefined) {
        this._ownerNetworkShortName = value;
    }

    get userNetworkId(): number | undefined {
        return this._userNetworkId;
    }

    set userNetworkId(value: number | undefined) {
        this._userNetworkId = value;
    }

    get userNetworkName(): string | undefined {
        return this._userNetworkName;
    }

    set userNetworkName(value: string | undefined) {
        this._userNetworkName = value;
    }
}

interface IDilNetwork {
    id: number;
    ownerNetworkId: number | undefined;
    ownerNetworkName: string | undefined;
    ownerNetworkShortName: string | undefined;
    userNetworkId: number | undefined;
    userNetworkName: string | undefined;
}

export {DilNetwork, IDilNetwork};
