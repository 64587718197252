import {AbstractControl, ValidationErrors} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class ThirdPartyCostCpmValidator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0.01;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const maxCpm: number = control.value;
        if (maxCpm === undefined || maxCpm === null) {
            return {[CpmErrorCodes.Required]: this.getTranslationForErrorCode(CpmErrorCodes.Required)};
        }

        if (maxCpm < ThirdPartyCostCpmValidator.minValue) {
            return {[CpmErrorCodes.MinValue]: this.getTranslationForErrorCode(CpmErrorCodes.MinValue)};
        }

        if (maxCpm > ThirdPartyCostCpmValidator.maxValue) {
            return {[CpmErrorCodes.MaxValue]: this.getTranslationForErrorCode(CpmErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: CpmErrorCodes): string {
        switch (code) {
            case CpmErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case CpmErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_THIRD_PARTY_COST_CPM_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_THIRD_PARTY_COST_CPM_MIN_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(ThirdPartyCostCpmValidator.minValue, 'EUR')}:INTERPOLATION:`;
            case CpmErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_THIRD_PARTY_COST_CPM_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_THIRD_PARTY_COST_CPM_MAX_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(ThirdPartyCostCpmValidator.maxValue, 'EUR')}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum CpmErrorCodes {
    Required = 'be8d872c-2f20-441d-8d5b-8551706a81a4',
    MinValue = '32a2eb49-d9fc-4be7-a131-7ebbd31ccd96',
    MaxValue = '46f6d60e-e082-47d9-99da-20777ccea394',
}

export {ThirdPartyCostCpmValidator};
