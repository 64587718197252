enum FrequencyCappingExternalIdTypes {
    ADITION = 'ADITION',
    ADSWIZZ_LISTENER_ID = 'ADSWIZZ_LISTENER_ID',
    CTV = 'CTV',
    DFORCE_ID = 'DFORCE_ID',
    ID5 = 'ID5',
    IDFV = 'IDFV',
    // if MAID is used, it references both ANDROID_ADVERTISING_ID & IOS_IDFA
    MAID = 'MAID',
    NET_ID = 'NET_ID',
    NITRO_ID = 'NITRO_ID',
    SALESFORCE_ID = 'SALESFORCE_ID',
    SOM_ADDRESSABLE_TV_ID = 'SOM_ADDRESSABLE_TV_ID',
}

enum FrequencyCappingMaidTypes {
    ANDROID_ADVERTISING_ID = 'ANDROID_ADVERTISING_ID',
    IOS_IDFA = 'IOS_IDFA',
}

export {FrequencyCappingExternalIdTypes, FrequencyCappingMaidTypes};
