class VastEventType {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _vastName: string;
    private readonly _vastVersions: Array<string>;

    constructor(
        id: number,
        name: string,
        vastName: string,
        vastVersions: Array<string>,
    ) {
        this._id = id;
        this._name = name;
        this._vastName = vastName;
        this._vastVersions = vastVersions;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get vastName(): string {
        return this._vastName;
    }

    get vastVersions(): Array<string> {
        return this._vastVersions;
    }
}

export {VastEventType};
