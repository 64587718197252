import {Component, Input, OnChanges} from '@angular/core';
import {differenceInMinutes, parseISO, startOfDay} from 'date-fns';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {NumberFormat, getTimeZonedNewDate} from '@active-agent/std';
import {IMaxValueTooltip, IRuntime, TransformViewValueCallback} from '@active-agent/types';
import {LibsPacingBarComponent} from '../pacing-bar/pacing-bar.component';

@Component({
    selector: 'libs-runtime-pacing-bar',
    templateUrl: './runtime-pacing-bar.html',
    standalone: true,
    imports: [
        LibsPacingBarComponent,
    ],
    providers: [
        DecimalPipe,
        CurrencyPipe,
    ],
})
class LibsRuntimePacingBarComponent implements OnChanges {
    @Input() public currentValue: number | null = null;
    @Input() public maxValue: number | null | undefined = null;
    @Input() public maxValueTooltip: IMaxValueTooltip | undefined | null;
    @Input() public runtime: IRuntime | null = null;
    @Input() public type: RuntimePacingBarType = 'impressions';
    @Input() public currencyCode: string = 'EUR';
    @Input() public isDisabled: boolean = false;
    @Input() public isLoading: boolean = true;
    @Input() public isTodayContext: boolean = false;
    @Input() public evenDelivery: boolean = false;
    @Input() public aheadPacing: number | null = null;
    public optimalPace: number | undefined;
    public optimalPaceTooltip: string | undefined;
    public transformViewValueCallback: TransformViewValueCallback = this.transformViewValue.bind(this);

    constructor(
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private percentPipe: PercentPipe,
    ) {}

    public ngOnChanges(): void {
        this.optimalPace = this.getOptimalPace();
        this.optimalPaceTooltip = this.getOptimalPaceTooltip(this.optimalPace);
    }

    private getOptimalPaceTooltip(pace: number | undefined): string | undefined {
        if (pace === undefined || this.maxValue === null || this.maxValue === undefined) {
            return '';
        }
        const optimalPaceValue: number = this.maxValue * (pace / 100);

        switch (this.type) {
            case 'budget':
                return this.aheadPacing
                    ? $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_BUDGET_WITH_AHEAD_PACING:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_BUDGET_WITH_AHEAD_PACING ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION: ${this.percentPipe.transform(this.aheadPacing)}:INTERPOLATION_1:`
                    : this.evenDelivery
                        ? $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_BUDGET_WITH_EVEN_DELIVERY:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_BUDGET_WITH_EVEN_DELIVERY ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION:`
                        : $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_BUDGET:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_BUDGET ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION:`;
            case 'impressions':
                return this.aheadPacing
                    ? $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_IMPRESSIONS_WITH_AHEAD_PACING:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_IMPRESSIONS_WITH_AHEAD_PACING ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION: ${this.percentPipe.transform(this.aheadPacing)}:INTERPOLATION_1:`
                    : this.evenDelivery
                        ? $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_IMPRESSIONS_WITH_EVEN_DELIVERY:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_IMPRESSIONS_WITH_EVEN_DELIVERY ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION:`
                        : $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_IMPRESSIONS:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_IMPRESSIONS ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION:`;
            case 'clicks':
                return this.aheadPacing
                    ? $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_CLICKS_WITH_AHEAD_PACING:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_CLICKS_WITH_AHEAD_PACING ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION: ${this.percentPipe.transform(this.aheadPacing)}:INTERPOLATION_1:`
                    : this.evenDelivery
                        ? $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_CLICKS_WITH_EVEN_DELIVERY:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_CLICKS_WITH_EVEN_DELIVERY ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION:`
                        : $localize`:@@RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_CLICKS:RUNTIME_PACING_BAR_OPTIMAL_PACE_TOOLTIP_FOR_CLICKS ${this.transformViewValue(optimalPaceValue)}:INTERPOLATION:`;
            default:
                return '';
        }
    }

    private getOptimalPace(): number | undefined {
        if (this.maxValue === null || this.runtime === null) {
            return undefined;
        }

        const runtimeMinutes: number = differenceInMinutes(parseISO(this.runtime.endTime), parseISO(this.runtime.startTime));
        const currentRuntimeMinutes: number = differenceInMinutes(getTimeZonedNewDate(), parseISO(this.runtime.startTime));

        const elapsedMilliseconds: number = getTimeZonedNewDate().valueOf() - startOfDay(getTimeZonedNewDate()).valueOf();
        const optimalPaceRate: number = this.isTodayContext
            ? (getTrafficPortion(elapsedMilliseconds))
            : (currentRuntimeMinutes / runtimeMinutes);

        if (optimalPaceRate < 0) {
            return 0;
        } else if (optimalPaceRate > 1) {
            return 100;
        }

        return Math.round(optimalPaceRate * 100);
    }

    private transformViewValue(value: number | undefined | null): string {
        if (value === undefined) {
            return '';
        }
        switch (this.type) {
            case 'budget':
                return this.currencyPipe.transform(value, this.currencyCode) || '';
            case 'impressions':
            case 'clicks':
            default:
                return this.decimalPipe.transform(value, NumberFormat.Number) || '';
        }
    }
}

/**
 * This function is provided by the API backend. To be similar to their definition we disable tslint rules that would force us to write
 * the function differently.
 */
function getTrafficPortion(milliseconds: number): number {
    const hour: number = milliseconds / 3_600_000.0;

    return Math.max(
        0.0,
        0.0164 * hour - 0.0028 * Math.pow(hour, 2) + 0.00045 * Math.pow(hour, 3) - 0.0000118 * Math.pow(hour, 4),
    );
}

type RuntimePacingBarType = 'clicks' | 'impressions' | 'budget' | 'bids';

export {LibsRuntimePacingBarComponent, getTrafficPortion};
