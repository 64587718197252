import {Observable, of} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

type ProfileFilterType = FilterTypes.PROFILE
| FilterTypes.CAMPAIGN_DOOH_TRAFFIC_TYPE
| FilterTypes.CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE;

interface IBaseProfileFilterProperties extends IFilterProperties {
    profile: string;
}

abstract class BaseProfileFilter<TFilterType extends ProfileFilterType = ProfileFilterType> extends Filter<TFilterType> {
    protected readonly _isMultiFilter: boolean = true;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected abstract readonly props: IBaseProfileFilterProperties;

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    public get profile(): string {
        return this.props.profile;
    }

    public set profile(value: string) {
        this.props.profile = value;
    }

    public isValid(): Observable<boolean> {
        return of(!!this.profile.length);
    }
}

export {BaseProfileFilter, IBaseProfileFilterProperties, ProfileFilterType};
