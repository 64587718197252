import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IProviderFilterProperties extends IFilterProperties {
    providerIds: Array<number>;
    inverted: boolean;
}

class ProviderFilter extends Filter<FilterTypes.PROVIDER> {
    protected readonly _type: FilterTypes.PROVIDER = FilterTypes.PROVIDER;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = true;
    protected readonly props: IProviderFilterProperties = this.getDefaultOptions();

    get inverted(): boolean {
        return this.props.inverted;
    }

    set inverted(value: boolean) {
        this.props.inverted = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return this.isInverted().pipe(map((inverted: boolean) => !inverted));
    }

    public isInverted(): Observable<boolean> {
        return of(this.props.inverted);
    }

    get providerIds(): Array<number> {
        return this.props.providerIds;
    }

    set providerIds(value: Array<number>) {
        this.props.providerIds = value;
    }

    public isValid(): Observable<boolean> {
        return of(!!this.providerIds.length);
    }

    protected getDefaultOptions(): IProviderFilterProperties {
        return {
            ...super.getDefaultOptions(),
            providerIds: [],
            inverted: false,
        };
    }
}

export {ProviderFilter, IProviderFilterProperties};
