import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class HostingFeeValidator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number>): ValidationErrors | null {
        const hostingFee: number = control.value;

        if (hostingFee === undefined || hostingFee === null) {
            return {[HostingFeeErrorCodes.Required]: this.getTranslationForErrorCode(HostingFeeErrorCodes.Required)};
        }

        if (hostingFee < HostingFeeValidator.minValue) {
            return {[HostingFeeErrorCodes.MinValue]: this.getTranslationForErrorCode(HostingFeeErrorCodes.MinValue)};
        }

        if (hostingFee > HostingFeeValidator.maxValue) {
            return {[HostingFeeErrorCodes.MaxValue]: this.getTranslationForErrorCode(HostingFeeErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: HostingFeeErrorCodes): string {
        const min: string = this.currencyPipe.transform(HostingFeeValidator.minValue, 'EUR') || '';
        const max: string = this.currencyPipe.transform(HostingFeeValidator.maxValue, 'EUR') || '';

        switch (code) {
            case HostingFeeErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case HostingFeeErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_HOSTING_FEE_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_HOSTING_FEE_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
            case HostingFeeErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_HOSTING_FEE_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_HOSTING_FEE_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum HostingFeeErrorCodes {
    Required = '3a285e27-deb6-4965-933b-222597989cae',
    MinValue = 'e4b272ec-9d5a-4bda-9ae5-6b0527a09e57',
    MaxValue = '83e2e76c-40f1-49fe-a4ad-8c4b78426671',
}

export {HostingFeeValidator, HostingFeeErrorCodes};
