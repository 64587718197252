import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {firstValueFrom, Observable, Subscriber} from 'rxjs';
import {format} from 'date-fns';
import {HttpClient} from '@angular/common/http';
import {DateFormat, API_FILE_TYPES, CommonsError, IBannerFile, replaceAditionPlaceholders} from '@active-agent/types';
import {getBase64FilePattern} from '@active-agent/pattern';
import {NumberFormat, getTimeZonedNewDate} from '@active-agent/std';
import {flatten} from 'lodash-es';
import {GuessableDelimiters, parse, ParseResult} from 'papaparse';

@Injectable({
    providedIn: 'root',
})
class CommonsFileService {

    /**
     * https://en.wikipedia.org/wiki/Kilobyte
     */
    public static readonly fileNamePrefix: string = 'ActiveAgent';
    private static readonly conversionDivider: number = 1_000;

    constructor(
        private decimalPipe: DecimalPipe,
        private http: HttpClient,
    ) { }

    /**
     * https://stackoverflow.com/a/14919494/3303652
     *
     * Show a filesize in a human readable format
     */
    public humanFileSize(bytes: number): string {
        const threshold: number = 1_000;

        if (Math.abs(bytes) < threshold) {
            return `${bytes} B`;
        }

        const units: Array<string> = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        let u: number = -1;
        do {
            bytes /= CommonsFileService.conversionDivider;
            ++u;
        } while (Math.abs(bytes) >= threshold && u < units.length - 1);

        return `${this.decimalPipe.transform(bytes, NumberFormat.SingleDecimal)} ${units[u]}`;
    }

    /**
     * returns the translation of a filetype
     */
    public getTranslationForType(type: API_FILE_TYPES): string {
        switch (type) {
            case API_FILE_TYPES.PNG:
                return $localize`:@@FILE_TYPE_PNG:FILE_TYPE_PNG`;
            case API_FILE_TYPES.JPEG:
                return $localize`:@@FILE_TYPE_JPEG:FILE_TYPE_JPEG`;
            case API_FILE_TYPES.GIF:
                return $localize`:@@FILE_TYPE_GIF:FILE_TYPE_GIF`;
            case API_FILE_TYPES.MISC:
                return $localize`:@@FILE_TYPE_MISC:FILE_TYPE_MISC`;
            case API_FILE_TYPES.FLASH:
                return $localize`:@@FILE_TYPE_FLASH:FILE_TYPE_FLASH`;
            case API_FILE_TYPES.ZIP:
                return $localize`:@@FILE_TYPE_ZIP:FILE_TYPE_ZIP`;
            default:
                return $localize`:@@UNKNOWN:UNKNOWN`;
        }
    }

    public getFileFromAditionPath(filePath: string | undefined): Promise<string> {
        if (!filePath) {
            return Promise.reject(
                new CommonsError('Invalid filepath', {data: {filePath}}),
            );
        }

        return firstValueFrom(this.http
            .get(
                replaceAditionPlaceholders(filePath),
                {responseType: 'blob'},
            ))
            .then((response: Blob): Promise<string> => {
                return CommonsFileService.readFileAsBase64(response);
            });
    }

    public static convertMegaBytesToBytes(mb: number): number {
        return CommonsFileService.convertKiloBytesToBytes(CommonsFileService.convertMegaBytesToKiloBytes(mb));
    }

    public static convertMegaBytesToKiloBytes(mb: number): number {
        return mb * CommonsFileService.conversionDivider;
    }

    public static convertKiloBytesToMegaBytes(kb: number): number {
        return kb / CommonsFileService.conversionDivider;
    }

    public static convertKiloBytesToBytes(kb: number): number {
        return kb * CommonsFileService.conversionDivider;
    }

    public static convertBytesToKiloBytes(byte: number): number {
        return byte / CommonsFileService.conversionDivider;
    }

    public static convertBytesToMegaBytes(byte: number): number {
        return CommonsFileService.convertKiloBytesToMegaBytes(CommonsFileService.convertBytesToKiloBytes(byte));
    }

    public static readFileAsBase64(file: File | Blob): Promise<string> {
        return new Promise(((resolve: (base64: string) => void, reject: (reason: CommonsError) => void): void => {
            /* tslint:disable:only-arrow-functions */
            (function(currentFile: File | Blob): void {
                const reader: FileReader = new FileReader();
                reader.onload = (): void => {
                    resolve(reader.result as string);
                };
                reader.onerror = (): void => {
                    reject(new CommonsError('failed to read file as base 64', {data: {readerResult: reader.result}}));
                };

                reader.readAsDataURL(currentFile);
            })(file);
            /* tslint:enable:only-arrow-functions */
        }));
    }

    /**
     * @deprecated Use getSizeFromBase64Image instead
     */
    public static getImageDimensions(base64Image: string): Promise<IImageSize> {
        /* tslint:disable:only-arrow-functions */
        return new Promise(((resolve: (base64: IImageSize) => void, reject: (reason: Error) => void): void => {
            const imageElement: HTMLImageElement = new Image();
            imageElement.onload = ((): void => {
                resolve({width: imageElement.width, height: imageElement.height});
            });
            imageElement.onerror = (): void => {
                reject(new Error('failed to load image'));
            };
            imageElement.src = base64Image;
        }));
    }

    public static convertBase64ToApiObject(base64FileString: string): IBannerFile {
        const pattern: RegExp = getBase64FilePattern();

        if (!base64FileString.match(pattern)) {
            throw new CommonsError('not a valid base64 string');
        }

        const base64FileType: RegExpExecArray | null = pattern.exec(base64FileString);
        if (!base64FileType) {
            throw new CommonsError('not a valid base64 string');
        }
        let apiFileType: API_FILE_TYPES;
        switch (base64FileType[1]) {
            case 'png':
                apiFileType = API_FILE_TYPES.PNG;
                break;
            case 'jpeg':
            case 'jpg':
            case 'jpe':
                apiFileType = API_FILE_TYPES.JPEG;
                break;
            case 'gif':
                apiFileType = API_FILE_TYPES.GIF;
                break;
            case 'x-flv':
            case 'x-shockwave-flash':
                apiFileType = API_FILE_TYPES.FLASH;
                break;
            case 'zip':
                apiFileType = API_FILE_TYPES.ZIP;
                break;
            default:
                apiFileType = API_FILE_TYPES.MISC;
        }

        return {
            data: base64FileString.replace(pattern, ''),
            type: apiFileType,
        };
    }

    public static convertBase64ImageToApiObject(base64ImageString: string): IBannerFile {
        const result: IBannerFile = CommonsFileService.convertBase64ToApiObject(base64ImageString);

        const validImageTypes: Array<API_FILE_TYPES> = [
            API_FILE_TYPES.PNG,
            API_FILE_TYPES.JPEG,
            API_FILE_TYPES.GIF,
        ];
        if (!result.type || !validImageTypes.includes(result.type as API_FILE_TYPES)) {
            throw new CommonsError('not a valid base64 string with type', {data: {type: result.type}});
        }

        return result;
    }

    public static getSizeFromBase64Image(base64ImageString: string): Promise<IImageSize> {
        return new Promise((resolve: (result: IImageSize) => void, reject: (reason: CommonsError) => void): void => {
            const image: HTMLImageElement = new Image();
            image.onload = (): void => {
                resolve({
                    width: image.width,
                    height: image.height,
                });
            };
            image.onerror = (event: Event | string): void => {
                reject(new CommonsError('Failed to get size from base 64 image', {data: {event}}));
            };

            image.src = base64ImageString;
        });
    }

    /**
     * Helper function that is able to compare the size from a base64 image string.
     * Returns a promise that will be rejected if the size from the image is not equal/not in range of the expected
     * size.
     *
     * @param base64ImageString
     * @param expectedSizes {Object|Array} - object in one of the two following formats
     * {width: number, height: number}
     * {minWidth: number, maxWidth: number, minHeight: number, maxHeight: number}
     * - or Array with {width: number, height: number} objects
     */
    public static async checkImageDimensions(
        base64ImageString: string,
        expectedSizes: Array<IImageSize> | IExpectedSize,
    ): Promise<boolean> {
        const {width, height}: IImageSize = await this.getSizeFromBase64Image(base64ImageString);
        if (Array.isArray(expectedSizes)) {
            return expectedSizes.some((expectedSize: IImageSize): boolean => {
                if (
                    (expectedSize.width && expectedSize.width === width)
                    && (expectedSize.height && expectedSize.height === height)
                ) {
                    return true;
                }

                return false;
            });
        }

        return !((expectedSizes.width && expectedSizes.width !== width)
                || (expectedSizes.height && expectedSizes.height !== height)
                || (expectedSizes.minWidth && expectedSizes.minWidth > width)
                || (expectedSizes.maxWidth && expectedSizes.maxWidth < width)
                || (expectedSizes.minHeight && expectedSizes.minHeight > height)
                || (expectedSizes.maxHeight && expectedSizes.maxHeight < height));
    }

    public static readFileAsText(file: File): Observable<string> {
        return new Observable(
            (observer: Subscriber<string>): void => {
                const reader: FileReader = new FileReader();
                reader.onload = (): void => {
                    observer.next(reader.result as string);
                    observer.complete();
                };
                reader.onerror = (event: ProgressEvent): void => {
                    observer.error(new CommonsError('Failed to read file', {data: {event}}));
                };

                reader.readAsText(file);
            });
    }

    public static getFileName(name: string): string {
        return `${CommonsFileService.fileNamePrefix}-${name}-${format(getTimeZonedNewDate(), DateFormat.FileName)}`;
    }

    public static parseCsv(csvText: string): Array<string> {
        const csvData: ParseResult<Array<string>> = parse(
            csvText.trim(),
            {
                header: false,
                skipEmptyLines: 'greedy',
                delimitersToGuess: csvDelimiters,
            },
        );

        return  flatten<string>(csvData.data)
            .map((entry: string) => entry.trim())
            .filter((entry: string) => !!entry.length);
    }
}

interface IImageSize {
    width: number;
    height: number;
}

interface IExpectedSize {
    width?: number;
    height?: number;
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
}
enum FileTypes {
    Csv = 'Csv',
}

const csvDelimiters: Array<GuessableDelimiters> = [',', ';'];

export {
    API_FILE_TYPES,
    csvDelimiters,
    FileTypes,
    CommonsFileService,
    IImageSize,
    IExpectedSize,
};
