import {remove} from 'lodash-es';
import {DilAttribute} from './dil-attribute.model';
import {ITaggingAttribute} from './attribute.types';
import {TargetingPeculiarity} from '../peculiarity/targeting-peculiarity.model';
import {INetwork, Network} from '../network/network.model';
import {DilNetwork} from '../network/dil-network.model';
import {TaggingAttributeMode} from './tagging-attribute-mode';
import {TaggingAttributeOperation} from './tagging-attribute-operation';

class TaggingAttribute implements ITaggingAttribute {
    private readonly _id: number | null;
    private _active: boolean = false;
    private _network: Network | undefined;
    private _description: string | null = null;
    private _expireDays: number | undefined;
    private _key: string | undefined;
    private _maxHistoryEntries: number | undefined;
    private _minSecondsBetweenTags: number | undefined;
    private _mode: TaggingAttributeMode | undefined;
    private _name: string | undefined;
    private _displayName: string | undefined;
    private _operation: TaggingAttributeOperation | undefined;
    private _peculiarities: Array<TargetingPeculiarity> = [];
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;
    private _dilAttributes: Array<DilAttribute> = [];
    private _allowedCompanyIds: Array<number> = [];

    constructor(id: number | null = null) {
        this._id = id;
    }

    get id(): number | null {
        return this._id;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get network(): Network | undefined {
        return this._network;
    }

    set network(value: Network | undefined) {
        this._network = value;
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get expireDays(): number | undefined {
        return this._expireDays;
    }

    set expireDays(value: number | undefined) {
        this._expireDays = value;
    }

    get key(): string | undefined {
        return this._key;
    }

    set key(value: string | undefined) {
        this._key = value;
    }

    get maxHistoryEntries(): number | undefined {
        return this._maxHistoryEntries;
    }

    set maxHistoryEntries(value: number | undefined) {
        this._maxHistoryEntries = value;
    }

    get minSecondsBetweenTags(): number | undefined {
        return this._minSecondsBetweenTags;
    }

    set minSecondsBetweenTags(value: number | undefined) {
        this._minSecondsBetweenTags = value;
    }

    get mode(): TaggingAttributeMode | undefined {
        return this._mode;
    }

    set mode(value: TaggingAttributeMode | undefined) {
        this._mode = value;
    }

    get name(): string | undefined {
        return this._name;
    }

    set name(value: string | undefined) {
        this._name = value;
    }

    get displayName(): string | undefined {
        return this._displayName;
    }

    set displayName(value: string | undefined) {
        this._displayName = value;
    }

    get operation(): TaggingAttributeOperation | undefined {
        return this._operation;
    }

    set operation(value: TaggingAttributeOperation | undefined) {
        this._operation = value;
    }

    get peculiarities(): Array<TargetingPeculiarity> {
        return this._peculiarities;
    }

    set peculiarities(value: Array<TargetingPeculiarity>) {
        this._peculiarities = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get dilAttributes(): Array<DilAttribute> {
        return this._dilAttributes;
    }

    set dilAttributes(value: Array<DilAttribute>) {
        this._dilAttributes = value;
    }

    public isFromNetwork(network: Network | INetwork): boolean {
        return !!(this.network && this.network.id === network.id);
    }

    public addDilAttribute(value: DilAttribute): void {
        this._dilAttributes.push(value);
    }

    public removeDilAttribute(value: DilAttribute): void {
        remove(this._dilAttributes, (attribute: DilAttribute) => {
            return Object.is(attribute.attributeId, value.attributeId)
                && Object.is(attribute.ownerNetworkId, value.ownerNetworkId)
                && Object.is(attribute.userNetworkId, value.userNetworkId);
        });
    }

    public get allowedCompanyIds(): Array<number> {
        return this._allowedCompanyIds;
    }

    public set allowedCompanyIds(value: Array<number>) {
        this._allowedCompanyIds = value;
    }

    public containsDilUserNetwork(dilNetwork: DilNetwork): boolean {
        return this._dilAttributes.some((attribute: DilAttribute) => {
            if (!this.network) {
                return false;
            }

            return Object.is(attribute.attributeId, this.id)
                && Object.is(attribute.ownerNetworkId, this.network.id)
                && Object.is(attribute.userNetworkId, dilNetwork.userNetworkId);
        });
    }

    public merge(source: TaggingAttribute): void {
        this.active = source.active;
        this.network = source.network ? Network.clone(source.network) : undefined;
        this.dilAttributes = source.dilAttributes.map((dilAttribute: DilAttribute) => {
            return DilAttribute.clone(dilAttribute);
        });
        this.description = source.description;
        this.expireDays = source.expireDays;
        this.key = source.key;
        this.maxHistoryEntries = source.maxHistoryEntries;
        this.minSecondsBetweenTags = source.minSecondsBetweenTags;
        this.mode = source.mode;
        this.name = source.name;
        this.displayName = source.displayName;
        this.operation = source.operation;
        this.peculiarities = source.peculiarities.map((peculiarity: TargetingPeculiarity) => {
            return TargetingPeculiarity.clone(peculiarity);
        });
        this.allowedCompanyIds = [...source.allowedCompanyIds];
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }

    public clone(): TaggingAttribute {
        const newModel: TaggingAttribute = new TaggingAttribute(this.id);
        newModel.merge(this);

        return newModel;
    }
}

export {TaggingAttribute};
