import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LibsSectionHeaderComponent} from './section-header.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        LibsSectionHeaderComponent,
    ],
    declarations: [
        LibsSectionHeaderComponent,
    ],
})
class LibsSectionHeaderModule {}

export {LibsSectionHeaderModule};
