/**
 * Chunks given array into separate arrays with the given chunkSize
 */
function chunkArray<T>(array: Array<T>, chunkSize: number): Array<Array<T>> {
    const returnValues: Array<Array<T>> = [];
    for (let i: number = 0; i < array.length; i += chunkSize) {
        const chunk: Array<T> = array.slice(i, i + chunkSize);
        returnValues.push(chunk);
    }

    return returnValues;
}

export {chunkArray};
