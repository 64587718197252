import {BaseVastSettings, VastSettings, VastSettingsType} from './base-vast-settings';
import {IAudioVastSettings} from '../banner';
import {ICloneable, IMergable} from '../types';
import {Encoding} from './encoding';

class AudioVastSettings extends BaseVastSettings
    implements IAudioVastSettings, ICloneable<AudioVastSettings>, IMergable<AudioVastSettings> {

    private readonly _type: VastSettingsType = VastSettingsType.Audio;
    private _encodings: Array<Encoding> = [];

    public get type(): VastSettingsType {
        return this._type;
    }

    public get encodings(): Array<Encoding> {
        return this._encodings;
    }

    public set encodings(value: Array<Encoding>) {
        this._encodings = value;
    }

    public merge(source: VastSettings): void {
        super.merge(source);
        AudioVastSettings.fillAdditionalFields(this, source);
    }

    public clone(): AudioVastSettings {
        const newModel: AudioVastSettings = new AudioVastSettings(
            this.id,
            this.networkId,
            this.assetId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: AudioVastSettings, source: VastSettings): void {
        BaseVastSettings.fillAdditionalFields(target, source);

        target.encodings = source.encodings;
    }
}

export {AudioVastSettings};
