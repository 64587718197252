import CodeMirror from 'codemirror';

/**
 * This selects the entire code on triple click on the code
 * @param codeMirror
 */
function getSelectAllOnTripleClickMouseConfiguration(codeMirror: CodeMirror.Editor): IConfigureMouseConfiguration {
    return {
        unit: (): IConfigureMouseUnitCallbackResponse => {
            return {
                from: {
                    ch: 0,
                    line: 0,
                },
                to: {
                    ch: codeMirror.getLine(codeMirror.lineCount() - 1).length,
                    line: codeMirror.lineCount() - 1,
                },
            };
        },
    };
}

interface IConfigureMouseConfiguration {
    unit: ConfigureMouseUnitType | IConfigureMouseUnitCallback;
}

interface IConfigureMouseUnitCallbackResponse {
    from: CodeMirror.Position;
    to: CodeMirror.Position;
}

type IConfigureMouseUnitCallback = (codeMirror: CodeMirror.Editor, position: CodeMirror.Position) => IConfigureMouseUnitCallbackResponse;
type ConfigureMouseUnitType = 'char' | 'word' | 'line' | 'rectangle' | null;
type ConfigureMouseRepeatType = 'single' | 'double' | 'triple';

export {
    IConfigureMouseConfiguration,
    ConfigureMouseRepeatType,
    getSelectAllOnTripleClickMouseConfiguration,
};
