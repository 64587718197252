import {VideoPlayerFilterImportParser} from '@active-agent/jison';

enum VideoPlayerFilterType {
    IsSmallVideoPlayer = 'isSmallVideoPlayer',
    IsMediumVideoPlayer = 'isMediumVideoPlayer',
    IsLargeVideoPlayer = 'isLargeVideoPlayer',
}

type VideoPlayerFilterImportParserResult = Record<VideoPlayerFilterType, boolean>;

function parseVideoPlayerFilterImport(rawData: string): VideoPlayerFilterImportParserResult {
    return JSON.parse(new VideoPlayerFilterImportParser().parse(rawData));
}

export {
    VideoPlayerFilterImportParserResult,
    parseVideoPlayerFilterImport,
    VideoPlayerFilterType,
};
