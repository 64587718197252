import {ComponentType} from '@angular/cdk/portal';
import {Directive, HostListener, Input} from '@angular/core';
import {LibsOverlayService} from './overlay.service';
import {ISearchPopupData} from '@active-agent/types';

@Directive({
    selector: 'button[libsOpenOverlay]',
})
class LibsOpenOverlayDirective {
    @Input() private overlayData: ISearchPopupData | null = null;
    @Input() private overlayComponentName: ComponentType<unknown> | null = null;

    constructor(private overlayService: LibsOverlayService) {}

    @HostListener('click')
    private click(): void {
        if (!this.overlayComponentName || !this.overlayData) {
            return;
        }

        this.overlayService.open<unknown, ISearchPopupData>(
            this.overlayComponentName,
            this.overlayData,
        );
    }
}

export {LibsOpenOverlayDirective};
