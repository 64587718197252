import {AbstractControl, FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';
import {CommonsError} from '@active-agent/types';

function CpxMetricsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!(control instanceof FormArray)) {
            throw new CommonsError('seems to be the wrong instance');
        }

        const oneOptionShouldBeChecked: boolean = control.controls.some((checkboxControl: AbstractControl<boolean>) => {
            return checkboxControl.value;
        });

        if (!oneOptionShouldBeChecked) {
            return {
                cpxMetricsAreNotValid: true,
            };
        }

        return null;
    };
}

export {CpxMetricsValidator};
