import {CommonsError} from './error';

class ErrorLog {
    private readonly message: string;
    private readonly data: unknown;
    private readonly stack: string;
    private readonly date: string;
    private readonly state: Record<string, unknown> | string;
    private readonly isCritical: boolean;
    private readonly error: Error | undefined;

    constructor(
        message: string,
        data: unknown,
        stack: string,
        date: string,
        state: Record<string, unknown> | string,
        isCritical: boolean = false,
        error?: Error,
    ) {
        this.message = message;
        this.data = data;
        this.stack = stack;
        this.date = date;
        this.state = state;
        this.isCritical = isCritical;
        this.error = error;
    }

    /**
     * returns the object as it should be logged.
     *
     * @return {Object} the object as it should be logged
     */
    public getData(): IErrorLogData {
        return {
            message: this.message,
            data: JSON.stringify(trimLogData(this.data)),
            stack: this.stack,
            date: this.date,
            state: JSON.stringify(this.state),
            isCritical: this.isCritical,
        };
    }

    public getOriginalData(): any {
        return this.data;
    }

    public getError(): Error | undefined {
        return this.error;
    }
}

function trimLogData(errorData: any): unknown {
    if (!Object.is(errorData, Object(errorData)) || Object.is(typeof errorData, 'function')) {
        return errorData;
    }

    if (errorData instanceof Error || errorData instanceof CommonsError) {
        const error: any = {};

        Object.getOwnPropertyNames(errorData).forEach((key: any): any => {
            error[key] = errorData[key];
        });

        errorData = error;
    }

    return Object
        .keys(errorData)
        .map((key: any)  => {
            return [key, errorData[key]];
        })
        .filter((entry: any): any => {
            return !Object.is(entry[0], 'Authorization');
        })
        .reduce((previous: any, next: any): any => {
            const [key, value]: any = next;

            if (Object.is(value, String(value))) {
                previous[key] = value.length > 1000 ? `${value.slice(0, 1000)}…` : value;
            } else {
                previous[key] = trimLogData(value);
            }

            return previous;
        }, Array.isArray(errorData) ? [] : {});
}

interface IErrorLogData {
    message: string;
    data: string;
    stack: string;
    date: string;
    state: string;
    isCritical: boolean;
}

export {ErrorLog, IErrorLogData, trimLogData};
