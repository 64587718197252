import {CommonsError} from '@active-agent/types';

function trimLogData(errorData: any): unknown {
    if (!Object.is(errorData, Object(errorData)) || Object.is(typeof errorData, 'function')) {
        return errorData;
    }

    if (errorData instanceof Error || errorData instanceof CommonsError) {
        const error: any = {};

        Object.getOwnPropertyNames(errorData).forEach((key: any): any => {
            error[key] = errorData[key];
        });

        errorData = error;
    }

    return Object
        .keys(errorData)
        .map((key: any)  => {
            return [key, errorData[key]];
        })
        .filter((entry: any): any => {
            return !Object.is(entry[0], 'Authorization');
        })
        .reduce((previous: any, next: any): any => {
            const [key, value]: any = next;

            if (Object.is(value, String(value))) {
                previous[key] = value.length > 1000 ? `${value.slice(0, 1000)}…` : value;
            } else {
                previous[key] = trimLogData(value);
            }

            return previous;
        }, Array.isArray(errorData) ? [] : {});
}

export {trimLogData};
