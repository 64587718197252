import {VastBanner} from './vast-banner';
import {CampaignBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {ICampaignBanner} from '../banner';

class CampaignVastBanner extends VastBanner implements
    ICampaignBanner,
    IMergable<CampaignBanner>,
    ICloneable<CampaignVastBanner> {

    private _campaignId: number;
    private _parentBannerId: number | null = null;

    constructor(id: number | null, networkId: number, name: string, landingPage: string, vastXml: string, campaignId: number) {
        super(id, networkId, name, landingPage, vastXml);
        this._campaignId = campaignId;
    }

    get campaignId(): number {
        return this._campaignId;
    }

    set campaignId(value: number) {
        this._campaignId = value;
    }

    get parentBannerId(): number | null {
        return this._parentBannerId;
    }

    set parentBannerId(value: number | null) {
        this._parentBannerId = value;
    }

    public merge(source: CampaignBanner): void {
        super.merge(source);
        CampaignVastBanner.fillAdditionalFields(this, source);
    }

    public clone(): CampaignVastBanner {
        const newModel: CampaignVastBanner = new CampaignVastBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.vastXml,
            this.campaignId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: CampaignVastBanner, source: CampaignBanner): void {
        VastBanner.fillAdditionalFields(target, source);

        target.campaignId = source.campaignId;
        target.parentBannerId = source.parentBannerId;
    }
}

export {CampaignVastBanner};
