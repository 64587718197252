import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {
    LibsWebsocketService,
    IWebsocketAnnouncementEventData,
} from '@active-agent/websocket';
import {parseMarkdown} from '@active-agent/markdown';
import {Status} from '../status-icon/status-icon.component';

@Component({
    selector: 'libs-announcement-header',
    templateUrl: 'announcement-header.component.html',
    styleUrls: ['./announcement-header.component.scss'],
})
class LibsAnnouncementHeaderComponent implements OnInit, OnDestroy {
    public infoStatus: Status = Status.Info;
    public message: null | string = null;
    private subscription: Subscription = new Subscription();

    public constructor(
        private websocketService: LibsWebsocketService,
        @Inject(LOCALE_ID) public locale: string,
    ) {}

    public ngOnInit(): void {
        this.subscription.add(this.websocketService
            .getAnnouncementMessage()
            .subscribe({
                next: (eventData: IWebsocketAnnouncementEventData): void => {
                    this.message = eventData.data && eventData.data[this.locale].trim()
                        ? parseMarkdown(eventData.data[this.locale])
                        : null;
                },
            }));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}

export {LibsAnnouncementHeaderComponent};
