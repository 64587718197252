import {Observable, ReplaySubject} from 'rxjs';
import {
    IWebsocketPingEventData,
    IWebsocketAnnouncementEventData,
    IWebsocketEventData,
    WebsocketEventType,
} from './event-data';

class WebsocketClient {
    private readonly eventsDataByType: Record<WebsocketEventType, ReplaySubject<IWebsocketEventData>> = {
        [WebsocketEventType.Announcement]: new ReplaySubject(1),
        [WebsocketEventType.Ping]: new ReplaySubject(1),
    };

    private socket?: WebSocket;
    /**
     * Defined Status Codes
     * @link https://www.rfc-editor.org/rfc/rfc6455.html#section-7.4.1
     */
    private readonly normalClosureCode: number = 1000;

    constructor(websocketUrl: string) {
        this.connect(websocketUrl);
    }

    public connect(webSocketUrl: string): void {
        this.socket = new WebSocket(webSocketUrl);
        this.socket.addEventListener('message', (event: MessageEvent<string>) => {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const data: IWebsocketEventData = JSON.parse(event.data);
                const subject: ReplaySubject<IWebsocketEventData> = this.eventsDataByType[data.type];
                subject.next(data);
            } catch (_error: unknown) {
                // do nothing
            }
        });

        /**
         * inspired by reconnectIfNotNormalClose
         * @link https://github.com/PatrickJS/angular-websocket/blob/master/src/angular-websocket.js#LL211C1-L212C1
         */
        this.socket.addEventListener('close', (event: CloseEvent) => {
            if (event.code === this.normalClosureCode) {
                return;
            }

            setTimeout(() => {
                this.connect(webSocketUrl);
            }, 5_000);
        });
    }

    public getEventsDataByType(type: WebsocketEventType.Announcement): Observable<IWebsocketAnnouncementEventData>;
    public getEventsDataByType(type: WebsocketEventType.Ping): Observable<IWebsocketPingEventData>;
    public getEventsDataByType(type: WebsocketEventType): Observable<IWebsocketEventData> {
        return this.eventsDataByType[type];
    }

    public disconnect(): void {
        if (this.socket && this.socket.readyState === WebSocket.OPEN && 'close' in this.socket) {
            this.socket.close(this.normalClosureCode);
        }
        Object.values(this.eventsDataByType).forEach((subject: ReplaySubject<IWebsocketEventData>) => {
            subject.complete();
        });
    }
}

export {WebsocketClient};
