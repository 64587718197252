import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface ITimeFilterProperties extends IFilterProperties {
    timeData: Array<ITimeObject>;
    inverted: boolean;
}

class TimeFilter extends Filter<FilterTypes.TIME> {
    protected readonly _type: FilterTypes.TIME = FilterTypes.TIME;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = true;
    protected readonly props: ITimeFilterProperties = this.getDefaultOptions();

    get inverted(): boolean {
        return this.props.inverted;
    }

    set inverted(value: boolean) {
        this.props.inverted = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return this.isInverted().pipe(map((inverted: boolean) => !inverted));
    }

    public isInverted(): Observable<boolean> {
        return of(this.props.inverted);
    }

    get timeData(): Array<ITimeObject> {
        return this.props.timeData;
    }

    set timeData(value: Array<ITimeObject>) {
        this.props.timeData = value;
    }

    public isValid(): Observable<boolean> {
        return of(this.timeData.every((entry: ITimeObject) => entry.days.length));
    }

    protected getDefaultOptions(): ITimeFilterProperties {
        return {
            ...super.getDefaultOptions(),
            timeData: [{days: [], from: 0, to: 23}],
            inverted: false,
        };
    }
}

type ITimeData = Record<Weekdays, Array<number>>;

enum Weekdays {
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY',
}

interface ITimeObject {
    days: Array<Weekdays>;
    from: number;
    to: number;
}

export {TimeFilter, ITimeFilterProperties, ITimeData, Weekdays, ITimeObject};
