// eslint-disable-next-line @typescript-eslint/typedef
const InventoryPlatformTranslations = {
    UNKNOWN: $localize`:@@UNKNOWN:UNKNOWN`,
    DESKTOP: $localize`:@@PLATFORM_DESKTOP:PLATFORM_DESKTOP`,
    MOBILE: $localize`:@@PLATFORM_MOBILE:PLATFORM_MOBILE`,
    APP: $localize`:@@PLATFORM_APP:PLATFORM_APP`,
    DOOH: $localize`:@@PLATFORM_DOOH:PLATFORM_DOOH`,
    TV: $localize`:@@PLATFORM_TV:PLATFORM_TV`,
    CTV: $localize`:@@PLATFORM_CTV:PLATFORM_CTV`,
    LTV: $localize`:@@PLATFORM_PTV:PLATFORM_PTV`,
} as const;

type InventoryPlatform = keyof typeof InventoryPlatformTranslations;

function getInventoryPlatformTranslation(id: InventoryPlatform | string): string {
    return InventoryPlatformTranslations[id] || $localize`:@@PLATFORM_UNKNOWN:PLATFORM_UNKNOWN`;
}

export {
    InventoryPlatform,
    getInventoryPlatformTranslation,
    InventoryPlatformTranslations,
};
