import {InjectionToken} from '@angular/core';
// The unused type imports are used in the jsdoc tags and removed at compile time.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import type {HrefOptions, TransitionOptions} from '@uirouter/core';

const ROUTE_HANDLER: InjectionToken<IRouterHandler> = new InjectionToken<IRouterHandler>('route.handler');

interface IRouterHandler {
    navigate(
        commands: RouterHandlerCommands,
        queryParams?: RouterHandlerQueryParams,
        transitionOptions?: IRouterHandlerTransitionOptions,
    ): void;

    navigateBack(): void;

    transform(
        commands: RouterHandlerCommands,
        queryParams?: RouterHandlerQueryParams,
        transitionOptions?: IRouterHandlerTransitionOptions,
    ): string;

    transformWithQueryParameter(
        commands: RouterHandlerCommands,
        queryParams?: RouterHandlerQueryParams,
        hrefOptions?: IRouterHandlerHrefOptions,
    ): string;

    updateQueryParameter(commands: RouterHandlerCommands, queryParams?: RouterHandlerQueryParams): void;

    getQueryParameter(queryParam: string): string;

    reload(options?: IRouterHandlerTransitionOptions): Promise<void>;

    isActive(commands: RouterHandlerCommands): boolean;

    params: RouterHandlerQueryParams;
}

type RouterHandlerCommands = Array<string | number | Array<string | number> | null | undefined>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouterHandlerQueryParams = Record<string, any>;

interface IRouterHandlerTransitionOptions {
    /**
     * ui-router specific option.
     * @see TransitionOptions.reload
     */
    reload?: boolean | string;
    /**
     * ui-router specific option.
     * @see TransitionOptions.location
     */
    location?: boolean | 'replace';
    /**
     * ui-router specific option.
     * @see TransitionOptions.custom
     */
    custom?: Record<string, unknown>;
}

interface IRouterHandlerHrefOptions {
    /**
     * ui-router specific option.
     * @see HrefOptions.absolute
     */
    absolute?: boolean;
}

interface IRouterHandlerRouteConfiguration<TObject = number> {
    getDataForLink(id: TObject, networkId: number): IRouterHandlerData;
}

interface IRouterHandlerData {
    commands: RouterHandlerCommands;
    queryParams?: RouterHandlerQueryParams;
    transitionOptions?: IRouterHandlerTransitionOptions;
    hrefOptions?: IRouterHandlerHrefOptions;
}

export {
    type IRouterHandler,
    type IRouterHandlerData,
    type IRouterHandlerHrefOptions,
    type IRouterHandlerRouteConfiguration,
    type IRouterHandlerTransitionOptions,
    ROUTE_HANDLER,
    type RouterHandlerCommands,
    type RouterHandlerQueryParams,
};
