import {NgModule} from '@angular/core';
import {LibsSegmentListComponent} from './segment-list.component';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CoreSearchToolbarModule} from '@angular-clan/core/search-toolbar';
import {CommonsSwitchListItemComponent} from '../switch-list-item/switch-list-item.component';
import {CommonsNoItemsAvailableItemModule} from '../no-items-available-item/no-items-available-item.module';

@NgModule({
    imports: [
        CommonModule,
        ScrollingModule,
        CommonsSwitchListItemComponent,
        CoreSearchToolbarModule,
        CommonsNoItemsAvailableItemModule,
        CurrencyPipe,
    ],
    exports: [
        LibsSegmentListComponent,
    ],
    declarations: [
        LibsSegmentListComponent,
    ],
    providers: [
        CurrencyPipe,
    ],
})
class LibsSegmentListModule {}

export {LibsSegmentListModule};
