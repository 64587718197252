import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {differenceInMinutes, isAfter} from 'date-fns';
import {saveParseISO} from '@active-agent/std';

@Injectable({
    providedIn: 'root',
})
class FootfallControlGroupRuntimeValidator implements Validator {
    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const startTimeControl: AbstractControl = control.get('startTime') as AbstractControl;
        const endTimeControl: AbstractControl = control.get('endTime') as AbstractControl;

        if (startTimeControl.valid && endTimeControl.valid) {

            const startTime: Date = saveParseISO(startTimeControl.value);
            const endTime: Date = saveParseISO(endTimeControl.value);

            if (isAfter(startTime, endTime)) {
                return getValidationError(FootfallControlGroupRuntimeErrorCode.StartTimeAfterEndTime);
            }

            if (differenceInMinutes(endTime, startTime) < daysInMinutes(7) - 1) {
                return getValidationError(FootfallControlGroupRuntimeErrorCode.MinRuntime);
            }
        }

        return null;
    }
}

function getValidationError(code: FootfallControlGroupRuntimeErrorCode): ValidationErrors {
    return {[code]: getTranslationForErrorCode(code)};
}

function getTranslationForErrorCode(code: FootfallControlGroupRuntimeErrorCode): string {
    switch (code) {
        case FootfallControlGroupRuntimeErrorCode.StartTimeAfterEndTime:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_CONTROL_GROUP_RUNTIME_START_TIME_AFTER_END_TIME_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_CONTROL_GROUP_RUNTIME_START_TIME_AFTER_END_TIME_ERROR_MESSAGE`;
        case FootfallControlGroupRuntimeErrorCode.MinRuntime:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_CONTROL_GROUP_RUNTIME_MIN_RUNTIME_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_CONTROL_GROUP_RUNTIME_MIN_RUNTIME_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

const enum FootfallControlGroupRuntimeErrorCode {
    StartTimeAfterEndTime = '1317e4d5-cf59-4278-8af6-7326e42aae2b',
    MinRuntime = '72c72c85-3090-4dab-bfe6-2fc54506cc4e',
}

function daysInMinutes(days: number): number {
    return days * 24 * 60;
}

export {FootfallControlGroupRuntimeValidator};
