import {Ssps} from '../ssp';
import {ISeat} from './seat.types';

class DefaultSeat implements ISeat {
    private readonly _seatId: string;
    private readonly _ssp: Ssps;

    constructor(
        seatId: string,
        ssp: Ssps,
    ) {
        this._seatId = seatId;
        this._ssp = ssp;
    }

    get id(): null {
        return null;
    }

    get companyId(): number {
        return 0;
    }

    get networkId(): number {
        return 0;
    }

    get name(): string {
        return '';
    }

    get seatId(): string {
        return this._seatId;
    }

    get ssp(): Ssps {
        return this._ssp;
    }

}

export {DefaultSeat};
