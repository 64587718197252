<form [formGroup]="customSizeForm" (ngSubmit)="addCustomSize()">
    <div class="vm-flex-direction-column">
        <commons-input-row>
            <core-number-input
                class="vm-display-flex vm-flex-100"
                formControlName="width"
                type="number"
                i18n-label
                label="WIDTH"
                i18n-hint
                hint="IMAGE_SELECTION_CROPPER_CUSTOM_WIDTH_HINT"
                numberSuffix="px"
                [isRequired]="true"
                [fraction]="0"
            ></core-number-input>
        </commons-input-row>
        <commons-input-row>
            <core-number-input
                class="vm-display-flex vm-flex-100"
                formControlName="height"
                type="number"
                i18n-label
                label="HEIGHT"
                i18n-hint
                hint="IMAGE_SELECTION_CROPPER_CUSTOM_HEIGHT_HINT"
                numberSuffix="px"
                [isRequired]="true"
                [fraction]="0"
            ></core-number-input>
        </commons-input-row>

        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="customSizeForm.invalid"
            i18n="@@FORM_SAVE_BUTTON_LABEL"
        >FORM_SAVE_BUTTON_LABEL</button>
    </div>
</form>
