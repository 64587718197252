import {IFootfallRoutesConfiguration, IFootfallStoreSettingsRouteData} from '@active-agent/footfall-routes';
import {IRouterHandlerData} from '@active-agent/types';
import {assert} from '@active-agent/std';
import {AppPath} from '../../../../commons/enums';

const footfallRoutesConfiguration: IFootfallRoutesConfiguration = {
    storeSettings: {
        getDataForLink({order}: IFootfallStoreSettingsRouteData): IRouterHandlerData {
            assert(order.id, 'missing order id');

            return {
                commands: [
                    order.networkId,
                    AppPath.Order,
                    order.id,
                    AppPath.FootfallStore,
                ],
            };
        },
    },
};

export {footfallRoutesConfiguration};
