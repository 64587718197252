import {ICloneable, IMergable} from '../types';
import {AdvertiserMargin} from './advertiser-margin';
import {ICompanyAdvertiserMargin, AdvertiserMarginComponent} from './advertister-margin.types';

class CompanyAdvertiserMargin extends AdvertiserMargin implements
    ICompanyAdvertiserMargin,
    IMergable<CompanyAdvertiserMargin>,
    ICloneable<CompanyAdvertiserMargin> {

    private _companyId: number;

    constructor(
        id: number | null,
        networkId: number,
        companyId: number,
        components: Array<AdvertiserMarginComponent>,
        margin: number,
    ) {
        super(id, networkId, components, margin);

        this._companyId = companyId;
    }

    get companyId(): number {
        return this._companyId;
    }

    set companyId(value: number) {
        this._companyId = value;
    }

    public merge(source: CompanyAdvertiserMargin): void {
        super.merge(source);

        this.companyId = source.companyId;
    }

    public clone(): CompanyAdvertiserMargin {
        const newModel: CompanyAdvertiserMargin = new CompanyAdvertiserMargin(
            this.id,
            this.networkId,
            this.companyId,
            this.components,
            this.margin,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {CompanyAdvertiserMargin};
