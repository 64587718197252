import {SwitchInBanner} from './switch-in-banner';
import {CampaignBanner} from '../base-banner';
import {BannerFileOrUrl, ICampaignBanner} from '../banner';
import {ICloneable, IMergable} from '../../types';

class CampaignSwitchInBanner extends SwitchInBanner implements
    ICampaignBanner,
    IMergable<CampaignBanner>,
    ICloneable<CampaignSwitchInBanner> {

    private _campaignId: number;
    private _parentBannerId: number | null = null;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        switchInFileOrUrl: BannerFileOrUrl,
        campaignId: number,
    ) {
        super(id, networkId, name, landingPage, switchInFileOrUrl);
        this._campaignId = campaignId;
    }

    get campaignId(): number {
        return this._campaignId;
    }

    set campaignId(value: number) {
        this._campaignId = value;
    }

    get parentBannerId(): number | null {
        return this._parentBannerId;
    }

    set parentBannerId(value: number | null) {
        this._parentBannerId = value;
    }

    public merge(source: CampaignBanner): void {
        super.merge(source);
        CampaignSwitchInBanner.fillAdditionalFields(this, source);
    }

    public clone(): CampaignSwitchInBanner {
        let switchInFileOrUrl: BannerFileOrUrl = '';
        if (this.switchInFile) {
            switchInFileOrUrl = this.switchInFile;
        } else if (this.switchInUrl) {
            switchInFileOrUrl = this.switchInUrl;
        }

        const newModel: CampaignSwitchInBanner = new CampaignSwitchInBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            switchInFileOrUrl,
            this.campaignId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: CampaignSwitchInBanner, source: CampaignBanner): void {
        SwitchInBanner.fillAdditionalFields(target, source);

        target.campaignId = source.campaignId;
        target.parentBannerId = source.parentBannerId;
    }
}

export {CampaignSwitchInBanner};
