import {CommonsError} from '../error/error';
import {IRouterHandlerRouteConfiguration} from '../route/route-handler';

enum ExternalDealTypes {
    Private = 'PRIVATE',
    Preferred = 'PREFERRED',
    Guaranteed = 'GUARANTEED',
}

function getExternalDealTypeTranslation(type: ExternalDealTypes | string): string {
    switch (type) {
        case ExternalDealTypes.Private:
            return $localize`:@@EXTERNAL_DEAL_TYPE_PRIVATE:EXTERNAL_DEAL_TYPE_PRIVATE`;
        case ExternalDealTypes.Preferred:
            return $localize`:@@EXTERNAL_DEAL_TYPE_PREFERRED:EXTERNAL_DEAL_TYPE_PREFERRED`;
        case ExternalDealTypes.Guaranteed:
            return $localize`:@@EXTERNAL_DEAL_TYPE_GUARANTEED:EXTERNAL_DEAL_TYPE_GUARANTEED`;
        default:
            throw new CommonsError(
                'type translation for requested external deal type does not exist',
                {data: {type}},
            );
    }
}

interface IExternalDealListConfiguration {
    tableLinkConfiguration: IRouterHandlerRouteConfiguration;
    linkedCampaignsTableLinkConfiguration: IRouterHandlerRouteConfiguration;
}

export {ExternalDealTypes, getExternalDealTypeTranslation, IExternalDealListConfiguration};
