import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    breadcrumbsIntegration,
    // browserTracingIntegration,
    init,
    linkedErrorsIntegration,
    // replayIntegration,
} from '@sentry/angular-ivy';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {EventLoggerService} from './commons/event/event-logger.service';
import {CoreTitleService, ITitleData} from '@angular-clan/core';
import {Subscription} from 'rxjs';
import {CommonsEventLog} from '@active-agent/event';
import {IWebsocketEventData, LibsWebsocketService} from '@active-agent/websocket';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.scss'],
})
class AppComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    constructor(
        private eventLoggerService: EventLoggerService,
        private title: Title,
        private titleService: CoreTitleService,
        private websocketService: LibsWebsocketService,
    ) {}

    public ngOnInit(): void {
        if (environment.sentryUrl) {
            init({
                dsn: environment.sentryUrl,
                release: environment.version,
                // replaysSessionSampleRate: 0.1,
                // replaysOnErrorSampleRate: 1.0,
                integrations: [
                    // replayIntegration(),
                    // browserTracingIntegration(),

                    // Workaround to fix console stacktrace in debug mode:
                    // https://github.com/getsentry/sentry-react-native/issues/794#issuecomment-908189765
                    ...(environment.production ? [] : [breadcrumbsIntegration({
                        console: false,
                    })]),
                    linkedErrorsIntegration({
                        limit: 7,
                    }),
                ],
                environment: environment.production
                    ? 'production'
                    : 'development',
                normalizeDepth: 10,
            });
        }

        this.subscription.add(this.titleService.getData().subscribe({
            next: (titleData: ITitleData) => {
                let newTitle: string = 'Active Agent';
                if (titleData.title) {
                    newTitle = `${titleData.title} - ${newTitle}`;
                }

                this.title.setTitle(newTitle);
            },
        }));

        this.subscription.add(this.websocketService
            .getPingMessage()
            .subscribe({
                next: (_eventData: IWebsocketEventData): void => {
                    // do nothing
                },
            }));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onBlockerDetected(value: boolean): void {
        void this.eventLoggerService.log(new CommonsEventLog('ad-blocker-detector', {isAddBlockerDetected: value}));
    }
}

export {AppComponent};
