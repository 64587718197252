import {Input, Component, OnInit, Output, EventEmitter} from '@angular/core';
import {CommonsError, IIcon} from '@active-agent/types';
import {CommonsBorderStyle} from '../list-item/list-item.component';
import {CommonsTextListItemModule} from '../text-list-item/text-list-item.module';
import {CommonModule} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
    selector: 'commons-switch-list-item',
    templateUrl: './switch-list-item.html',
    styleUrl: './switch-list-item.scss',
    standalone: true,
    imports: [
        CommonModule,
        MatSlideToggleModule,
        MatRippleModule,
        CommonsTextListItemModule,
        MatTooltipModule,
    ],
})
class CommonsSwitchListItemComponent implements OnInit {
    @Input({required: true}) public title!: string;
    @Input() public subtitle?: string;
    @Input() public label?: string;
    @Input() public isDisabled: boolean = false;
    @Input() public isActive: boolean | null = null;
    @Input() public isSelectable: boolean = true;
    @Input() public hasStatusIcon: boolean = true;
    @Input() public customStatusIcon: IIcon | null = null;
    @Input() public borderStyle: CommonsBorderStyle = 'dotted';
    @Input() public enableEllipsis: boolean = true;
    @Input() public disabledSwitchTooltip: string = '';
    @Input() public reverseOrder: boolean = false;
    @Output() private onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public ngOnInit(): void {
        if (!this.title) {
            throw new CommonsError('title component binding has to be provided');
        }
    }

    public onSwitchChange(): void {
        if (this.isDisabled) {
            return;
        }

        setTimeout(() => {
            this.isActive = !this.isActive;
            this.onChange.emit(this.isActive);
        });
    }
}

export {CommonsSwitchListItemComponent};
