import {Observable, of} from 'rxjs';
import {parseLanguageFilterImport} from './language-targeting-import';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface ILanguageFilterProperties extends IFilterProperties {
    profile: string;
}

class LanguageFilter extends Filter<FilterTypes.LANGUAGE> {
    protected readonly _type: FilterTypes.LANGUAGE = FilterTypes.LANGUAGE;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: ILanguageFilterProperties = this.getDefaultOptions();

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    public isValid(): Observable<boolean> {
        return of(isLanguageProfileValid(this.profile));
    }

    protected getDefaultOptions(): ILanguageFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

function isLanguageProfileValid(profile: string = ''): boolean {
    if (!profile.length) {
        return false;
    }

    try {
        parseLanguageFilterImport(profile);

        return true;
    } catch (_error: unknown) {
        return false;
    }
}

export {LanguageFilter, ILanguageFilterProperties, isLanguageProfileValid};
