interface ICommonsErrorOptions<TData extends Record<string, unknown>> extends ErrorOptions {
    isCritical?: boolean;
    data?: TData;
}

class CommonsError<TData extends Record<string, unknown> = Record<string, unknown>> extends Error {
    public name: string = 'CommonsError';
    private _isCritical: boolean;
    private readonly _data: TData;

    constructor(message: string, options?: ICommonsErrorOptions<TData>) {
        super(message, options);
        this._isCritical = options?.isCritical ?? false;
        this._data = options?.data ?? {} as TData;
    }

    public getData(): TData {
        return this._data;
    }

    get isCritical(): boolean {
        return this._isCritical;
    }

    set isCritical(value: boolean) {
        this._isCritical = value;
    }
}

export {CommonsError, ICommonsErrorOptions};
