import {Observable, of} from 'rxjs';
import {FilterTypes} from '../types/filter.types';
import {Filter, IFilterProperties} from '../types/filter';

type TvChannelFilterTypes = FilterTypes.TV_CHANNEL | FilterTypes.PROGRAMMATIC_TV_CHANNEL;

interface IBaseTvChannelFilterProperties extends IFilterProperties {
    domains: Array<string>;
    inverted: boolean;
}

abstract class BaseTvChannelFilter<TFilterType extends TvChannelFilterTypes = TvChannelFilterTypes> extends Filter<TFilterType> {
    protected abstract readonly _type: TFilterType;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IBaseTvChannelFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        if (!this.domains.length) {
            return of(false);
        }

        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(this.inverted);
    }

    get inverted(): boolean {
        return this.props.inverted;
    }

    set inverted(value: boolean) {
        this.props.inverted = value;
    }

    get domains(): Array<string> {
        return this.props.domains;
    }

    set domains(value: Array<string>) {
        this.props.domains = value;
    }

    protected getDefaultOptions(): IBaseTvChannelFilterProperties {
        return {
            ...super.getDefaultOptions(),
            domains: [],
            inverted: false,
        };
    }
}

export {BaseTvChannelFilter, IBaseTvChannelFilterProperties, TvChannelFilterTypes};
