import {Banner, BaseBanner} from '../base-banner';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';

class GenericBanner extends BaseBanner implements ICloneable<GenericBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes;
    private _ssl: boolean = false;
    private _clickUrl: string | null = null;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        type: BannerTypes,
    ) {
        super(id, networkId, name, landingPage);

        this._type = type;
    }

    get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string | null {
        return this._clickUrl;
    }

    set clickUrl(value: string | null) {
        this._clickUrl = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        GenericBanner.fillAdditionalFields(this, source);
    }

    public clone(): GenericBanner {
        const newModel: GenericBanner = new GenericBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.type,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: GenericBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('clickUrl' in source) {
            target.clickUrl = source.clickUrl;
        }
    }
}

export {GenericBanner};
