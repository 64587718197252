enum VastVersion {
    Vast2 = 'VAST_2',
    Vast4 = 'VAST_4',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Vast4_1 = 'VAST_4_1',
}

const supportedVastVersions: Set<VastVersion | string> = new Set([
    VastVersion.Vast2,
]);

export {VastVersion, supportedVastVersions};
