import {IManualPageContent} from '@angular-clan/core/manual';
import {ISecondarySidenavEntry} from '@angular-clan/core/secondary-sidenav';

enum FrontendServicesEndpoints {
    Health = 'health',
    EventLogger = 'event-logger',
    SlowLogger = 'slow-logger',
    Metrics = 'metrics',
    Favicon = 'favicon',
    PartnerMappings = 'mappings/contextual-targeting/partners',
    ManualPosts = 'manual/posts',
    ManualMenu = 'manual/menu',
    ManualSearch = 'manual/search',
    ManualGlossary = 'manual/glossary',
}

enum FrontendServicesQueryParams {
    ManualDomain = 'manual-domain',
    SearchQuery = 'query',
}

interface IManualResult {
    data: IManualPageContent;
}

interface IManualMenuResult {
    data: Array<ISecondarySidenavEntry>;
}

interface IManualGlossaryResult {
    data: Record<string, string>;
}

interface IManualSearchResult {
    data: Array<IManualSearchContent>;
}

interface IManualSearchContent {
    id: number;
    title: string;
    content: string;
    path: string;
}

export {
    FrontendServicesEndpoints,
    IManualResult,
    IManualMenuResult,
    FrontendServicesQueryParams,
    IManualGlossaryResult,
    IManualSearchResult,
    IManualSearchContent,
};
