import {NgClass} from '@angular/common';
import {Component, InputSignal, input} from '@angular/core';

@Component({
    selector: 'commons-input-row',
    templateUrl: './input-row.html',
    styleUrl: './input-row.scss',
    standalone: true,
    imports: [
        NgClass,
    ],
})
class CommonsInputRowComponent {
    public hasPadding: InputSignal<boolean> = input<boolean>(true);
}

export {CommonsInputRowComponent};
