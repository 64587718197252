import {BannerPoolBanner} from '../base-banner';
import {PtvAdSpotBanner} from './ptv-ad-spot-banner';
import {ICloneable, IMergable} from '../../types';
import {IBannerPoolBanner} from '../banner';

class BannerPoolPtvAdSpotBanner extends PtvAdSpotBanner implements
    IBannerPoolBanner,
    ICloneable<BannerPoolPtvAdSpotBanner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        adSpotId: string,
        duration: number,
        bannerPoolId: number,
    ) {
        super(id, networkId, name, adSpotId, duration);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolPtvAdSpotBanner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolPtvAdSpotBanner {
        const newModel: BannerPoolPtvAdSpotBanner = new BannerPoolPtvAdSpotBanner(
            this.id,
            this.networkId,
            this.name,
            this.adSpotId,
            this.duration,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolPtvAdSpotBanner, source: BannerPoolBanner): void {
        PtvAdSpotBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolPtvAdSpotBanner};
