import {FILTER_ROUTES_CONFIGURATION, IFilterRoutesConfiguration} from './filter-routes-configuration';
import {
    Campaign,
    CampaignBanner,
    FilterTypes,
    IRouterHandler,
    IRouterHandlerData,
    ROUTE_HANDLER,
} from '@active-agent/types';
import {Inject, inject, Injectable, Optional} from '@angular/core';

@Injectable()
class FilterRouteHandler implements IFilterRouteHandler {
    private readonly routeHandler: IRouterHandler = inject(ROUTE_HANDLER);

    constructor(
        @Optional() @Inject(FILTER_ROUTES_CONFIGURATION) private filterRoutesConfiguration: IFilterRoutesConfiguration | null,
    ) {}

    public redirectToFilterSettings(type: FilterTypes, campaign: Campaign, banner?: CampaignBanner | null): void {
        if (!this.filterRoutesConfiguration) {
            return;
        }

        const {
            commands,
            queryParams,
            transitionOptions,
        }: IRouterHandlerData = this.filterRoutesConfiguration.settingsFilter.getDataForLink({
            type,
            banner: banner ?? undefined,
            campaign,
        }, campaign.networkId);

        this.routeHandler.navigate(commands, queryParams, transitionOptions);
    }

    public redirectToFilterDashboard(campaign: Campaign, banner?: CampaignBanner | null): void {
        if (!this.filterRoutesConfiguration) {
            return;
        }

        const {commands, queryParams, transitionOptions}: IRouterHandlerData = this.filterRoutesConfiguration.dashboard.getDataForLink({
            banner: banner ?? undefined,
            campaign,
        }, campaign.networkId);
        this.routeHandler.navigate(commands, queryParams, transitionOptions);
    }

    public redirectToCreateFilter(type: FilterTypes, campaign: Campaign, banner?: CampaignBanner | null): void {
        if (!this.filterRoutesConfiguration) {
            return;
        }

        const {commands, queryParams, transitionOptions}: IRouterHandlerData = this.filterRoutesConfiguration.createFilter.getDataForLink({
            type,
            banner: banner ?? undefined,
            campaign,
        }, campaign.networkId);
        this.routeHandler.navigate(commands, queryParams, transitionOptions);
    }

    public isFilterRoutesConfigurationAvailable(): boolean {
        return !!this.filterRoutesConfiguration;
    }
}

interface IFilterRouteHandler {
    redirectToFilterSettings(type: FilterTypes, campaign: Campaign, banner?: CampaignBanner | null): void;
    redirectToFilterDashboard(campaign: Campaign, banner?: CampaignBanner | null): void;
    redirectToCreateFilter(type: FilterTypes, campaign: Campaign, banner?: CampaignBanner | null): void;
}

export {FilterRouteHandler, type IFilterRouteHandler};
