import {RtbCampaign, Campaign} from './rtb-campaign';
import {CampaignTypes} from './campaign-types';
import {ICloneable, IMergable} from '../types';
import {IDigitalOutOfHomeCampaign} from './campaign';

class DigitalOutOfHomeCampaign extends RtbCampaign implements
    IDigitalOutOfHomeCampaign,
    IMergable<Campaign>,
    ICloneable<DigitalOutOfHomeCampaign> {

    private readonly _type: CampaignTypes = CampaignTypes.DigitalOutOfHome;

    public static isDoohCampaign(campaign: Campaign): campaign is DigitalOutOfHomeCampaign {
        return campaign instanceof DigitalOutOfHomeCampaign;
    }

    public get type(): CampaignTypes {
        return this._type;
    }

    public clone(): DigitalOutOfHomeCampaign {
        const newModel: DigitalOutOfHomeCampaign = new DigitalOutOfHomeCampaign(
            this.id,
            this.orderId,
            this.networkId,
            this.biddingStrategy,
            this.dailyBudget,
            this.deliveryTechnique,
            this.maxCpm,
            this.name,
            this.totalBudget,
        );
        newModel.merge(this);

        return newModel;
    }
}

function isDigitalOutOfHomeCampaign(campaign: unknown) {
    return campaign instanceof DigitalOutOfHomeCampaign;
}

export {DigitalOutOfHomeCampaign, isDigitalOutOfHomeCampaign};
