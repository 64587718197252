function pluralize(
    translations: IPluralizeTranslations,
    count: number,
): string {
    return translations[count] || translations.other;
}

interface IPluralizeTranslations {
    [count: number]: string | null;
    other: string;
}

export {pluralize, IPluralizeTranslations};
