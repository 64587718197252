import {FilterTypes} from '../types/filter.types';
import {BaseTvChannelFilter, IBaseTvChannelFilterProperties} from './base-tv-channel-filter';

type TvChannelFilterProperties = IBaseTvChannelFilterProperties;

class TvChannelFilter extends BaseTvChannelFilter<FilterTypes.TV_CHANNEL>  {
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _type: FilterTypes.TV_CHANNEL = FilterTypes.TV_CHANNEL;
    protected readonly props: TvChannelFilterProperties = this.getDefaultOptions();
}

export {TvChannelFilter};
