// this is the list of country IDs they should be shown as first in the list of available countries
enum FavoriteCountryId {
    Germany = 1,
    Austria = 2,
    Switzerland = 3,
}

enum CountryCode {
    AT = 'AT',
    CH = 'CH',
    DE = 'DE',
}

export {FavoriteCountryId, CountryCode};
