import {AudioStartPositionFilterParser} from '@active-agent/jison';

enum AudioStartPositionFilterType {
    PreRoll = 'preRoll',
    MidRoll = 'midRoll',
    PostRoll = 'postRoll',
    OutStream = 'outStream',
}

type AudioStartPositionFilterImportParserResult = Partial<Record<AudioStartPositionFilterType, boolean>>;

function parseAudioStartPositionFilterImport(rawData: string): AudioStartPositionFilterImportParserResult {
    return JSON.parse(new AudioStartPositionFilterParser().parse(rawData));
}

export {
    AudioStartPositionFilterImportParserResult,
    parseAudioStartPositionFilterImport,
    AudioStartPositionFilterType,
};
