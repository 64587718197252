import {CommonsError} from './error';

abstract class ApiError<TErrorCode extends string> extends CommonsError<ApiErrorData<TErrorCode>> {}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ApiErrorData<TErrorCode> = {
    errorCodes: Array<TErrorCode>;
};

export {ApiError};
