import {CommonsError} from '../../error/error';
import {IWeatherFilterImportParserResult, parseWeatherFilterImport, WeatherKey} from './weather-filter-import';
import {WeatherFilter} from './weather-filter';

const weatherOptions: Array<IWeatherFilterWeather> = [
    {
        id: 1,
        translation: $localize`:@@WEATHER_FILTER_WEATHER_VALUE_SUNNY_CLOUDY:WEATHER_FILTER_WEATHER_VALUE_SUNNY_CLOUDY`,
        key: 'sunny-cloudy',
    },
    {
        id: 2,
        translation: $localize`:@@WEATHER_FILTER_WEATHER_VALUE_CLOUDY:WEATHER_FILTER_WEATHER_VALUE_CLOUDY`,
        key: 'cloudy',
    },
    {
        id: 3,
        translation: $localize`:@@WEATHER_FILTER_WEATHER_VALUE_FOGGY:WEATHER_FILTER_WEATHER_VALUE_FOGGY`,
        key: 'foggy',
    },
    {
        id: 4,
        translation: $localize`:@@WEATHER_FILTER_WEATHER_VALUE_DRIZZLE_RAINY:WEATHER_FILTER_WEATHER_VALUE_DRIZZLE_RAINY`,
        key: 'drizzle-rainy',
    },
    {
        id: 5,
        translation: $localize`:@@WEATHER_FILTER_WEATHER_VALUE_RAINY_THUNDERSTORM:WEATHER_FILTER_WEATHER_VALUE_RAINY_THUNDERSTORM`,
        key: 'rainy-thunderstorm',
    },
    {
        id: 6,
        translation: $localize`:@@WEATHER_FILTER_WEATHER_VALUE_SNOW:WEATHER_FILTER_WEATHER_VALUE_SNOW`,
        key: 'snow',
    },
    {
        id: 7,
        translation: $localize`:@@WEATHER_FILTER_WEATHER_VALUE_ICEY:WEATHER_FILTER_WEATHER_VALUE_ICEY`,
        key: 'icey',
    },
];

const temperatureOptions: Array<IWeatherFilterTemperature> = [
    {id: 1, translation: getTemperatureLimitTranslation(0, '<'), operator: '<', min: 0},
    {id: 2, translation: getTemperatureTranslation(0, 5), min: 0, max: 5},
    {id: 3, translation: getTemperatureTranslation(6, 10), min: 6, max: 10},
    {id: 4, translation: getTemperatureTranslation(11, 15), min: 11, max: 15},
    {id: 5, translation: getTemperatureTranslation(16, 20), min: 16, max: 20},
    {id: 6, translation: getTemperatureTranslation(21, 25), min: 21, max: 25},
    {id: 7, translation: getTemperatureLimitTranslation(25, '>'), operator: '>', max: 25},
];

function getTemperatureTranslation(min: number | string, max: number | string): string {
    return $localize`:@@WEATHER_FILTER_TEMPERATURE_VALUE:WEATHER_FILTER_TEMPERATURE_VALUE ${min}:INTERPOLATION: ${max}:INTERPOLATION_1:`;
}

function getTemperatureLimitTranslation(value: number, operator: string): string {
    return $localize`:@@WEATHER_FILTER_TEMPERATURE_VALUE_LIMIT:WEATHER_FILTER_TEMPERATURE_VALUE_LIMIT ${value}:INTERPOLATION: ${operator}:INTERPOLATION_1:`;
}

function fromAdition(filter: WeatherFilter): IWeatherFilterImportParserResult {
    const parsedData: IWeatherFilterImportParserResult = parseWeatherFilterImport(filter.profile);

    const validWeatherIds: Array<number> = weatherOptions.map((item: IWeatherFilterWeather) => item.id);
    const hasInvalidWeatherIds: boolean = parsedData[WeatherKey.Weather].some((weatherId: number) => !validWeatherIds.includes(weatherId));
    if (hasInvalidWeatherIds) {
        throw new CommonsError('weather filter contains invalid weather ids');
    }

    const hasInvalidTemperatureIds: boolean = parsedData[WeatherKey.Temperature].some((temperatureId: number) => {
        return !temperatureOptions.find((option: IWeatherFilterTemperature) => {
            return option.id === temperatureId;
        });
    });
    if (hasInvalidTemperatureIds) {
        throw new CommonsError('weather filter contains invalid temperature ids');
    }

    return parsedData;
}

function toAdition(weatherIds: Array<number>, temperatureIds: Array<number>): string {
    const data: Array<string> = [];
    if (weatherIds.length) {
        data.push(weatherIds.map((id: number): string => {
            return `${WeatherKey.Weather}=${id}`;
        }).join(' OR '));
    }
    if (temperatureIds.length) {
        data.push(temperatureIds.map((id: number): string => {
            return `${WeatherKey.Temperature}=${id}`;
        }).join(' OR '));
    }

    return `(${data.join(') AND (')})`;
}

interface IWeatherFilterWeather {
    id: number;
    translation: string;
    key: string;
}

interface IWeatherFilterTemperature {
    id: number;
    translation: string;
    operator?: string;
    min?: number;
    max?: number;
}

export {
    weatherOptions, IWeatherFilterWeather, temperatureOptions, IWeatherFilterTemperature, fromAdition, toAdition,
};
