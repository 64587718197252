import {BaseVideoBanner} from '../video/base-video-banner';
import {ICloneable} from '../../types';
import {DoohVastSettings} from '../../vast-settings/dooh-vast-settings';
import {VastSettingsPreset} from '../../vast-settings/vast-settings.service';
import {VastVersion} from '../../vast-versions/vast-version';
import {BannerTypes} from '../banner-types';

class DoohBanner extends BaseVideoBanner implements ICloneable<DoohBanner> {
    private _vastSettings: DoohVastSettings;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        assetId: number,
        vastSettings: DoohVastSettings,
        vastTemplateId: VastSettingsPreset,
        vastVersion: VastVersion | string,
    ) {
        super(id, networkId, name, landingPage, clickUrl, assetId, vastTemplateId, BannerTypes.DOOH, vastVersion);

        this._vastSettings = vastSettings;
    }

    public get vastSettings(): DoohVastSettings {
        return this._vastSettings;
    }

    public set vastSettings(value: DoohVastSettings) {
        this._vastSettings = value;
    }

    public clone(): DoohBanner {
        const newModel: DoohBanner = new DoohBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.clickUrl,
            this.assetId,
            this.vastSettings,
            this.vastTemplateId,
            this.vastVersion,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {DoohBanner};
