import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface ITaggingFilterProperties extends IFilterProperties {
    isValid: boolean;
    code: string;
    referencedFilterIds: Array<number>;
    referencedPeculiarityIds: Array<number>;
}

class TaggingFilter extends Filter<FilterTypes.TAGGING> {
    protected readonly _type: FilterTypes.TAGGING = FilterTypes.TAGGING;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: ITaggingFilterProperties = this.getDefaultOptions();

    public updateValidStatus(newStatus: boolean): void {
        this.props.isValid = newStatus;
    }

    public isValid(): Observable<boolean> {
        return of(null).pipe(
            map(() => {
                if (!this.code.length) {
                    return false;
                }

                return this.props.isValid;
            }),
        );
    }

    public setIsValid(value: boolean): void{
        this.props.isValid = value;
    }

    get code(): string {
        return this.props.code;
    }

    set code(value: string) {
        this.props.code = value;
    }

    get referencedFilterIds(): Array<number> {
        return this.props.referencedFilterIds;
    }

    set referencedFilterIds(value: Array<number>) {
        this.props.referencedFilterIds = value;
    }

    get referencedPeculiarityIds(): Array<number> {
        return this.props.referencedPeculiarityIds;
    }

    set referencedPeculiarityIds(value: Array<number>) {
        this.props.referencedPeculiarityIds = value;
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    protected getDefaultOptions(): ITaggingFilterProperties {
        return {
            ...super.getDefaultOptions(),
            isValid: true,
            code: '',
            referencedFilterIds: [],
            referencedPeculiarityIds: [],
        };
    }
}

export {TaggingFilter, ITaggingFilterProperties};
