import {ICloneable, IMergable} from '../types';
import {IRouterHandlerData} from '../route/route-handler';

class TrackingPage implements ICloneable<TrackingPage>, IMergable<TrackingPage> {

    private readonly _id: number | null;
    private _networkId: number;
    private _companyId: number;
    private _campaignIds: Array<number> = [];
    private _trackingSpotIds: Array<number> = [];
    private _defaultForCompany: boolean;
    private _defaultForOrderIds: Array<number> = [];
    private _name: string;
    private _lastUpdate: number | null = null;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        name: string,
        defaultForCompany: boolean,
        networkId: number,
        companyId: number,
    ) {
        this._id = id;
        this._name = name;
        this._defaultForCompany = defaultForCompany;
        this._networkId = networkId;
        this._companyId = companyId;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get companyId(): number {
        return this._companyId;
    }

    set companyId(value: number) {
        this._companyId = value;
    }

    get campaignIds(): Array<number> {
        return this._campaignIds;
    }

    set campaignIds(value: Array<number>) {
        this._campaignIds = value;
    }

    get trackingSpotIds(): Array<number> {
        return this._trackingSpotIds;
    }

    set trackingSpotIds(value: Array<number>) {
        this._trackingSpotIds = value;
    }

    get defaultForCompany(): boolean {
        return this._defaultForCompany;
    }

    set defaultForCompany(value: boolean) {
        this._defaultForCompany = value;
    }

    get defaultForOrderIds(): Array<number> {
        return this._defaultForOrderIds;
    }

    set defaultForOrderIds(value: Array<number>) {
        this._defaultForOrderIds = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get lastUpdate(): number | null {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | null) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: TrackingPage): void {
        this.networkId = source.networkId;
        this.companyId = source.companyId;
        this.campaignIds = [...source.campaignIds];
        this.trackingSpotIds = [...source.trackingSpotIds];
        this.defaultForOrderIds = [...source.defaultForOrderIds];
        this.defaultForCompany = source.defaultForCompany;
        this.name = source.name;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }

    public clone(): TrackingPage {
        const newModel: TrackingPage = new TrackingPage(
            this.id,
            this.name,
            this.defaultForCompany,
            this.networkId,
            this.companyId,
        );
        newModel.merge(this);

        return newModel;
    }
}

interface ITrackingPagesConfiguration {
    getDataForLink(trackingPageId: number, networkId: number): IRouterHandlerData;
    create: {
        getDataForLink(advertiserId: number, networkId: number): IRouterHandlerData;
    };
    setting: {
        getDataForLink(trackingPageId: number, networkId: number): IRouterHandlerData;
    };
}

export {TrackingPage, ITrackingPagesConfiguration};
