import {Observable, of} from 'rxjs';
import {VideoPositionFilterParser} from './video-position-filter-parser';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IVideoPositionFilterProperties extends IFilterProperties {
    profile: string;
}

class VideoPositionFilter extends Filter<FilterTypes.VIDEO_POSITION> {
    protected readonly _type: FilterTypes.VIDEO_POSITION = FilterTypes.VIDEO_POSITION;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IVideoPositionFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        if (!this.profile.length) {
            return of(false);
        }

        try {
            VideoPositionFilterParser.fromAdition(this.profile);
        } catch (e) {
            return of(false);
        }

        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    protected getDefaultOptions(): IVideoPositionFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

export {VideoPositionFilter, IVideoPositionFilterProperties};
