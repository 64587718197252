// eslint-disable-next-line @typescript-eslint/ban-types
type AssertionContext = object;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AssertionConstructor = (...args: Array<any>) => unknown;

class AssertionError extends Error {
    public override get name(): 'AssertionError' {
        return 'AssertionError';
    }

    constructor(
        message?: string,
        context?: AssertionContext,
        constructor?: AssertionConstructor,
    ) {
        super(message);
        Error.captureStackTrace(context ?? this, constructor ?? AssertionError);
    }
}

/** Make an assertion, error will be thrown if `expression` does not have truthy value. */
function assert(
    this: void,
    expression: unknown,
    message?: string,
): asserts expression {
    if (!expression) {
        throw new AssertionError(message || 'assertion failed', this as unknown as AssertionContext, assert);
    }
}

export {
    assert,
    AssertionConstructor,
    AssertionContext,
    AssertionError,
};
