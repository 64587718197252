import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiRequestInterceptorService} from './api-request-interceptor.service';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {CoreCookieModule} from '@angular-clan/core';
import {AaEventLoggerModule} from '../event/event-logger.module';

@NgModule({
    imports: [
        CommonModule,
        CoreCookieModule,
        AaEventLoggerModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptorService, multi: true},
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
class AaApiModule {}

export {AaApiModule};
