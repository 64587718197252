import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {getAdvertiserDomainPattern} from '@active-agent/pattern';

@Injectable({
    providedIn: 'root',
})
class DomainValidator implements Validator {
    public static readonly maxLength: number = 256;
    public static readonly pattern: RegExp = getAdvertiserDomainPattern();

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<string | null | undefined>): ValidationErrors | null {
        const domain: string | null | undefined = control.value;

        if (domain === undefined || domain === null || domain.length === 0) {
            return {[DomainErrorCodes.Required]: this.getTranslationForErrorCode(DomainErrorCodes.Required)};
        }

        if (domain.length > DomainValidator.maxLength) {
            return {[DomainErrorCodes.MaxLength]: this.getTranslationForErrorCode(DomainErrorCodes.MaxLength)};
        }

        if (!DomainValidator.pattern.test(domain)) {
            return {[DomainErrorCodes.Pattern]: this.getTranslationForErrorCode(DomainErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: DomainErrorCodes): string {
        const requiredMessage: string = $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        const max: number = DomainValidator.maxLength;
        const maxMessage: string = $localize`:@@DOMAIN_VALIDATOR_MAX_LENGTH_ERROR_MESSAGE:DOMAIN_VALIDATOR_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const patternMessage: string = $localize`:@@DOMAIN_VALIDATOR_PATTERN_ERROR_MESSAGE:DOMAIN_VALIDATOR_PATTERN_ERROR_MESSAGE`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        switch (code) {
            case DomainErrorCodes.Required:
                return requiredMessage;
            case DomainErrorCodes.MaxLength:
                return maxMessage;
            case DomainErrorCodes.Pattern:
                return patternMessage;
            default:
                return defaultMessage;
        }
    }
}

enum DomainErrorCodes {
    Required = 'ae9d0c46-b934-4e69-ad88-99874b2055a9',
    MaxLength = 'e65b85b2-30ce-4379-953a-7c32ac1b6831',
    Pattern = '02fe32ee-9295-41d2-b5ce-459a7810eec1',
}

export {DomainValidator, DomainErrorCodes};
