import {inject} from '@angular/core';
import {CanActivateFn, Params, Router} from '@angular/router';
import {CoreCookieService} from '@angular-clan/core';
import {Location} from '@angular/common';

export function libsUserLoggedInGuard(fallbackRoute: string): CanActivateFn {
    return (): boolean => {
        const hasToken: boolean = inject(CoreCookieService).has('token');
        const queryParams: Params = Object.fromEntries(new URL(window.location.href).searchParams);
        const path: string = inject(Location).path();
        const isEmptyParams: boolean = Object.keys(queryParams).length === 0;
        const fullPath: string =  isEmptyParams ? path : `${path}?${new URLSearchParams(queryParams).toString()}`;

        if (!hasToken) {
            void inject(Router).navigate([fallbackRoute], {
                queryParams: {
                    redirectTo: fullPath,
                },
            });

            return false;
        }

        return true;
    };
}
