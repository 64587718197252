import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {InfoBoxLevel} from '@angular-clan/core/info-box';
import {CommonsSegment} from '@active-agent/types';

@Component({
    selector: 'libs-deleted-segment-info-container',
    templateUrl: './deleted-segment-info-container.html',
    styleUrl: '/deleted-segment-info-container.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
class LibsDeletedSegmentInfoContainerComponent {
    @Input() public deletedSegments: Array<CommonsSegment> = [];
    @Input() public isDisabled: boolean = false;
    @Input() public isReadOnly: boolean = false;
    @Input() public deletedExternalSegmentIdsSubject: Subject<Set<string>> = new Subject();
    @Output() public onClearDeletedExternalSegmentIds: EventEmitter<Subject<Set<string>>> = new EventEmitter<Subject<Set<string>>>();
    public readonly errorInfoStatus: InfoBoxLevel = InfoBoxLevel.Error;

    public clearDeletedExternalSegmentIds(): void {
        this.onClearDeletedExternalSegmentIds.emit(this.deletedExternalSegmentIdsSubject);
    }
}

export {LibsDeletedSegmentInfoContainerComponent};
