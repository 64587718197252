import {FilterTypes} from '../types/filter.types';
import {BaseProfileFilter, IBaseProfileFilterProperties} from './base-profile-filter';

type CampaignDoohTrafficTypeFilterProperties = IBaseProfileFilterProperties;

class CampaignDoohTrafficTypeFilter extends BaseProfileFilter<FilterTypes.CAMPAIGN_DOOH_TRAFFIC_TYPE>  {
    protected readonly _isAvailableForPreview: boolean = false;
    protected readonly _type: FilterTypes.CAMPAIGN_DOOH_TRAFFIC_TYPE = FilterTypes.CAMPAIGN_DOOH_TRAFFIC_TYPE;
    protected readonly props: CampaignDoohTrafficTypeFilterProperties = this.getDefaultOptions();

    protected getDefaultOptions(): CampaignDoohTrafficTypeFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: 'trafficType = 5',
        };
    }
}

export {CampaignDoohTrafficTypeFilter};
