/* parser generated by jison 0.3.0 */
/**
 * Returns a Parser implementing JisonParserApi and a Lexer implementing JisonLexerApi.
 */
import { JisonParser, JisonParserApi, StateType, SymbolsType, TerminalsType, ProductionsType, o } from '@ts-jison/parser';const $V0=[1,10],$V1=[1,11],$V2=[5,10],$V3=[5,10,16];

export class JisonParserParser extends JisonParser implements JisonParserApi {
    $?: any;

    constructor (yy = {}, lexer = new JisonParserLexer(yy)) {
      super(yy, lexer);
    }

    symbols_: SymbolsType = {"error":2,"exp":3,"contents":4,"EOF":5,"WEATHER_GROUP":6,"TEMPERATURE_GROUP":7,"TEMPERATURE_GROUP_ONLY":8,"OPEN_GROUP":9,"CLOSE_GROUP":10,"AND":11,"WEATHER_VALUE":12,"WEATHER_KEY":13,"=":14,"VALUE":15,"OR":16,"TEMPERATURE_VALUE":17,"TEMPERATURE_KEY":18,"$accept":0,"$end":1};
    terminals_: TerminalsType = {2:"error",5:"EOF",9:"OPEN_GROUP",10:"CLOSE_GROUP",11:"AND",13:"WEATHER_KEY",14:"=",15:"VALUE",16:"OR",18:"TEMPERATURE_KEY"};
    productions_: ProductionsType = [0,[3,2],[3,1],[4,1],[4,1],[4,1],[4,7],[12,3],[6,1],[6,3],[17,3],[8,3],[7,1],[7,3]];
    table: Array<StateType> = [{3:1,4:2,5:[1,3],6:4,7:5,8:6,9:[1,7],12:8,13:$V0,17:9,18:$V1},{1:[3]},{5:[1,12]},{1:[2,2]},{5:[2,3]},{5:[2,4]},{5:[2,5]},{6:13,7:14,12:8,13:$V0,17:9,18:$V1},o($V2,[2,8],{16:[1,15]}),o($V2,[2,12],{16:[1,16]}),{14:[1,17]},{14:[1,18]},{1:[2,1]},{10:[1,19]},{10:[1,20]},{6:21,12:8,13:$V0},{7:22,17:9,18:$V1},{15:[1,23]},{15:[1,24]},{11:[1,25]},{5:[2,11]},o($V2,[2,9]),o($V2,[2,13]),o($V3,[2,7]),o($V3,[2,10]),{9:[1,26]},{7:27,17:9,18:$V1},{10:[1,28]},{5:[2,6]}];
    defaultActions: {[key:number]: any} = {3:[2,2],4:[2,3],5:[2,4],6:[2,5],12:[2,1],20:[2,11],28:[2,6]};

    performAction (yytext:string, yyleng:number, yylineno:number, yy:any, yystate:number /* action[1] */, $$:any /* vstack */, _$:any /* lstack */): any {
/* this == yyval */
          var $0 = $$.length - 1;
        switch (yystate) {
case 1:
return $$[$0-1];
break;
case 2:
return {};
break;
case 6:
this.$ = {"wNow": $$[$0-5], "txToday": $$[$0-1]}
break;
case 7: case 10:
this.$ = [parseInt($$[$0], 10)]
break;
case 9:
this.$ = $$[$0-2].concat($$[$0]);
break;
case 11:
this.$ = {"txToday": $$[$0-1]}
break;
case 13:
this.$ = $$[$0-2].concat($$[$0])
break;
        }
    }
}


/* generated by ts-jison-lex 0.3.0 */
import { JisonLexer, JisonLexerApi } from '@ts-jison/lexer';
export class JisonParserLexer extends JisonLexer implements JisonLexerApi {
    options: any = {"moduleName":"JisonParser"};
    constructor (yy = {}) {
        super(yy);
    }

    rules: RegExp[] = [/^(?:\s+)/,/^(?:wNow\b)/,/^(?:txToday\b)/,/^(?:=)/,/^(?:\d\b)/,/^(?:[aA][nN][dD])/,/^(?:[oO][rR])/,/^(?:\()/,/^(?:\))/,/^(?:$)/];
    conditions: any = {"INITIAL":{"rules":[0,1,2,3,4,5,6,7,8,9],"inclusive":true}}
    performAction (yy:any,yy_:any,$avoiding_name_collisions:any,YY_START:any): any {
          var YYSTATE=YY_START;
        switch($avoiding_name_collisions) {
    case 0:/* skip whitespace */
      break;
    case 1:return 13;
      break;
    case 2:return 18;
      break;
    case 3:return 14
      break;
    case 4:return 15;
      break;
    case 5:return 11;
      break;
    case 6:return 16;
      break;
    case 7:return 9;
      break;
    case 8:return 10;
      break;
    case 9:return 5;
      break;
        }
    }
}

