import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface ICityFilterProperties extends IFilterProperties {
    geoIds: Array<number>;
    inverted: boolean;
}

class CityFilter extends Filter<FilterTypes.CITY> {
    protected readonly _type: FilterTypes.CITY = FilterTypes.CITY;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = true;
    protected readonly props: ICityFilterProperties = this.getDefaultOptions();
    private _isValid: boolean = true;

    get inverted(): boolean {
        return this.props.inverted;
    }

    set inverted(value: boolean) {
        this.props.inverted = value;
    }

    get geoIds(): Array<number> {
        return this.props.geoIds;
    }

    set geoIds(value: Array<number>) {
        this.props.geoIds = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return this.isInverted().pipe(map((inverted: boolean) => !inverted));
    }

    public isInverted(): Observable<boolean> {
        return of(this.props.inverted);
    }

    public isValid(): Observable<boolean> {
        return of(this._isValid);
    }

    public setIsValid(value: boolean): void{
        this._isValid = value;
    }

    protected getDefaultOptions(): ICityFilterProperties {
        return {
            ...super.getDefaultOptions(),
            geoIds: [],
            inverted: false,
        };
    }
}

export {CityFilter, ICityFilterProperties};
