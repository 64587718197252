<div class="vm-fill vm-flex-direction-row vm-layout-justify-center vm-layout-align-start">
    <div
        class="content vm-flex-direction-column vm-flex"
        [class.content--max-width]="limitWidth()"
        [class.content--fill-height]="fillHeight()"
        [style.minHeight]="minHeight()"
    >
        <ng-content></ng-content>
    </div>
</div>
