<commons-text-list-item
    [title]="title"
    [titleTooltip]="titleTooltip"
    [subtitle]="subtitle"
    [isActive]="isActive"
    [borderStyle]="borderStyle"
    [hasPadding]="hasPadding"
    class="select-list-item vm-flex"
>
    <mat-form-field appearance="fill" class="select-list-item vm-flex-100">
        <mat-label>{{placeholder}}</mat-label>
        <mat-select
            [compareWith]="compareOptionsWithSelectedValues"
            name="selectInput"
            [required]="isRequired"
            [(value)]="selectedOptionValue"
            [disabled]="isDisabled"
            (selectionChange)="onSelectionChange($event)"
            class="select-list-item__selection"
        >
            <mat-option
                [value]="option.value"
                *ngFor="let option of options"
            >{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</commons-text-list-item>

