import {FilterTypes} from '../types/filter.types';
import {BaseTvChannelFilter, IBaseTvChannelFilterProperties} from './base-tv-channel-filter';

type ProgrammaticTvChannelFilterProperties = IBaseTvChannelFilterProperties;

class ProgrammaticTvChannelFilter extends BaseTvChannelFilter<FilterTypes.PROGRAMMATIC_TV_CHANNEL>  {
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _type: FilterTypes.PROGRAMMATIC_TV_CHANNEL = FilterTypes.PROGRAMMATIC_TV_CHANNEL;
    protected readonly props: ProgrammaticTvChannelFilterProperties = this.getDefaultOptions();
}

export {ProgrammaticTvChannelFilter, ProgrammaticTvChannelFilterProperties};
