import {Component, input, InputSignal} from '@angular/core';

@Component({
    selector: 'libs-section-header-divider',
    templateUrl: 'section-header-divider.html',
    styleUrl: 'section-header-divider.scss',
    standalone: true,
})
class LibsSectionHeaderDividerComponent {
    public hasError: InputSignal<boolean> = input<boolean>(false);
    public title: InputSignal<string> = input.required<string>();
}

export {LibsSectionHeaderDividerComponent};
