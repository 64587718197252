import {Observable, of} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IWifiFilterProperties extends IFilterProperties {
    isWifiEnabled: boolean;
}

class WifiFilter extends Filter<FilterTypes.WIFI> {
    protected readonly _type: FilterTypes.WIFI = FilterTypes.WIFI;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IWifiFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    public get isWifiEnabled(): boolean {
        return this.props.isWifiEnabled;
    }

    public set isWifiEnabled(value: boolean) {
        this.props.isWifiEnabled = value;
    }

    protected getDefaultOptions(): IWifiFilterProperties {
        return {
            ...super.getDefaultOptions(),
            isWifiEnabled: false,
        };
    }
}

export {WifiFilter, IWifiFilterProperties};
