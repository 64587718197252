import {ICloneable, IMergable} from '../types';
import {ICampaignPublisherTag} from './campaign-publisher-tag.types';

class CampaignPublisherTag implements ICampaignPublisherTag, IMergable<CampaignPublisherTag>, ICloneable<CampaignPublisherTag> {
    private _tag: string;

    constructor(tag: string) {
        this._tag = tag;
    }

    public get tag(): string {
        return this._tag;
    }

    public set tag(value: string) {
        this._tag = value;
    }

    public merge(source: CampaignPublisherTag): void {
        this.tag = source.tag;
    }

    public clone(): CampaignPublisherTag {
        const newModel: CampaignPublisherTag = new CampaignPublisherTag(this.tag);
        newModel.merge(this);

        return newModel;
    }
}

export {CampaignPublisherTag};
