enum Ssps {
    YIELDLAB = 'YIELDLAB',
    GOOGLE = 'GOOGLE',
    APPNEXUS = 'APPNEXUS',
    IMPROVEDIGITAL = 'IMPROVEDIGITAL',
    SMART_ADSERVER = 'SMART_ADSERVER',
    SMARTSTREAM = 'SMARTSTREAM',
    RUBICON = 'RUBICON',
    YAHOO = 'YAHOO',
    STICKY_ADS = 'STICKY_ADS',
    LIVERAIL = 'LIVERAIL',
    SPOTXCHANGE = 'SPOTXCHANGE',
    DAILYMOTION = 'DAILYMOTION',
    ADSCALE = 'ADSCALE',
    SMARTCLIP = 'SMARTCLIP',
    AYUDA = 'AYUDA',
    PERMODO = 'PERMODO',
    ADSWIZZ = 'ADSWIZZ',
    LIGATUS = 'LIGATUS',
    TEADS = 'TEADS',
    PUBMATIC = 'PUBMATIC',
    /**
     * @deprecated
     */
    OATH_VIDEO = 'OATH_VIDEO',
    ORBIDDER = 'ORBIDDER',
    OUTBRAIN = 'OUTBRAIN',
    VIOOH = 'VIOOH',
    ATG = 'ATG',
    BROADSIGN = 'BROADSIGN',
    SMAATO = 'SMAATO',
    MOPUB = 'MOPUB',
    ADITION_TV = 'ADITION_TV',
}

export {Ssps};
