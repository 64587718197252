import {cloneDeep} from 'lodash-es';
import {CommonsError} from '../../error/error';
import {AudioStartPositionFilterImportParserResult, AudioStartPositionFilterType, parseAudioStartPositionFilterImport} from './audio-start-position-filter-import';

const FILTER_SEPARATOR: string = ' OR ';
const FILTER_VALUE_KEY: string = 'startPosition';

const options: Array<IAudioTypeOption> = [
    {
        name: AudioStartPositionFilterType.PreRoll,
        active: true,
        translation: $localize`:@@START_POSITION_PRE_ROLL:START_POSITION_PRE_ROLL`,
    },
    {
        name: AudioStartPositionFilterType.MidRoll,
        active: true,
        translation: $localize`:@@START_POSITION_MID_ROLL:START_POSITION_MID_ROLL`,
    },
    {
        name: AudioStartPositionFilterType.PostRoll,
        active: true,
        translation: $localize`:@@START_POSITION_POST_ROLL:START_POSITION_POST_ROLL`,
    },
    {
        name: AudioStartPositionFilterType.OutStream,
        active: true,
        translation: $localize`:@@START_POSITION_OUT_STREAM:START_POSITION_OUT_STREAM`,
    },
];

function getAudioFilterOptions(): Array<IAudioTypeOption> {
    return cloneDeep(options);
}

function fromAditionAudioFilter(rawData: string): Array<IAudioTypeOption> {
    const parsedData: AudioStartPositionFilterImportParserResult = parseAudioStartPositionFilterImport(rawData);
    const activeFilters: Array<string> = Object.keys(parsedData);
    const filterObject: Array<IAudioTypeOption> = getAudioFilterOptions();

    filterObject
        .filter((currentFilter: IAudioTypeOption): boolean => {
            return !activeFilters.includes(currentFilter.name);
        })
        .forEach((currentFilter: IAudioTypeOption): void => {
            currentFilter.active = false;
        });

    const invalidFilters: Array<string> = activeFilters
        .filter((activeFilter: string) => {
            return !filterObject.some((object: IAudioTypeOption) => object.name === activeFilter);
        });
    if (invalidFilters.length) {
        throw new CommonsError(
            'audio profile targeting contains invalid options',
            {data: {rawData, invalidFilters}},
        );
    }

    return filterObject;
}

function toAditionAudioFilter(paramFilter: Array<IAudioTypeOption>): string {
    return paramFilter
        .filter((currentFilter: IAudioTypeOption): boolean => {
            return currentFilter.active;
        })
        .map((currentFilter: IAudioTypeOption): string => {
            return `${FILTER_VALUE_KEY} = '${currentFilter.name}'`;
        })
        .join(FILTER_SEPARATOR);
}

interface IAudioTypeOption {
    name: AudioStartPositionFilterType;
    active: boolean;
    translation: string;
}

export {fromAditionAudioFilter, toAditionAudioFilter, getAudioFilterOptions, IAudioTypeOption};
