import {
    Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import Cropper from 'cropperjs';
import {ICropPosition} from '../image-selection/image-selection-cropper.component';
import {AdCroppedPreviewDialogComponent, ICroppedPreviewDialogData} from './cropped-preview-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'ad-cropped-preview',
    templateUrl: 'cropped-preview.html',
    styleUrls: ['./cropped-preview.scss'],
})

class AdCroppedPreviewComponent implements OnChanges {
    @Input() public image!: string | null;
    @Input() public cropWidth!: number;
    @Input() public cropHeight!: number;

    @ViewChild('preview', {static: false}) private previewElement!: ElementRef;
    @ViewChild('cropper', {static: false}) private cropperElement!: ElementRef;

    @Input() private cropPosition!: ICropPosition;
    @Output() private onCropperInit: EventEmitter<Cropper> = new EventEmitter<Cropper>();

    private cropperInstance: Cropper | null = null;

    constructor(
        private dialog: MatDialog,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.image
            && changes.image.currentValue !== undefined
            && changes.image.currentValue !== null
        ) {
            setTimeout(() => {
                const cropWidth: number = this.cropWidth;
                const cropHeight: number = this.cropHeight;
                const cropPosition: ICropPosition = this.cropPosition;
                this.cropperInstance = new Cropper(
                    this.cropperElement.nativeElement,
                    {
                        preview: this.previewElement.nativeElement,
                        // eslint-disable-next-line object-shorthand
                        ready: function(_event: CustomEvent<any>): void {
                            this.cropper
                                .setData({
                                    x: cropPosition.x - (cropWidth / 2),
                                    y: cropPosition.y - (cropHeight / 2),
                                    width: cropWidth,
                                    height: cropHeight,
                                });
                        },
                    },
                );
                this.onCropperInit.emit(this.cropperInstance);
            });
        }

        if (changes.cropPosition && changes.cropPosition.currentValue !== undefined) {
            if (this.cropperInstance) {
                this.cropperInstance.setData({
                    x: this.cropPosition.x - (this.cropWidth / 2),
                    y: this.cropPosition.y - (this.cropHeight / 2),
                });
            }
        }
    }

    public openCroppedPreview(): void {
        if (!this.cropperInstance) {
            return;
        }
        this.dialog
            .open<AdCroppedPreviewDialogComponent, ICroppedPreviewDialogData>(AdCroppedPreviewDialogComponent, {
            data: {
                image: this.cropperInstance.getCroppedCanvas().toDataURL(),
            },
            width: `${this.cropWidth}`,
            height: `${this.cropHeight}`,
        });
    }
}

export {AdCroppedPreviewComponent};
