import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    NG_VALUE_ACCESSOR,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import {CommonsError} from '@active-agent/types';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'libs-inverted-status',
    templateUrl: './inverted-status.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LibsInvertedStatusComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
class LibsInvertedStatusComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() public formControlName!: string;
    @Input() public isReadOnly: boolean = false;
    @Input() public isDisabled: boolean = false;
    public parentControl!: AbstractControl;
    public invertedStatusOptions: Array<IInvertedStatusOption> = [
        {
            value: false,
            name: $localize`:@@ALLOWLIST:ALLOWLIST`,
        },
        {
            value: true,
            name: $localize`:@@BLOCKLIST:BLOCKLIST`,
        },
    ];
    public inverted: UntypedFormControl = this.formBuilder.control(null);
    private onControlChange: ((inverted: boolean) => void) | undefined;
    private onControlTouched: (() => void) | undefined;

    constructor(
        private controlContainer: ControlContainer,
        private formBuilder: UntypedFormBuilder,
        private detectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.inverted.valueChanges.subscribe(this.onChange.bind(this));

        if (this.controlContainer) {
            if (this.formControlName && this.controlContainer.control) {
                const control: AbstractControl | null = this.controlContainer.control.get(this.formControlName);
                if (control) {
                    this.parentControl = control;
                    this.parentControl.statusChanges.subscribe(() => {
                        this.inverted.setErrors(this.parentControl.errors);
                        this.detectorRef.detectChanges();
                    });
                    if (this.parentControl.touched && this.parentControl.errors) {
                        this.inverted.markAsTouched();
                    }
                }
            } else {
                throw new CommonsError('Missing FormControlName directive from host element of the component');
            }
        } else {
            throw new CommonsError('Can\'t find parent FormGroup directive');
        }

        this.detectorRef.detectChanges();
    }

    public ngOnChanges(): void {
        if (this.isDisabled || this.isReadOnly) {
            this.inverted.disable();
        } else {
            this.inverted.enable();
        }
    }

    public registerOnChange(fn: (inverted: boolean) => void): void {
        this.onControlChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onControlTouched = fn;
    }

    public writeValue(inverted: boolean): void {
        this.inverted.setValue(inverted);
    }

    private onChange(): void {
        if (this.onControlChange && this.onControlTouched) {
            this.onControlChange(this.inverted.value);
            this.onControlTouched();
        }

        this.detectorRef.detectChanges();
    }
}

interface IInvertedStatusOption {
    value: boolean;
    name: string;
}

export {LibsInvertedStatusComponent};
