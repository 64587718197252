import {NgModule} from '@angular/core';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';
import {LibsSslSwitchListItemComponent} from './ssl-switch-list-item.component';
import {CommonModule} from '@angular/common';
import {CommonsSwitchListItemComponent} from '../switch-list-item/switch-list-item.component';

@NgModule({
    imports: [
        CommonsSwitchListItemComponent,
        CommonsStatusIconModule,
        CommonModule,
    ],
    exports: [
        LibsSslSwitchListItemComponent,
    ],
    declarations: [
        LibsSslSwitchListItemComponent,
    ],
})
class LibsSslSwitchListItemModule {}

export {LibsSslSwitchListItemModule};
