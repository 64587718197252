<!-- Title -->
<h1 mat-dialog-title *ngIf="data.title">
    <!-- Warning Icon -->
    <commons-status-icon *ngIf="isWarningStatus()" [status]="warningStatus" class="confirmation-dialog__status-icon"></commons-status-icon>
    <commons-status-icon *ngIf="isErrorStatus()" [status]="errorStatus" class="confirmation-dialog__status-icon"></commons-status-icon>
    <commons-status-icon *ngIf="isInfoStatus()" [status]="infoStatus" class="confirmation-dialog__status-icon"></commons-status-icon>{{data.title}}</h1>
<mat-dialog-content class="confirmation-dialog">
    <div class="flex-direction-row vm-layout-justify-space-between vm-layout-align-start">
        <div class="vm-flex flex-direction-column">
            <!-- Additional info box -->
            <core-info-box *ngIf="infoBox" [level]="infoBox.level" class="confirmation-dialog__info-box">
                <ng-container core-info-box-title>{{infoBox.title}}</ng-container>
                <ng-container core-info-box-description>{{infoBox.description}}</ng-container>
            </core-info-box>

            <!-- Description -->
            <p [innerHTML]="data.description"></p>

            <!-- Checkbox -->
            <mat-list *ngIf="data.checkboxLabel">
                <mat-list-item class="list-item list-item--no-height">
                    <mat-checkbox [(ngModel)]="checkbox" color="primary">
                        <span class="list-item__title">{{data.checkboxLabel}}</span>
                        <div class="list-item__description" *ngIf="data.checkboxLabelDescription" [innerHtml]="data.checkboxLabelDescription"></div>
                    </mat-checkbox>
                </mat-list-item>
            </mat-list>

            <!-- Template -->
            <ng-container *ngTemplateOutlet="data.template || null; context: data.templateContext || null"></ng-container>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-button
        (click)="close()"
        i18n="@@CONFIRMATION_DIALOG_CANCEL_BUTTON"
        *ngIf="!data.cancelButtonLabel && data.hasCancelButton"
    >CONFIRMATION_DIALOG_CANCEL_BUTTON</button>
    <button
        mat-button
        (click)="close()"
        *ngIf="data.cancelButtonLabel && data.hasCancelButton"
    >{{data.cancelButtonLabel}}</button>

    <button
        mat-button
        [color]="primaryButtonColor"
        [mat-dialog-close]="getResultValue(true)"
        cdkFocusInitial
        i18n="@@CONFIRMATION_DIALOG_CONFIRM_BUTTON"
        *ngIf="!data.confirmButtonLabel"
    >CONFIRMATION_DIALOG_CONFIRM_BUTTON</button>
    <button
        mat-button
        [color]="primaryButtonColor"
        [mat-dialog-close]="getResultValue(true)"
        cdkFocusInitial
        *ngIf="data.confirmButtonLabel"
    >{{data.confirmButtonLabel}}</button>
</mat-dialog-actions>
