import {Injectable} from '@angular/core';
import {LibsNotificationComponent, INotificationData} from './notification.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
class NotificationService {
    private durationInSeconds: number = 5;

    constructor(
        private snackBar: MatSnackBar,
    ) { }

    public showError(message: string): void {
        this.showNotification(message, NotificationType.Error);
    }

    public showSuccess(message: string): void {
        this.showNotification(message, NotificationType.Success);
    }

    public showInfo(message: string): void {
        this.showNotification(message, NotificationType.Info);
    }

    private showNotification(message: string, type: NotificationType): void {
        let icon: string = '';
        const panelClass: Array<string> = ['notification-wrapper'];
        switch (type) {
            case NotificationType.Info:
                icon = 'info';
                panelClass.push('notification-wrapper--info');
                break;
            case NotificationType.Error:
                icon = 'warning';
                panelClass.push('notification-wrapper--error');
                break;
            case NotificationType.Success:
                icon = 'done';
                panelClass.push('notification-wrapper--success');
                break;
            default:
                throw new Error(`given type (${JSON.stringify(type)}) is not supported yet'`);
        }

        const data: INotificationData = {
            message,
            icon,
        };

        this.snackBar.openFromComponent<LibsNotificationComponent>(LibsNotificationComponent, {
            duration: this.durationInSeconds * 1000,
            data,
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
            panelClass,
        });
    }
}

enum NotificationType {
    Info = 'Info',
    Error = 'Error',
    Success = 'Success',
}

export {NotificationService, NotificationType};
