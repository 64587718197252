interface ICountryDTO {
    id: number;
    name: string;
    isoName: string;
    deleted: boolean;
}

class Country implements ICountryDTO {
    private _id: number;
    private _name: string = '';
    private _isoName: string ='';
    private _deleted: boolean = false;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get isoName(): string {
        return this._isoName;
    }

    set isoName(value: string) {
        this._isoName = value;
    }

    constructor(data: ICountryDTO);
    constructor(id: number, name: string);
    constructor(data: ICountryDTO | number, name?: string) {
        if (typeof data === 'number') {
            this._id = data;

            if (name) {
                this._name = name;
            }

        } else {
            this._id = data.id;
            this._name = data.name;
            this._deleted = data.deleted;
            this._isoName = data.isoName;
        }
    }
}

export {ICountryDTO, Country};
