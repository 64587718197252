import {AbstractControl, ValidationErrors} from '@angular/forms';
import {getUrlAditionPattern} from '@active-agent/pattern';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class RequiredClickUrlValidator {
    public static readonly maxLength: number = 2_000;
    public static readonly pattern: RegExp = getUrlAditionPattern();

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<string | null>): ValidationErrors | null {
        const clickUrl: string | null = control.value;

        if (clickUrl === undefined || clickUrl === null || !clickUrl.length) {
            return {[RequiredClickUrlErrorCodes.Required]: this.getTranslationForErrorCode(RequiredClickUrlErrorCodes.Required)};
        }

        if (clickUrl.length > RequiredClickUrlValidator.maxLength) {
            return {[RequiredClickUrlErrorCodes.MaxLength]: this.getTranslationForErrorCode(RequiredClickUrlErrorCodes.MaxLength)};
        }

        if (!RequiredClickUrlValidator.pattern.test(clickUrl)) {
            return {[RequiredClickUrlErrorCodes.Pattern]: this.getTranslationForErrorCode(RequiredClickUrlErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: RequiredClickUrlErrorCodes): string {
        switch (code) {
            case RequiredClickUrlErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case RequiredClickUrlErrorCodes.MaxLength:
                const max: string | null = this.decimalPipe.transform(RequiredClickUrlValidator.maxLength);

                return $localize`:@@VALIDATOR_REQUIRED_CLICK_URL_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_REQUIRED_CLICK_URL_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            case RequiredClickUrlErrorCodes.Pattern:
                return $localize`:@@VALIDATOR_REQUIRED_CLICK_URL_PATTERN_ERROR_MESSAGE:VALIDATOR_REQUIRED_CLICK_URL_PATTERN_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum RequiredClickUrlErrorCodes {
    Required = '0dc83f28-00ff-4ab9-9c98-31ed36cd31a3',
    MaxLength = '81034006-1dd4-4264-bde1-cac4f5ae474b',
    Pattern = 'd6c0dbe1-4762-4107-b440-fe49ae44a072',
}

export {RequiredClickUrlValidator};
