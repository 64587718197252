import {Injectable} from '@angular/core';
import {isEqual} from 'lodash-es';
import {IEncoding, IVideoEncoding} from '../banner';
import {CommonsError} from '../error';
import {VastSettingsType} from './base-vast-settings';

@Injectable({
    providedIn: 'root',
})
class CommonsVastSettingsService {
    private readonly videoVastSettingsPresets: Map<VastSettingsPreset, IVastSettingsPresetValue> = new Map([
        [VastSettingsPreset.videoSmartClip, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_SMARTCLIP:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_SMARTCLIP`,
            encodings: [
                {type: 'VIDEO_MP4', width: 640, height: 360, bitRate: 800},
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 800},
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 800},
            ],
        }],
        [VastSettingsPreset.videoMagnite, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_MAGNITE:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_MAGNITE`,
            encodings: [
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 1600},
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 2500},
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 3500},
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 8000},
            ],
        }],
        [VastSettingsPreset.videoSevenOneMedia, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_SEVENONE:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_SEVENONE`,
            encodings: [
                {type: 'VIDEO_MP4', width: 854, height: 480, bitRate: 750},
                {type: 'VIDEO_MP4', width: 854, height: 480, bitRate: 1500},
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 2500},
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 3500},
            ],
        }],
        [VastSettingsPreset.videoStroer, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_STROER:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_STROER`,
            encodings: [
                {type: 'VIDEO_MP4', width: 640, height: 360, bitRate: 800},
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 1600},
            ],
        }],
        [VastSettingsPreset.videoSmartStream, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_SMART_STREAM:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_SMART_STREAM`,
            encodings: [
                {type: 'VIDEO_MP4', width: 480, height: 270, bitRate: 768},
                {type: 'VIDEO_WEBM', width: 480, height: 270, bitRate: 768},
                {type: 'VIDEO_MP4', width: 818, height: 460, bitRate: 1500},
                {type: 'VIDEO_WEBM', width: 818, height: 460, bitRate: 1500},
                {type: 'VIDEO_MP4', width: 1024, height: 576, bitRate: 3500},
                {type: 'VIDEO_WEBM', width: 1024, height: 576, bitRate: 3500},
            ],
        }],
        [VastSettingsPreset.videoDach, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_DACH:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_DACH`,
            encodings: [
                {type: 'VIDEO_MP4', width: 640, height: 360, bitRate: 800},
                {type: 'VIDEO_MP4', width: 818, height: 460, bitRate: 1000},
                {type: 'VIDEO_MP4', width: 1024, height: 576, bitRate: 3500},
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 1600},
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 2500},
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 800},
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 3500},
            ],
        }],
        [VastSettingsPreset.videoIabDefaultOne, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_IAB_DEFAULT_16_9:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_IAB_DEFAULT_16_9`,
            encodings: [
                {type: 'VIDEO_MP4', width: 640, height: 360, bitRate: 500},
                {type: 'VIDEO_WEBM', width: 640, height: 360, bitRate: 500},
                {type: 'VIDEO_MP4', width: 1024, height: 576, bitRate: 700},
                {type: 'VIDEO_WEBM', width: 1024, height: 576, bitRate: 700},
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 2500},
                {type: 'VIDEO_WEBM', width: 1920, height: 1080, bitRate: 2500},
            ],
        }],
        [VastSettingsPreset.videoIabDefaultTwo, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_IAB_DEFAULT_4_3:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_IAB_DEFAULT_4_3`,
            encodings: [
                {type: 'VIDEO_MP4', width: 640, height: 480, bitRate: 500},
                {type: 'VIDEO_WEBM', width: 640, height: 480, bitRate: 500},
                {type: 'VIDEO_MP4', width: 640, height: 480, bitRate: 700},
                {type: 'VIDEO_WEBM', width: 640, height: 480, bitRate: 700},
                {type: 'VIDEO_MP4', width: 768, height: 576, bitRate: 700},
                {type: 'VIDEO_WEBM', width: 768, height: 576, bitRate: 700},
            ],
        }],
        [VastSettingsPreset.adAlliance, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_AD_ALLIANCE:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_AD_ALLIANCE`,
            encodings: [
                {type: 'VIDEO_MP4', width: 640, height: 360, bitRate: 750},
                {type: 'VIDEO_MP4', width: 320, height: 180, bitRate: 320},
                {type: 'VIDEO_MP4', width: 960, height: 540, bitRate: 1500},
            ],
        }],
        [VastSettingsPreset.disney, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_DISNEY:VAST_SETTINGS_ENCODINGS_VIDEO_PRESETS_SELECTION_OPTION_DISNEY`,
            encodings: [
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 15_000},
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 20_000},
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 15_000},
                {type: 'VIDEO_MP4', width: 1280, height: 720, bitRate: 20_000},
                {type: 'VIDEO_MOV', width: 1920, height: 1080, bitRate: 15_000},
                {type: 'VIDEO_MOV', width: 1920, height: 1080, bitRate: 20_000},
                {type: 'VIDEO_MOV', width: 1280, height: 720, bitRate: 15_000},
                {type: 'VIDEO_MOV', width: 1280, height: 720, bitRate: 20_000},
            ],
        }],
    ]);

    private readonly doohVastSettingsPresets: Map<VastSettingsPreset, IVastSettingsPresetValue> = new Map([
        [VastSettingsPreset.doohDachOne, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_DOOH_PRESETS_SELECTION_OPTION_DACH_9_16:VAST_SETTINGS_ENCODINGS_DOOH_PRESETS_SELECTION_OPTION_DACH_9_16`,
            encodings: [
                {type: 'VIDEO_MP4', width: 1080, height: 1920, bitRate: 6000},
            ],
        }],
        [VastSettingsPreset.doohDachTwo, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_DOOH_PRESETS_SELECTION_OPTION_DACH_16_9:VAST_SETTINGS_ENCODINGS_DOOH_PRESETS_SELECTION_OPTION_DACH_16_9`,
            encodings: [
                {type: 'VIDEO_MP4', width: 1920, height: 1080, bitRate: 6000},
            ],
        }],
        [VastSettingsPreset.doohDachThree, {
            translation: $localize`:@@VAST_SETTINGS_ENCODINGS_DOOH_PRESETS_SELECTION_OPTION_DCLB_WALL_DECAUX:VAST_SETTINGS_ENCODINGS_DOOH_PRESETS_SELECTION_OPTION_DCLB_WALL_DECAUX`,
            encodings: [
                {type: 'VIDEO_MP4', width: 440, height: 300, bitRate: 6000},
            ],
        }],
    ]);

    private readonly audioVastSettingsPresets: Map<VastSettingsPreset, IVastSettingsPresetValue> = new Map([
    ]);

    public getVastSettingsPresets(type: VastSettingsType): Map<VastSettingsPreset, IVastSettingsPresetValue> {
        switch (type) {
            case VastSettingsType.Audio:
                return this.audioVastSettingsPresets;
            case VastSettingsType.Video:
                return this.videoVastSettingsPresets;
            case VastSettingsType.Dooh:
                return this.doohVastSettingsPresets;
            default:
                throw new CommonsError('unknown vast settings type', {data: {type}});
        }
    }

    public compareWithDefaultEncodings(
        preset: VastSettingsPreset,
        type: VastSettingsType,
        encodings: Array<IEncoding | IVideoEncoding>,
    ): boolean {

        const defaultPresetValue: IVastSettingsPresetValue | undefined = this.getVastSettingsPresets(type).get(preset);

        return defaultPresetValue !== undefined && isEqual(encodings, defaultPresetValue.encodings);
    }
}

enum VastSettingsPreset {
    Custom = 0,
    videoSmartClip = 1,
    videoSevenOneMedia = 2,
    videoStroer = 3,
    videoSmartStream = 4,
    videoDach = 5,
    videoIabDefaultOne = 6,
    videoIabDefaultTwo = 7,
    doohDachOne = 8,
    doohDachTwo = 9,
    adAlliance = 10,
    doohDachThree = 11,
    videoMagnite = 12,
    disney = 13,
}

interface IVastSettingsPresetValue {
    translation: string;
    encodings: Array<IEncoding | IVideoEncoding>;
}

export {CommonsVastSettingsService, VastSettingsPreset, IVastSettingsPresetValue};
