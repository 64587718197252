import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class MaxCpcvValidator implements Validator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0.01;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const maxCpcv: number | undefined | null = control.value;

        if (maxCpcv === undefined || maxCpcv === null) {
            return {[MaxCpcvErrorCodes.Required]: this.getTranslationForErrorCode(MaxCpcvErrorCodes.Required)};
        }

        if (maxCpcv < MaxCpcvValidator.minValue) {
            return {[MaxCpcvErrorCodes.MinValue]: this.getTranslationForErrorCode(MaxCpcvErrorCodes.MinValue)};
        }

        if (maxCpcv > MaxCpcvValidator.maxValue) {
            return {[MaxCpcvErrorCodes.MaxValue]: this.getTranslationForErrorCode(MaxCpcvErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: MaxCpcvErrorCodes): string {
        switch (code) {
            case MaxCpcvErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case MaxCpcvErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_MAX_CPCV_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_CPCV_MIN_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(MaxCpcvValidator.minValue, 'EUR')}:INTERPOLATION:`;
            case MaxCpcvErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_MAX_CPCV_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_CPCV_MAX_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(MaxCpcvValidator.maxValue, 'EUR')}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum MaxCpcvErrorCodes {
    Required = 'f8fa9c23-6507-4af6-b108-55e3e73880a8',
    MinValue = 'c3d0e2da-e8f0-4891-b898-66e524157761',
    MaxValue = '8d83f60e-949b-42e1-aa15-738a212bc52d',
}

export {MaxCpcvValidator, MaxCpcvErrorCodes};
