<main class="app">
    <section class="app__section">
        <libs-announcement-header></libs-announcement-header>
        <commons-outdated-browser></commons-outdated-browser>
        <libs-ad-blocker-detector (onBlockerDetected)="onBlockerDetected($event)"></libs-ad-blocker-detector>
        <commons-new-version></commons-new-version>
    </section>
    <section class="app__section">
        <router-outlet></router-outlet>
    </section>
</main>
