import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class AtvCappingSegmentCpmValidator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number>): ValidationErrors | null {
        const atvCappingSegmentCpm: number = control.value;

        if (atvCappingSegmentCpm === undefined || atvCappingSegmentCpm === null) {
            return null;
        }

        if (atvCappingSegmentCpm < AtvCappingSegmentCpmValidator.minValue) {
            return {[AtvCappingSegmentCpmErrorCodes.MinValue]: this.getTranslationForErrorCode(AtvCappingSegmentCpmErrorCodes.MinValue)};
        }

        if (atvCappingSegmentCpm > AtvCappingSegmentCpmValidator.maxValue) {
            return {[AtvCappingSegmentCpmErrorCodes.MaxValue]: this.getTranslationForErrorCode(AtvCappingSegmentCpmErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: AtvCappingSegmentCpmErrorCodes): string {
        const min: string = this.currencyPipe.transform(AtvCappingSegmentCpmValidator.minValue, 'EUR') || '';
        const max: string = this.currencyPipe.transform(AtvCappingSegmentCpmValidator.maxValue, 'EUR') || '';

        switch (code) {
            case AtvCappingSegmentCpmErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_CPM_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_CPM_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
            case AtvCappingSegmentCpmErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_CPM_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_CPM_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum AtvCappingSegmentCpmErrorCodes {
    MinValue = '9ed527db-a6e8-4c2c-b680-8538d5d361f7',
    MaxValue = '02d941aa-9fd0-4dd7-83d7-63b81d084368',
}

export {AtvCappingSegmentCpmValidator, AtvCappingSegmentCpmErrorCodes};
