import {BaseVastSettings, VastSettings, VastSettingsType} from './base-vast-settings';
import {IDoohVastSettings} from '../banner';
import {ICloneable, IMergable} from '../types';
import {VideoEncoding} from './video-encoding';

class DoohVastSettings extends BaseVastSettings implements IDoohVastSettings, ICloneable<DoohVastSettings>, IMergable<DoohVastSettings> {
    private readonly _type: VastSettingsType = VastSettingsType.Dooh;
    private _encodings: Array<VideoEncoding> = [];
    private _aspectRatioMaintained: boolean | undefined;
    private _scalable: boolean | undefined;

    public get type(): VastSettingsType {
        return this._type;
    }

    public get encodings(): Array<VideoEncoding> {
        return this._encodings;
    }

    public set encodings(value: Array<VideoEncoding>) {
        this._encodings = value;
    }

    public get aspectRatioMaintained(): boolean | undefined {
        return this._aspectRatioMaintained;
    }

    public set aspectRatioMaintained(value: boolean | undefined) {
        this._aspectRatioMaintained = value;
    }

    public get scalable(): boolean | undefined {
        return this._scalable;
    }

    public set scalable(value: boolean | undefined) {
        this._scalable = value;
    }

    public merge(source: VastSettings): void {
        super.merge(source);
        DoohVastSettings.fillAdditionalFields(this, source);
    }

    public clone(): DoohVastSettings {
        const newModel: DoohVastSettings = new DoohVastSettings(
            this.id,
            this.networkId,
            this.assetId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: DoohVastSettings, source: VastSettings): void {
        BaseVastSettings.fillAdditionalFields(target, source);

        const encodings: Array<unknown> = source.encodings;
        target.encodings = encodings.reduce((result: Array<VideoEncoding>, encoding: unknown) => {
            if (encoding instanceof VideoEncoding) {
                result.push(encoding);
            }

            return result;
        }, []);
        if ('aspectRatioMaintained' in source) {
            target.aspectRatioMaintained = source.aspectRatioMaintained;
        }
        if ('scalable' in source) {
            target.scalable = source.scalable;
        }
    }
}

export {DoohVastSettings};
