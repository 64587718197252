<h5 class="libs-multiselect-title">{{label}}</h5>

<div class="libs-multiselect">
    <core-search-toolbar
        (onChange)="onSearchChange($event)"
        [alwaysOpen]="true"
    ></core-search-toolbar>

    <mat-divider></mat-divider>

    <div
        *ngIf="!filteredOptions.length"
        class="
            libs-multiselect__list
            vm-flex-direction-row
            vm-layout-justify-center vm-layout-align-center
        "
    >
        <span
            class="libs-multiselect__no-entries"
            i18n="@@MULTISELECT_LIST_NO_ENTRIES_FOUND"
        >MULTISELECT_LIST_NO_ENTRIES_FOUND</span>
    </div>

    <cdk-virtual-scroll-viewport
        *ngIf="filteredOptions.length"
        minBufferPx="1200"
        maxBufferPx="1200"
        itemSize="50"
        class="libs-multiselect__list"
    >
        <div
            *cdkVirtualFor="let option of filteredOptions; let i = index"
            (dblclick)="onDoubleClick(option)"
            (click)="onClick(option)"
            class="libs-multiselect__list-item"
            [class.libs-multiselect__list-item--selectable]="hasPermissions()"
            [class.libs-multiselect__list-item--selected]="isOptionSelected(option)"
        >
            <ng-container *ngTemplateOutlet="optionValueTemplate; context: {option : option}"></ng-container>
        </div>
    </cdk-virtual-scroll-viewport>
</div>

<div
    *ngIf="filteredOptions.length"
    class="libs-multiselect-number-of-list"
    i18n="@@MULTISELECT_LIST_FOOTER_AMOUNT_TITLE"
>MULTISELECT_LIST_FOOTER_AMOUNT_TITLE {{filteredOptions.length}}</div>
