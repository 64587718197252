<mat-dialog-content class="cropped-preview-dialog">
    <img
        [src]="image"
        alt="cropped preview"
        *ngIf="image"
        class="cropped-preview-dialog__preview"
    />
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()" i18n="@@OK">OK</button>
</mat-dialog-actions>
