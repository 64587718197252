import {NgModule} from '@angular/core';
import {EventLoggerService} from './event-logger.service';
import {AaErrorHandlerModule} from '../error/error-handler.module';

@NgModule({
    declarations: [],
    imports: [AaErrorHandlerModule],
    providers: [EventLoggerService],
})
class AaEventLoggerModule {}

export {AaEventLoggerModule};
