import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {TotalBudgetErrorCodes, RequiredTotalBudgetValidator} from './required-total-budget-validator';

@Injectable({
    providedIn: 'root',
})
class OptionalTotalBudgetValidator extends RequiredTotalBudgetValidator {
    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const validateResult: ValidationErrors | null = super.validate(control);

        if (validateResult && validateResult[TotalBudgetErrorCodes.Required]) {
            return null;
        }

        return validateResult;
    }
}

export {OptionalTotalBudgetValidator};
