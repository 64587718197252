<commons-text-list-item
    matRipple
    [matRippleDisabled]="isDisabled || !isSelectable"
    [title]="title"
    [subtitle]="subtitle"
    [isActive]="isActive"
    [hasStatusIcon]="hasStatusIcon"
    [customStatusIcon]="customStatusIcon"
    [borderStyle]="borderStyle"
    [enableEllipsis]="enableEllipsis"
    class="switch-list-item vm-flex"
    (click)="onSwitchChange()"
    [class.switch-list-item--clickable]="!isDisabled"
    [class.switch-list-item--unselectable]="!isSelectable"
    [reverseOrder]="reverseOrder"
>
    <div class="switch-list-item__switch-container"
        [matTooltip]="disabledSwitchTooltip"
        [matTooltipDisabled]="!isDisabled || !disabledSwitchTooltip"
        matTooltipClass="tooltip"
    >
        <div
            *ngIf="label"
            class="switch-list-item__label"
            [class.switch-list-item__label--disabled]="!isActive"
        >{{label}}</div>
        <div class="switch-list-item__custom-content">
            <ng-content></ng-content>
        </div>
        <mat-slide-toggle
            *ngIf="isSelectable"
            class="switch-list-item__switch"
            color="primary"
            [disabled]="isDisabled"
            [checked]="isActive"
            [disableRipple]="true"
        ></mat-slide-toggle>
    </div>
</commons-text-list-item>
