import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ContentCategoryReader, IContentCategory} from './content-category-reader';
import {IContentCategoryFilterImportResult, parseContentCategoryProfile} from './content-category-filter-import';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IContentCategoryFilterProperties extends IFilterProperties {
    profile: string;
}

class ContentCategoryFilter extends Filter<FilterTypes.CONTENT_CATEGORY> {
    protected readonly _type: FilterTypes.CONTENT_CATEGORY = FilterTypes.CONTENT_CATEGORY;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = true;
    protected readonly props: IContentCategoryFilterProperties = this.getDefaultOptions();

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        if (!this.profile.length) {
            return of(false);
        }

        return getContentCategoryData(this.profile)
            .pipe(
                map((data: IContentCategoryFilterImportResult) => data.inverted),
            );
    }

    public isValid(): Observable<boolean> {
        if (!this.profile.length) {
            return of(false);
        }

        return getContentCategoryData(this.profile)
            .pipe(
                map(() => {
                    return true;
                }),
                catchError(() => {
                    return of(false);
                }),
            );
    }

    protected getDefaultOptions(): IContentCategoryFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

function getContentCategoryData(profile: string): Observable<IContentCategoryFilterImportResult> {
    return ContentCategoryReader.getContentCategories()
        .pipe(
            map((iabMap: Map<string, IContentCategory>) => parseContentCategoryProfile(profile, iabMap)),
        );
}

export {ContentCategoryFilter, IContentCategoryFilterProperties};
