import {AbstractControl, ValidationErrors} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class TotalClickValidator {
    public static readonly maxValue: number = 1_000_000_000;
    public static readonly minValue: number = 0;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const totalClicks: number | undefined | null = control.value;

        if (totalClicks === undefined || totalClicks === null) {
            return null;
        }

        if (totalClicks < TotalClickValidator.minValue) {
            return {[TotalClickErrorCodes.MinValue]: this.getTranslationForErrorCode(TotalClickErrorCodes.MinValue)};
        }

        if (totalClicks > TotalClickValidator.maxValue) {
            return {[TotalClickErrorCodes.MaxValue]: this.getTranslationForErrorCode(TotalClickErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: TotalClickErrorCodes): string {
        const min: string = this.decimalPipe.transform(TotalClickValidator.minValue) || '';
        const minMessage: string = $localize`:@@VALIDATOR_TOTAL_CLICK_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_TOTAL_CLICK_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
        const max: string = this.decimalPipe.transform(TotalClickValidator.maxValue) || '';
        const maxMessage: string = $localize`:@@VALIDATOR_TOTAL_CLICK_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_TOTAL_CLICK_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;

        switch (code) {
            case TotalClickErrorCodes.MinValue:
                return minMessage;
            case TotalClickErrorCodes.MaxValue:
                return maxMessage;
            default:
                return defaultMessage;
        }
    }
}

enum TotalClickErrorCodes {
    Required = '372414cd-733a-4483-aa80-4447f490b234',
    MinValue = '18fbcb45-9d1e-49ac-b66d-158673902c1e',
    MaxValue = '7d80258a-13be-4118-835a-053cc85074bf',
}

export {TotalClickValidator, TotalClickErrorCodes};
