import {Component, Input} from '@angular/core';

@Component({
    selector: 'libs-spacer',
    templateUrl: 'spacer.component.html',
    styleUrls: ['./spacer.component.scss'],
})
class LibsSpacerComponent {

    @Input() public horizontal: LibsSpacerSize = LibsSpacerSize.None;
    @Input() public vertical: LibsSpacerSize = LibsSpacerSize.None;
}

enum LibsSpacerSize {
    None = 'none',
    Small = 'small',
    Medium = 'medium',
}

export {LibsSpacerComponent, LibsSpacerSize};
