import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class TextValidator {
    public static readonly maxLength: number = 1_000;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const text: string | null | undefined = control.value;

        if (text && text.length > TextValidator.maxLength) {
            return {[TextErrorCodes.MaxLength]: getTranslationForErrorCode(TextErrorCodes.MaxLength)};
        }

        return null;
    }
}

function getTranslationForErrorCode(code: TextErrorCodes): string {
    const maxMessage: string = $localize`:@@VALIDATOR_TEXT_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_TEXT_MAX_LENGTH_ERROR_MESSAGE ${TextValidator.maxLength}:INTERPOLATION:`;
    const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;

    switch (code) {
        case TextErrorCodes.MaxLength:
            return maxMessage;
        default:
            return defaultMessage;
    }
}

enum TextErrorCodes {
    MaxLength = '390e6d89-650c-4c78-9a0c-eaee44d708c2',
}

export {TextValidator, TextErrorCodes};
