import {ActivatedRouteSnapshot, CanDeactivateFn} from '@angular/router';
import {ILayoutResolveData, unsubscribeResolver} from '../layout-resolver';

export const libsUnsubscribeLayoutResolverGuard: CanDeactivateFn<unknown> = function(
    _component: unknown,
    currentRoute: ActivatedRouteSnapshot,
): true {
    const routeData: ILayoutResolveData = currentRoute.data;
    unsubscribeResolver(routeData);

    return true;
};
