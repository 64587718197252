import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CommonsRequestBuilder} from './request-builder';
import {differenceWith, fromPairs, toPairs, isEqual} from 'lodash-es';
import {map} from 'rxjs/operators';
import {CommonsError, IGenericObject} from '@active-agent/types';

class CommonsPutRequestBuilder extends CommonsRequestBuilder {
    protected mandatoryFields: Array<string> = [];

    private readonly originalObject: IGenericObject;
    private modifiedObject: IGenericObject;
    private customFields: Array<string> = [];

    constructor(
        private http: HttpClient,
        resourcePath: string,
        baseUrl: string,
        originalObject: IGenericObject,
        modifiedObject: IGenericObject,
    ) {
        super(resourcePath, baseUrl);
        this.originalObject = originalObject;
        this.modifiedObject = modifiedObject;
    }

    /**
     * Sets custom fields. Custom fields will never be send to the API, even if there are changes.
     * Use this to remove unwanted properties before requesting.
     *
     * @param customFields object property names
     */
    public setCustomFields(customFields: Array<string>): this {
        this.customFields = customFields;

        return this;
    }

    /**
     * Sets mandatory fields. Mandatory fields will always be send to the API, even if they don't differ from the modified to the orginal
     * object.
     *
     * @param mandatoryFields object property names
     */
    public setMandatoryFields(mandatoryFields: Array<string>): this {
        this.mandatoryFields = mandatoryFields;

        return this;
    }

    /**
     * Creates a valid api update request
     */
    public build(): Observable<IGenericObject> {
        return this.put();
    }

    /**
     * Generally you should try to avoid to use this function!
     * This is used when you need to modify the object after creating the builder instance.
     * An example implementation where this is needed is the update-banner.builder.ts
     */
    protected updateModifiedObject(object: IGenericObject): this {
        this.modifiedObject = object;

        return this;
    }

    /**
     * Determines the difference between the given original element and the modified element and makes a PUT call
     * to the API to put given object.
     * Throws an exception if the original object does not contain an id.
     *
     * @throws {CommonsError}
     *
     * @returns A promise for updating the original object.
     */
    protected put(): Observable<IGenericObject> {
        if (!this.originalObject.id) {
            throw new CommonsError('The original object is missing an id and can thus not be updated', {
                data: {object: this.originalObject},
            });
        }

        const objectToUpdate: Record<string, unknown> = getObjectDiff(this.modifiedObject, this.originalObject);
        objectToUpdate.id = this.originalObject.id;

        this.mandatoryFields.forEach((field: string): void => {
            objectToUpdate[field] = this.modifiedObject[field];
        });

        // remove user-defined fields so they are not send to the api
        this.customFields.forEach((field: string): void => {
            delete objectToUpdate[field];
        });

        let url: string = `${this.getApiBaseUrl()}/${objectToUpdate.id}`;

        if (this.getSubResourcePath()) {
            url += `/${this.getSubResourcePath()}`;
        }

        return this.http
            .put(url, objectToUpdate, {headers: this.getHeadersForRequest()})
            .pipe(
                map((response: IGenericObject): IGenericObject => {
                    return response;
                }),
                map((savedObject: IGenericObject): IGenericObject => {
                    Object
                        .keys(this.originalObject)
                        .forEach((key: string): void => {
                            if (savedObject[key] === undefined) {
                                savedObject[key] = null;
                            }
                        });

                    return savedObject;
                }),
            );
    }
}

function getObjectDiff(modifiedObjectA: Record<string, unknown>, originalObjectB: Record<string, unknown>): Record<string, unknown> {
    const objectToUpdate: Array<[string, unknown]> = differenceWith(
        toPairs(modifiedObjectA),
        toPairs(originalObjectB),
        isEqual,
    );

    return fromPairs(objectToUpdate);
}

export {CommonsPutRequestBuilder, getObjectDiff};
