import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {NameValidator} from './name-validator';

@Injectable({
    providedIn: 'root',
})
class FootfallNameValidator extends NameValidator {

    public validate(control: AbstractControl): ValidationErrors | null {
        const name: string = control.value;

        if (name === undefined || name === null || name.length === 0) {
            return null;
        }

        return super.validate(control);
    }
}

export {FootfallNameValidator};
