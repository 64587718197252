import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ContextualFilter, IContextualFilterProperties} from '../types/contextual-filter';
import {FilterTypes} from '../types/filter.types';
import {ICustomSiteContextualValues, ISegmentExpression} from '../types/targeting-segment';
import {CustomSiteContextualHelper} from './custom-site-contextual-helper';
import {ICommonsFilterService} from '../types/filter';

type CustomSiteContextualFilterProperties = IContextualFilterProperties;

class CustomSiteContextualFilter extends ContextualFilter<FilterTypes.CUSTOM_SITE_CONTEXTUAL> {
    protected readonly _type: FilterTypes.CUSTOM_SITE_CONTEXTUAL = FilterTypes.CUSTOM_SITE_CONTEXTUAL;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = true;
    protected readonly props: CustomSiteContextualFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        if (!this.expression.length) {
            return of(false);
        }

        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(filterService: ICommonsFilterService): Observable<boolean> {
        if (!this.expression.length) {
            return of(false);
        }

        return filterService.evaluateExpression(this.expression, this.type)
            .pipe(
                map((expression: ISegmentExpression) => {
                    return CustomSiteContextualHelper.getCustomSiteContextualValues(expression);
                }),
                map((filterValues: ICustomSiteContextualValues) => {
                    if (filterValues.negated !== undefined) {
                        return filterValues.negated;
                    }

                    return false;
                }),
            );
    }
}

export {CustomSiteContextualFilter};
