import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {CommonsError} from '@active-agent/types';

function MatchOtherFieldValidator(otherControlName: string): ValidatorFn {
    let thisControl: AbstractControl;
    let otherControl: AbstractControl;

    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        if (!thisControl) {
            thisControl = control;
            otherControl = control.parent.get(otherControlName) as AbstractControl;
            if (!otherControl) {
                throw new CommonsError(
                    'No other form control with the given name exists',
                    {data: {name: otherControlName}},
                );
            }
            otherControl.valueChanges.subscribe(() => {
                thisControl.updateValueAndValidity();
            });
        }

        if (!otherControl) {
            return null;
        }

        if (otherControl.value !== thisControl.value) {
            return {
                matchOther: true,
            };
        }

        return null;
    };
}

export {MatchOtherFieldValidator};
