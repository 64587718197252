import {NgModule} from '@angular/core';
import {CommonsStaticInformationComponent} from './static-information.component';
import {CoreContentBoxModule} from '@angular-clan/core';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';
import {NgTemplateOutlet} from '@angular/common';

@NgModule({
    imports: [
        CoreContentBoxModule,
        CommonsStatusIconModule,
        NgTemplateOutlet,
    ],
    exports: [
        CommonsStaticInformationComponent,
    ],
    declarations: [
        CommonsStaticInformationComponent,
    ],
})
class CommonsStaticInformationModule {}

export {CommonsStaticInformationModule};
