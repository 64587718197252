import {ICloneable, IMergable} from '../../types';
import {ImportFormat} from './polygon-filter';

class Polygon implements IMergable<Polygon>, ICloneable<Polygon> {
    private readonly _id: number | null = null;
    private _externalId: string;
    private _importFormat: ImportFormat;
    private _wktString: string | null;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        externalId: string,
        importFormat: ImportFormat,
        wktString: string | null,
    ) {
        this._id = id;
        this._externalId = externalId;
        this._importFormat = importFormat;
        this._wktString = wktString;
    }

    get id(): number | null {
        return this._id;
    }

    set externalId(value: string) {
        this._externalId = value;
    }

    get externalId(): string {
        return this._externalId;
    }

    set importFormat(value: ImportFormat) {
        this._importFormat = value;
    }

    get importFormat(): ImportFormat {
        return this._importFormat;
    }

    set wktString(value: string | null) {
        this._wktString = value;
    }

    get wktString(): string | null {
        return this._wktString;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public clone(): Polygon {
        const newModel: Polygon = new Polygon(
            this.id,
            this.externalId,
            this.importFormat,
            this.wktString,
        );
        newModel.merge(this);

        return newModel;
    }

    public merge(source: this): void {
        this.externalId = source.externalId;
        this.importFormat = source.importFormat;
        this.wktString = source.wktString;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }
}

export {Polygon};
