import {Observable, of} from 'rxjs';
import {Filter, FilterTypes, IFilterProperties} from '../types';

interface IPostalCodeFilterProperties extends IFilterProperties {
    postalCodeIds: Array<number>;
}

class PostalCodeFilter extends Filter<FilterTypes.POSTAL_CODE> {
    protected readonly _type: FilterTypes.POSTAL_CODE = FilterTypes.POSTAL_CODE;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IPostalCodeFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        return of(!!this.postalCodeIds.length);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get postalCodeIds(): Array<number> {
        return this.props.postalCodeIds;
    }

    set postalCodeIds(value: Array<number>) {
        this.props.postalCodeIds = value;
    }

    protected getDefaultOptions(): IPostalCodeFilterProperties {
        return {
            ...super.getDefaultOptions(),
            postalCodeIds: [],
        };
    }
}

export {
    PostalCodeFilter,
    IPostalCodeFilterProperties,
};
