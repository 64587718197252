import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ERROR_HANDLER, ICommonsErrorHandler} from '@active-agent/error';
import {
    ProjectTypes, CommonsError, IEventBody, Network, SERVICES_URL, User,
} from '@active-agent/types';
import {TimeZoneStore} from '@active-agent/std';
import Bowser from 'bowser';
import {ICommonsEventLogger} from './event-logger';
import {LibsAppDataService} from '@active-agent/app-data';
import {CommonsEventLog} from './event-log';
import {retry} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
abstract class LibsEventLoggerService implements ICommonsEventLogger {
    protected abstract version: string;
    protected abstract project: ProjectTypes;

    public constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(ERROR_HANDLER) protected errorHandler: ICommonsErrorHandler | null,
        protected appData: LibsAppDataService,
        @Optional() @Inject(SERVICES_URL) protected servicesUrl: string | null,
    ) {}

    public log(eventLog: CommonsEventLog): void {
        if (!this.servicesUrl) {
            return;
        }

        try {
            this.sendEventLogRequest(this.createEventBody(eventLog));
        } catch (error: unknown) {
            void this.errorHandler?.handle(
                new CommonsError(
                    'error when preparing for sending event log',
                    {cause: error, data: {eventLog}},
                ),
                false,
            );
        }
    }

    private sendEventLogRequest(eventBody: IEventBody): void {
        if (!this.servicesUrl) {
            return;
        }

        this.httpClient
            .post(`${this.servicesUrl}/event-logger`, eventBody)
            .pipe(
                retry({
                    count: 3,
                    delay: 7_000,
                    resetOnSuccess: true,
                }),
            )
            .subscribe({
                error: (error: Error): void => {
                    void this.errorHandler?.handle(
                        new CommonsError(
                            'error when sending event log to event logger',
                            {cause: error, data: {eventBody}},
                        ),
                        false,
                    );
                },
            });
    }

    private createEventBody(eventLog: CommonsEventLog): IEventBody {
        return {
            ...eventLog.getData(),
            browserName: getBrowserName(),
            browserVersion: getBrowserVersion(),
            networkId: this.getCurrentNetworkId(),
            project: this.project,
            username: this.getCurrentUserName(),
            version: String(this.version),
            timeZone: TimeZoneStore.getSelected(),
        };
    }

    private getCurrentNetworkId(): number {
        const defaultNetworkId: number = -1;

        try {
            const network: Network = this.appData.getCurrentNetwork();

            return network.id || defaultNetworkId;
        } catch (_error: unknown) {
            return defaultNetworkId;
        }
    }

    private getCurrentUserName(): string {
        try {
            const user: User = this.appData.getCurrentUser();

            return user.name;
        } catch (_error: unknown) {
            return '-';
        }
    }
}

function getBrowserName(): string {
    const parser: Bowser.Parser.Parser = Bowser.getParser(window.navigator.userAgent);

    return parser.getBrowserName();
}

function getBrowserVersion(): string {
    const parser: Bowser.Parser.Parser = Bowser.getParser(window.navigator.userAgent);

    return parser.getBrowserVersion();
}

export {LibsEventLoggerService};
