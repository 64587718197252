import {OttoPntaFilter, IOttoPntaFilterProperties} from './otto-pnta-filter';
import {FilterTypes} from '../types/filter.types';

type OttoPntaIntentFilterProperties = IOttoPntaFilterProperties;

class OttoPntaIntentFilter extends OttoPntaFilter<FilterTypes.OTTO_PNTA_INTENT> {
    protected readonly _type: FilterTypes.OTTO_PNTA_INTENT = FilterTypes.OTTO_PNTA_INTENT;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly props: OttoPntaIntentFilterProperties = this.getDefaultOptions();
}

export {OttoPntaIntentFilter, OttoPntaIntentFilterProperties};
