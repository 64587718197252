<commons-list-item [hasPadding]="hasPadding" [borderStyle]="borderStyle">
    <div class="text-list-item" [class.text-list-item--reversed]="reverseOrder">
        <div
            *ngIf="hasStatusIcon && isActive !== null"
            class="text-list-item__status-icon"
        >
            <mat-icon
                *ngIf="customStatusIcon"
                [class.text-list-item__custom-status-icon-color]="!iconColor"
                [color]="iconColor"
                [fontSet]="customStatusIcon.font || ''"
            >{{customStatusIcon.name}}</mat-icon>

            <commons-status-icon
                *ngIf="!customStatusIcon"
                [class.text-list-item__status-icon--disabled]="!isActive"
                [status]="successStatus"
            ></commons-status-icon>
        </div>

        <div
            class="text-list-item__title"
            [class.text-list-item__title--single-line]="!subtitle"
            [class.text-list-item__title--ellipsis]="enableEllipsis"
        >
            {{title}}
            <commons-status-icon
                *ngIf="titleTooltip.length"
                [status]="titleTooltipInfoStatus"
                [tooltip]="titleTooltip"
                [size]="titleTooltipIconSize"
                [grayscale]="true"
            ></commons-status-icon>
        </div>
        <div
            *ngIf="subtitle"
            class="text-list-item__subtitle"
            [class.text-list-item__subtitle--ellipsis]="enableEllipsis"
        >{{subtitle}}</div>
        <div class="text-list-item__custom-content">
            <ng-content></ng-content>
        </div>
    </div>
</commons-list-item>
