import {IRouterHandlerData} from '@active-agent/types';
import {ISyncBannerPoolRouteConfig} from '@active-agent/sync-banner-routes';
import {AppPath} from '../../../../../commons/enums';

const syncBannerPoolRouterConfig: ISyncBannerPoolRouteConfig = {
    campaignList: {
        getDataForLink: (id: number, networkId: number): IRouterHandlerData => {
            return {
                commands: [
                    networkId,
                    AppPath.Order,
                    id,
                    AppPath.Campaigns,
                    AppPath.List,
                ],
            };
        },
    },
    bannerList: {
        getDataForLink: (id: number, networkId: number): IRouterHandlerData => {
            return {
                commands: [
                    networkId,
                    AppPath.Campaign,
                    id,
                    AppPath.Banners,
                    AppPath.List,
                ],
            };
        },
    },
};

export {syncBannerPoolRouterConfig};
