import {NgModule} from '@angular/core';
import {CommonsPluralizeComponent} from './pluralize.component';

@NgModule({
    exports: [
        CommonsPluralizeComponent,
    ],
    declarations: [
        CommonsPluralizeComponent,
    ],
})
class CommonsPluralizeModule {}

export {CommonsPluralizeModule};
