import {MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import {Inject, Injectable} from '@angular/core';
import {setDefaultOptions} from 'date-fns';

@Injectable()
class CustomDateAdapter extends NativeDateAdapter {

    constructor(@Inject(MAT_DATE_LOCALE) matDateLocale: string) {
        super(matDateLocale);

        // set start of week to monday for date-fns
        setDefaultOptions({
            weekStartsOn: FirstDayOfTheWeek.Monday,
        });
    }

    public getFirstDayOfWeek(): number {
        return FirstDayOfTheWeek.Monday;
    }
}

enum FirstDayOfTheWeek {
    Monday = 1,
}

export {CustomDateAdapter};
