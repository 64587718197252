import {VastEvent} from '../vast-event/vast-event';
import {Banner, BaseBanner} from '../base-banner';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {AudioVastSettings} from '../../vast-settings/audio-vast-settings';
import {VastSettingsPreset} from '../../vast-settings/vast-settings.service';
import {VastVersion} from '../../vast-versions/vast-version';
import {IAudioBanner} from '../banner';

class AudioBanner extends BaseBanner implements IAudioBanner, ICloneable<AudioBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.AUDIO;
    private _ssl: boolean = false;
    private _clickUrl: string;
    private _assetId: number;
    private _vastSettings: AudioVastSettings;
    private _vastEvents: Array<VastEvent> = [];
    private _vastXml: string | undefined;
    private _vastTemplateId: VastSettingsPreset;
    private _vastVersion: VastVersion | string;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        assetId: number,
        vastSettings: AudioVastSettings,
        vastTemplateId: VastSettingsPreset,
        vastVersion: VastVersion | string,
    ) {
        super(id, networkId, name, landingPage);

        this._clickUrl = clickUrl;
        this._assetId = assetId;
        this._vastSettings = vastSettings;
        this._vastTemplateId = vastTemplateId;
        this._vastVersion = vastVersion;
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string {
        return this._clickUrl;
    }

    set clickUrl(value: string) {
        this._clickUrl = value;
    }

    get assetId(): number {
        return this._assetId;
    }

    set assetId(value: number) {
        this._assetId = value;
    }

    get vastSettings(): AudioVastSettings {
        return this._vastSettings;
    }

    set vastSettings(value: AudioVastSettings) {
        this._vastSettings = value;
    }

    get vastEvents(): Array<VastEvent> {
        return this._vastEvents;
    }

    set vastEvents(value: Array<VastEvent>) {
        this._vastEvents = value;
    }

    get vastXml(): string | undefined {
        return this._vastXml;
    }

    set vastXml(value: string | undefined) {
        this._vastXml = value;
    }

    get vastTemplateId(): VastSettingsPreset {
        return this._vastTemplateId;
    }

    set vastTemplateId(value: VastSettingsPreset) {
        this._vastTemplateId = value;
    }

    get vastVersion(): VastVersion | string {
        return this._vastVersion;
    }

    set vastVersion(value: VastVersion | string) {
        this._vastVersion = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        AudioBanner.fillAdditionalFields(this, source);
    }

    public clone(): AudioBanner {
        const newModel: AudioBanner = new AudioBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.clickUrl,
            this.assetId,
            this.vastSettings,
            this.vastTemplateId,
            this.vastVersion,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: AudioBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('clickUrl' in source && source.clickUrl !== null) {
            target.clickUrl = source.clickUrl;
        }
        if ('assetId' in source) {
            target.assetId = source.assetId;
        }
        if ('vastSettings' in source && source.vastSettings instanceof AudioVastSettings) {
            target.vastSettings = source.vastSettings.clone();
        }
        if ('vastEvents' in source) {
            target.vastEvents = source.vastEvents.map((vastEvent: VastEvent) => vastEvent.clone());
        }
        if ('vastXml' in source) {
            target.vastXml = source.vastXml;
        }
        if ('vastTemplateId' in source) {
            target.vastTemplateId = source.vastTemplateId;
        }
        if ('vastVersion' in source) {
            target.vastVersion = source.vastVersion;
        }
    }
}

export {AudioBanner};
