import {Observable, of} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface ISegmentListFilterProperties extends IFilterProperties {
    externalSegmentIds: Array<string>;
    referencedSegmentIds: Array<number>;
}

abstract class SegmentListFilter<TFilterType extends FilterTypes = FilterTypes> extends Filter<TFilterType> {
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected abstract readonly props: ISegmentListFilterProperties;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    private _isValid: boolean = true;

    public updateValidStatus(newStatus: boolean): void {
        this._isValid = newStatus;
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    public isValid(): Observable<boolean> {
        if (!this.externalSegmentIds.length) {
            return of(false);
        }

        return of(this._isValid);
    }

    public get externalSegmentIds(): Array<string> {
        return this.props.externalSegmentIds;
    }

    public set externalSegmentIds(value: Array<string>) {
        this.props.externalSegmentIds = value;
    }

    public get referencedSegmentIds(): Array<number> {
        return this.props.referencedSegmentIds;
    }

    public set referencedSegmentIds(value: Array<number>) {
        this.props.referencedSegmentIds = value;
    }

    protected getDefaultOptions(): ISegmentListFilterProperties {
        return {
            ...super.getDefaultOptions(),
            externalSegmentIds: [],
            referencedSegmentIds: [],
        };
    }
}

export {SegmentListFilter, ISegmentListFilterProperties};
