import {remove} from 'lodash-es';
import {DilPeculiarityPrice} from './dil-peculiarity-price.model';
import {PeculiarityTypes} from './peculiarity-types';
import {TargetingAttribute} from '../attribute/targeting-attribute';
import {INetwork, Network} from '../network/network.model';
import {IPeculiarity} from './peculiarity';

class TargetingPeculiarity implements IPeculiarity {
    private readonly _id: number | null;
    private _active: boolean = false;
    private _billable: boolean | undefined = undefined;
    private _attribute:  TargetingAttribute | undefined;
    private _attributeType: string | undefined;
    private _network: Network | undefined;
    private _description: string | null = null;
    private _subKey: string | undefined;
    private _name: string | undefined;
    private _displayName: string | undefined;
    private _cpm: number | null = null;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;
    private _dilPeculiarityPrices: Array<DilPeculiarityPrice> = [];
    private _type: PeculiarityTypes = PeculiarityTypes.Default;
    private _netShare: number | null = null;

    constructor(id: number | null = null) {
        this._id = id;
    }

    get id(): number | null {
        return this._id;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get billable(): boolean | undefined {
        return this._billable;
    }

    set billable(value: boolean | undefined) {
        this._billable = value;
    }

    get attribute(): TargetingAttribute | undefined {
        return this._attribute;
    }

    set attribute(value: TargetingAttribute | undefined) {
        this._attribute = value;
    }

    get attributeType(): string | undefined {
        return this._attributeType;
    }

    set attributeType(value: string | undefined) {
        this._attributeType = value;
    }

    get network(): Network | undefined {
        return this._network;
    }

    set network(value: Network | undefined) {
        this._network = value;
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get subKey(): string | undefined {
        return this._subKey;
    }

    set subKey(value: string | undefined) {
        this._subKey = value;
    }

    get name(): string | undefined {
        return this._name;
    }

    set name(value: string | undefined) {
        this._name = value;
    }

    get displayName(): string | undefined {
        return this._displayName;
    }

    set displayName(value: string | undefined) {
        this._displayName = value;
    }

    get cpm(): number | null {
        return this._cpm;
    }

    set cpm(value: number | null) {
        this._cpm = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get dilPeculiarityPrices(): Array<DilPeculiarityPrice> {
        return this._dilPeculiarityPrices;
    }

    set dilPeculiarityPrices(value: Array<DilPeculiarityPrice>) {
        this._dilPeculiarityPrices = value;
    }

    get type(): PeculiarityTypes {
        return this._type;
    }

    set type(value: PeculiarityTypes) {
        this._type = value;
    }

    get netShare(): number | null {
        return this._netShare;
    }

    set netShare(value: number | null) {
        this._netShare = value;
    }

    public isFromNetwork(network: Network | INetwork): boolean {
        return !this.network || this.network.id === network.id;
    }

    public addDilPeculiarityPrice(value: DilPeculiarityPrice): void {
        this._dilPeculiarityPrices.push(value);
    }

    public removeDilPeculiarityPrice(value: DilPeculiarityPrice): void {
        remove(this._dilPeculiarityPrices, (dilPeculiarityPrice: DilPeculiarityPrice) => {
            return Object.is(dilPeculiarityPrice.peculiarityId, value.peculiarityId)
                && Object.is(dilPeculiarityPrice.ownerNetworkId, value.ownerNetworkId)
                && Object.is(dilPeculiarityPrice.userNetworkId, value.userNetworkId);
        });
    }

    public static merge(modelOne: TargetingPeculiarity, modelTwo: TargetingPeculiarity): TargetingPeculiarity {
        modelOne.active = modelTwo.active;
        modelOne.billable = modelTwo.billable;
        modelOne.attribute = modelTwo.attribute ? modelTwo.attribute.clone() : undefined;
        modelOne.dilPeculiarityPrices = modelTwo.dilPeculiarityPrices.map((price: DilPeculiarityPrice): DilPeculiarityPrice => {
            return DilPeculiarityPrice.clone(price);
        });
        modelOne.attributeType = modelTwo.attributeType;
        modelOne.network = modelTwo.network ? Network.clone(modelTwo.network) : undefined;
        modelOne.description = modelTwo.description;
        modelOne.subKey = modelTwo.subKey;
        modelOne.name = modelTwo.name;
        modelOne.displayName = modelTwo.displayName;
        modelOne.cpm = modelTwo.cpm;
        modelOne.lastUpdate = modelTwo.lastUpdate;
        modelOne.deleted = modelTwo.deleted;
        modelOne.type = modelTwo.type;
        modelOne.netShare = modelTwo.netShare;

        return modelOne;
    }

    public static clone(model: TargetingPeculiarity): TargetingPeculiarity {
        const newModel: TargetingPeculiarity = new TargetingPeculiarity(model.id);
        TargetingPeculiarity.merge(newModel, model);

        return newModel;
    }
}

export {TargetingPeculiarity};
