
/**
 * Use from: https://github.com/angular
 * https://github.com/angular/angular/blob/58408d6a60bd43b89cb1d9ad6f8812c8e696d42d/packages/compiler/src/util.ts#L225
 * Determine if the argument is shaped like a Promise
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
function isPromise<T = any>(obj: any): obj is Promise<T> {
    // allow any Promise/A+ compliant thenable.
    // It's up to the caller to ensure that obj.then conforms to the spec
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return !!obj && typeof obj.then === 'function';
}

function isFunction(value: unknown): value is (args: Array<any>) => any {
    return typeof value === 'function';
}

const objectIdMap: WeakMap<object, number> = new WeakMap<object, number>();
let objectCount: number = 1;
function getObjectId(object: object): number {
    const objectId: number | undefined = objectIdMap.get(object);
    if (objectId === undefined) {
        objectCount += 1;
        objectIdMap.set(object, objectCount);

        return objectCount;
    }

    return objectId;
}

export {
    isPromise,
    isFunction,
    getObjectId,
};
