import {AbstractControl, ValidationErrors} from '@angular/forms';
import {getUrlAditionPattern} from '@active-agent/pattern';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class LandingPageValidator {
    public static readonly maxLength: number = 255;
    public static readonly pattern: RegExp = getUrlAditionPattern();

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<string | null>): ValidationErrors | null {
        const landingPage: string | null = control.value;
        if (landingPage === undefined || landingPage === null || !landingPage.length) {
            return {[LandingPageErrorCodes.Required]: this.getTranslationForErrorCode(LandingPageErrorCodes.Required)};
        }

        if (landingPage.length > LandingPageValidator.maxLength) {
            return {[LandingPageErrorCodes.MaxLength]: this.getTranslationForErrorCode(LandingPageErrorCodes.MaxLength)};
        }

        if (!LandingPageValidator.pattern.test(landingPage)) {
            return {[LandingPageErrorCodes.Pattern]: this.getTranslationForErrorCode(LandingPageErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: LandingPageErrorCodes): string {
        switch (code) {
            case LandingPageErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case LandingPageErrorCodes.MaxLength:
                const max: string | null = this.decimalPipe.transform(LandingPageValidator.maxLength);

                return $localize`:@@VALIDATOR_LANDING_PAGE_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_LANDING_PAGE_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            case LandingPageErrorCodes.Pattern:
                return $localize`:@@VALIDATOR_LANDING_PAGE_PATTERN_ERROR_MESSAGE:VALIDATOR_LANDING_PAGE_PATTERN_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum LandingPageErrorCodes {
    Required = 'ce8c0dc4-c6e6-4833-9e9a-54d97b1f33f5',
    MaxLength = '314acc1c-6736-4a96-bf8c-69e869d1515c',
    Pattern = 'd2ecd9fa-4986-4ac4-aec8-7d9d7f84d53b',
}

export {LandingPageValidator, LandingPageErrorCodes};
