import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class LocationIdValidator {
    public static readonly maxLength: number = 64;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const locationId: string = control.value;

        if (locationId === undefined || locationId === null || !locationId.length) {
            return {[LocationIdErrorCodes.Required]: getTranslationForErrorCode(LocationIdErrorCodes.Required)};
        }

        if (locationId.length > LocationIdValidator.maxLength) {
            return {[LocationIdErrorCodes.MaxLength]: getTranslationForErrorCode(LocationIdErrorCodes.MaxLength)};
        }

        return null;
    }
}

function getTranslationForErrorCode(code: LocationIdErrorCodes): string {
    switch (code) {
        case LocationIdErrorCodes.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        case LocationIdErrorCodes.MaxLength:
            return $localize`:@@VALIDATOR_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_MAX_LENGTH_ERROR_MESSAGE ${LocationIdValidator.maxLength}:INTERPOLATION:`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum LocationIdErrorCodes {
    Required = '08a8930f-c106-453c-ba52-6323ca9e901e',
    MaxLength = '7309ad68-5b4f-40f5-abdb-d37ccd61759b',
}

export {LocationIdValidator};
