import {
    Component,
    ContentChild,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {HighchartsChartComponent} from 'highcharts-angular';
import {MatProgressSpinnerDefaultOptions, MatProgressSpinnerModule, ProgressSpinnerMode} from '@angular/material/progress-spinner';

interface ISpinnerConfig extends MatProgressSpinnerDefaultOptions {
    mode: ProgressSpinnerMode;
}

type ISpinnerOptions = Partial<ISpinnerConfig>;

const DEFAULT_SPINNER_OPTIONS: ISpinnerConfig = {
    mode: 'indeterminate',
    color: 'primary',
    diameter: 50,
};

@Component({
    selector: 'lib-widget',
    templateUrl: 'widget.html',
    styleUrls: ['widget.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgTemplateOutlet,
        MatProgressSpinnerModule,
    ],
})
/**
 * You can use this component with an <ng-template> content instead of the regular ng-container selectors
 * This might be useful if you render components that need the proper width / height of the parent container
 * For those cases you need to set `hasNgTemplateContent` property to true
 */
class LibWidgetComponent implements OnInit {
    @Input() public widgetSize: LibWidgetSize | null = null;
    @Input() public hasMargin: boolean = true;
    @Input() public hasNgTemplateContent: boolean = false;
    @Input() public isLoading: boolean = false;
    @Input() public set spinnerOptions(value: ISpinnerOptions) {
        this.spinnerConfig = {...DEFAULT_SPINNER_OPTIONS, ...value};
    }
    @ContentChild(TemplateRef) public childContent: TemplateRef<HighchartsChartComponent> | null = null;
    public readonly size: typeof LibWidgetSize = LibWidgetSize;
    public spinnerConfig: ISpinnerConfig = DEFAULT_SPINNER_OPTIONS;
    public showTemplateRef: boolean = false;

    public ngOnInit(): void {
        setTimeout(() => {
            if (this.hasNgTemplateContent) {
                this.showTemplateRef = true;
            }
        });
    }
}

enum LibWidgetSize {
    Small = 'small',
    SmallMedium = 'small-medium',
    Medium = 'medium',
    Large = 'large',
    XLarge = 'xlarge',
}

export {LibWidgetComponent, LibWidgetSize, ISpinnerOptions};
