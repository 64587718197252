import {NgModule} from '@angular/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {LibsOverlayService} from './overlay.service';
import {LibsOpenOverlayDirective} from './open-overlay.directive';

@NgModule({
    imports: [
        OverlayModule,
    ],
    declarations: [
        LibsOpenOverlayDirective,
    ],
    exports: [
        LibsOpenOverlayDirective,
    ],
    providers: [
        LibsOverlayService,
    ],
})

class LibsOverlayModule {}

export {LibsOverlayModule};
