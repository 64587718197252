import {ILibsEnvironment} from '@active-agent/types';

class LibsSlowLog {
    private readonly time: number;
    private readonly url: string;
    private readonly method: string;
    private readonly params: {[key: string]: string};
    private readonly environment: ILibsEnvironment;

    constructor(
        time: number,
        url: string,
        method: string,
        params: {[key: string]: string},
        environment: ILibsEnvironment,
    ) {
        this.time = time;
        this.url = url;
        this.method = method;
        this.params = params;
        this.environment = environment;
    }

    public getData(): ISlowLogData {
        return {
            version: this.environment.version,
            time: this.time,
            url: this.url,
            method: this.method,
            params: JSON.stringify(this.getPreparedParams()),
        };
    }

    private getPreparedParams(): {[key: string]: string} {
        return Object.keys(this.params).reduce((result: {[key: string]: string}, key: string): {[key: string]: string} => {
            if (key.toLowerCase().includes('password')) {
                return result;
            }

            result[key] = this.params[key];

            return result;
        }, {});
    }
}

interface ISlowLogData {
    version: string;
    time: number;
    url: string;
    method: string;
    params: string;
}

export {LibsSlowLog, ISlowLogData};
