import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {
    IWebsocketPingEventData,
    IWebsocketAnnouncementEventData,
    WebsocketEventType,
} from './event-data';
import {WEB_SOCKET_URL} from '@active-agent/types';
import {WebsocketClient} from './websocket-client';

@Injectable({
    providedIn: 'root',
})
class LibsWebsocketService implements OnDestroy {
    private readonly client?: WebsocketClient;

    constructor(
        @Optional() @Inject(WEB_SOCKET_URL) private webSocketUrl: string | null,
    ) {
        if (this.webSocketUrl === null) {
            return;
        }
        this.client = new WebsocketClient(this.webSocketUrl);
    }

    public ngOnDestroy(): void {
        this.client?.disconnect();
    }

    public getAnnouncementMessage(): Observable<IWebsocketAnnouncementEventData> {
        if (!this.client) {
            return throwError(() => new Error('no open connection. cannot get any events data'));
        }

        return this.client.getEventsDataByType(WebsocketEventType.Announcement);
    }

    public getPingMessage(): Observable<IWebsocketPingEventData> {
        if (!this.client) {
            return throwError(() => new Error('no open connection. cannot get any events data'));
        }

        return this.client.getEventsDataByType(WebsocketEventType.Ping);
    }
}

export {LibsWebsocketService};
