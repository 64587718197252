import {format} from 'date-fns';
import {DateFormat, IOrderRuntime, Order, IGenericObject} from '@active-agent/types';
import {parseDateFromApiTimeToTargetTime, parseDateFromTargetTimeToApiTime} from '@active-agent/std';

abstract class OrderApiRepresentation {

    public static fromApi(object: IGenericObject): Order {
        const newModel: Order = new Order(
            object.id,
            object.advertiserId,
            object.networkId,
            object.name,
        );

        newModel.campaignIds = object.campaignIds || [];
        newModel.runtimeBudgets = object.runtimeBudgets.map((runtime: IOrderRuntime): IOrderRuntime => {
            return {
                startTime: format(parseDateFromApiTimeToTargetTime(runtime.startTime), DateFormat.ApiRequestDateTime),
                endTime: format(parseDateFromApiTimeToTargetTime(runtime.endTime), DateFormat.ApiRequestDateTime),
                budget: runtime.budget,
                impressions: runtime.impressions,
                clicks: runtime.clicks,
            };
        });
        if (object.deleted !== undefined) {
            newModel.deleted = object.deleted;
        }
        newModel.lastUpdate = object.lastUpdate;
        newModel.footfallStoreConfigId = object.footfallStoreConfigId;
        if (object.dsaBehalf !== undefined) {
            newModel.dsaBehalf = object.dsaBehalf;
        }
        if (object.dsaPaid !== undefined) {
            newModel.dsaPaid = object.dsaPaid;
        }
        if (object.calculatedDailyBudget !== undefined) {
            newModel.calculatedDailyBudget = object.calculatedDailyBudget;
        }
        if (object.calculatedDailyImpressions !== undefined) {
            newModel.calculatedDailyImpressions = object.calculatedDailyImpressions;
        }
        if (object.calculatedDailyClicks !== undefined) {
            newModel.calculatedDailyClicks = object.calculatedDailyClicks;
        }
        if (object.advertiserMarginId !== undefined) {
            newModel.advertiserMarginId = object.advertiserMarginId;
        }
        if (object.agencyId !== undefined) {
            newModel.agencyId = object.agencyId;
        }
        if (object.dailyBudget !== undefined) {
            newModel.dailyBudget = object.dailyBudget;
        }
        if (object.totalBudget !== undefined) {
            newModel.totalBudget = object.totalBudget;
        }
        if (object.dailyPacing !== undefined) {
            newModel.dailyPacing = object.dailyPacing;
        }
        if (object.runtimePacing !== undefined) {
            newModel.runtimePacing = object.runtimePacing;
        }
        if (object.dailyImpressions !== undefined) {
            newModel.dailyImpressions = object.dailyImpressions;
        }
        if (object.dailyClicks !== undefined) {
            newModel.dailyClicks = object.dailyClicks;
        }
        if (object.pace !== undefined) {
            newModel.pace = object.pace;
        }
        if (object.runtimePacingFactor !== undefined) {
            newModel.runtimePacingFactor = object.runtimePacingFactor;
        }
        if (object.totalImpressions !== undefined) {
            newModel.totalImpressions = object.totalImpressions;
        }
        if (object.totalClicks !== undefined) {
            newModel.totalClicks = object.totalClicks;
        }
        newModel.activeThirdPartyFeatures = object.activeThirdPartyFeatures;

        return newModel;
    }

    public static toApi(model: Order): IGenericObject {
        const runtimePacingFactor: number | null = model.runtimePacing && model.runtimePacingFactor && model.runtimePacingFactor > 1
            ? model.runtimePacingFactor
            : null;

        return {
            id: model.id ? model.id : undefined,
            networkId: model.networkId,
            advertiserId: model.advertiserId,
            agencyId: model.agencyId,
            dailyBudget: model.dailyBudget,
            dailyImpressions: model.dailyImpressions,
            dailyClicks: model.dailyClicks,
            dailyPacing: model.dailyPacing,
            name: model.name,
            runtimePacing: model.runtimePacing,
            runtimePacingFactor,
            runtimeBudgets: model.runtimeBudgets.map((runtime: IOrderRuntime): IOrderRuntime => {
                return {
                    startTime: format(parseDateFromTargetTimeToApiTime(runtime.startTime), DateFormat.ApiRequestDateTime),
                    endTime: format(parseDateFromTargetTimeToApiTime(runtime.endTime), DateFormat.ApiRequestDateTime),
                    budget: runtime.budget,
                    impressions: runtime.impressions,
                    clicks: runtime.clicks,
                };
            }),
            totalBudget: model.totalBudget,
            totalImpressions: model.totalImpressions,
            totalClicks: model.totalClicks,
            dsaPaid: model.dsaPaid,
            dsaBehalf: model.dsaBehalf,
            activeThirdPartyFeatures: model.activeThirdPartyFeatures,
        };
    }
}

export {OrderApiRepresentation};
