enum BannerTypes {
    APP_INSTALL = 'APP_INSTALL',
    CONTENT_AD = 'CONTENT_AD',
    NATIVE = 'NATIVE',
    IMAGE = 'IMAGE',
    FLASH = 'FLASH',
    REDIRECT = 'REDIRECT',
    RICH_MEDIA = 'RICH_MEDIA',
    SWITCH_IN = 'SWITCH_IN',
    SWITCH_IN_FREESTYLE = 'SWITCH_IN_FREESTYLE',
    SWITCH_IN_XXL = 'SWITCH_IN_XXL',
    SWITCH_IN_XXL_ANIMATED = 'SWITCH_IN_XXL_ANIMATED',
    SWITCH_IN_XXL_ANIMATED_OLD = 'SWITCH_IN_XXL_ANIMATED_OLD',
    DYNAMIC = 'DYNAMIC',
    VAST = 'VAST',
    VAST_WRAPPER = 'VAST_WRAPPER',
    HTML5 = 'HTML5',
    CONATIVE = 'CONATIVE',
    DOOH_FULL_HD_PORTRAIT = 'DOOH_FULL_HD_PORTRAIT',
    DOOH_FULL_HD_LANDSCAPE = 'DOOH_FULL_HD_LANDSCAPE',
    DOOH_INFOSCREEN = 'DOOH_INFOSCREEN',
    AUDIO = 'AUDIO',
    VIDEO = 'VIDEO',
    DOOH = 'DOOH',
    BUMPER_AD = 'BUMPER_AD',
    DEPRECATED_ATV_SPOT = 'ATV_SPOT',
    /** ATV_SPOT is only used internally and represents either URL_ATV_SPOT or VIDEO_UPLOAD_ATV_SPOT. */
    ATV_SPOT = 'VIDEO_BASED_ATV_SPOT',
    URL_ATV_SPOT = 'URL_ATV_SPOT',
    VIDEO_UPLOAD_ATV_SPOT = 'VIDEO_UPLOAD_ATV_SPOT',
    TEMPLATE = 'TEMPLATE',
    PTV_AD_SPOT = 'PTV_AD_SPOT',
    UNSUPPORTED = 'UNSUPPORTED',
}

export {BannerTypes};
