/**
 * Pad an array to a fixed size
 */
function padArray<TValue>(array: Array<TValue>, size: number, fillValue: TValue): Array<TValue> {
    const paddedArray: Array<TValue> = [...array];
    for (let i: number = array.length; i < size; i += 1) {
        paddedArray.push(fillValue);
    }

    return paddedArray;
}

export {padArray};
