import {IFootfallPoi, IFootfallScreenConfig} from './footfall-poi';
import {ICloneable, IMergable} from '../types';
import {cloneDeep} from 'lodash-es';

class FootfallScreenConfig implements IFootfallScreenConfig, IMergable<IFootfallScreenConfig>, ICloneable<IFootfallScreenConfig>  {
    private readonly _id: number | null;
    private _networkId: number;
    private _externalId: string | undefined;
    private _bannerId: number;
    private _footfallStoreConfigId: number;
    private _footfallPois: Array<IFootfallPoi> = [];
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        bannerId: number,
        footfallStoreConfigId: number,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._bannerId = bannerId;
        this._footfallStoreConfigId = footfallStoreConfigId;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get externalId(): string | undefined {
        return this._externalId;
    }

    set externalId(value: string | undefined) {
        this._externalId = value;
    }

    get bannerId(): number {
        return this._bannerId;
    }

    set bannerId(value: number) {
        this._bannerId = value;
    }

    get footfallStoreConfigId(): number {
        return this._footfallStoreConfigId;
    }

    set footfallStoreConfigId(value: number) {
        this._footfallStoreConfigId = value;
    }

    get footfallPois(): Array<IFootfallPoi> {
        return this._footfallPois;
    }

    set footfallPois(value: Array<IFootfallPoi>) {
        this._footfallPois = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: FootfallScreenConfig): void {
        this.footfallPois = cloneDeep(source.footfallPois);
        this.externalId = source.externalId;
        this.deleted = source.deleted;
        this.lastUpdate = source.lastUpdate;
    }

    public clone(): FootfallScreenConfig {
        const newModel: FootfallScreenConfig = new FootfallScreenConfig(
            this.id,
            this.networkId,
            this.bannerId,
            this.footfallStoreConfigId,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {FootfallScreenConfig};
