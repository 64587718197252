enum PTVReportDimensions {
    PtvEventDate = 'PTV_EVENT_DATE',
    PtvEventDay = 'PTV_EVENT_DAY',
    PtvEventHour = 'PTV_EVENT_HOUR',
    PtvEventMonth = 'PTV_EVENT_MONTH',
    PtvEventYear = 'PTV_EVENT_YEAR',
    PtvExternalAdvertiser = 'PTV_EXTERNAL_ADVERTISER',
    Ptv71DCampaignId = 'PTV_71D_CAMPAIGN_ID',
    PtvExternalAdvertiserId = 'PTV_EXTERNAL_ADVERTISER_ID',
    PtvExternalAgencyId = 'PTV_EXTERNAL_AGENCY_ID',
    PtvExternalAgency = 'PTV_EXTERNAL_AGENCY',
    PtvAdBlockStart = 'PTV_AD_BLOCK_START',
    PtvDealId = 'PTV_DEAL_ID',
    PtvChannel = 'PTV_CHANNEL',
    PtvContentBefore = 'PTV_CONTENT_BEFORE',
    PtvContentAfter = 'PTV_CONTENT_AFTER',
    PtvAdSpotId = 'PTV_AD_SPOT_ID',
    PtvAdSpotDuration = 'PTV_AD_SPOT_DURATION',
    PtvAdBlockId = 'PTV_AD_BLOCK_ID',
    PtvCountry = 'PTV_COUNTRY',
    PtvAdSpotPosition = 'PTV_AD_SPOT_POSITION',
    PtvTargetGroup = 'PTV_TARGET_GROUP',
    PtvAdBlockDuration = 'PTV_AD_BLOCK_DURATION',
    PtvInvoiceReceiver = 'PTV_INVOICE_RECEIVER',
}

enum PTVReportMetrics {
    PtvNetCpm = 'PTV_NET_CPM',
    PtvAdBlockAgfAudienceTargeted = 'PTV_ADBLOCK_AGF_AUDIENCE_TARGETED',
    PtvAdBlockAgfTotal = 'PTV_ADBLOCK_AGF_TOTAL',
    PtvMediaValue = 'PTV_MEDIA_VALUE',
    PtvPrebookedContacts = 'PTV_PREBOOKED_CONTACTS',
}

// eslint-disable-next-line @typescript-eslint/typedef
const PTVReportField = {...PTVReportDimensions, ...PTVReportMetrics};

type PTVReportFieldType = PTVReportDimensions | PTVReportMetrics;

interface IPTVReportResult {
    // dimensions
    [PTVReportField.PtvEventDate]?: string;
    [PTVReportField.PtvEventDay]?: number;
    [PTVReportField.PtvEventHour]?: number;
    [PTVReportField.PtvEventMonth]?: number;
    [PTVReportField.PtvEventYear]?: number;
    [PTVReportField.Ptv71DCampaignId]?: string;
    [PTVReportField.PtvExternalAdvertiserId]?: number;
    [PTVReportField.PtvExternalAdvertiser]?: string;
    [PTVReportField.PtvExternalAgencyId]?: string;
    [PTVReportField.PtvExternalAgency]?: string;
    [PTVReportField.PtvAdBlockStart]?: string;
    [PTVReportField.PtvDealId]?: string;
    [PTVReportField.PtvChannel]?: string;
    [PTVReportField.PtvContentBefore]?: string;
    [PTVReportField.PtvContentAfter]?: string;
    [PTVReportField.PtvAdSpotId]?: string;
    [PTVReportField.PtvAdSpotDuration]?: string;
    [PTVReportField.PtvAdBlockId]?: string;
    [PTVReportField.PtvCountry]?: string;
    [PTVReportField.PtvAdSpotPosition]?: string;
    [PTVReportField.PtvTargetGroup]?: string;
    [PTVReportField.PtvAdBlockDuration]?: string;
    [PTVReportField.PtvInvoiceReceiver]?: string;

    // metrics
    [PTVReportField.PtvNetCpm]?: number;
    [PTVReportField.PtvAdBlockAgfAudienceTargeted]?: number;
    [PTVReportField.PtvAdBlockAgfTotal]?: number;
    [PTVReportField.PtvMediaValue]?: number;
    [PTVReportField.PtvPrebookedContacts]?: string;
}

export {
    PTVReportDimensions,
    PTVReportMetrics,
    IPTVReportResult,
    PTVReportField,
    PTVReportFieldType,
};
