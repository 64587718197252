import {NgModule} from '@angular/core';
import {CommonsNoItemsAvailableItemComponent} from './no-items-available-item.component';
import {CommonsListItemModule} from '../list-item/list-item.module';

@NgModule({
    imports: [
        CommonsListItemModule,
    ],
    exports: [
        CommonsNoItemsAvailableItemComponent,
    ],
    declarations: [
        CommonsNoItemsAvailableItemComponent,
    ],
})
class CommonsNoItemsAvailableItemModule {}

export {CommonsNoItemsAvailableItemModule};
