<div class="vm-flex vm-display-flex vm-flex-direction-row vm-gap-05 vm-layout-justify-start vm-layout-align-start">
    <mat-form-field class="url-input__protocol-selection">
        <mat-label i18n="@@ULR_INPUT_COMPONENT_PROTOCOL_SELECT_LABEL">ULR_INPUT_COMPONENT_PROTOCOL_SELECT_LABEL</mat-label>
        <mat-select
            [formControl]="urlProtocol"
            name="urlProtocol"
            [panelClass]="urlProtocolPanelCssClasses"
        >
            <mat-option
                [value]="option"
                *ngFor="let option of protocolTypes"
            >{{option}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field
        [hintLabel]="hint"
        [subscriptSizing]="subscriptSizingFormField"
        class="vm-flex"
    >
        <mat-label>{{label}}</mat-label>
        <input
            matInput
            #urlInput
            [formControl]="urlAddress"
            (blur)="onBlurHandler()"
            [required]="isRequired"
            [readonly]="isReadOnly"
            [maxlength]="getMaxLength()"
        >
        <mat-hint align="end" *ngIf="maxLength > 0" class="url-input__max-length-input">{{urlAddress.value.length}} / {{getMaxLength()}}</mat-hint>
        <mat-error [coreFormValidatorErrorMessages]="parentControl"></mat-error>
    </mat-form-field>
</div>

