enum CellType {
    // eslint-disable-next-line id-blacklist
    Boolean = 'Boolean',
    Number = 'Number',
    PlainNumber = 'PlainNumber',
    DecimalNumber = 'DecimalNumber',
    LongLatNumber = 'LongLatNumber',
    String = 'String',
    Date = 'Date',
    DateTime = 'DateTime',
    Empty = 'Empty',
    Currency = 'Currency',
    Percentage = 'Percentage',
}

type CellValueDataType = number | string | Date | boolean;

export {
    CellType,
    CellValueDataType,
};
