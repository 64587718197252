import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class HeightValidator {
    public static readonly maxValue: number = 65535;
    public static readonly minValue: number = 0;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl, customHeight?: number): ValidationErrors | null {
        const height: number | undefined = control.value;

        if (height === undefined || height === null) {
            return {[HeightErrorCodes.Required]: getTranslationForErrorCode(HeightErrorCodes.Required)};
        }

        if (isNaN(height)) {
            return {[HeightErrorCodes.NaN]: getTranslationForErrorCode(HeightErrorCodes.NaN)};
        }

        if (height < HeightValidator.minValue) {
            return {[HeightErrorCodes.MinValue]: getTranslationForErrorCode(HeightErrorCodes.MinValue)};
        }

        if (height > (customHeight || HeightValidator.maxValue)) {
            return {[HeightErrorCodes.MaxValue]: getTranslationForErrorCode(HeightErrorCodes.MaxValue, customHeight)};
        }

        return null;
    }

    public validateWithCustomHeight(height: number): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.validate(control, height);
        };
    }
}

function getTranslationForErrorCode(code: HeightErrorCodes, dynamicValue?: number): string {
    switch (code) {
        case HeightErrorCodes.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        case HeightErrorCodes.MinValue:
            return $localize`:@@VALIDATOR_HEIGHT_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_HEIGHT_MIN_VALUE_ERROR_MESSAGE ${(dynamicValue || HeightValidator.minValue)}:INTERPOLATION:`;
        case HeightErrorCodes.MaxValue:
            return $localize`:@@VALIDATOR_HEIGHT_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_HEIGHT_MAX_VALUE_ERROR_MESSAGE ${dynamicValue || HeightValidator.maxValue}:INTERPOLATION:`;
        case HeightErrorCodes.NaN:
            return $localize`:@@VALIDATOR_HEIGHT_NOT_A_NUMBER_ERROR_MESSAGE:VALIDATOR_HEIGHT_NOT_A_NUMBER_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum HeightErrorCodes {
    Required = '9b3d085d-10a2-4a67-93c0-aaf802b4fd49',
    MinValue = '6796fc20-8b2d-4316-bb9f-e42b4918f009',
    MaxValue = '3ff8ef62-3c27-481d-a6b1-f7188cdca377',
    NaN = 'c8999937-64a6-46e5-94f6-73b324445302',
}

export {HeightValidator};
