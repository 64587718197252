/* parser generated by jison 0.3.0 */
/**
 * Returns a Parser implementing JisonParserApi and a Lexer implementing JisonLexerApi.
 */
import { JisonParser, JisonParserApi, StateType, SymbolsType, TerminalsType, ProductionsType, o } from '@ts-jison/parser';const $V0=[1,9],$V1=[1,10],$V2=[5,9],$V3=[5,11];

export class JisonParserParser extends JisonParser implements JisonParserApi {
    $?: any;

    constructor (yy = {}, lexer = new JisonParserLexer(yy)) {
      super(yy, lexer);
    }

    symbols_: SymbolsType = {"error":2,"exp":3,"ContentCategories":4,"EOF":5,"InvertedContentCategories":6,"VAR":7,"CONTENT_CATEGORY_ACTIVE":8,"OR":9,"CONTENT_CATEGORY_INACTIVE":10,"AND":11,"$accept":0,"$end":1};
    terminals_: TerminalsType = {2:"error",5:"EOF",7:"VAR",8:"CONTENT_CATEGORY_ACTIVE",9:"OR",10:"CONTENT_CATEGORY_INACTIVE",11:"AND"};
    productions_: ProductionsType = [0,[3,2],[3,2],[4,2],[4,3],[6,2],[6,3]];
    table: Array<StateType> = [{3:1,4:2,6:3,7:[1,4]},{1:[3]},{5:[1,5],9:[1,6]},{5:[1,7],11:[1,8]},{8:$V0,10:$V1},{1:[2,1]},{4:11,7:[1,12]},{1:[2,2]},{6:13,7:[1,14]},o($V2,[2,3]),o($V3,[2,5]),o($V2,[2,4]),{8:$V0},o($V3,[2,6]),{10:$V1}];
    defaultActions: {[key:number]: any} = {5:[2,1],7:[2,2]};

    performAction (yytext:string, yyleng:number, yylineno:number, yy:any, yystate:number /* action[1] */, $$:any /* vstack */, _$:any /* lstack */): any {
/* this == yyval */
          var $0 = $$.length - 1;
        switch (yystate) {
case 1:
return {profileVariables: $$[$0-1], isInverted: false}
break;
case 2:
return {profileVariables: $$[$0-1], isInverted: true}
break;
case 3: case 5:
this.$ = [$$[$0-1]];
break;
case 4: case 6:
this.$ = [...$$[$0-2], ...$$[$0]];
break;
        }
    }
}


/* generated by ts-jison-lex 0.3.0 */
import { JisonLexer, JisonLexerApi } from '@ts-jison/lexer';
export class JisonParserLexer extends JisonLexer implements JisonLexerApi {
    options: any = {"moduleName":"JisonParser"};
    constructor (yy = {}) {
        super(yy);
    }

    rules: RegExp[] = [/^(?:\s+)/,/^(?:= 1\b)/,/^(?:!= 1\b)/,/^(?:[aA][nN][dD])/,/^(?:[oO][rR])/,/^(?:[^ ]+)/,/^(?:$)/];
    conditions: any = {"INITIAL":{"rules":[0,1,2,3,4,5,6],"inclusive":true}}
    performAction (yy:any,yy_:any,$avoiding_name_collisions:any,YY_START:any): any {
          var YYSTATE=YY_START;
        switch($avoiding_name_collisions) {
    case 0:/* skip whitespace */
      break;
    case 1:return 8;
      break;
    case 2:return 10;
      break;
    case 3:return 11;
      break;
    case 4:return 9;
      break;
    case 5:return 7;
      break;
    case 6:return 5;
      break;
        }
    }
}

