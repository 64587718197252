import {OdcFilter, OdcFilterProperties} from '../odc-filter';
import {FilterTypes} from '../../types/filter.types';

type OdcBrandSafetyContextualFilterProperties = OdcFilterProperties;

class OdcBrandSafetyContextualFilter extends OdcFilter<FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL> {
    protected readonly _type: FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL = FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL;
    protected readonly props: OdcBrandSafetyContextualFilterProperties = this.getDefaultOptions();
}

export {OdcBrandSafetyContextualFilter};

