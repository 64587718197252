import {Injectable} from '@angular/core';
import {getTrackingSpotCodePattern} from '@active-agent/pattern';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class TrackingSpotCodeValidator implements Validator {
    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<string | null>): ValidationErrors | null {
        const code: string | null = control.value;

        if (!code) {
            return null;
        }

        if (!getTrackingSpotCodePattern().test(code)) {
            return {
                [TrackingSpotCodeValidatorErrorCodes.Pattern]:
                    this.getTranslationForErrorCode(TrackingSpotCodeValidatorErrorCodes.Pattern),
            };
        }

        return null;
    }

    private getTranslationForErrorCode(code: TrackingSpotCodeValidatorErrorCodes): string {
        switch (code) {
            case TrackingSpotCodeValidatorErrorCodes.Pattern:
                return $localize`:@@TRACKING_SPOT_CODE_VALIDATOR_ERROR_MESSAGE:TRACKING_SPOT_CODE_VALIDATOR_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum TrackingSpotCodeValidatorErrorCodes {
    Pattern = '509fa128-62ff-4010-8083-6df6af15722b',
}

export {TrackingSpotCodeValidator, TrackingSpotCodeValidatorErrorCodes};
