import {trimLogData} from '@active-agent/error';

class CommonsEventLog<TData extends Record<string, any> = Record<string, any>> {
    private type: string;
    private data: TData;

    constructor(
        type: string,
        data: TData,
    ) {
        this.type = type;
        this.data = data;
    }

    /**
     * returns the object as it should be logged.
     *
     * @return {Object} the object as it should be logged
     */
    public getData(): IData {
        return {
            type: this.type,
            data: JSON.stringify(trimLogData(this.data)),
        };
    }
}

interface IData {
    type: string;
    data: string;
}

export {CommonsEventLog};
