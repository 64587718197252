import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CommonsRequestBuilder} from './request-builder';
import {CommonsError, IGenericObject} from '@active-agent/types';
import {map} from 'rxjs/operators';

class CommonsPostRequestBuilder<TBody extends IGenericObject = IGenericObject> extends CommonsRequestBuilder {
    private body: TBody | null;

    constructor(
        private http: HttpClient,
        resourcePath: string,
        basePath: string,
        body?: TBody,
    ) {
        super(resourcePath, basePath);
        this.body = body ?? null;
    }

    /**
     * Creates a valid api creation request and fetches the result from the api
     */
    public build(): Observable<IGenericObject> {
        return this.post();
    }

    /**
     * Returns an observable of the creation of the given object on the given resource.
     *
     * @throws {CommonsError}
     */
    public post(): Observable<IGenericObject> {
        let url: string = this.getApiBaseUrl();

        if (this.getSubResourcePath()) {
            const queryIds: Array<number | string> | null = this.getQueryIds();
            if (!queryIds || !queryIds.length) {
                return throwError(() => new CommonsError(
                    'param "parentId" must be given',
                    {
                        data: {
                            resourcePath: this.getResourcePath(),
                            subResourcePath: this.getSubResourcePath(),
                            body: this.body,
                        },
                    },
                ));
            }
            url = `${url}/${queryIds.join(',')}/${this.getSubResourcePath()}`;
        }

        if (this.body) {
            for (const key of Object.keys(this.body)) {
                if (this.body[key] === null) {
                    delete this.body[key];
                }
            }
        }

        return this.http
            .post(url, this.body, {headers: this.getHeadersForRequest()})
            .pipe(
                map((response: IGenericObject): IGenericObject => {
                    return response;
                }),
            );
    }

    protected setBody(body: TBody): this {
        this.body = body;

        return this;
    }
}

export {CommonsPostRequestBuilder};
