import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class RequiredTotalBudgetValidator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const totalBudget: number | undefined | null = control.value;

        if (totalBudget === undefined || totalBudget === null) {
            return {[TotalBudgetErrorCodes.Required]: this.getTranslationForErrorCode(TotalBudgetErrorCodes.Required)};
        }

        if (totalBudget < RequiredTotalBudgetValidator.minValue) {
            return {[TotalBudgetErrorCodes.MinValue]: this.getTranslationForErrorCode(TotalBudgetErrorCodes.MinValue)};
        }

        if (totalBudget > RequiredTotalBudgetValidator.maxValue) {
            return {[TotalBudgetErrorCodes.MaxValue]: this.getTranslationForErrorCode(TotalBudgetErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: TotalBudgetErrorCodes): string {
        const requiredMessage: string = $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        const min: string = this.currencyPipe.transform(RequiredTotalBudgetValidator.minValue, 'EUR') || '';
        const minMessage: string = $localize`:@@VALIDATOR_TOTAL_BUDGET_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_TOTAL_BUDGET_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
        const max: string = this.currencyPipe.transform(RequiredTotalBudgetValidator.maxValue, 'EUR') || '';
        const maxMessage: string = $localize`:@@VALIDATOR_TOTAL_BUDGET_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_TOTAL_BUDGET_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;

        switch (code) {
            case TotalBudgetErrorCodes.Required:
                return requiredMessage;
            case TotalBudgetErrorCodes.MinValue:
                return minMessage;
            case TotalBudgetErrorCodes.MaxValue:
                return maxMessage;
            default:
                return defaultMessage;
        }
    }
}

enum TotalBudgetErrorCodes {
    Required = '039df734-4cdf-4e45-afac-a5b9bc37b161',
    MinValue = '4e3e887a-87cd-4b41-8299-e191b087ac9a',
    MaxValue = 'a1bc310c-6595-474e-bf3d-f11af227dcb9',
}

export {RequiredTotalBudgetValidator, TotalBudgetErrorCodes};
