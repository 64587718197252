<div class="image-selection-cropper">
    <div class="image-selection-cropper__size-sidebar">
        <div *ngFor="let configuration of availableSizeConfigurations; let firstParent = first;">
            <div class="image-selection-cropper__group-title">
                <div (click)="toggleGroup(configuration)" class="image-selection-cropper__group-title-icon">
                    <mat-icon *ngIf="!configuration.expanded">expand_more</mat-icon>
                    <mat-icon *ngIf="configuration.expanded">expand_less</mat-icon>
                </div>
                <mat-checkbox
                    color="primary"
                    [indeterminate]="isAtLeastOneItemInGroupSelected(configuration) && !areAllItemsInGroupSelected(configuration)"
                    [checked]="areAllItemsInGroupSelected(configuration)"
                    (change)="onGroupChange(configuration, $event)"
                    [disabled]="areAllItemsInGroupDisabled(configuration)"
                    labelPosition="before"
                >{{configuration.title}}</mat-checkbox>
            </div>
            <mat-selection-list
                (selectionChange)="onSizeSelectionChange($event, configuration)"
                *ngIf="configuration.sizes.length && configuration.expanded"
                class="image-selection-cropper__selection-list"
            >
                <mat-list-option
                    color="primary"
                    [value]="size"
                    [selected]="size.selected"
                    [disabled]="isSizeDisabled(size.dimensions)"
                    i18n-matTooltip="@@IMAGE_SELECTION_CROPPER_SIZE_OPTION_DISABLED_TOOLTIP"
                    matTooltip="IMAGE_SELECTION_CROPPER_SIZE_OPTION_DISABLED_TOOLTIP"
                    [matTooltipDisabled]="!isSizeDisabled(size.dimensions)"
                    matTooltipClass="tooltip"
                    *ngFor="let size of configuration.sizes"
                    class="image-selection-cropper__list-entry"
                >
                    <div class="vm-display-flex vm-flex-direction-row vm-layout-justify-start vm-layout-align-center">
                        <div class="image-selection-cropper__list-entry-original-size-icon-container">
                            <mat-icon
                                class="image-selection-cropper__list-entry-original-size-icon"
                                *ngIf="isOriginalImageSize(size.dimensions)"
                                i18n-matTooltip="@@IMAGE_SELECTION_CROPPER_SELECTED_SIZE_IS_ORIGINAL_IMAGE_SIZE"
                                matTooltip="IMAGE_SELECTION_CROPPER_SELECTED_SIZE_IS_ORIGINAL_IMAGE_SIZE"
                                matTooltipClass="tooltip"
                            >crop_original</mat-icon>
                        </div>
                        <ng-template
                            let-width="width"
                            let-height="height"
                            [ngTemplateOutletContext]="{ width: size.dimensions.width, height: size.dimensions.height}"
                            [ngTemplateOutlet]="translation"
                            #translation
                        >
                            <span i18n="@@WIDTH_BY_HEIGHT_IN_PIXEL" class="image-selection-cropper__list-entry-size-label"
                            >WIDTH_BY_HEIGHT_IN_PIXEL {{width}} {{height}}</span>
                        </ng-template>
                    </div>
                </mat-list-option>
            </mat-selection-list>
            <div *ngIf="firstParent && configuration.expanded" class="image-selection-cropper__custom-size-selection-entry">
                <ng-template #popupContent>
                    <div class="image-selection-cropper__custom-size-form">
                        <ad-add-custom-size-popover
                            (onSubmit)="onAddCustomSize($event)"
                            [maxWidth]="imageFileDimensions ? imageFileDimensions.width : 0"
                            [maxHeight]="imageFileDimensions ? imageFileDimensions.height : 0"
                        ></ad-add-custom-size-popover>
                    </div>
                </ng-template>
                <core-popup
                    [trigger]="trigger"
                    [popupContent]="popupContent"
                    [allowDrag]="false"
                    [allowMulti]="false"
                    [width]="212"
                    [(close)]="closePopup"
                    [direction]="popupPlacement"
                    [disabledContentCaching]="true"
                >
                    <button
                        type="button"
                        mat-stroked-button
                        color="primary"
                        i18n="@@IMAGE_SELECTION_CROPPER_CUSTOM_SIZE_LABEL"
                        class="vm-flex-100"
                    >IMAGE_SELECTION_CROPPER_CUSTOM_SIZE_LABEL</button>
                </core-popup>
            </div>
        </div>
    </div>
    <div class="image-selection-cropper__preview-container vm-display-flex vm-flex-direction-row vm-flex-wrap">
        <div *ngFor="let size of selectedSizes">
            <div class="image-selection-cropper__cropped-preview-wrapper">
                <ad-cropped-preview
                    [image]="base64Image"
                    [cropWidth]="size.width"
                    [cropHeight]="size.height"
                    [cropPosition]="currentCropPosition"
                    (onCropperInit)="onPreviewCropperInit($event, size)"
                ></ad-cropped-preview>
                <mat-icon class="image-selection-cropper__remove-preview-action" (click)="removeItemFromPreview(size)">close</mat-icon>
            </div>
        </div>
        <div
            class="image-selection-cropper__no-previews-available"
            *ngIf="selectedSizes.length === 0"
            i18n="@@IMAGE_SELECTION_CROPPER_NO_PREVIEWS_AVAILABLE"
        >IMAGE_SELECTION_CROPPER_NO_PREVIEWS_AVAILABLE</div>
    </div>
    <div
        class="image-selection-cropper__actions vm-display-flex vm-flex-direction-row vm-layout-justify-space-between vm-layout-align-end"
    >
        <div
            i18n-matTooltip="@@IMAGE_SELECTION_CROPPER_TOGGLE_EDITOR_MODE_BUTTON_DISABLED_MESSAGE"
            matTooltip="IMAGE_SELECTION_CROPPER_TOGGLE_EDITOR_MODE_BUTTON_DISABLED_MESSAGE"
            matTooltipClass="tooltip"
            [matTooltipDisabled]="!isOriginalImageSizeSelected()"
        >
            <button
                type="button"
                mat-button
                color="primary"
                [disabled]="!selectedSizes.length || isOriginalImageSizeSelected()"
                (click)="toggleEditor()"
            >
                <span style="margin-right: 6px;" i18n="@@IMAGE_SELECTION_CROPPER_TOGGLE_EDITOR_MODE_BUTTON_LABEL">IMAGE_SELECTION_CROPPER_TOGGLE_EDITOR_MODE_BUTTON_LABEL</span>
                <mat-icon *ngIf="!isEditorVisible">expand_more</mat-icon>
                <mat-icon *ngIf="isEditorVisible">expand_less</mat-icon>
            </button>
        </div>

        <div class="vm-display-flex vm-flex-direction-row">
            <button
                type="button"
                mat-raised-button
                (click)="cancel()"
                i18n="@@CANCEL"
            >CANCEL</button>
            <libs-spacer [vertical]="spacerSize"></libs-spacer>
            <button
                type="button"
                mat-raised-button
                [disabled]="!selectedSizes.length"
                color="primary"
                (click)="addFiles()"
                i18n="@@ADD_ELEMENT_BUTTON_LABEL"
            >ADD_ELEMENT_BUTTON_LABEL</button>
        </div>

    </div>
    <div class="image-selection-cropper__editor-sidebar" *ngIf="isEditorVisible">
        <core-info-box>
            <ng-container core-info-box-title><span i18n="@@IMAGE_SELECTION_CROPPER_EDITOR_HELP_TITLE">IMAGE_SELECTION_CROPPER_EDITOR_HELP_TITLE</span></ng-container>
            <ng-container core-info-box-description><span i18n="@@IMAGE_SELECTION_CROPPER_EDITOR_HELP_MESSAGE">IMAGE_SELECTION_CROPPER_EDITOR_HELP_MESSAGE</span></ng-container>
        </core-info-box>
    </div>
    <div class="image-selection-cropper__editor" *ngIf="isEditorVisible">
        <div class="image-selection-cropper__cropper-container">
            <img
                #cropper
                [src]="base64Image"
                class="image-selection-cropper__cropper-instance"
                alt="cropper preview"
            />
        </div>
    </div>
</div>
