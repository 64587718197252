import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Campaign, IRequestBuilderResult, Order} from '@active-agent/types';
import {GetOrderBuilder} from './builder/get-order-builder';
import {UpdateOrderBuilder} from './builder/update-order-builder';
import {DeleteOrderBuilder} from './builder/delete-order-builder';
import {CreateOrderBuilder} from './builder/create-order-builder';
import {API_URL} from '@active-agent/api';
import {ERROR_HANDLER, ICommonsErrorHandler} from '@active-agent/error';
import {map, Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
class LibsOrderService {
    constructor(
        private http: HttpClient,
        @Optional() @Inject(ERROR_HANDLER) private errorHandler: ICommonsErrorHandler | null,
        @Inject(API_URL) private apiUrl: string,
    ) {}

    public createGetOrderBuilder(networkIds: Array<number>): GetOrderBuilder {
        return new GetOrderBuilder(this.apiUrl, this.http, networkIds, this.errorHandler);
    }

    public createUpdateOrderBuilder(originalOrder: Order, modifiedOrder: Order): UpdateOrderBuilder {
        return new UpdateOrderBuilder(this.apiUrl, this.http, originalOrder, modifiedOrder);
    }

    public createDeleteOrderBuilder(): DeleteOrderBuilder {
        return new DeleteOrderBuilder(this.apiUrl, this.http);
    }

    public createCreateOrderBuilder(model: Order): CreateOrderBuilder {
        return new CreateOrderBuilder(this.apiUrl, this.http, model);
    }

    public getOrdersByCampaigns(campaigns: Array<Campaign>): Observable<Array<Order>> {
        const networkIds: Array<number> = Array.from(new Set([
            ...campaigns
                .map((campaign: Campaign) => campaign.networkId || 0)
                .filter((networkId: number) => !!networkId),
        ]));
        const orderIds: Array<number> = Array.from(new Set([
            ...campaigns
                .map((campaign: Campaign) => campaign.orderId || 0)
                .filter((orderId: number) => !!orderId),
        ]));

        if (!orderIds.length) {
            return of([]);
        }

        return this.createGetOrderBuilder(networkIds)
            .addQueryIds(orderIds)
            .build()
            .pipe(
                map((result: IRequestBuilderResult<Array<Order>>) => result.data),
            );
    }
}

export {LibsOrderService};
