import {AbstractControl, ValidationErrors} from '@angular/forms';
import {getUrlAditionPattern} from '@active-agent/pattern';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class OptionalClickUrlValidator {
    public static readonly maxLength: number = 2_000;
    public static readonly pattern: RegExp = getUrlAditionPattern();

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<string | null>): ValidationErrors | null {
        const clickUrl: string | null = control.value;

        if (clickUrl === undefined || clickUrl === null || !clickUrl.length) {
            return null;
        }

        if (clickUrl.length > OptionalClickUrlValidator.maxLength) {
            return {[OptionalClickUrlErrorCodes.MaxLength]: this.getTranslationForErrorCode(OptionalClickUrlErrorCodes.MaxLength)};
        }

        if (!OptionalClickUrlValidator.pattern.test(clickUrl)) {
            return {[OptionalClickUrlErrorCodes.Pattern]: this.getTranslationForErrorCode(OptionalClickUrlErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: OptionalClickUrlErrorCodes): string {
        switch (code) {
            case OptionalClickUrlErrorCodes.Required:
                return $localize`:@@VALIDATOR_OPTIONAL_CLICK_URL_REQUIRED_ERROR_MESSAGE:VALIDATOR_OPTIONAL_CLICK_URL_REQUIRED_ERROR_MESSAGE`;
            case OptionalClickUrlErrorCodes.MaxLength:
                const max: string | null = this.decimalPipe.transform(OptionalClickUrlValidator.maxLength);

                return $localize`:@@VALIDATOR_OPTIONAL_CLICK_URL_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_OPTIONAL_CLICK_URL_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            case OptionalClickUrlErrorCodes.Pattern:
                return $localize`:@@VALIDATOR_OPTIONAL_CLICK_URL_PATTERN_ERROR_MESSAGE:VALIDATOR_OPTIONAL_CLICK_URL_PATTERN_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum OptionalClickUrlErrorCodes {
    Required = '0393e1e0-d582-4a1b-927e-5cc1ee5f8561',
    MaxLength = '4c11f3af-ff86-4eaf-b522-87f98d1aab0b',
    Pattern = '32e01396-ca64-4aec-ab26-883be49a3832',
}

export {OptionalClickUrlValidator};
