import {OttoPntaFilter, IOttoPntaFilterProperties} from './otto-pnta-filter';
import {FilterTypes} from '../types/filter.types';

type OttoPntaCustomFilterProperties = IOttoPntaFilterProperties;

class OttoPntaCustomFilter extends OttoPntaFilter<FilterTypes.OTTO_PNTA_CUSTOM> {
    protected readonly _type: FilterTypes.OTTO_PNTA_CUSTOM = FilterTypes.OTTO_PNTA_CUSTOM;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly props: OttoPntaCustomFilterProperties = this.getDefaultOptions();
}

export {OttoPntaCustomFilter, OttoPntaCustomFilterProperties};
