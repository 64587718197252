import {NgModule} from '@angular/core';
import {LibsWebsocketService} from './websocket.service';

@NgModule({
    providers: [
        LibsWebsocketService,
    ],
})
class LibsWebsocketModule {}

export {LibsWebsocketModule};
