import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';
import {PercentPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class MinVtrValidator implements Validator {
    public static readonly maxValue: number = 100;
    public static readonly minValue: number = 1;

    constructor(private percentPipe: PercentPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const minVtr: number | undefined | null = control.value;
        if (minVtr === undefined || minVtr === null) {
            return {[MinVtrErrorCodes.Required]: this.getTranslationForErrorCode(MinVtrErrorCodes.Required)};
        }

        if (minVtr < MinVtrValidator.minValue) {
            return {[MinVtrErrorCodes.MinValue]: this.getTranslationForErrorCode(MinVtrErrorCodes.MinValue)};
        }

        if (minVtr > MinVtrValidator.maxValue) {
            return {[MinVtrErrorCodes.MaxValue]: this.getTranslationForErrorCode(MinVtrErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: MinVtrErrorCodes): string {
        switch (code) {
            case MinVtrErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case MinVtrErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_MIN_VTR_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_MIN_VTR_MIN_VALUE_ERROR_MESSAGE ${this.percentPipe.transform(MinVtrValidator.minValue / 100)}:INTERPOLATION:`;
            case MinVtrErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_MIN_VTR_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_MIN_VTR_MAX_VALUE_ERROR_MESSAGE ${this.percentPipe.transform(MinVtrValidator.maxValue / 100)}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum MinVtrErrorCodes {
    Required = 'b5b89020-6aa4-460f-bccc-617c39df0319',
    MinValue = 'ae458b43-7b1c-4124-a9f3-310274228bec',
    MaxValue = 'f096a067-b5fb-40ec-b710-627c53104c9f',
}

export {MinVtrValidator, MinVtrErrorCodes};
