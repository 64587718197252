import {Banner, BaseBanner} from '../base-banner';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {IBannerSize, IRedirectBanner} from '../banner';

class RedirectBanner extends BaseBanner implements IRedirectBanner, ICloneable<RedirectBanner>, IMergable<RedirectBanner> {
    private readonly _type: BannerTypes = BannerTypes.REDIRECT;
    private _parse: boolean = true;
    private _clickUrl: string | null = null;
    private _redirectTag: string;
    private _size: IBannerSize;
    private _preHtmlCode: string = '';
    private _postHtmlCode: string = '';
    private _ssl: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        redirectTag: string,
        size: IBannerSize,
    ) {
        super(id, networkId, name, landingPage);

        this._redirectTag = redirectTag;
        this._size = size;
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get parse(): boolean {
        return this._parse;
    }

    set parse(value: boolean) {
        this._parse = value;
    }

    get clickUrl(): string | null {
        return this._clickUrl;
    }

    set clickUrl(value: string | null) {
        this._clickUrl = value;
    }

    get redirectTag(): string {
        return this._redirectTag;
    }

    set redirectTag(value: string) {
        this._redirectTag = value;
    }

    get size(): IBannerSize {
        return this._size;
    }

    set size(value: IBannerSize) {
        this._size = value;
    }

    get preHtmlCode(): string {
        return this._preHtmlCode;
    }

    set preHtmlCode(value: string) {
        this._preHtmlCode = value;
    }

    get postHtmlCode(): string {
        return this._postHtmlCode;
    }

    set postHtmlCode(value: string) {
        this._postHtmlCode = value;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        RedirectBanner.fillAdditionalFields(this, source);
    }

    public clone(): RedirectBanner {
        const newModel: RedirectBanner = new RedirectBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.redirectTag,
            this.size,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: RedirectBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('parse' in source) {
            target.parse = source.parse;
        }
        if ('clickUrl' in source) {
            target.clickUrl = source.clickUrl;
        }
        if ('preHtmlCode' in source) {
            target.preHtmlCode = source.preHtmlCode;
        }
        if ('postHtmlCode' in source) {
            target.postHtmlCode = source.postHtmlCode;
        }
        if ('redirectTag' in source) {
            target.redirectTag = source.redirectTag;
        }
        if ('size' in source) {
            target.size = {...source.size};
        }
    }
}

export {RedirectBanner};
