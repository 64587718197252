import {BaseProfileFilter, IBaseProfileFilterProperties} from './base-profile-filter';
import {FilterTypes} from '../types/filter.types';

type CampaignAtvTrafficTypeFilterProperties = IBaseProfileFilterProperties;

class CampaignAtvTrafficTypeFilter extends BaseProfileFilter<FilterTypes.CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE>  {
    protected readonly _isAvailableForPreview: boolean = false;
    protected readonly _type: FilterTypes.CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE = FilterTypes.CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE;
    protected readonly props: CampaignAtvTrafficTypeFilterProperties = this.getDefaultOptions();

    protected getDefaultOptions(): CampaignAtvTrafficTypeFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: 'trafficType = 6',
        };
    }
}

export {CampaignAtvTrafficTypeFilter};
