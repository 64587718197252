
enum DoohLocationFileType {
    HTML5 = 'HTML5',
    MP4 = 'MP4',
}

function getTranslationForType(type: DoohLocationFileType): string {
    switch (type) {
        case DoohLocationFileType.HTML5:
            return $localize`:@@DOOH_LOCATION_FILE_TYPE_HTML5_LABEL:DOOH_LOCATION_FILE_TYPE_HTML5_LABEL`;
        case DoohLocationFileType.MP4:
            return $localize`:@@DOOH_LOCATION_FILE_TYPE_MP4_LABEL:DOOH_LOCATION_FILE_TYPE_MP4_LABEL`;
        default:
            return $localize`:@@DOOH_LOCATION_FILE_TYPE_UNKNOWN_LABEL:DOOH_LOCATION_FILE_TYPE_UNKNOWN_LABEL`;
    }
}

export {DoohLocationFileType, getTranslationForType};
