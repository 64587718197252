import {NgModule} from '@angular/core';
import {LibsOrderService} from './order.service';
import {CommonsValidatorsModule} from '@active-agent/validators';
import {LibsNotificationModule} from '@active-agent/notification';

@NgModule({
    imports: [
        CommonsValidatorsModule,
        LibsNotificationModule,
    ],
    providers: [
        LibsOrderService,
    ],
})
class LibsOrderModule {}

export {LibsOrderModule};
