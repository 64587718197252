import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnChanges,
    Optional,
    Signal,
    SimpleChanges,
    TemplateRef,
    input,
} from '@angular/core';
import {ICommonsErrorHandler, ERROR_HANDLER} from '@active-agent/error';
import {CommonsError} from '@active-agent/types';
import {CommonsBorderStyle} from '../list-item/list-item.component';
import {Status} from '../status-icon/status-icon.component';
import {getObjectId} from '@active-agent/std';

@Component({
    selector: 'commons-static-information',
    templateUrl: 'static-information.html',
    styleUrls: ['static-information.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
class CommonsStaticInformationComponent implements OnChanges {
    @Input() public fields: Array<ICommonsStaticInformationField> = [];
    public addMargin: Signal<boolean> = input(true);
    public readonly infoStatus: Status = Status.Info;
    public readonly borderStyle: CommonsBorderStyle = 'solid';

    public constructor(
        @Optional() @Inject(ERROR_HANDLER) private errorHandlerService: ICommonsErrorHandler | null,
        private changeDetector: ChangeDetectorRef,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.fields || changes.fields.currentValue === undefined) {
            return;
        }
        this.initializeFields(changes.fields.currentValue);
        this.changeDetector.detectChanges();
    }

    public getFieldId(entry: ICommonsStaticInformationField): number {
        return getObjectId(entry);
    }

    private initializeFields(fields: Array<ICommonsStaticInformationField>): void {
        fields.forEach((field: ICommonsStaticInformationField) => {
            this.removeHtmlTagsFromTooltip(field);
        });
        this.fields = fields.filter((field: ICommonsStaticInformationField) => {
            return this.isValidField(field);
        });
    }

    private removeHtmlTagsFromTooltip(field: ICommonsStaticInformationField): void {
        if (field.tooltip) {
            field.tooltip = field.tooltip.replace(/<[^>]*>/g, '');
        }
    }

    private isValidField(field: ICommonsStaticInformationField): boolean {
        let isValid: boolean = true;
        if (field.tooltip && field.template) {
            if (this.errorHandlerService) {
                void this.errorHandlerService.handle(new CommonsError('Static Information: tooltip used with a template'));
            }
            isValid = false;
        }
        if (field.template && (field.header || field.description)) {
            if (this.errorHandlerService) {
                void this.errorHandlerService.handle(new CommonsError('Static Information: template used with header or description'));
            }
            isValid = false;
        }

        return isValid;
    }
}

interface ICommonsStaticInformationField {
    tooltip?: string;
    template?: TemplateRef<void>;
    header?: string;
    description?: string;
    hasTemplateClickEvent?: boolean;
}

export {CommonsStaticInformationComponent, ICommonsStaticInformationField};
