import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class AttributeMinSecondsBetweenTagsValidator {
    public static readonly maxValue: number = 86400;
    public static readonly minValue: number = 0;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const minSecondsBetweenTags: number = control.value;

        if (minSecondsBetweenTags === undefined || minSecondsBetweenTags === null) {
            return {[MinSecondsBetweenTagsErrorCodes.Required]: this.getTranslationForErrorCode(MinSecondsBetweenTagsErrorCodes.Required)};
        }

        if (minSecondsBetweenTags < AttributeMinSecondsBetweenTagsValidator.minValue) {
            return {[MinSecondsBetweenTagsErrorCodes.MinValue]: this.getTranslationForErrorCode(MinSecondsBetweenTagsErrorCodes.MinValue)};
        }

        if (minSecondsBetweenTags > AttributeMinSecondsBetweenTagsValidator.maxValue) {
            return {[MinSecondsBetweenTagsErrorCodes.MaxValue]: this.getTranslationForErrorCode(MinSecondsBetweenTagsErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: MinSecondsBetweenTagsErrorCodes): string {
        switch (code) {
            case MinSecondsBetweenTagsErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case MinSecondsBetweenTagsErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_MIN_SECONDS_BETWEEN_TAGS_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_MIN_SECONDS_BETWEEN_TAGS_MIN_VALUE_ERROR_MESSAGE ${this.decimalPipe.transform(AttributeMinSecondsBetweenTagsValidator.minValue)}:INTERPOLATION:`;
            case MinSecondsBetweenTagsErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_MIN_SECONDS_BETWEEN_TAGS_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_MIN_SECONDS_BETWEEN_TAGS_MAX_VALUE_ERROR_MESSAGE ${this.decimalPipe.transform(AttributeMinSecondsBetweenTagsValidator.maxValue)}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum MinSecondsBetweenTagsErrorCodes {
    Required = '4e3aca1e-3b52-4c94-afad-e8f1e91d87e7',
    MinValue = 'a9e22695-68ee-4fff-afa6-c833e5f9ad72',
    MaxValue = 'b5cbede8-0fe3-486b-94c1-aec62bb8ff5b',
}

export {AttributeMinSecondsBetweenTagsValidator};
