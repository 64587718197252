import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {RequiredDailyBudgetErrorCodes, RequiredDailyBudgetValidator} from '@active-agent/validators';

@Injectable({
    providedIn: 'root',
})
class OrderDailyBudgetValidator {

    constructor(private dailyBudgetValidator: RequiredDailyBudgetValidator) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | null | undefined>): ValidationErrors | null {
        const defaultValidation: ValidationErrors | null = this.dailyBudgetValidator.validate(control);

        if (!defaultValidation) {
            return null;
        }

        if (defaultValidation[RequiredDailyBudgetErrorCodes.Required]) {
            return null;
        }

        return defaultValidation;
    }
}

export {OrderDailyBudgetValidator};
