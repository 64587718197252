// eslint-disable-next-line @typescript-eslint/naming-convention
enum API_FILE_TYPES {
    PNG = 'PNG',
    JPEG = 'JPEG',
    FLASH = 'FLASH',
    GIF = 'GIF',
    MISC = 'MISC',
    ZIP = 'ZIP',
}

export {API_FILE_TYPES};
