import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class RequiredMaxCpmValidator implements Validator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const maxCpm: number | undefined | null = control.value;
        if (maxCpm === undefined || maxCpm === null) {
            return {[MaxCpmErrorCodes.Required]: this.getTranslationForErrorCode(MaxCpmErrorCodes.Required)};
        }

        if (maxCpm < RequiredMaxCpmValidator.minValue) {
            return {[MaxCpmErrorCodes.MinValue]: this.getTranslationForErrorCode(MaxCpmErrorCodes.MinValue)};
        }

        if (maxCpm > RequiredMaxCpmValidator.maxValue) {
            return {[MaxCpmErrorCodes.MaxValue]: this.getTranslationForErrorCode(MaxCpmErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: MaxCpmErrorCodes): string {
        switch (code) {
            case MaxCpmErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case MaxCpmErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_MAX_CPM_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_CPM_MIN_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(RequiredMaxCpmValidator.minValue, 'EUR')}:INTERPOLATION:`;
            case MaxCpmErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_MAX_CPM_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_CPM_MAX_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(RequiredMaxCpmValidator.maxValue, 'EUR')}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum MaxCpmErrorCodes {
    Required = '1fef6874-daae-4925-ae71-2c99579f9195',
    MinValue = '354cbc7a-55e3-4668-9f2e-58917de1c3eb',
    MaxValue = '5ad6fa74-7df1-4c61-ac76-00f700f499d7',
}

export {RequiredMaxCpmValidator, MaxCpmErrorCodes};
