import {Observable, of} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IVisibilityFilterProperties extends IFilterProperties {
    profile: string;
}

class VisibilityFilter extends Filter<FilterTypes.VISIBILITY> {
    protected readonly _type: FilterTypes.VISIBILITY = FilterTypes.VISIBILITY;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IVisibilityFilterProperties = this.getDefaultOptions();

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    public isValid(): Observable<boolean> {
        return of(isVisibilityProfileValid(this.profile));
    }

    protected getDefaultOptions(): IVisibilityFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

function isVisibilityProfileValid(profile: string = ''): boolean {
    return !!profile.length;
}

export {VisibilityFilter, IVisibilityFilterProperties, isVisibilityProfileValid};
