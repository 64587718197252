enum CampaignTypes {
    Display = 'DISPLAY',
    AddressableTv = 'ADDRESSABLE_TV',
    DigitalOutOfHome = 'DIGITAL_OUT_OF_HOME',
    Radio = 'RADIO',
    Pep = 'PEP',
    Redirect = 'Redirect',
    ProgrammaticTv = 'PROGRAMMATIC_TV',
}

enum CampaignApiTypes {
    RTB = 'RTB',
    REDIRECT = 'REDIRECT',
}

export {CampaignTypes, CampaignApiTypes};
