function stripTopLevelDirectory(path: string): string {
    let parts: Array<string> = path.split('/');
    if (parts.length > 1) {
        parts = parts.slice(1);
    }

    return parts.join('/');
}

export {stripTopLevelDirectory};
