import {Observable} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {fromFetch} from 'rxjs/fetch';

class ContentCategoryReader {
    private static contentCategoryObservable: Observable<Map<string, IContentCategory>> | null = null;
    private static contentCategoryIdToTranslationsObservable: Observable<Map<number, string>> | null = null;
    private static readonly URL: string = './assets/commons/content-categories/content-categories.json';

    public static getContentCategories(): Observable<Map<string, IContentCategory>> {
        if (!this.contentCategoryObservable) {
            this.contentCategoryObservable = fromFetch(ContentCategoryReader.URL)
                .pipe(
                    switchMap((response: Response) => response.json()),
                    map((values: Array<IContentCategory>) => getIabMap(values)),
                    shareReplay(),
                );
        }

        return this.contentCategoryObservable;
    }

    public static getContentCategoryIdToTranslations(): Observable<Map<number, string>> {
        if (!this.contentCategoryIdToTranslationsObservable) {
            this.contentCategoryIdToTranslationsObservable = fromFetch(ContentCategoryReader.URL)
                .pipe(
                    switchMap((response: Response) => response.json()),
                    map((values: Array<IContentCategory>) => getIabTranslationMap(values)),
                    shareReplay(),
                );
        }

        return this.contentCategoryIdToTranslationsObservable;
    }
}

function getIabMap(list: Array<IContentCategory>): Map<string, IContentCategory> {
    const iabMap: Map<string, IContentCategory> = new Map<string, IContentCategory>();
    for (const item of list) {
        iabMap.set(item.key, {
            ...item,
            id: Number(item.id),
            groupId: Number(item.groupId),
        });
    }

    return iabMap;
}

function getIabTranslationMap(list: Array<IContentCategory>): Map<number, string> {
    const iabMap: Map<number, string> = new Map<number, string>();
    for (const item of list) {
        iabMap.set(Number(item.id), item.translation);
    }

    return iabMap;
}

interface IContentCategory {
    id: number;
    key: string;
    translation: string;
    groupId: number;
}

export  {ContentCategoryReader, IContentCategory};
