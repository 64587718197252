import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {TransformHelper} from '@active-agent/std';
import {CurrencyPipe, DecimalPipe, NgIf} from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
    selector: 'libs-progress-bar',
    templateUrl: 'progress-bar.html',
    styleUrls: ['progress-bar.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatProgressBarModule,
        NgIf,
    ],
})
class LibsProgressBarComponent {
    @Input() public type: ProgressBarValueType = 'number';
    @Input() public percentage: number = 0;
    @Input() public firstValue: number = 0;
    @Input() public secondValue: number = 0;
    @Input() public title: string | null = null;

    constructor(
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe,
    ) {}

    public getFormattedValue(value: number): string {
        switch (this.type) {
            case 'currency-in-cent':
                return this.currencyPipe.transform(TransformHelper.centToEuro(value), 'EUR') || '';
            case 'number':
            default:
                return this.decimalPipe.transform(value) || '';
        }
    }
}

type ProgressBarValueType = 'number' | 'currency-in-cent';

export {LibsProgressBarComponent, ProgressBarValueType};
