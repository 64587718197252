import {NgModule} from '@angular/core';
import {NotificationService} from './notification.service';
import {LibsNotificationComponent} from './notification.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        MatSnackBarModule,
        MatIconModule,
    ],
    exports: [
        MatSnackBarModule,
        MatIconModule,
        LibsNotificationComponent,
    ],
    declarations: [
        LibsNotificationComponent,
    ],
    providers: [
        NotificationService,
    ],
})
class LibsNotificationModule {}

export {LibsNotificationModule};
