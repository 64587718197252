import {NgModule} from '@angular/core';
import {LibsTargetingAttributeStaticInformationComponent} from './targeting-attribute-static-information.component';
import {CommonsStaticInformationModule} from '../static-information.module';

@NgModule({
    imports: [
        CommonsStaticInformationModule,
    ],
    exports: [
        LibsTargetingAttributeStaticInformationComponent,
    ],
    declarations: [
        LibsTargetingAttributeStaticInformationComponent,
    ],
})
class LibsTargetingAttributeStaticInformationModule {}

export {LibsTargetingAttributeStaticInformationModule};
