import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {inject} from '@angular/core';
import {LibsAppDataService, IAppData} from '@active-agent/app-data';
import {map} from 'rxjs/operators';

function getHasCreatePermissionResolverObservable(redirectCallback: HasCreatePermissionResolverRedirectCallback): Observable<void> {
    const router: Router = inject(Router);

    return inject(LibsAppDataService).getData().pipe(
        map(({user}: IAppData): void => {
            if (user && !user.canCreate()) {
                redirectCallback(router);
            }
        }),
    );
}

type HasCreatePermissionResolverRedirectCallback = (router: Router) => void;

export {getHasCreatePermissionResolverObservable, HasCreatePermissionResolverRedirectCallback};
