<commons-switch-list-item
    i18n-title="@@BANNER_FORM_SSL_STATUS_LABEL"
    title="BANNER_FORM_SSL_STATUS_LABEL"
    [isActive]="isActive"
    [hasStatusIcon]="false"
    [isDisabled]="isDisabled"
    (onChange)="onSwitchChange($event)"
>
    <commons-status-icon
        [status]="warningStatus"
        *ngIf="isWarningIconVisible()"
        [tooltip]="getWarningIconTooltip()"
    >
    </commons-status-icon>
</commons-switch-list-item>
