import {ICloneable, IMergable} from '../types';
import {CpxMetric, IOptimizationSupport, Optimization} from './campaign';

class RtbCampaignOptimization implements IOptimizationSupport, IMergable<IOptimizationSupport>, ICloneable<RtbCampaignOptimization> {
    private _maxCpc: number | null = null;
    private _maxCpx: number | null = null;
    private _maxCpcv: number | null = null;
    private _minVtr: number | null = null;
    private _optimization: Optimization = 'NONE';
    private _cpxMetrics: Array<CpxMetric> = [];

    get maxCpc(): number | null {
        return this._maxCpc;
    }

    set maxCpc(value: number | null) {
        this._maxCpc = value;
    }

    get maxCpx(): number | null {
        return this._maxCpx;
    }

    set maxCpx(value: number | null) {
        this._maxCpx = value;
    }

    get maxCpcv(): number | null {
        return this._maxCpcv;
    }

    set maxCpcv(value: number | null) {
        this._maxCpcv = value;
    }

    get minVtr(): number | null {
        return this._minVtr;
    }

    set minVtr(value: number | null) {
        this._minVtr = value;
    }

    get optimization(): Optimization {
        return this._optimization;
    }

    set optimization(value: Optimization) {
        this._optimization = value;
    }

    get cpxMetrics(): Array<CpxMetric> {
        return this._cpxMetrics;
    }

    set cpxMetrics(value: Array<CpxMetric>) {
        this._cpxMetrics = value;
    }

    public merge(source: IOptimizationSupport): void {
        this.maxCpc = source.maxCpc;
        this.maxCpx = source.maxCpx;
        this.maxCpcv = source.maxCpcv;
        this.minVtr = source.minVtr;
        this.optimization = source.optimization;
        if (source.cpxMetrics) {
            this.cpxMetrics = source.cpxMetrics.map((value: CpxMetric) => value);
        }
    }

    public clone(): RtbCampaignOptimization {
        const newModel: RtbCampaignOptimization = new RtbCampaignOptimization();
        newModel.merge(this);

        return newModel;
    }
}

function isSupportingOptimization(value: unknown): value is IOptimizationSupport {
    return typeof value === 'object' && value !== null
        && 'maxCpc' in value
        && 'maxCpx' in value
        && 'maxCpcv' in value
        && 'minVtr' in value
        && 'optimization' in value;
}

export {RtbCampaignOptimization, isSupportingOptimization};
