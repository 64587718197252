import {Campaign, RtbCampaign} from './rtb-campaign';
import {CampaignTypes} from './campaign-types';
import {ICloneable, IMergable} from '../types';
import {CpxMetric, IAddressableTvCampaign} from './campaign';

class AddressableTvCampaign extends RtbCampaign implements
    IAddressableTvCampaign,
    IMergable<Campaign>,
    ICloneable<AddressableTvCampaign> {

    private readonly _type: CampaignTypes = CampaignTypes.AddressableTv;
    private _cpxMetrics: Array<CpxMetric> = [];

    public get type(): CampaignTypes {
        return this._type;
    }

    get cpxMetrics(): Array<CpxMetric> {
        return this._cpxMetrics;
    }

    set cpxMetrics(value: Array<CpxMetric>) {
        this._cpxMetrics = value;
    }

    public merge(source: Campaign): void {
        super.merge(source);
        AddressableTvCampaign.fillAdditionalFields(this, source);
    }

    public clone(): AddressableTvCampaign {
        const newModel: AddressableTvCampaign = new AddressableTvCampaign(
            this.id,
            this.orderId,
            this.networkId,
            this.biddingStrategy,
            this.dailyBudget,
            this.deliveryTechnique,
            this.maxCpm,
            this.name,
            this.totalBudget,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: AddressableTvCampaign, source: Campaign): void {
        RtbCampaign.fillAdditionalFields(target, source);

        if ('cpxMetrics' in source) {
            const cpxMetrics: Array<CpxMetric> = [];
            target.cpxMetrics = cpxMetrics.concat(source.cpxMetrics);
        }
    }
}

function isAddressableTvCampaign(campaign: unknown): campaign is AddressableTvCampaign {
    return campaign instanceof AddressableTvCampaign;
}

export {AddressableTvCampaign, isAddressableTvCampaign};
