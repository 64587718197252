import {LibsErrorHandler} from '@active-agent/error';
import {environment} from '../../../environments/environment';

class ErrorHandler extends LibsErrorHandler {
    constructor() {
        super(environment);
    }
}

export {ErrorHandler};
