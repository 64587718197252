<libs-pacing-bar
    [currentValue]="currentValue"
    [maxValue]="maxValue"
    [maxValueTooltip]="maxValueTooltip"
    [optimalPace]="optimalPace"
    [optimalPaceTooltip]="optimalPaceTooltip"
    [transformViewValueCallback]="transformViewValueCallback"
    [isDisabled]="isDisabled"
    [isLoading]="isLoading"
></libs-pacing-bar>
