import {TemplateRef} from '@angular/core';
import {InfoBoxLevel} from '@angular-clan/core/info-box';

interface IDialogConfirmationData {
    title?: string;
    description: string;
    template?: TemplateRef<any>;
    templateContext?: Record<string, unknown>;
    checkboxLabel?: string;
    checkboxLabelDescription?: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    hasCancelButton?: boolean;
    status?: ConfirmationStatus;
    hasInvalidDeals?: boolean;
    infoBox?: IInfoBoxContent;
}

interface IInfoBoxContent {
    level: InfoBoxLevel;
    title: string;
    description: string;
}

enum ConfirmationStatus {
    Default = 'default',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
}

export {IDialogConfirmationData, IInfoBoxContent, ConfirmationStatus};
