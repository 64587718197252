import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class DailyImpressionValidator {
    public static readonly maxValue: number = 1_000_000_000;
    public static readonly minValue: number = 0;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const dailyImpressions: number | undefined | null = control.value;

        if (dailyImpressions === undefined || dailyImpressions === null) {
            return null;
        }

        if (dailyImpressions < DailyImpressionValidator.minValue) {
            return {[DailyImpressionErrorCodes.MinValue]: this.getTranslationForErrorCode(DailyImpressionErrorCodes.MinValue)};
        }

        if (dailyImpressions > DailyImpressionValidator.maxValue) {
            return {[DailyImpressionErrorCodes.MaxValue]: this.getTranslationForErrorCode(DailyImpressionErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: DailyImpressionErrorCodes): string {
        const min: string = this.decimalPipe.transform(DailyImpressionValidator.minValue) || '';
        const minMessage: string = $localize`:@@VALIDATOR_DAILY_IMPRESSION_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_DAILY_IMPRESSION_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
        const max: string = this.decimalPipe.transform(DailyImpressionValidator.maxValue) || '';
        const maxMessage: string = $localize`:@@VALIDATOR_DAILY_IMPRESSION_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_DAILY_IMPRESSION_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;

        switch (code) {
            case DailyImpressionErrorCodes.MinValue:
                return minMessage;
            case DailyImpressionErrorCodes.MaxValue:
                return maxMessage;
            default:
                return defaultMessage;
        }
    }
}

enum DailyImpressionErrorCodes {
    Required = '5cbc5c8e-13a1-4472-b4a3-1d55884f7895',
    MinValue = '8d6eb2c8-5a24-44a9-9966-29cea248882e',
    MaxValue = 'eb067e77-b26b-4c30-9fb9-f038d5cc4b24',
}

export {DailyImpressionValidator, DailyImpressionErrorCodes};
