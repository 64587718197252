import {ErrorLog, IErrorLogData, ILibsEnvironment} from '@active-agent/types';
import {ErrorHandler as AngularErrorHandler} from '@angular/core';
import {configureScope, Scope, captureException} from '@sentry/angular-ivy';
import {TimeZoneStore} from '@active-agent/std';

class LibsErrorHandler implements AngularErrorHandler {

    constructor(private environment: ILibsEnvironment) {}

    public handleError(error: unknown): void {
        if (this.environment.sentryUrl) {
            if (error instanceof ErrorLog) {
                const errorLogData: IErrorLogData = error.getData();
                const errorObject: Error | undefined = error.getError();

                configureScope((scope: Scope): void => {
                    scope.setExtra('data', error.getOriginalData());
                    scope.setExtra('state', JSON.parse(errorLogData.state));
                    scope.setTag('isCritical', errorLogData.isCritical.toString());
                    scope.setTag('timeZone', TimeZoneStore.getSelected());

                    if (errorObject !== undefined) {
                        captureException(errorObject);
                    } else {
                        captureException(new Error(errorLogData.message));
                    }
                });
            } else {
                configureScope((scope: Scope): void => {
                    scope.setExtra('data', undefined);
                    scope.setExtra('state', undefined);
                    scope.setTag('isCritical', false.toString());
                    scope.setTag('timeZone', TimeZoneStore.getSelected());
                    captureException(error);
                });
            }
        }

        if (this.environment.debug) {
            if (error instanceof ErrorLog) {
                console.error(error.getError() ?? error);
            } else {
                console.error(error);
            }
        }
    }
}

export {LibsErrorHandler};
