import {Component, ElementRef, Input, ViewChild} from '@angular/core';

/**
 * @deprecated use CoreInfoBoxComponent "core-info-box" instead
 */
@Component({
    selector: 'commons-info-container',
    templateUrl: './info-container.html',
    styleUrls: ['./info-container.scss'],
})
class CommonsInfoContainerComponent {
    @Input() public status: InfoStatus = InfoStatus.Default;
    @Input() public hasMargin: boolean = true;
    @Input() public hintTitle: string = $localize`:@@INFO_CONTAINER_HINT_LABEL:INFO_CONTAINER_HINT_LABEL`;
    public isExpanded: boolean = false;
    public readonly defaultStatus: InfoStatus = InfoStatus.Default;
    public readonly warningStatus: InfoStatus = InfoStatus.Warning;
    public readonly errorStatus: InfoStatus = InfoStatus.Error;
    public readonly infoStatus: InfoStatus = InfoStatus.Info;
    public readonly successStatus: InfoStatus = InfoStatus.Success;
    public readonly newStatus: InfoStatus = InfoStatus.New;
    @ViewChild('title', {static: true}) private title!: ElementRef<HTMLElement>;
    @ViewChild('subtitle', {static: true}) private subtitle!: ElementRef<HTMLElement>;
    @ViewChild('description', {static: true}) private description!: ElementRef<HTMLElement>;
    @ViewChild('moreDescription', {static: true}) private moreDescription!: ElementRef<HTMLElement>;
    @ViewChild('hint', {static: true}) private hint!: ElementRef<HTMLElement>;
    @ViewChild('footerDescription', {static: true}) private footerDescription!: ElementRef<HTMLElement>;

    public hasTitle(): boolean {
        return this.title.nativeElement && this.title.nativeElement.textContent !== null && this.title.nativeElement.textContent.length > 0;
    }

    public hasSubtitle(): boolean {
        return this.subtitle.nativeElement &&
            this.subtitle.nativeElement.textContent !== null &&
            this.subtitle.nativeElement.textContent.length > 0;
    }

    public hasDescription(): boolean {
        return this.description.nativeElement
            && this.description.nativeElement.textContent !== null
            && this.description.nativeElement.textContent.length > 0;
    }

    public hasMoreDescription(): boolean {
        return this.moreDescription.nativeElement
            && this.moreDescription.nativeElement.textContent !== null
            && this.moreDescription.nativeElement.textContent.length > 0;
    }

    public hasHint(): boolean {
        return this.hint.nativeElement && this.hint.nativeElement.textContent !== null && this.hint.nativeElement.textContent.length > 0;
    }

    public hasFooterDescription(): boolean {
        return this.footerDescription.nativeElement &&
            (
                (this.footerDescription.nativeElement.textContent !== null && this.footerDescription.nativeElement.textContent.length > 0)
                || this.footerDescription.nativeElement.hasChildNodes()
            );
    }
}

/**
 * @deprecated use CoreInfoBoxComponent "core-info-box" instead
 */
enum InfoStatus {
    Default = 'default',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
    Success = 'success',
    New = 'new',
}

export {CommonsInfoContainerComponent, InfoStatus};
