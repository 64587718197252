import {Observable, of} from 'rxjs';
import {Ssps} from '../../ssp';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface ISspFilterProperties extends IFilterProperties {
    ssps: Array<Ssps | string>;
}

class SspFilter extends Filter<FilterTypes.SSP> {
    protected readonly _type: FilterTypes.SSP = FilterTypes.SSP;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = false;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: ISspFilterProperties = this.getDefaultOptions();

    get ssps(): Array<Ssps | string> {
        return this.props.ssps;
    }

    set ssps(value: Array<Ssps | string>) {
        this.props.ssps = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isValid(): Observable<boolean> {
        if (!this.props.ssps.length) {
            return of(false);
        }

        return of(true);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    protected getDefaultOptions(): ISspFilterProperties {
        return {
            ...super.getDefaultOptions(),
            ssps: [],
        };
    }
}

function isSspFilter(filter: Filter): filter is SspFilter {
    return filter instanceof SspFilter;
}

export {SspFilter, ISspFilterProperties, isSspFilter};
