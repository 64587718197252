enum NumberFormat {
    Rate = '1.3-3',
    Percentage = '1.2-2',
    Number = '1.0-0',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    Decimal = '1.2-2',
    DynamicDecimal = '1.0-2',
    Numeric = '1.0-10',
    SingleDecimal = '1.1-1',
}

export {NumberFormat};
