import {SwitchInXxlBanner} from './switch-in-xxl-banner';
import {CampaignBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {BannerFileOrUrl, ICampaignBanner} from '../banner';

class CampaignSwitchInXxlBanner extends SwitchInXxlBanner implements
    ICampaignBanner,
    IMergable<CampaignBanner>,
    ICloneable<CampaignSwitchInXxlBanner> {

    private _campaignId: number;
    private _parentBannerId: number | null = null;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        bottomFileOrUrl: BannerFileOrUrl,
        leftFileOrUrl: BannerFileOrUrl,
        campaignId: number,
    ) {
        super(id, networkId, name, landingPage, bottomFileOrUrl, leftFileOrUrl);
        this._campaignId = campaignId;
    }

    get campaignId(): number {
        return this._campaignId;
    }

    set campaignId(value: number) {
        this._campaignId = value;
    }

    get parentBannerId(): number | null {
        return this._parentBannerId;
    }

    set parentBannerId(value: number | null) {
        this._parentBannerId = value;
    }

    public merge(source: CampaignBanner): void {
        super.merge(source);
        CampaignSwitchInXxlBanner.fillAdditionalFields(this, source);
    }

    public clone(): CampaignSwitchInXxlBanner {
        let bottomFileOrUrl: BannerFileOrUrl = '';
        if (this.bottomFile) {
            bottomFileOrUrl = this.bottomFile;
        } else if (this.bottomUrl) {
            bottomFileOrUrl = this.bottomUrl;
        }
        let leftFileOrUrl: BannerFileOrUrl = '';
        if (this.leftFile) {
            leftFileOrUrl = this.leftFile;
        } else if (this.leftUrl) {
            leftFileOrUrl = this.leftUrl;
        }

        const newModel: CampaignSwitchInXxlBanner = new CampaignSwitchInXxlBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            bottomFileOrUrl,
            leftFileOrUrl,
            this.campaignId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: CampaignSwitchInXxlBanner, source: CampaignBanner): void {
        SwitchInXxlBanner.fillAdditionalFields(target, source);

        target.campaignId = source.campaignId;
        target.parentBannerId = source.parentBannerId;
    }
}

export {CampaignSwitchInXxlBanner};
