import {BaseVastSettings, VastSettings, VastSettingsType} from './base-vast-settings';
import {IVideoVastSettings} from '../banner';
import {ICloneable, IMergable} from '../types';
import {VideoEncoding} from './video-encoding';

class VideoVastSettings extends BaseVastSettings
    implements IVideoVastSettings, ICloneable<VideoVastSettings>, IMergable<VideoVastSettings> {

    private readonly _type: VastSettingsType = VastSettingsType.Video;
    private _encodings: Array<VideoEncoding> = [];
    private _aspectRatioMaintained: boolean | undefined;
    private _scalable: boolean | undefined;
    private _skippable: boolean = false;

    public get type(): VastSettingsType {
        return this._type;
    }

    public get encodings(): Array<VideoEncoding> {
        return this._encodings;
    }

    public set encodings(value: Array<VideoEncoding>) {
        this._encodings = value;
    }

    public get aspectRatioMaintained(): boolean | undefined {
        return this._aspectRatioMaintained;
    }

    public set aspectRatioMaintained(value: boolean | undefined) {
        this._aspectRatioMaintained = value;
    }

    public get scalable(): boolean | undefined {
        return this._scalable;
    }

    public set scalable(value: boolean | undefined) {
        this._scalable = value;
    }

    public get skippable(): boolean {
        return this._skippable;
    }

    public set skippable(value: boolean) {
        this._skippable = value;
    }

    public merge(source: VastSettings): void {
        super.merge(source);
        VideoVastSettings.fillAdditionalFields(this, source);
    }

    public clone(): VideoVastSettings {
        const newModel: VideoVastSettings = new VideoVastSettings(
            this.id,
            this.networkId,
            this.assetId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: VideoVastSettings, source: VastSettings): void {
        BaseVastSettings.fillAdditionalFields(target, source);

        const encodings: Array<unknown> = source.encodings;
        target.encodings = encodings.reduce((result: Array<VideoEncoding>, encoding: unknown) => {
            if (encoding instanceof VideoEncoding) {
                result.push(encoding);
            }

            return result;
        }, []);

        if ('aspectRatioMaintained' in source) {
            target.aspectRatioMaintained = source.aspectRatioMaintained;
        }
        if ('scalable' in source) {
            target.scalable = source.scalable;
        }
        if ('skippable' in source) {
            target.skippable = source.skippable;
        }
    }
}

export {VideoVastSettings};
