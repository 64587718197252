import {ColDef, IRowNode} from '@ag-grid-community/core';

enum GridColumnType {
    Numeric = 'Numeric',
    Decimal = 'Decimal',
    CurrencyInCent = 'CurrencyInCent',
    Currency = 'Currency',
    Rate = 'Rate',
    Icon = 'Icon',
    ModifiableIcon = 'ModifiableIcon',
    Selection = 'Selection',
    Text = 'Text',
    Percent = 'Percent',
}

interface ICustomRowNode<T = unknown> extends IRowNode<T> {
    isDisabled?: boolean;
}

interface IColDef<TData = any, TValue = any> extends ColDef<TData, TValue> {
    colId: string;
}

interface ITableDefinitionContext {
    getColumnDefinitions(...args: Array<any>): Array<IColDef>;
}

export {IColDef, ITableDefinitionContext, GridColumnType, ICustomRowNode};
