import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {NameErrorCodes, NameValidator} from './name-validator';

@Injectable({
    providedIn: 'root',
})
export class OptionalNameValidator extends NameValidator {
    public validate(control: AbstractControl<string | null | undefined>): ValidationErrors | null {
        const validateResult: ValidationErrors | null = super.validate(control);

        if (validateResult && validateResult[NameErrorCodes.Required]) {
            return null;
        }

        return validateResult;
    }
}
