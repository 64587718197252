import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {getDefaultNamePattern} from '@active-agent/pattern';

@Injectable({
    providedIn: 'root',
})
class OptionalTextValidator implements Validator {
    public static readonly maxLength: number = 255;
    public static readonly pattern: RegExp = getDefaultNamePattern();

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<string | null | undefined>): ValidationErrors | null {
        const name: string | null | undefined = control.value;

        if (!name) {
            return null;
        }

        if (name.length > OptionalTextValidator.maxLength) {
            return {[OptionalTextErrorCodes.MaxLength]: this.getTranslationForErrorCode(OptionalTextErrorCodes.MaxLength)};
        }

        if (!OptionalTextValidator.pattern.test(name)) {
            return {[OptionalTextErrorCodes.Pattern]: this.getTranslationForErrorCode(OptionalTextErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: OptionalTextErrorCodes): string {
        const max: number = OptionalTextValidator.maxLength;
        const maxMessage: string = $localize`:@@VALIDATOR_NAME_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_NAME_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const patternMessage: string = $localize`:@@VALIDATOR_NAME_PATTERN_ERROR_MESSAGE:VALIDATOR_NAME_PATTERN_ERROR_MESSAGE`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        switch (code) {
            case OptionalTextErrorCodes.MaxLength:
                return maxMessage;
            case OptionalTextErrorCodes.Pattern:
                return patternMessage;
            default:
                return defaultMessage;
        }
    }
}

enum OptionalTextErrorCodes {
    MaxLength = '9a591b8a-a151-4c63-9659-ed4b436a60d2',
    Pattern = 'ece57786-b935-48be-aa4c-b3733689065b',
}

export {OptionalTextValidator, OptionalTextErrorCodes};
