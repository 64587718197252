enum DoohLocationCountryCode {
    Deu = 'DEU',
    Aut = 'AUT',
    Che = 'CHE',
    Are = 'ARE',
    Cze = 'CZE',
    Esp = 'ESP',
    Fra = 'FRA',
    Gbr = 'GBR',
    Irl = 'IRL',
    Lie = 'LIE',
    Nld = 'NLD',
    Pol = 'POL',
    Rus = 'RUS',
}

export {DoohLocationCountryCode};
