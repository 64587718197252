/* parser generated by jison 0.3.0 */
/**
 * Returns a Parser implementing JisonParserApi and a Lexer implementing JisonLexerApi.
 */
import { JisonParser, JisonParserApi, StateType, SymbolsType, TerminalsType, ProductionsType, o } from '@ts-jison/parser';const $V0=[1,4],$V1=[5,8];

export class JisonParserParser extends JisonParser implements JisonParserApi {
    $?: any;

    constructor (yy = {}, lexer = new JisonParserLexer(yy)) {
      super(yy, lexer);
    }

    symbols_: SymbolsType = {"error":2,"exp":3,"contents":4,"EOF":5,"VAR":6,"ACTIVE":7,"OR":8,"$accept":0,"$end":1};
    terminals_: TerminalsType = {2:"error",5:"EOF",6:"VAR",7:"ACTIVE",8:"OR"};
    productions_: ProductionsType = [0,[3,2],[3,1],[4,2],[4,3]];
    table: Array<StateType> = [{3:1,4:2,5:[1,3],6:$V0},{1:[3]},{5:[1,5],8:[1,6]},{1:[2,2]},{7:[1,7]},{1:[2,1]},{4:8,6:$V0},o($V1,[2,3]),o($V1,[2,4])];
    defaultActions: {[key:number]: any} = {3:[2,2],5:[2,1]};

    performAction (yytext:string, yyleng:number, yylineno:number, yy:any, yystate:number /* action[1] */, $$:any /* vstack */, _$:any /* lstack */): any {
/* this == yyval */
          var $0 = $$.length - 1;
        switch (yystate) {
case 1:
return '[' + $$[$0-1] + ']';
break;
case 2:
return '[]';
break;
case 3:
this.$ = '"'.concat($$[$0-1]).concat('"')
break;
case 4:
this.$ = $$[$0-2].concat(',').concat($$[$0])
break;
        }
    }
}


/* generated by ts-jison-lex 0.3.0 */
import { JisonLexer, JisonLexerApi } from '@ts-jison/lexer';
export class JisonParserLexer extends JisonLexer implements JisonLexerApi {
    options: any = {"moduleName":"JisonParser"};
    constructor (yy = {}) {
        super(yy);
    }

    rules: RegExp[] = [/^(?: = 1\b)/,/^(?: OR )/,/^(?: or )/,/^(?:[^ ]+)/,/^(?:$)/];
    conditions: any = {"INITIAL":{"rules":[0,1,2,3,4],"inclusive":true}}
    performAction (yy:any,yy_:any,$avoiding_name_collisions:any,YY_START:any): any {
          var YYSTATE=YY_START;
        switch($avoiding_name_collisions) {
    case 0:return 7;
      break;
    case 1:return 8;
      break;
    case 2:return 8;
      break;
    case 3:return 6;
      break;
    case 4:return 5;
      break;
        }
    }
}

