import {CommonsError} from '../../error/error';
import {VideoStartPositionFilterImportParser} from '@active-agent/jison';

enum VideoStartPosition {
    PreRoll = 'preRoll',
    MidRoll = 'midRoll',
    PostRoll = 'postRoll',
    InFeed = 'inFeed',
    InArticle = 'inArticle',
    InBanner = 'inBanner',
}

enum VideoPlacement {
    InStream = 'inStream',
    OutStream = 'outStream',
    Interstitial = 'interstitial',
}

interface IVideoPositionProfile {
    videoPlacement: VideoPlacement;
    startPositions: Array<VideoStartPosition>;
}

function parseVideoPositionProfile(rawData: string): Array<IVideoPositionProfile> {
    try {
        return new VideoStartPositionFilterImportParser().parse(rawData);
    } catch (error: unknown) {
        throw new CommonsError(
            'video position profile filter contains invalid syntax',
            {
                data: {rawData},
                cause: error,
            },
        );
    }
}

export {
    IVideoPositionProfile,
    VideoPlacement,
    VideoStartPosition,
    parseVideoPositionProfile,
};
