import {
    Component,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {
    AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormsModule, ReactiveFormsModule,
} from '@angular/forms';
import {CommonsError} from '@active-agent/types';
import {getUrlProtocolPattern} from '@active-agent/pattern';
import {MatFormFieldModule, SubscriptSizing} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {CommonModule} from '@angular/common';
import {CoreFormErrorMessageModule} from '@angular-clan/core';

@Component({
    selector: 'libs-url-input',
    templateUrl: './url-input.html',
    styleUrl: './url-input.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LibsUrlInputComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreFormErrorMessageModule,
    ],
})
class LibsUrlInputComponent implements ControlValueAccessor, OnInit {
    @Input() public label: string = '';
    @Input() public hint: string = '';
    /**
     * @deprecated Because formControlName input is not type safe please use formControl input instead.
     */
    @Input() public formControlName: string | null = null;
    @Input() public formControl: AbstractControl<string | null> | null = null;
    @Input() public isDisabled: boolean = false;
    @Input() public isReadOnly: boolean = false;
    @Input() public focusInitial: boolean = false;
    @Input() public isAgGridInlineEdit: boolean = false;
    @Input() public readonly maxLength: number = 0;
    @Output() public onBlur: EventEmitter<string | null> = new EventEmitter<string | null>();
    @ViewChild('urlInput', {read: ViewContainerRef, static: true}) public urlInput!: ViewContainerRef;
    public parentControl!: AbstractControl<string | null>;
    public isRequired: boolean = false;
    public readonly subscriptSizingFormField: SubscriptSizing = 'dynamic';
    public readonly protocolTypes: Array<string> = ['http://', 'https://'];
    public readonly urlProtocol: FormControl<string> = new FormControl(this.protocolTypes[1], {nonNullable: true});
    public readonly urlAddress: FormControl<string> = new FormControl('', {nonNullable: true});
    public urlProtocolPanelCssClasses: Array<string> = ['foo-bar'];
    private readonly urlProtocolPattern: RegExp = getUrlProtocolPattern();
    private onControlChange: ((url: string | null) => void) | undefined;
    private onControlTouched: (() => void) | undefined;

    constructor(
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer,
    ) {}

    public ngOnInit(): void {
        if (this.isAgGridInlineEdit) {
            /**
             * https://www.ag-grid.com/javascript-grid-filter-component/#custom-filters-containing-a-popup-element
             *
             * To prevent the ag grid popup on selection from being closed
             */
            this.urlProtocolPanelCssClasses.push('ag-custom-component-popup');
        }

        this.urlAddress.valueChanges.subscribe(this.onChange.bind(this));
        this.urlProtocol.valueChanges.subscribe(this.onChange.bind(this));
        if (this.focusInitial) {
            setTimeout(() => {
                this.urlInput.element.nativeElement.focus();
            });
        }

        if (this.controlContainer) {
            let control: AbstractControl<string | null> | null = null;

            if (this.formControl && this.controlContainer.control) {
                control = this.formControl;
            } else if (this.formControlName && this.controlContainer.control) {
                control = this.controlContainer.control.get(this.formControlName);
            } else {
                throw new CommonsError('formControl param has to be provided');
            }

            if (!control) {
                throw new CommonsError('formControl was not found');
            }

            this.parentControl = control;
            this.parentControl.statusChanges.subscribe(() => {
                this.urlAddress.setErrors(this.parentControl.errors);
            });
            if (this.parentControl.touched && this.parentControl.errors) {
                this.urlAddress.markAsTouched();
            }

            const emptyValueControl: AbstractControl<string | null> = {...control, value: null} as AbstractControl<string | null>;
            if (this.parentControl.validator && this.parentControl.validator(emptyValueControl) !== null) {
                this.isRequired = true;
            }
        } else {
            throw new CommonsError('Can\'t find parent FormGroup directive');
        }

        if (this.isReadOnly) {
            this.urlProtocol.disable();
        } else {
            this.urlProtocol.enable();
        }
    }

    public getMaxLength(): number {
        return this.maxLength - this.urlProtocol.value.length;
    }

    public registerOnChange(fn: (url: string | null) => void): void {
        this.onControlChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onControlTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled || this.isReadOnly) {
            this.urlProtocol.disable();
            this.urlAddress.disable();
        } else {
            this.urlProtocol.enable();
            this.urlAddress.enable();
        }
    }

    public writeValue(url: string | null): void {
        if (url === null) {
            return;
        }
        const matches: Array<string> = url.split(this.urlProtocolPattern);
        if (matches.length === 1) {
            this.urlProtocol.setValue(this.protocolTypes[1]);
            this.urlAddress.setValue(matches[0]);
        } else {
            this.urlProtocol.setValue(matches[1]);
            this.urlAddress.setValue(matches[2]);
        }
    }

    public onBlurHandler(): void {
        let url: string | null;
        if (!this.urlAddress.value || this.urlAddress.value.length === 0) {
            url = null;
        } else {
            url = `${this.urlProtocol.value}${this.urlAddress.value}`;
        }
        this.onBlur.emit(url);
        if (this.onControlTouched) {
            this.onControlTouched();
        }
        // needed to fix the field requirement in some cases
        if (!this.urlAddress.value) {
            this.urlAddress.setValue('');
        }
    }

    private onChange(): void {
        let url: string | null;

        if (!this.urlAddress.value || this.urlAddress.value.length === 0) {
            url = null;
        } else {
            // Handler if entire urls are copy pasted into the input (with protocol)
            const matches: Array<string> = this.urlAddress.value.split(this.urlProtocolPattern);
            if (matches.length === 3) {
                /**
                 * The setValue triggers another call to onChange().
                 * First we update the protocol and if the protocol is the same as the selected one
                 * we update the URL and strip away the protocol
                 */
                if (this.urlProtocol.value !== matches[1]) {
                    this.urlProtocol.setValue(matches[1]);
                } else {
                    this.urlAddress.setValue(matches[2]);
                }

                return;
            }
            url = `${this.urlProtocol.value}${matches.length === 3 ? matches[2] : this.urlAddress.value}`;
        }
        if (this.onControlChange && this.onControlTouched) {
            this.onControlChange(url);
            this.onControlTouched();
        }
    }
}

export {LibsUrlInputComponent};
