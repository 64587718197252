import {Banner, BaseBanner} from '../base-banner';
import {cloneDeep} from 'lodash-es';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {INativeBanner, INativeBannerFile} from '../banner';

class NativeBanner extends BaseBanner implements INativeBanner, ICloneable<NativeBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.NATIVE;
    private _ssl: boolean = false;
    private _clickUrl: string;
    private _trackingUrls: Array<string> = [];
    private _title: string;
    private _description: string | null = null;
    private _callToAction: string | null = null;
    private _price: string | null = null;
    private _sponsored: string | null = null;
    private _rating: number | null = null;
    private _imageFiles: Array<INativeBannerFile> = [];
    private _externalImageFiles: Array<INativeBannerFile> = [];
    private _iconFiles: Array<INativeBannerFile> = [];
    private _externalIconFiles: Array<INativeBannerFile> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        title: string,
        clickUrl: string,
    ) {
        super(id, networkId, name, landingPage);

        this._title = title;
        this._clickUrl = clickUrl;
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string {
        return this._clickUrl;
    }

    set clickUrl(value: string) {
        this._clickUrl = value;
    }

    get trackingUrls(): Array<string> {
        return this._trackingUrls;
    }

    set trackingUrls(value: Array<string>) {
        this._trackingUrls = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get callToAction(): string | null {
        return this._callToAction;
    }

    set callToAction(value: string | null) {
        this._callToAction = value;
    }

    get price(): string | null {
        return this._price;
    }

    set price(value: string | null) {
        this._price = value;
    }

    get sponsored(): string | null {
        return this._sponsored;
    }

    set sponsored(value: string | null) {
        this._sponsored = value;
    }

    get rating(): number | null {
        return this._rating;
    }

    set rating(value: number | null) {
        this._rating = value;
    }

    get imageFiles(): Array<INativeBannerFile> {
        return this._imageFiles;
    }

    set imageFiles(value: Array<INativeBannerFile>) {
        this._imageFiles = value;
    }

    get externalImageFiles(): Array<INativeBannerFile> {
        return this._externalImageFiles;
    }

    set externalImageFiles(value: Array<INativeBannerFile>) {
        this._externalImageFiles = value;
    }

    get iconFiles(): Array<INativeBannerFile> {
        return this._iconFiles;
    }

    set iconFiles(value: Array<INativeBannerFile>) {
        this._iconFiles = value;
    }

    get externalIconFiles(): Array<INativeBannerFile> {
        return this._externalIconFiles;
    }

    set externalIconFiles(value: Array<INativeBannerFile>) {
        this._externalIconFiles = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        NativeBanner.fillAdditionalFields(this, source);
    }

    public clone(): NativeBanner {
        const newModel: NativeBanner = new NativeBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.title,
            this.clickUrl,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: NativeBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('clickUrl' in source && source.clickUrl !== null) {
            target.clickUrl = source.clickUrl;
        }
        if ('trackingUrls' in source) {
            target.trackingUrls = cloneDeep(source.trackingUrls);
        }
        if ('title' in source) {
            target.title = source.title;
        }
        if ('description' in source) {
            target.description = source.description;
        }
        if ('callToAction' in source) {
            target.callToAction = source.callToAction;
        }
        if ('price' in source) {
            target.price = source.price;
        }
        if ('sponsored' in source) {
            target.sponsored = source.sponsored;
        }
        if ('rating' in source) {
            target.rating = source.rating;
        }
        if ('imageFiles' in source) {
            target.imageFiles = cloneDeep(source.imageFiles);
        }
        if ('iconFiles' in source) {
            target.iconFiles = cloneDeep(source.iconFiles);
        }
        if ('externalImageFiles' in source) {
            target.externalImageFiles = cloneDeep(source.externalImageFiles);
        }
        if ('externalIconFiles' in source) {
            target.externalIconFiles = cloneDeep(source.externalIconFiles);
        }
    }
}

export {NativeBanner};
