import {CommonsError} from '../../error/error';
import {VideoPlayerFilterType} from './video-player-filter-import';

const videoPlayerFilterOptions: Array<IVideoPlayerFilterOption> = [
    {
        id: VideoPlayerFilterType.IsSmallVideoPlayer,
        translation: $localize`:@@VIDEO_PLAYER_FILTER_OPTION_IS_SMALL_VIDEO_PLAYER:VIDEO_PLAYER_FILTER_OPTION_IS_SMALL_VIDEO_PLAYER`,
        description: $localize`:@@VIDEO_PLAYER_FILTER_OPTION_IS_SMALL_VIDEO_PLAYER_DESCRIPTION:VIDEO_PLAYER_FILTER_OPTION_IS_SMALL_VIDEO_PLAYER_DESCRIPTION`,
        active: true,
    },
    {
        id: VideoPlayerFilterType.IsMediumVideoPlayer,
        translation: $localize`:@@VIDEO_PLAYER_FILTER_OPTION_IS_MEDIUM_VIDEO_PLAYER:VIDEO_PLAYER_FILTER_OPTION_IS_MEDIUM_VIDEO_PLAYER`,
        description: $localize`:@@VIDEO_PLAYER_FILTER_OPTION_IS_MEDIUM_VIDEO_PLAYER_DESCRIPTION:VIDEO_PLAYER_FILTER_OPTION_IS_MEDIUM_VIDEO_PLAYER_DESCRIPTION`,
        active: true,
    },
    {
        id: VideoPlayerFilterType.IsLargeVideoPlayer,
        translation: $localize`:@@VIDEO_PLAYER_FILTER_OPTION_IS_LARGE_VIDEO_PLAYER:VIDEO_PLAYER_FILTER_OPTION_IS_LARGE_VIDEO_PLAYER`,
        description: $localize`:@@VIDEO_PLAYER_FILTER_OPTION_IS_LARGE_VIDEO_PLAYER_DESCRIPTION:VIDEO_PLAYER_FILTER_OPTION_IS_LARGE_VIDEO_PLAYER_DESCRIPTION`,
        active: true,
    },
];

interface IVideoPlayerFilterOption {
    id: VideoPlayerFilterType;
    active: boolean;
    translation: string;
    description: string;
}

function getVideoPlayerFilterOptionTranslation(videoPlayerType: VideoPlayerFilterType): string {
    const videoPlayerTypeFilterOption: IVideoPlayerFilterOption | undefined = videoPlayerFilterOptions
        .find((option: IVideoPlayerFilterOption) => option.id === videoPlayerType);

    if (!videoPlayerTypeFilterOption) {
        throw new CommonsError('Translation no found');
    }

    return videoPlayerTypeFilterOption.translation;
}

export {videoPlayerFilterOptions, IVideoPlayerFilterOption, getVideoPlayerFilterOptionTranslation};
