import {Injectable, InjectionToken, Injector, StaticProvider} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType} from '@angular/cdk/portal';

@Injectable()
class LibsOverlayService {
    constructor(
        public overlay: Overlay,
        private injector: Injector,
    ) {}

    public open<T, D = unknown>(component: ComponentType<T>, data: D, config: OverlayConfig = {}): OverlayRef {
        const overlayRef: OverlayRef = this.overlay.create(config);
        const injector: Injector = this.createInjector<D>(data, overlayRef);
        const componentPortal: ComponentPortal<T> = new ComponentPortal(
            component,
            null,
            injector,
        );

        overlayRef.attach(componentPortal);

        return overlayRef;
    }

    private createInjector<T>(data: T, overlayRef: OverlayRef): Injector {
        const providers: Array<StaticProvider> = [
            {provide: OVERLAY_DATA, useValue: data},
            {provide: OVERLAY_REF, useValue: overlayRef},
        ];

        return Injector.create({parent: this.injector, providers});
    }
}

const OVERLAY_DATA: InjectionToken<unknown> = new InjectionToken<unknown>('OverlayData');
const OVERLAY_REF: InjectionToken<unknown> = new InjectionToken<unknown>('OverlayRef');

export {LibsOverlayService, OVERLAY_DATA, OVERLAY_REF};
