import {IEncoding} from '../banner';

class Encoding implements IEncoding {
    private _type: string;
    private _bitRate: number;

    constructor(type: string, bitRate: number) {
        this._type = type;
        this._bitRate = bitRate;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get bitRate(): number {
        return this._bitRate;
    }

    set bitRate(value: number) {
        this._bitRate = value;
    }

    public toObject(): IEncoding {
        return {
            type: this.type,
            bitRate: this.bitRate,
        };
    }
}

export {Encoding};
