import {NgModule} from '@angular/core';
import {AdImageSelectionCropperComponent} from './image-selection-cropper.component';
import {AdCroppedPreviewModule} from '../preview/cropped-preview.module';
import {CoreInfoBoxComponent} from '@angular-clan/core/info-box';
import {CommonsInputRowComponent} from '../../input-row/input-row.component';
import {LibsSpacerModule} from '../../spacer/spacer.module';
import {CorePopupModule} from '@angular-clan/core/popup';
import {CoreNumberInputModule} from '@angular-clan/core';
import {AdAddCustomSizePopoverComponent} from './add-custom-size-popover.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    imports: [
        AdCroppedPreviewModule,
        MatListModule,
        MatButtonModule,
        LibsSpacerModule,
        MatIconModule,
        CoreInfoBoxComponent,
        CorePopupModule,
        CoreNumberInputModule,
        CommonsInputRowComponent,
        MatCheckboxModule,
        MatTooltipModule,
    ],
    exports: [
        AdImageSelectionCropperComponent,
        AdAddCustomSizePopoverComponent,
        AdCroppedPreviewModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        CorePopupModule,
        CoreNumberInputModule,
        CommonsInputRowComponent,
        MatCheckboxModule,
        MatTooltipModule,
    ],
    declarations: [
        AdImageSelectionCropperComponent,
        AdAddCustomSizePopoverComponent,
    ],
    providers: [],
})
class AdImageCropperModule {}

export {AdImageCropperModule};
