import {ThirdPartyFeaturePrices} from './third-party-feature-prices';
import {ViewabilityNetworkPrice} from './viewability-network-price';
import {ViewabilityTagType} from './viewability-tag-type';

export class ViewabilityPrices extends ThirdPartyFeaturePrices {
    private _defaultCpmByViewabilityTagType: Record<ViewabilityTagType, number> = {
        [ViewabilityTagType.Display]: 0,
        [ViewabilityTagType.Video]: 0,
    };
    private _networkCpmByViewabilityTagType: Array<ViewabilityNetworkPrice> = [];

    public get defaultCpmByViewabilityTagType(): Record<ViewabilityTagType, number> {
        return this._defaultCpmByViewabilityTagType;
    }

    public set defaultCpmByViewabilityTagType(value: Record<ViewabilityTagType, number>) {
        this._defaultCpmByViewabilityTagType = value;
    }

    public get networkCpmByViewabilityTagType(): Array<ViewabilityNetworkPrice> {
        return this._networkCpmByViewabilityTagType;
    }

    public set networkCpmByViewabilityTagType(value: Array<ViewabilityNetworkPrice>) {
        this._networkCpmByViewabilityTagType = value;
    }

    public clone(): ViewabilityPrices {
        const viewabilityPrices: ViewabilityPrices = new ViewabilityPrices();
        viewabilityPrices.defaultCpmByViewabilityTagType = {...this.defaultCpmByViewabilityTagType};
        viewabilityPrices.networkCpmByViewabilityTagType = this.networkCpmByViewabilityTagType.map(
            (networkPrice: ViewabilityNetworkPrice) => networkPrice.clone(),
        );

        return viewabilityPrices;
    }
}
