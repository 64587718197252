<div class="libs-dual-list">
    <libs-multiselect
        class="libs-dual-list__multiselect-left"
        *ngIf="!isReadOnly"
        [optionValueTemplate]="optionValueTemplate"
        [options]="optionsLeft"
        [label]="labelLeft"
        [searchFields]="searchFields"
        (onSelect)="moveOptionFromLeftToRight($event)"
        (onPreSelect)="preSelectFromLeftToRight($event)"
    ></libs-multiselect>

    <div *ngIf="!isReadOnly" class="libs-dual-list__buttons">
        <button
            class="libs-dual-list__arrow-right libs-dual-list-button"
            [class.libs-dual-list__arrow-right--disabled]="!preSelectedOptionsLeft.length"
            mat-icon-button
            (click)="onRightArrowClick()"
            i18n-matTooltip="@@DUAL_LIST_SELECTION_ADD_ITEMS_TOOLTIP_TITLE"
            matTooltip="DUAL_LIST_SELECTION_ADD_ITEMS_TOOLTIP_TITLE"
            matTooltipClass="tooltip"
            [disabled]="!preSelectedOptionsLeft.length"
        >
            <mat-icon class="libs-dual-list-button__arrow-down">keyboard_arrow_down</mat-icon>
            <mat-icon class="libs-dual-list-button__arrow-right">keyboard_arrow_right</mat-icon>
        </button>

        <button
            class="libs-dual-list__arrow-left libs-dual-list-button"
            [class.libs-dual-list__arrow-left--disabled]="!preSelectedOptionsRight.length"
            mat-icon-button
            (click)="onLeftArrowClick()"
            i18n-matTooltip="@@DUAL_LIST_SELECTION_REMOVE_ITEMS_TOOLTIP_TITLE"
            matTooltip="DUAL_LIST_SELECTION_REMOVE_ITEMS_TOOLTIP_TITLE"
            matTooltipClass="tooltip"
            [disabled]="!preSelectedOptionsRight.length"
        >
            <mat-icon class="libs-dual-list-button__arrow-up">keyboard_arrow_up</mat-icon>
            <mat-icon class="libs-dual-list-button__arrow-left">keyboard_arrow_left</mat-icon>
        </button>
    </div>

    <libs-multiselect
        class="libs-dual-list__multiselect-right"
        [optionValueTemplate]="optionValueTemplate"
        [options]="optionsRight"
        [label]="labelRight"
        [searchFields]="searchFields"
        [isReadOnly]="isReadOnly"
        (onSelect)="moveOptionFromRightToLeft($event)"
        (onPreSelect)="preSelectFromRightToLeft($event)"
    ></libs-multiselect>
</div>
