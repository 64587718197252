import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LibsAnnouncementHeaderComponent} from './announcement-header.component';
import {LibsWebsocketModule} from '@active-agent/websocket';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';

@NgModule({
    imports: [
        CommonModule,
        LibsWebsocketModule,
        CommonsStatusIconModule,
        MatButtonModule,
        MatToolbarModule,
    ],
    exports: [
        LibsAnnouncementHeaderComponent,
    ],
    declarations: [
        LibsAnnouncementHeaderComponent,
    ],
})
class LibsAnnouncementHeaderModule {}

export {LibsAnnouncementHeaderModule};
