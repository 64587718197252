import {NgModule} from '@angular/core';

import {AdCroppedPreviewComponent} from './cropped-preview.component';
import {CommonModule} from '@angular/common';
import {AdCroppedPreviewDialogComponent} from './cropped-preview-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
    ],
    exports: [
        CommonModule,
        AdCroppedPreviewComponent,
        MatDialogModule,
        MatButtonModule,
    ],
    declarations: [
        AdCroppedPreviewComponent,
        AdCroppedPreviewDialogComponent,
    ],
    providers: [],
})
class AdCroppedPreviewModule {}

export {AdCroppedPreviewModule};
