import {ICloneable, IMergable} from '../types';
import {AdvertiserMargin} from './advertiser-margin';
import {IOrderAdvertiserMargin, AdvertiserMarginComponent} from './advertister-margin.types';

class OrderAdvertiserMargin extends AdvertiserMargin implements
    IOrderAdvertiserMargin,
    IMergable<OrderAdvertiserMargin>,
    ICloneable<OrderAdvertiserMargin> {

    private _orderId: number;

    constructor(
        id: number | null,
        networkId: number,
        orderId: number,
        components: Array<AdvertiserMarginComponent>,
        margin: number,
    ) {
        super(id, networkId, components, margin);

        this._orderId = orderId;
    }

    get orderId(): number {
        return this._orderId;
    }

    set orderId(value: number) {
        this._orderId = value;
    }

    public merge(source: OrderAdvertiserMargin): void {
        super.merge(source);

        this.orderId = source.orderId;
    }

    public clone(): OrderAdvertiserMargin {
        const newModel: OrderAdvertiserMargin = new OrderAdvertiserMargin(
            this.id,
            this.networkId,
            this.orderId,
            this.components,
            this.margin,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {OrderAdvertiserMargin};
