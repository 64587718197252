import {NgModule} from '@angular/core';
import {CommonsOutdatedBrowserComponent} from './outdated-browser.component';
import {BrowserCompatibilityComponent} from '@angular-clan/core/browser-compatibility';

@NgModule({
    imports: [
        BrowserCompatibilityComponent,
    ],
    exports: [
        CommonsOutdatedBrowserComponent,
    ],
    declarations: [
        CommonsOutdatedBrowserComponent,
    ],
})
class CommonsOutdatedBrowserModule {}

export {CommonsOutdatedBrowserModule};
