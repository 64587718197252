import {ICloneable, IMergable} from '../types';

class ThirdPartyCost implements ICloneable<ThirdPartyCost>, IMergable<ThirdPartyCost> {

    private readonly _id: number | null;
    private _networkId: number;
    private _campaignIds: Array<number> = [];
    private _active: boolean = false;
    private _billable: boolean = false;
    private _name: string;
    private _description: string | null = null;
    private _cpm: number;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        cpm: number,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._name = name;
        this._cpm = cpm;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get campaignIds(): Array<number> {
        return this._campaignIds;
    }

    set campaignIds(value: Array<number>) {
        this._campaignIds = value;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get billable(): boolean {
        return this._billable;
    }

    set billable(value: boolean) {
        this._billable = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get cpm(): number {
        return this._cpm;
    }

    set cpm(value: number) {
        this._cpm = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: ThirdPartyCost): void {
        this.campaignIds = source.campaignIds.map((id: number) => id);
        this.active = source.active;
        this.billable = source.billable;
        this.name = source.name;
        this.cpm = source.cpm;
        this.description = source.description;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }

    public clone(): ThirdPartyCost {
        const newModel: ThirdPartyCost = new ThirdPartyCost(
            this.id,
            this.networkId,
            this.name,
            this.cpm,
        );
        newModel.merge(this);

        return newModel;
    }
}

interface IOnThirdPartyCostSaveEvent {
    thirdPartyCost: ThirdPartyCost;
}

export {ThirdPartyCost, IOnThirdPartyCostSaveEvent};
