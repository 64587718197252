import {BaseProfileFilter, IBaseProfileFilterProperties} from './base-profile-filter';
import {FilterTypes} from '../types/filter.types';

type ProfileFilterProperties = IBaseProfileFilterProperties;

class ProfileFilter extends BaseProfileFilter<FilterTypes.PROFILE> {
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _type: FilterTypes.PROFILE = FilterTypes.PROFILE;
    protected readonly props: ProfileFilterProperties = this.getDefaultOptions();

    protected getDefaultOptions(): ProfileFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

export {ProfileFilter, ProfileFilterProperties};
