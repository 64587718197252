import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class DailyClickValidator {
    public static readonly maxValue: number = 1_000_000_000;
    public static readonly minValue: number = 0;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const dailyClicks: number | undefined | null = control.value;

        if (dailyClicks === undefined || dailyClicks === null) {
            return null;
        }

        if (dailyClicks < DailyClickValidator.minValue) {
            return {[DailyClickErrorCodes.MinValue]: this.getTranslationForErrorCode(DailyClickErrorCodes.MinValue)};
        }

        if (dailyClicks > DailyClickValidator.maxValue) {
            return {[DailyClickErrorCodes.MaxValue]: this.getTranslationForErrorCode(DailyClickErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: DailyClickErrorCodes): string {
        const min: string = this.decimalPipe.transform(DailyClickValidator.minValue) || '';
        const minMessage: string = $localize`:@@VALIDATOR_DAILY_CLICK_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_DAILY_CLICK_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
        const max: string = this.decimalPipe.transform(DailyClickValidator.maxValue) || '';
        const maxMessage: string = $localize`:@@VALIDATOR_DAILY_CLICK_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_DAILY_CLICK_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;

        switch (code) {
            case DailyClickErrorCodes.MinValue:
                return minMessage;
            case DailyClickErrorCodes.MaxValue:
                return maxMessage;
            default:
                return defaultMessage;
        }
    }
}

enum DailyClickErrorCodes {
    Required = '45f7acb7-5bac-4e76-bd0b-f9a577f486a4',
    MinValue = 'f636426b-ac83-48a8-917a-41d800074674',
    MaxValue = '39577e07-242d-4cea-8e55-9d5826ef5021',
}

export {DailyClickValidator, DailyClickErrorCodes};
