import {Banner} from '@active-agent/types';

function formatBannerSize(banner: Banner): string {
    if (!('size' in banner)) {
        return '-';
    }

    return $localize`:@@SIZE_WITH_WIDTH_AND_HEIGHT:SIZE_WITH_WIDTH_AND_HEIGHT ${banner.size.width}:INTERPOLATION: ${banner.size.height}:INTERPOLATION_1:`;
}

export {formatBannerSize};
