import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({providedIn: 'root'})
class HumanReadableNumberService {
    private readonly englishSuffixes: Array<string> = ['', 'k', 'm', 'bn'];
    private readonly germanSuffixes: Array<string> = ['', 'k', 'Mio', 'Mrd'];

    constructor(private decimalPipe: DecimalPipe, @Inject(LOCALE_ID) private locale: string) {}

    public format(n: number, numberOfDecimals: number = 2): string {
        const suffixes: Array<string> = this.locale === 'de' ? this.germanSuffixes : this.englishSuffixes;

        const suffixIndex: number = Math.max(0,
            Math.min(
                suffixes.length - 1,
                Math.floor(
                    Math.log10(Math.abs(n)) / 3,
                ),
            ),
        );

        const newValue: number = n / Math.pow(10, 3 * suffixIndex);
        const truncated: number = Math.round(newValue * Math.pow(10, numberOfDecimals)) / Math.pow(10, numberOfDecimals);

        return `${this.decimalPipe.transform(truncated)} ${suffixes[suffixIndex]}`;
    }
}

export {HumanReadableNumberService};
