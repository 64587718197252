import {NgModule} from '@angular/core';
import {LibsConfirmationDialogComponent} from './confirmation-dialog.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';
import {CoreInfoBoxComponent} from '@angular-clan/core/info-box';

@NgModule({
    imports: [
        MatDialogModule,
        MatButtonModule,
        CommonModule,
        MatCheckboxModule,
        FormsModule,
        MatListModule,

        CommonsStatusIconModule,
        CoreInfoBoxComponent,
    ],
    exports: [
        LibsConfirmationDialogComponent,
    ],
    declarations: [
        LibsConfirmationDialogComponent,
    ],
})
class LibsConfirmationDialogModule {}

export {LibsConfirmationDialogModule};
