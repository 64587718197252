import {getTimeZonedNewDate} from '../timezone/time-zone-store';
import {isAfter, isBefore} from 'date-fns';

class DateHelper {
    /**
     * https://stackoverflow.com/a/17565646
     */
    public static humanDuration(secondsAmount: number): string {
        const seconds: number = Math.floor(secondsAmount % 60);

        secondsAmount /= 60;
        const minutes: number = Math.floor((secondsAmount % 60));

        secondsAmount /= 60;
        const hours: number = Math.floor((secondsAmount % 24));

        const hoursOutput: string = hours !== 0 ? `${normalizeTime(hours)}:` : '';

        return `${hoursOutput}${normalizeTime(minutes)}:${normalizeTime(seconds)}`;
    }
}

function isDateWithinRange(targetDate: Date, startDate: Date, endDate: Date): boolean {
    return isAfter(targetDate, startDate) && isBefore(targetDate, endDate);
}

function normalizeTime(value: number): string {
    return value.toString().padStart(2, '0');
}

function getLastMonday(): Date {
    const today: Date = getTimeZonedNewDate();
    today.setDate(today.getDate() - (today.getDay() + 6) % 7);

    return today;
}

function humanSeconds(seconds: number): string {
    return `${seconds}s`;
}

export {DateHelper, isDateWithinRange, getLastMonday, humanSeconds};
