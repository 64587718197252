import {ThirdPartyFeatureType} from '../third-party-feature/third-party-feature-type';
import {Campaign} from './rtb-campaign';
import {CampaignTypes} from './campaign-types';
import {ICloneable, IMergable} from '../types';
import {IRedirectCampaign} from './campaign';
import {IRuntime} from '../runtime/runtime';

class RedirectCampaign implements IRedirectCampaign, IMergable<Campaign>, ICloneable<RedirectCampaign> {
    private readonly _id: number | null = null;
    private _active: boolean = false;
    private _networkId: number;
    private _name: string;
    private _orderId: number;
    private _bannerIds: Array<number> = [];
    private _filterIds: Array<string> = [];
    private _runtime: IRuntime;
    private _trackingPageIds: Array<number> = [];
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;
    private _cpc: number | null = null;
    private _cpm: number | null = null;
    private _totalBudget: number | null = null;
    private _totalClicks: number | null = null;
    private _totalImpressions: number | null = null;
    private _remainingRuntimeDays: number | undefined;
    private _runtimePercentage: number | undefined;
    private _dsaPaid: string | null = null;
    private _dsaBehalf: string | null = null;
    private _activeThirdPartyFeatures: Array<ThirdPartyFeatureType> = [];

    constructor(
        id: number | null,
        orderId: number,
        networkId: number,
        name: string,
        runtime: IRuntime,
    ) {
        this._id = id;
        this._orderId = orderId;
        this._networkId = networkId;
        this._name = name;
        this._runtime = runtime;
    }

    get id(): number | null {
        return this._id;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get orderId(): number {
        return this._orderId;
    }

    set orderId(value: number) {
        this._orderId = value;
    }

    get bannerIds(): Array<number> {
        return this._bannerIds;
    }

    set bannerIds(value: Array<number>) {
        this._bannerIds = value;
    }

    get filterIds(): Array<string> {
        return this._filterIds;
    }

    set filterIds(value: Array<string>) {
        this._filterIds = value;
    }

    get runtime(): IRuntime {
        return this._runtime;
    }

    set runtime(value: IRuntime) {
        this._runtime = value;
    }

    get trackingPageIds(): Array<number> {
        return this._trackingPageIds;
    }

    set trackingPageIds(value: Array<number>) {
        this._trackingPageIds = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get type(): CampaignTypes {
        return CampaignTypes.Redirect;
    }

    get remainingRuntimeDays(): number | undefined {
        return this._remainingRuntimeDays;
    }

    set remainingRuntimeDays(value: number | undefined) {
        this._remainingRuntimeDays = value;
    }

    get runtimePercentage(): number | undefined {
        return this._runtimePercentage;
    }

    set runtimePercentage(value: number | undefined) {
        this._runtimePercentage = value;
    }

    set cpc(value: number | null) {
        this._cpc = value;
    }

    get cpc(): number | null {
        return this._cpc;
    }

    set cpm(value: number | null) {
        this._cpm = value;
    }

    get cpm(): number | null {
        return this._cpm;
    }

    set totalBudget(value: number | null) {
        this._totalBudget = value;
    }

    get totalBudget(): number | null {
        return this._totalBudget;
    }

    set totalClicks(value: number | null) {
        this._totalClicks = value;
    }

    get totalClicks(): number | null {
        return this._totalClicks;
    }

    set totalImpressions(value: number | null) {
        this._totalImpressions = value;
    }

    get totalImpressions(): number | null {
        return this._totalImpressions;
    }

    get dsaPaid(): string | null {
        return this._dsaPaid;
    }

    set dsaPaid(value: string | null) {
        this._dsaPaid = value;
    }

    get dsaBehalf(): string | null {
        return this._dsaBehalf;
    }

    set dsaBehalf(value: string | null) {
        this._dsaBehalf = value;
    }

    get activeThirdPartyFeatures(): Array<ThirdPartyFeatureType> {
        return this._activeThirdPartyFeatures;
    }

    set activeThirdPartyFeatures(thirdPartyFeatures: Array<ThirdPartyFeatureType>) {
        this._activeThirdPartyFeatures = thirdPartyFeatures;
    }

    public merge(source: Campaign): void {
        RedirectCampaign.fillAdditionalFields(this, source);
    }

    public clone(): RedirectCampaign {
        const newModel: RedirectCampaign = new RedirectCampaign(
            this.id,
            this.orderId,
            this.networkId,
            this.name,
            this.runtime,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: RedirectCampaign, source: Campaign): void {
        target.active = source.active;
        target.bannerIds = source.bannerIds.map((id: number) => id);
        target.deleted = source.deleted;
        target.filterIds = source.filterIds.map((id: string) => id);
        target.lastUpdate = source.lastUpdate;
        target.trackingPageIds = source.trackingPageIds.map((id: number) => id);
        target.name = source.name;
        target.dsaPaid = source.dsaPaid;
        target.dsaBehalf = source.dsaBehalf;
        target.activeThirdPartyFeatures = source.activeThirdPartyFeatures;

        if (isRedirectCampaign(source)) {
            target.runtime = {...source.runtime};
            target.cpc = source.cpc;
            target.cpm = source.cpm;
            target.totalBudget = source.totalBudget;
            target.totalClicks = source.totalClicks;
            target.totalImpressions = source.totalImpressions;
        }
    }

    public isThirdPartyFeatureActive(thirdPartyFeature: ThirdPartyFeatureType): boolean {
        return this.activeThirdPartyFeatures.includes(thirdPartyFeature);
    }
}

function isRedirectCampaign(campaign: unknown): campaign is RedirectCampaign {
    return campaign instanceof RedirectCampaign;
}

export {RedirectCampaign, isRedirectCampaign};
