import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class AttributeMaxHistoryEntriesValidator {
    public static readonly maxValue: number = 10;
    public static readonly minValue: number = 1;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const maxHistoryEntries: number = control.value;

        if (maxHistoryEntries === undefined || maxHistoryEntries === null) {
            return {[MaxHistoryEntriesErrorCodes.Required]: this.getTranslationForErrorCode(MaxHistoryEntriesErrorCodes.Required)};
        }

        if (maxHistoryEntries < AttributeMaxHistoryEntriesValidator.minValue) {
            return {[MaxHistoryEntriesErrorCodes.MinValue]: this.getTranslationForErrorCode(MaxHistoryEntriesErrorCodes.MinValue)};
        }

        if (maxHistoryEntries > AttributeMaxHistoryEntriesValidator.maxValue) {
            return {[MaxHistoryEntriesErrorCodes.MaxValue]: this.getTranslationForErrorCode(MaxHistoryEntriesErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: MaxHistoryEntriesErrorCodes): string {
        switch (code) {
            case MaxHistoryEntriesErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case MaxHistoryEntriesErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_MAX_HISTORY_ENTRIES_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_HISTORY_ENTRIES_MIN_VALUE_ERROR_MESSAGE ${this.decimalPipe.transform(AttributeMaxHistoryEntriesValidator.minValue)}:INTERPOLATION:`;
            case MaxHistoryEntriesErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_MAX_HISTORY_ENTRIES_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_HISTORY_ENTRIES_MAX_VALUE_ERROR_MESSAGE ${this.decimalPipe.transform(AttributeMaxHistoryEntriesValidator.maxValue)}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum MaxHistoryEntriesErrorCodes {
    Required = '96a53719-0597-4ec8-aa64-f3b1bf212a2f',
    MinValue = '6c2cf044-6b50-41f5-af68-e36ef656b261',
    MaxValue = '8548523e-bf17-43e3-9d99-5e611724b6d5',
}

export {AttributeMaxHistoryEntriesValidator};
