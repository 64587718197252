import {RichMediaBanner} from './rich-media-banner';
import {BannerPoolBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {IBannerPoolBanner, IBannerSize} from '../banner';

class BannerPoolRichMediaBanner extends RichMediaBanner implements
    IBannerPoolBanner,
    IMergable<BannerPoolBanner>,
    ICloneable<BannerPoolRichMediaBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        size: IBannerSize,
        bannerPoolId: number,
    ) {
        super(id, networkId, name, landingPage, size);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolRichMediaBanner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolRichMediaBanner {
        const newModel: BannerPoolRichMediaBanner = new BannerPoolRichMediaBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.size,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolRichMediaBanner, source: BannerPoolBanner): void {
        RichMediaBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolRichMediaBanner};
