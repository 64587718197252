import {NgClass} from '@angular/common';
import {Component, InputSignal, input} from '@angular/core';

@Component({
    selector: 'libs-card',
    templateUrl: './card.html',
    styleUrl: './card.scss',
    standalone: true,
    imports: [
        NgClass,
    ],
})
class LibsCardComponent {
    public noMargin: InputSignal<boolean> = input<boolean>(false);
}

export {LibsCardComponent};
