import {IMaxValueTooltip, TransformViewValueCallback} from '@active-agent/types';
import {CoreCountUpModule} from '@angular-clan/core';
import {CommonModule} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
    selector: 'libs-pacing-bar',
    templateUrl: './pacing-bar.html',
    styleUrls: ['./pacing-bar.scss'],
    standalone: true,
    imports: [
        MatProgressBarModule,
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        CoreCountUpModule,
        MatProgressSpinnerModule,
    ],
})
class LibsPacingBarComponent implements OnChanges {
    @Input() public currentValue: number | undefined | null;
    @Input() public maxValue: number | undefined | null;
    @Input() public optimalPace: number | undefined;
    @Input() public optimalPaceTooltip: string | undefined;
    @Input() public maxValueTooltip: IMaxValueTooltip | undefined | null;
    @Input() public isDisabled: boolean = false;
    @Input() public isLoading: boolean = true;

    @Input() public transformViewValueCallback: TransformViewValueCallback | undefined;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.optimalPace && changes.optimalPace.currentValue !== undefined) {
            if (changes.optimalPace.currentValue < 0) {
                this.optimalPace = 0;
            } else if (changes.optimalPace.currentValue > 100) {
                this.optimalPace = 100;
            } else {
                this.optimalPace = changes.optimalPace.currentValue;
            }
        }
    }

    public getProgressValue(): number {
        if (!this.maxValue || !this.currentValue) {
            return 0;
        }

        return Math.trunc((this.currentValue / this.maxValue) * 100);
    }

    public isProgressBarDisabled(): boolean {
        return this.isDisabled || this.maxValue === undefined;
    }

    public getTransformedViewValue(value: number | undefined | null): string {
        return this.transformViewValueCallback ? this.transformViewValueCallback(value) : String(value);
    }

    public getOptimalPaceTooltip(): string {
        return this.optimalPaceTooltip ? this.optimalPaceTooltip : '';
    }

    public isOptimalPaceAvailable(): boolean {
        return this.optimalPace !== undefined;
    }

    public getMaxValueTooltip(): string {
        return this.maxValueTooltip ? this.maxValueTooltip.message : '';
    }

    public getMaxValueTooltipIcon(): string {
        return this.maxValueTooltip && this.maxValueTooltip.icon ? this.maxValueTooltip.icon : 'help_outline';
    }

    public isErrorTooltip(): boolean {
        return this.maxValueTooltip ? this.maxValueTooltip.isErrorTooltip === true : false;
    }

    public isRaisedTooltip(): boolean {
        return this.maxValueTooltip ? this.maxValueTooltip.isRaised === true : false;
    }
}

export {LibsPacingBarComponent};
