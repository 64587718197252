import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class FootfallConversionTimeUnitValidator implements Validator {
    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return getValidationError(FootfallConversionTimeUnitErrorCode.Required);
        }

        return null;
    }
}

function getValidationError(code: FootfallConversionTimeUnitErrorCode): ValidationErrors {
    return {[code]: getTranslationForErrorCode(code)};
}

function getTranslationForErrorCode(code: FootfallConversionTimeUnitErrorCode): string {
    switch (code) {
        case FootfallConversionTimeUnitErrorCode.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

const enum FootfallConversionTimeUnitErrorCode {
    Required = '1603f21e-4507-4886-a631-14790a60b8f7',
}

export {FootfallConversionTimeUnitValidator};
