<commons-list-item [borderStyle]="borderStyle">
    <div
        class="no-items-available-item"
        [class.no-items-available-item--align-left]="textAlign === TextAlign.Left"
        [class.no-items-available-item--align-center]="textAlign === TextAlign.Center"
        [class.no-items-available-item--align-right]="textAlign === TextAlign.Right"
    >
        {{message}}
    </div>
</commons-list-item>
