import {Language} from '@active-agent/types';

enum WebsocketEventType {
    Ping = 'Ping',
    Announcement = 'Announcement',
}

type IAnnouncementMessage = Record<Language, string>;
interface IWebsocketAnnouncementEventData {
    type: WebsocketEventType.Announcement;
    data: IAnnouncementMessage;
}

interface IWebsocketPingEventData {
    type: WebsocketEventType.Ping;
    data: string;
}

type IWebsocketEventData = IWebsocketAnnouncementEventData | IWebsocketPingEventData;

export {
    IWebsocketEventData,
    IWebsocketPingEventData,
    IWebsocketAnnouncementEventData,
    IAnnouncementMessage,
    WebsocketEventType,
};

