import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {getDefaultNamePattern} from '@active-agent/pattern';

@Injectable({
    providedIn: 'root',
})
class NameValidator implements Validator {
    public static readonly maxLength: number = 255;
    public static readonly pattern: RegExp = getDefaultNamePattern();

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<string | null | undefined>): ValidationErrors | null {
        const name: string | null | undefined = control.value;

        if (name === undefined || name === null || name.length === 0) {
            return {[NameErrorCodes.Required]: this.getTranslationForErrorCode(NameErrorCodes.Required)};
        }

        if (name.length > NameValidator.maxLength) {
            return {[NameErrorCodes.MaxLength]: this.getTranslationForErrorCode(NameErrorCodes.MaxLength)};
        }

        if (!NameValidator.pattern.test(name)) {
            return {[NameErrorCodes.Pattern]: this.getTranslationForErrorCode(NameErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: NameErrorCodes): string {
        const requiredMessage: string = $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        const max: number = NameValidator.maxLength;
        const maxMessage: string = $localize`:@@VALIDATOR_NAME_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_NAME_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const patternMessage: string = $localize`:@@VALIDATOR_NAME_PATTERN_ERROR_MESSAGE:VALIDATOR_NAME_PATTERN_ERROR_MESSAGE`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        switch (code) {
            case NameErrorCodes.Required:
                return requiredMessage;
            case NameErrorCodes.MaxLength:
                return maxMessage;
            case NameErrorCodes.Pattern:
                return patternMessage;
            default:
                return defaultMessage;
        }
    }
}

enum NameErrorCodes {
    Required = 'd97ae413-1ff0-418f-b59b-1408cd2a6b63',
    MaxLength = '5632e53c-a85a-4885-851a-1cf8ace339da',
    Pattern = '5f43f3e3-f3f6-4b6f-9929-68ef63b0f2dc',
}

export {NameValidator, NameErrorCodes};
