import {
    ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, Signal, computed, input, output,
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {LibsCardComponent} from '../card/card.component';
import {LibsFilterPriceComponent} from '@active-agent/filter-price';

@Component({
    selector: 'libs-info-card',
    templateUrl: './info-card.html',
    styleUrl: './info-card.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        LibsCardComponent,
        MatIconModule,
        LibsFilterPriceComponent,
    ],
})
class InfoCardComponent {
    public selected: InputSignal<boolean> = input(false);
    public disabled: InputSignal<boolean> = input(false);
    public title: InputSignal<string> = input('');
    public description: InputSignal<string> = input('');
    public pricePrefix: InputSignal<string> = input('');
    public price: InputSignal<number | null | undefined> = input<number | null | undefined>(null);
    public priceStyle: Signal<Partial<Record<string, string>>> = computed(() => (this.selected() ?
        {color: '#91763F',backgroundColor: '#FEFAE9'} : {}
    ));

    public onClick: OutputEmitterRef<void> = output<void>();

    public handleClick(): void {
        if (this.disabled()) {
            return;
        }
        this.onClick.emit();
    }
}

export {InfoCardComponent};
