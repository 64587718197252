import {CommonsError, IIcon} from '@active-agent/types';
import {NgIf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
    selector: 'ad-empty-page-info',
    templateUrl: './empty-page-info.html',
    styleUrls: ['./empty-page-info.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
    ],
})
class AdEmptyPageInfoComponent implements OnInit {
    @Input() public hasPermission: boolean = false;
    @Input() public icon: IIcon | null = null;
    @Input() public header: string = '';
    @Input() public description: string = '';

    public ngOnInit(): void {
        if (!this.icon) {
            throw new CommonsError('icon component binding has to be provided');
        }
    }
}

export {AdEmptyPageInfoComponent};
