import {ISeat} from './seat.types';
import {Ssps} from '../ssp/ssps';
import {ICloneable, IMergable} from '../types';

class Seat implements ISeat, IMergable<Seat>, ICloneable<Seat> {
    private readonly _id: number | null;
    private _companyId: number;
    private _networkId: number;
    private _name: string;
    private _seatId: string;
    private _ssp: Ssps;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        companyId: number,
        networkId: number,
        name: string,
        seatId: string,
        ssp: Ssps,
    ) {
        this._id = id;
        this._companyId = companyId;
        this._networkId = networkId;
        this._name = name;
        this._seatId = seatId;
        this._ssp = ssp;
    }

    get id(): number | null {
        return this._id;
    }

    get companyId(): number {
        return this._companyId;
    }

    set companyId(value: number) {
        this._companyId = value;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get seatId(): string {
        return this._seatId;
    }

    set seatId(value: string) {
        this._seatId = value;
    }

    get ssp(): Ssps {
        return this._ssp;
    }

    set ssp(value: Ssps) {
        this._ssp = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: Seat): void {
        this.companyId = source.companyId;
        this.networkId = source.networkId;
        this.name = source.name;
        this.seatId = source.seatId;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }

    public clone(): Seat {
        const newModel: Seat = new Seat(
            this.id,
            this.companyId,
            this.networkId,
            this.name,
            this.seatId,
            this.ssp,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {Seat};
