import {
    IFilterRoutesConfiguration,
    IFilterDashboardRouteData,
    IFilterSettingsRouteData,
} from '@active-agent/filter-routes';
import {getUrlSuffixForFilterType} from '@active-agent/filter-translation';
import {IRouterHandlerData} from '@active-agent/types';
import {AppPath} from '../../../commons/enums';

const filterRoutesConfiguration: IFilterRoutesConfiguration = {
    dashboard: {
        getDataForLink(data: IFilterDashboardRouteData, networkId: number): IRouterHandlerData {
            const businessObject: Array<string | number> = getBusinessObject(data, networkId);

            return {
                commands: [...businessObject, AppPath.Targeting],
            };
        },
    },
    settingsFilter: {
        getDataForLink(data: IFilterSettingsRouteData, networkId: number): IRouterHandlerData {
            const businessObject: Array<string | number> = getBusinessObject(data, networkId);
            const typeSuffix: string = getUrlSuffixForFilterType(data.type);

            return {
                commands: [...businessObject, AppPath.Targeting, typeSuffix],
            };
        },
    },
    createFilter: {
        getDataForLink(data: IFilterSettingsRouteData, networkId: number): IRouterHandlerData {
            const businessObject: Array<string | number> = getBusinessObject(data, networkId);
            const typeSuffix: string = getUrlSuffixForFilterType(data.type);

            return {
                commands: [...businessObject, AppPath.Targeting, typeSuffix, AppPath.Add],
            };
        },
    },
};

function getBusinessObject(
    data: IFilterDashboardRouteData | IFilterSettingsRouteData,
    networkId: number,
): Array<string | number> {
    return data.banner ?
        [networkId, AppPath.Banner, data.banner.id || 0]
        : [networkId, AppPath.Campaign, data.campaign.id || 0];
}

export {filterRoutesConfiguration};
