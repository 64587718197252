import {CommonsError} from '../../error/error';
import {groupBy} from 'lodash-es';
import {IContentCategory} from './content-category-reader';
import {ContentCategoryFilterImportParser} from '@active-agent/jison';

interface IContentCategoryFilterImportResult {
    inverted: boolean;
    iabList: Array<IContentCategory>;
}

function parseContentCategoryProfile(rawData: string, iabMap: Map<string, IContentCategory>): IContentCategoryFilterImportResult {
    try {
        const parserObject: {profileVariables: Array<string>; isInverted: boolean} = new ContentCategoryFilterImportParser().parse(rawData);
        const iabList: Array<IContentCategory> = parserObject.profileVariables
            .reduce((list: Array<IContentCategory>, item: string) => {
                const obj: IContentCategory | undefined = iabMap.get(item);
                if (obj) {
                    list.push(obj);
                } else {
                    throw new CommonsError('Iab category not found', {data: {item}});
                }

                return list;
            }, []);

        return {
            iabList,
            inverted: parserObject.isInverted,
        };
    } catch (error: unknown) {
        throw new CommonsError(
            'content category profile filter contains invalid syntax',
            {
                data: {rawData},
                cause: error,
            },
        );
    }
}

function getGrouped(iabMap: Map<string, IContentCategory>): INumericDictionary<Array<IContentCategory>> {
    return groupBy<IContentCategory>(Array.from(iabMap.values()), 'groupId');
}

function getGroupedList(iabList: Array<IContentCategory>, iabMap: Map<string, IContentCategory>): Array<IContentCategoryGroup> {
    const groupedItems: INumericDictionary<Array<IContentCategory>> = getGrouped(iabMap);

    return iabList.reduce((list: Array<IContentCategoryGroup>, item: IContentCategory): Array<IContentCategoryGroup> => {
        let group: IContentCategoryGroup | undefined = list.find((groupEntry: IContentCategoryGroup): boolean => {
            return groupEntry.id === item.groupId;
        });

        if (!group) {
            group = {
                id: item.groupId,
                translation: groupedItems[item.groupId][0].translation,
                items: [],
            };
            list.push(group);
        }

        group.items.push(item);

        return list;
    }, []);
}

interface IContentCategoryGroup {
    id: number;
    translation: string;
    items: Array<IContentCategory>;
}
interface INumericDictionary<T> {
    [index: number]: T;
}

export {parseContentCategoryProfile, getGroupedList, IContentCategoryFilterImportResult, IContentCategoryGroup};
