import {MatDateFormats, MAT_NATIVE_DATE_FORMATS} from '@angular/material/core';

/**
 * https://stackoverflow.com/a/59901724/3303652
 *
 * The dateInput is configured by the ECMAScript Internationalization API
 * https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 */
const DATEPICKER_DATE_FORMATS: MatDateFormats = {
    ...MAT_NATIVE_DATE_FORMATS,
    display: {
        ...MAT_NATIVE_DATE_FORMATS.display,
        dateInput: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        } as Intl.DateTimeFormatOptions,
    },
};

export {DATEPICKER_DATE_FORMATS};
