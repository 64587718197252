import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {MaxCpcErrorCodes, RequiredMaxCpcValidator} from './required-max-cpc-validator';

@Injectable({
    providedIn: 'root',
})
class OptionalMaxCpcValidator extends RequiredMaxCpcValidator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const validateResult: ValidationErrors | null = super.validate(control);

        if (validateResult && validateResult[MaxCpcErrorCodes.Required]) {
            return null;
        }

        return validateResult;
    }
}

export {OptionalMaxCpcValidator};
