import {Company} from '../company/company';
import {ICloneable, IMergable} from '../types';

class Advertiser extends Company implements IMergable<Advertiser>, ICloneable<Advertiser> {
    constructor(
        id: number | null,
        networkId: number,
        name: string,
        domain: string,
    ) {
        super(id, networkId, name, domain);

        this._isClient = true;
    }

    public clone(): Advertiser {
        const newModel: Advertiser = new Advertiser(
            this.id,
            this.networkId,
            this.name,
            this.domain,
        );
        newModel.merge(this);

        return newModel;
    }

    public merge(source: Advertiser): void {
        super.merge(source);

        this.isAgency = source.isAgency;
    }

    get isAgency(): boolean {
        return this._isAgency;
    }

    set isAgency(value: boolean) {
        this._isAgency = value;
    }

    get isClient(): boolean {
        return this._isClient;
    }

}

export {Advertiser};
