import {Asset, AssetType, BaseAsset} from './base-asset';
import {IVideoAsset} from './asset.types';
import {ICloneable, IMergable} from '../types';

class VideoAsset extends BaseAsset implements IVideoAsset, IMergable<Asset>, ICloneable<VideoAsset> {
    private readonly _type: AssetType = AssetType.Video;
    private _height: number | undefined;
    private _width: number | undefined;

    constructor(
        id: number | null,
        networkId: number,
    ) {
        super(id, networkId);
    }

    public get type(): AssetType {
        return this._type;
    }

    public get height(): number | undefined {
        return this._height;
    }

    public set height(value: number | undefined) {
        this._height = value;
    }

    public get width(): number | undefined {
        return this._width;
    }

    public set width(value: number | undefined) {
        this._width = value;
    }

    public merge(source: Asset): void {
        VideoAsset.fillAdditionalFields(this, source);
    }

    public clone(): VideoAsset {
        const newModel: VideoAsset = new VideoAsset(
            this.id,
            this.networkId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: VideoAsset, source: Asset): void {
        BaseAsset.fillAdditionalFields(target, source);

        if ('width' in source) {
            target.width = source.width;
        }
        if ('height' in source) {
            target.height = source.height;
        }
    }
}

export {VideoAsset};
