import {IBannerSize} from '../banner/banner';
import {BannerTypes} from '../banner/banner-types';

enum UniqueImpressionsReportDimensions {
    AgencyId = 'AGENCY_ID',
    AgencyName = 'AGENCY_NAME',
    AdvertiserId = 'ADVERTISER_ID',
    AdvertiserName = 'ADVERTISER_NAME',
    NetworkId = 'NETWORK_ID',
    NetworkName = 'NETWORK_NAME',
    OrderId = 'ORDER_ID',
    OrderName = 'ORDER_NAME',
    CampaignId = 'CAMPAIGN_ID',
    CampaignName = 'CAMPAIGN_NAME',
    BannerId = 'BANNER_ID',
    BannerName = 'BANNER_NAME',
    BannerSize = 'BANNER_SIZE',
    BannerType = 'BANNER_TYPE',
    Date = 'DATE',
    Month = 'MONTH',
    Day = 'DAY',
    Deal = 'DEAL',
    DealName = 'DEAL_NAME',
}

enum UniqueImpressionsReportMetrics {
    Count = 'COUNT',
    WonCount = 'WON_COUNT',
    WinRate = 'WIN_RATE',
    Contacts = 'CONTACTS',
    BidCpm = 'BID_CPM',
    BidSum = 'BID_SUM',
    AuctionPriceSum = 'AUCTION_PRICE_SUM',
    Ecpm = 'ECPM',
    AdvertiserFee = 'ADVERTISER_FEE',
    TechFee = 'TECH_FEE',
    ThirdPartyCosts = 'THIRD_PARTY_COSTS',
    PrebidCosts = 'PREBID_COSTS',
    AdsquareCosts = 'ADSQUARE_COSTS',
    IasCosts = 'IAS_COSTS',
    TaggingCosts = 'TAGGING_COSTS',
    NetCost = 'NET_COST',
    UniqueImpressionsCount = 'UNIQUE_IMPRESSIONS_COUNT',
    UniqueImpressionsCookieId = 'UNIQUE_IMPRESSIONS_COOKIE_ID',
    UniqueImpressionsNetId = 'UNIQUE_IMPRESSIONS_NET_ID',
    UniqueImpressionsMaid = 'UNIQUE_IMPRESSIONS_MAID',
    UniqueImpressionsCtv = 'UNIQUE_IMPRESSIONS_CTV',
    UniqueImpressionsDforceId = 'UNIQUE_IMPRESSIONS_DFORCE_ID',
    UniqueImpressionsSomAddressableTvId = 'UNIQUE_IMPRESSIONS_SOM_ADDRESSABLE_TV_ID',
    UniqueImpressionsId5 = 'UNIQUE_IMPRESSIONS_ID5',
    UniqueImpressionsListenerId = 'UNIQUE_IMPRESSIONS_LISTENER_ID',
    UniqueImpressionsIdfv = 'UNIQUE_IMPRESSIONS_IDFV',
    UniqueImpressionsSalesforceId = 'UNIQUE_IMPRESSIONS_SALESFORCE_ID',
    UniqueImpressionsWithoutId = 'IMPRESSIONS_WITHOUT_ID',
    UniqueImpressionsNitroId = 'UNIQUE_IMPRESSIONS_NITRO_ID',
}

// eslint-disable-next-line @typescript-eslint/typedef
const UniqueImpressionsReportField = {...UniqueImpressionsReportDimensions, ...UniqueImpressionsReportMetrics};

type UniqueImpressionsReportFieldType = UniqueImpressionsReportDimensions | UniqueImpressionsReportMetrics;

interface IUniqueImpressionsReportResult {
    // dimensions
    [UniqueImpressionsReportField.NetworkId]?: number;
    [UniqueImpressionsReportField.AdvertiserId]?: number;
    [UniqueImpressionsReportField.AgencyId]?: number;
    [UniqueImpressionsReportField.OrderId]?: number;
    [UniqueImpressionsReportField.CampaignId]?: number;
    [UniqueImpressionsReportField.BannerId]?: number;
    [UniqueImpressionsReportField.Deal]?: string;
    [UniqueImpressionsReportField.Date]?: string;
    [UniqueImpressionsReportField.Month]?: number;
    [UniqueImpressionsReportField.Day]?: number;

    // fields
    [UniqueImpressionsReportField.Count]?: number;
    [UniqueImpressionsReportField.WonCount]?: number;
    [UniqueImpressionsReportField.BidSum]?: number;
    [UniqueImpressionsReportField.AuctionPriceSum]?: number;
    [UniqueImpressionsReportField.NetCost]?: number;
    [UniqueImpressionsReportField.TechFee]?: number;
    [UniqueImpressionsReportField.AdvertiserFee]?: number;
    [UniqueImpressionsReportField.ThirdPartyCosts]?: number;
    [UniqueImpressionsReportField.TaggingCosts]?: number;
    [UniqueImpressionsReportField.IasCosts]?: number;
    [UniqueImpressionsReportField.AdsquareCosts]?: number;
    [UniqueImpressionsReportField.Contacts]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsCount]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsCookieId]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsNetId]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsMaid]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsCtv]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsDforceId]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsSomAddressableTvId]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsId5]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsListenerId]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsIdfv]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsSalesforceId]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsWithoutId]?: number;
    [UniqueImpressionsReportField.UniqueImpressionsNitroId]?: number;

    // additional fields
    [UniqueImpressionsReportField.NetworkName]?: string;
    [UniqueImpressionsReportField.AdvertiserName]?: string;
    [UniqueImpressionsReportField.AgencyName]?: string;
    [UniqueImpressionsReportField.OrderName]?: string;
    [UniqueImpressionsReportField.CampaignName]?: string;
    [UniqueImpressionsReportField.BannerName]?: string;
    [UniqueImpressionsReportField.DealName]?: string;
    [UniqueImpressionsReportField.BannerType]?: BannerTypes;
    [UniqueImpressionsReportField.BannerSize]?: IBannerSize;
}

enum UniqueImpressionsObjectType {
    NETWORK = 'NETWORK',
    ADVERTISER = 'ADVERTISER',
    ORDER = 'ORDER',
    CAMPAIGN = 'CAMPAIGN',
    BANNER = 'BANNER',
    DEAL = 'DEAL',
}

export {
    UniqueImpressionsReportMetrics,
    UniqueImpressionsReportDimensions,
    UniqueImpressionsReportField,
    IUniqueImpressionsReportResult,
    UniqueImpressionsReportFieldType,
    UniqueImpressionsObjectType,
};
