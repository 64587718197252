import {HeightValidator, WidthValidator} from '@active-agent/validators';
import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'ad-add-custom-size-popover',
    templateUrl: 'add-custom-size-popover.html',
})
class AdAddCustomSizePopoverComponent implements OnChanges {
    public customSizeForm: UntypedFormGroup;

    @Input() private maxWidth!: number;
    @Input() private maxHeight!: number;

    @Output() private onSubmit: EventEmitter<IAddCustomSizeOnSubmitData> = new EventEmitter();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private heightValidator: HeightValidator,
        private widthValidator: WidthValidator,
    ) {
        this.customSizeForm = this.formBuilder.group({
            width: [null],
            height: [null],
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.maxWidth && changes.maxWidth.currentValue !== undefined) {
            this.customSizeForm.controls.width.setValidators(this.widthValidator.validateWithCustomWidth(this.maxWidth));
        }
        if (changes.maxHeight && changes.maxHeight.currentValue !== undefined) {
            this.customSizeForm.controls.height.setValidators(this.heightValidator.validateWithCustomHeight(this.maxHeight));
        }
    }

    public addCustomSize(): void {
        if (this.customSizeForm.invalid) {
            return;
        }

        this.onSubmit.emit({
            width: this.customSizeForm.controls.width.value,
            height: this.customSizeForm.controls.height.value,
        });
    }
}

interface IAddCustomSizeOnSubmitData {
    width: number;
    height: number;
}

export {AdAddCustomSizePopoverComponent, IAddCustomSizeOnSubmitData};
