import {NgModule} from '@angular/core';
import {AdMarkdownParserDirective} from './markdown-parser.directive';

@NgModule({
    exports: [AdMarkdownParserDirective],
    declarations: [AdMarkdownParserDirective],
})
class AdMarkdownParserModule {}

export {AdMarkdownParserModule};
