import {IVastEvent} from '../vast/vast-event.types';

class VastEvent implements IVastEvent {
    private readonly _id: number | null;
    private _type: string;
    private _url: string;

    constructor(
        id: number | null,
        type: string,
        url: string,
    ) {
        this._id = id;
        this._type = type;
        this._url = url;
    }

    get id(): number | null {
        return this._id;
    }

    public get type(): string {
        return this._type;
    }

    public set type(value: string) {
        this._type = value;
    }

    public get url(): string {
        return this._url;
    }

    public set url(value: string) {
        this._url = value;
    }

    public clone(): VastEvent {
        return new VastEvent(this.id, this.type, this.url);
    }
}

export {VastEvent};
