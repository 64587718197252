import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class RedirectCampaignBannerHeightValidator {
    public static readonly maxValue: number = 65535;
    public static readonly minValue: number = 1;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const height: number | undefined | null = control.value;

        if (height === undefined || height === null) {
            return {[HeightErrorCodes.Required]: getTranslationForErrorCode(HeightErrorCodes.Required)};
        }

        if (isNaN(height)) {
            return {[HeightErrorCodes.NaN]: getTranslationForErrorCode(HeightErrorCodes.NaN)};
        }

        if (height < RedirectCampaignBannerHeightValidator.minValue) {
            return {[HeightErrorCodes.MinValue]: getTranslationForErrorCode(HeightErrorCodes.MinValue)};
        }

        if (height > RedirectCampaignBannerHeightValidator.maxValue) {
            return {[HeightErrorCodes.MaxValue]: getTranslationForErrorCode(HeightErrorCodes.MaxValue)};
        }

        return null;
    }
}

function getTranslationForErrorCode(code: HeightErrorCodes): string {
    const requiredMessage: string = $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
    const minValueMessage: string = $localize`:@@VALIDATOR_HEIGHT_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_HEIGHT_MIN_VALUE_ERROR_MESSAGE ${RedirectCampaignBannerHeightValidator.minValue}:INTERPOLATION:`;
    const maxValueMessage: string = $localize`:@@VALIDATOR_HEIGHT_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_HEIGHT_MAX_VALUE_ERROR_MESSAGE ${RedirectCampaignBannerHeightValidator.maxValue}:INTERPOLATION:`;
    const naNMessage: string = $localize`:@@VALIDATOR_HEIGHT_NOT_A_NUMBER_ERROR_MESSAGE:VALIDATOR_HEIGHT_NOT_A_NUMBER_ERROR_MESSAGE`;
    const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;

    switch (code) {
        case HeightErrorCodes.Required:
            return requiredMessage;
        case HeightErrorCodes.MinValue:
            return minValueMessage;
        case HeightErrorCodes.MaxValue:
            return maxValueMessage;
        case HeightErrorCodes.NaN:
            return naNMessage;
        default:
            return defaultMessage;
    }
}

enum HeightErrorCodes {
    Required = '878ee1fe-a832-4920-a7d3-ec9d362bf2b7',
    MinValue = '47cde9df-7974-4e54-aecc-457faaa7c940',
    MaxValue = 'd50a6bf8-0601-43c7-86f7-a5bccd0f2a97',
    NaN = '65768c6a-51c8-4ed0-8e2e-29be28fb22b2',
}

export {RedirectCampaignBannerHeightValidator, HeightErrorCodes};
