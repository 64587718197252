import {Directive, ElementRef, OnInit, Renderer2, SecurityContext} from '@angular/core';
import {unified, Plugin, Transformer} from 'unified';
import {visit} from 'unist-util-visit';
import markdown from 'remark-parse';
import html from 'remark-html';
import {Node} from 'unist';
import {VFile} from 'vfile';
import {DomSanitizer} from '@angular/platform-browser';

// trigger pipeline
@Directive({selector: '[ad-markdown-parser]'})
class AdMarkdownParserDirective implements OnInit {
    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer,
    ) {
    }

    public ngOnInit(): void {
        const content: string = parseMarkdown(this.elementRef.nativeElement.innerText);

        this.renderer.setProperty(
            this.elementRef.nativeElement,
            'innerHTML',
            /**
             * We are using dom sanitizer in order to prevent XSS attacks with
             * user input strings or translations with parameters
             */
            this.sanitizer.sanitize(SecurityContext.HTML, content),
        );
    }
}

function parseMarkdown(markdownStr: string): string {

    /**
     * Angular translations don't handle line breaks in xlf files. However in certain scenarios line breaks
     * might be intentional.
     * We replace the string for line break \n with the real line break character
     */
    const markdownWithProperLineBreaks: string = markdownStr
        .replace(new RegExp('\\\\n', 'g'), '\n')
        .replace(new RegExp('\\\\t', 'g'), '\t');

    const file: VFile = unified()
        .use(markdown)
        .use(customProperties)
        .use(html)
        .processSync(markdownWithProperLineBreaks);

    return file.toString('utf8');
}

const customProperties: Plugin = (): Transformer =>
    (tree: Node): void => {
        // Add target, class and rel to a's.
        visit(tree, 'link', (node: Node) => {
            node.data = {
                hProperties: {
                    target: '_blank',
                    class: 'primary-link',
                    rel: 'noopener noreferrer',
                },
            };
        });
        // Convert p's to div's.
        visit(tree, 'paragraph', (node: Node) => {
            node.data = {
                hName: 'div',
            };
        });
        // Add markdown-list class to ul's.
        visit(tree, 'list', (node: Node) => {
            node.data = {
                hProperties: {
                    class: 'markdown-list',
                },
            };
        });
    };

export {AdMarkdownParserDirective, parseMarkdown};
