import {ModuleWithProviders, NgModule} from '@angular/core';
import {LoadingDataService} from './loading-data.service';

@NgModule({
    imports: [],
})
class LibsLoadingDataModule {
    private static isInitialized: boolean = false;

    constructor() {
        if (!LibsLoadingDataModule.isInitialized) {
            throw new Error('Before importing `LibsLoadingDataModule` you should call `.forRoot()` in your Main Module');
        }
    }

    /**
     * Singleton pattern for services that are being used in lazy loaded modules
     * https://angular.io/guide/singleton-services#the-forroot-pattern
     */
    public static forRoot(): ModuleWithProviders<LibsLoadingDataModule> {
        if (this.isInitialized) {
            throw new Error('`LibsLoadingDataModule.forRoot` is being called multiple times. Make sure to only call it once');
        }
        this.isInitialized = true;

        return {
            ngModule: LibsLoadingDataModule,
            providers: [
                LoadingDataService,
            ],
        };
    }
}

export {LibsLoadingDataModule};
