import {InjectionToken} from '@angular/core';
import {IRouterHandlerRouteConfiguration} from '@active-agent/types';

const SYNC_BANNER_POOL_ROUTER_CONFIG: InjectionToken<ISyncBannerPoolRouteConfig>
    = new InjectionToken<ISyncBannerPoolRouteConfig>('sync-banner-pool.route-handler');

interface ISyncBannerPoolRouteConfig {
    campaignList: IRouterHandlerRouteConfiguration;
    bannerList: IRouterHandlerRouteConfiguration;
}

export {SYNC_BANNER_POOL_ROUTER_CONFIG, ISyncBannerPoolRouteConfig};
