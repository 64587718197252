import {Observable, of} from 'rxjs';
import {parseWeatherFilterImport} from './weather-filter-import';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IWeatherFilterProperties extends IFilterProperties {
    profile: string;
}

class WeatherFilter extends Filter<FilterTypes.WEATHER> {
    protected readonly _type: FilterTypes.WEATHER = FilterTypes.WEATHER;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IWeatherFilterProperties = this.getDefaultOptions();

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    public isValid(): Observable<boolean> {
        return of(isWeatherProfileValid(this.profile));
    }

    protected getDefaultOptions(): IWeatherFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

function isWeatherProfileValid(profile: string = ''): boolean {
    try {
        parseWeatherFilterImport(profile);
    } catch (error) {
        return false;
    }

    return true;
}

export {WeatherFilter, IWeatherFilterProperties, isWeatherProfileValid};
