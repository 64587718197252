import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class AttributeExpireDaysValidator {
    public static readonly maxValue: number = 730;
    public static readonly minValue: number = 1;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const expireDays: number = control.value;

        if (expireDays === undefined || expireDays === null) {
            return {[ExpireDaysErrorCodes.Required]: this.getTranslationForErrorCode(ExpireDaysErrorCodes.Required)};
        }

        if (expireDays < AttributeExpireDaysValidator.minValue) {
            return {[ExpireDaysErrorCodes.MinValue]: this.getTranslationForErrorCode(ExpireDaysErrorCodes.MinValue)};
        }

        if (expireDays > AttributeExpireDaysValidator.maxValue) {
            return {[ExpireDaysErrorCodes.MaxValue]: this.getTranslationForErrorCode(ExpireDaysErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: ExpireDaysErrorCodes): string {
        switch (code) {
            case ExpireDaysErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case ExpireDaysErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_EXPIRE_DAYS_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_EXPIRE_DAYS_MIN_VALUE_ERROR_MESSAGE ${this.decimalPipe.transform(AttributeExpireDaysValidator.minValue)}:INTERPOLATION:`;
            case ExpireDaysErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_EXPIRE_DAYS_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_EXPIRE_DAYS_MAX_VALUE_ERROR_MESSAGE ${this.decimalPipe.transform(AttributeExpireDaysValidator.maxValue)}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum ExpireDaysErrorCodes {
    Required = 'a211ccbb-b70c-42f0-a83c-b5838b9be185',
    MinValue = '5373a4e6-3119-4e7a-9385-5e47a1f10235',
    MaxValue = '72f4655a-6b55-4a12-8325-e8e083102250',
}

export {AttributeExpireDaysValidator};
