import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class MaxCombinedBidFactorValidator {
    public static readonly maxValue: number = 200;
    public static readonly minValue: number = 0.01;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const maxCombinedBidFactor: number = control.value;

        if (maxCombinedBidFactor === undefined || maxCombinedBidFactor === null) {
            return null;
        }

        if (maxCombinedBidFactor < MaxCombinedBidFactorValidator.minValue) {
            return {[MaxCombinedBidFactorErrorCodes.MinValue]: this.getTranslationForErrorCode(MaxCombinedBidFactorErrorCodes.MinValue)};
        }

        if (maxCombinedBidFactor > MaxCombinedBidFactorValidator.maxValue) {
            return {[MaxCombinedBidFactorErrorCodes.MaxValue]: this.getTranslationForErrorCode(MaxCombinedBidFactorErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: MaxCombinedBidFactorErrorCodes): string {
        switch (code) {
            case MaxCombinedBidFactorErrorCodes.MinValue:
                const min: string | null = this.decimalPipe.transform(MaxCombinedBidFactorValidator.minValue);

                return $localize`:@@VALIDATOR_MAX_COMBINED_BID_FACTOR_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_COMBINED_BID_FACTOR_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
            case MaxCombinedBidFactorErrorCodes.MaxValue:
                const max: string | null = this.decimalPipe.transform(MaxCombinedBidFactorValidator.maxValue);

                return $localize`:@@VALIDATOR_MAX_COMBINED_BID_FACTOR_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_COMBINED_BID_FACTOR_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum MaxCombinedBidFactorErrorCodes {
    MinValue = 'df379e4d-52cf-4908-8a2e-8ad866309865',
    MaxValue = '2dd23b30-ac55-409f-989b-e9094509743f',
}

export {MaxCombinedBidFactorValidator};
