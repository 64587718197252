import {of, Observable} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';
import {PeriodTypes} from '../types/period.types';

interface IAudienceGraphCappingFilterProperties extends IFilterProperties {
    graphType: GraphTypes;
    requests: number;
    time: number;
    period: PeriodTypes;
}

class AudienceGraphCappingFilter extends Filter<FilterTypes.GRAPH_CAPPING> {
    protected readonly _type: FilterTypes.GRAPH_CAPPING = FilterTypes.GRAPH_CAPPING;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IAudienceGraphCappingFilterProperties = this.getDefaultOptions();
    private _isValid: boolean = true;

    public updateValidStatus(newStatus: boolean): void {
        this._isValid = newStatus;
    }

    public isValid(): Observable<boolean> {
        if (
            !this.props.requests ||
            !this.props.time ||
            !this.props.period ||
            !this.props.graphType
        ) {
            return of(false);
        }

        return of(this._isValid);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get graphType(): GraphTypes {
        return this.props.graphType;
    }

    set graphType(value: GraphTypes) {
        this.props.graphType = value;
    }

    get requests(): number {
        return this.props.requests;
    }

    set requests(value: number) {
        this.props.requests = value;
    }

    get time(): number {
        return this.props.time;
    }

    set time(value: number) {
        this.props.time = value;
    }

    get period(): PeriodTypes {
        return this.props.period;
    }

    set period(value: PeriodTypes) {
        this.props.period = value;
    }

    protected getDefaultOptions(): IAudienceGraphCappingFilterProperties {
        return {
            ...super.getDefaultOptions(),
            graphType: GraphTypes.HouseHold,
            requests: 0,
            time: 0,
            period: PeriodTypes.Seconds,
        };
    }
}

enum GraphTypes {
    Custom = 'CUSTOM',
    HouseHold = 'HOUSE_HOLD',
    Atv = 'ATV',
    SameDevice = 'SAME_DEVICE',
}

function getGraphTypeTranslationForType(type: GraphTypes): string {
    switch (type) {
        case GraphTypes.Custom:
            return $localize`:@@AUDIENCE_GRAPH_CAPPING_GRAPH_TYPE_CUSTOM:AUDIENCE_GRAPH_CAPPING_GRAPH_TYPE_CUSTOM`;
        case GraphTypes.HouseHold:
            return $localize`:@@AUDIENCE_GRAPH_CAPPING_GRAPH_TYPE_HOUSE_HOLD:AUDIENCE_GRAPH_CAPPING_GRAPH_TYPE_HOUSE_HOLD`;
        case GraphTypes.Atv:
            return $localize`:@@AUDIENCE_GRAPH_CAPPING_GRAPH_TYPE_ATV:AUDIENCE_GRAPH_CAPPING_GRAPH_TYPE_ATV`;
        case GraphTypes.SameDevice:
            return $localize`:@@AUDIENCE_GRAPH_CAPPING_GRAPH_TYPE_SAME_DEVICE:AUDIENCE_GRAPH_CAPPING_GRAPH_TYPE_SAME_DEVICE`;
        default:
            return type;
    }
}

export {AudienceGraphCappingFilter, IAudienceGraphCappingFilterProperties, GraphTypes, getGraphTypeTranslationForType};
