/* eslint-disable no-console */
import {CommonsRequestBuilder} from './request-builder';
import {HttpClient, HttpParams, HttpParamsOptions} from '@angular/common/http';
import {forkJoin, Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {CommonsError, IGenericObject} from '@active-agent/types';
import {addCaching, chunkArray} from '@active-agent/std';

class CommonsGetRequestBuilder extends CommonsRequestBuilder {
    protected isCachingEnabled: boolean = false;
    private fetchAllEnabled: boolean = false;
    private chunkQueryParam: string | null = null;
    private chunkSize: number | null = null;
    private chunkAggregator: ChunkAggregator | null = null;
    private httpParamsOptions: HttpParamsOptions | null = null;

    public constructor(
        protected http: HttpClient,
        resourcePath: string,
        protected baseUrl: string,
    ) {
        super(resourcePath, baseUrl);
    }

    public enableCaching(enableCaching: boolean): this {
        this.isCachingEnabled = enableCaching;

        return this;
    }

    /**
     * If you want to chunk the requests by a given parameter you can specify it here
     */
    public setChunkQueryParam(param: string): this {
        this.chunkQueryParam = param;

        return this;
    }

    /**
     * Defines the chunk size. If not defined all information will be in one request.
     * It can be chunked by ids or query parameter (for this you have to use the `setChunkQueryParam` function
     */
    public setChunkSize(chunkSize: number | null): this {
        this.chunkSize = chunkSize;

        return this;
    }

    /**
     * Allows to provide an aggregator function that will be called after a chunked requests was completed.
     * With this you can transform your result since it will be returned in an array for chunked requests
     */
    public setChunkAggregator(aggregator: ChunkAggregator): this {
        this.chunkAggregator = aggregator;

        return this;
    }

    /**
     * There is a special handling in place as a safety measurement.
     * If you want to request a resource without any ids you need to call this method.
     *
     * We want to ensure that you do not accidentally request a resource without IDs if not intended,
     * if you accidentally set queryIds to null, undefined or empty array
     */
    public fetchAll(): this {
        this.fetchAllEnabled = true;

        return this;
    }

    public setCustomHttpParamsOptions(options: HttpParamsOptions): this {
        this.httpParamsOptions = options;

        return this;
    }

    /**
     * Creates a valid api get request and fetches the result from the api
     */
    public build(): Observable<IGenericObject> {
        const baseUrl: string = this.getApiBaseUrl();
        const queryIds: Array<number | string> | null = this.getQueryIds();

        const validateStatus: string | null = this.validateSettings(queryIds);
        if (validateStatus) {
            return throwError(new CommonsError(
                validateStatus,
                {data: {baseUrl, queryIds}},
            ));
        }
        let urls: Array<string>;
        if (queryIds && queryIds.length) {
            /**
             * If there is a chunk query param active we have to add all regular ids to the url
             * If there is no chunkSize provided we also want to add all ids to the url
             */
            if (this.chunkQueryParam || !this.chunkSize) {
                urls = [this.getUrlWithQueryIds(baseUrl, queryIds)];
            } else {
                /**
                 * If a chunkSize is available we want to split up the request into multiple requests based on the chunk
                 * size
                 */
                urls = chunkArray<number | string>(queryIds, this.chunkSize).map((chunk: Array<number | string>): string => {
                    return this.getUrlWithQueryIds(baseUrl, chunk);
                });
            }
        } else {
            urls = [this.getUrlWithQueryIds(baseUrl, null)];
        }

        const observable: Observable<IGenericObject> = forkJoin(
            ...urls.reduce((result: Array<Observable<IGenericObject>>, url: string): Array<Observable<IGenericObject>> => {
                /**
                 * If there is a chunk query param we have to create multiple sets of query params with all the default
                 * values and a chunk of the values provided in the chunkQueryParam field
                 */
                if (this.chunkQueryParam && this.chunkSize) {
                    this.getChunkedQueryParamsForRequest(this.chunkSize, this.chunkQueryParam)
                        .forEach((chunkParams: {[param: string]: string}): void => {
                            result.push(this.getHttpObservable(url, chunkParams));
                        });
                } else {
                    result.push(this.getHttpObservable(url));
                }

                return result;
            }, []),
        ).pipe(
            map((results: Array<IGenericObject>): IGenericObject => {
                if (!this.chunkSize) {
                    return results[0];
                } else if (this.chunkAggregator) {
                    return this.chunkAggregator(results);
                }

                return results;
            }),
        );

        if (this.isCachingEnabled) {
            return addCaching(observable);
        }

        return observable;
    }

    private validateSettings(queryIds: Array<string | number> | null): string | null {
        if (queryIds && this.fetchAllEnabled) {
            return 'It is not allowed to use queryIds in combination with fetchAll';
        }

        if ((!queryIds || queryIds.length === 0) && !this.fetchAllEnabled) {
            return 'If no query Ids are provided you have to explicitly call fetchAll';
        }

        if (queryIds) {
            const hasInvalidValues: boolean = queryIds.some((id: number | string): boolean => {
                return typeof id !== 'string' && !Number.isFinite(id);
            });
            if (hasInvalidValues) {
                return 'Invalid data format. Array of numbers or strings expected';
            }
        }

        return null;
    }

    private getHttpObservable(
        url: string,
        params: {[param: string]: string} = this.getQueryParamsForRequest(),
    ): Observable<IGenericObject> {
        let customHttpParams: HttpParams | null = null;
        if (this.httpParamsOptions) {
            customHttpParams = new HttpParams(this.httpParamsOptions);

            Object.keys(params).forEach((key: string) => {
                if (customHttpParams) {
                    customHttpParams = customHttpParams.set(key, params[key]);
                }
            });
        }

        return this.http
            .get(url, {
                params: customHttpParams || params,
                headers: this.getHeadersForRequest(),
            })
            .pipe(
                map((response: IGenericObject): IGenericObject => response),
            );
    }

    private getUrlWithQueryIds(baseUrl: string, queryIds: Array<string | number> | null): string {
        let url: string = queryIds && queryIds.length > 0 ? `${baseUrl}/${queryIds.join(',')}` : baseUrl;
        if (this.getSubResourcePath()) {
            url += `/${this.getSubResourcePath()}`;
        }

        return url;
    }
}

type ChunkAggregator = (chunkResults: Array<IGenericObject>) => IGenericObject;

export {CommonsGetRequestBuilder, ChunkAggregator};
