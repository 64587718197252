import {UntypedFormControl} from '@angular/forms';
import {CpxMetric} from './campaign';

function getCpxMetricsOptions(): Array<ICpxMetricOption> {
    return [
        {
            id: 'POSTCLICK_TURNOVER',
            translation: $localize`:@@FORM_CAMPAIGN_CPX_METRIC_SELECTION_POSTCLICK_TURNOVER_OPTION_LABEL:FORM_CAMPAIGN_CPX_METRIC_SELECTION_POSTCLICK_TURNOVER_OPTION_LABEL`,
            control: new UntypedFormControl(true),
        },
        {
            id: 'POSTVIEW_TURNOVER',
            translation: $localize`:@@FORM_CAMPAIGN_CPX_METRIC_SELECTION_POSTVIEW_TURNOVER_OPTION_LABEL:FORM_CAMPAIGN_CPX_METRIC_SELECTION_POSTVIEW_TURNOVER_OPTION_LABEL`,
            control: new UntypedFormControl(true),
        },
    ];
}

interface ICpxMetricOption {
    id: CpxMetric;
    translation: string;
    control: UntypedFormControl;
}

export {getCpxMetricsOptions, ICpxMetricOption};
