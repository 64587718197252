import {differenceInHoursIgnoringDST, getTimeZonedNewDate, saveParseISO} from '@active-agent/std';
import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {areIntervalsOverlapping, isBefore} from 'date-fns';

@Injectable({
    providedIn: 'root',
})
class FootfallRuntimeValidator implements Validator {
    public static readonly maxTotalRangeDays: number = 150;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const controlGroupControl: AbstractControl = control.get('controlGroup') as AbstractControl;
        const controlGroupEnabledControl: AbstractControl = control.get('controlGroup.enabled') as AbstractControl;

        if (!controlGroupEnabledControl.value) {
            return null;
        }

        const conversionControl: AbstractControl = control.get('conversion') as AbstractControl;

        const conversionStartTimeControl: AbstractControl = control.get('conversion.startTime') as AbstractControl;
        const conversionEndTimeControl: AbstractControl = control.get('conversion.endTime') as AbstractControl;
        const controlGroupStartTimeControl: AbstractControl = control.get('controlGroup.startTime') as AbstractControl;
        const controlGroupEndTimeControl: AbstractControl = control.get('controlGroup.endTime') as AbstractControl;

        const conversionStartTime: Date | null = saveParseISO(conversionStartTimeControl.value);
        const conversionEndTime: Date | null = saveParseISO(conversionEndTimeControl.value);
        const controlGroupStartTime: Date | null = saveParseISO(controlGroupStartTimeControl.value);
        const controlGroupEndTime: Date | null = saveParseISO(controlGroupEndTimeControl.value);

        if (
            !conversionStartTime
            || !conversionEndTime
            || !controlGroupStartTime
            || !controlGroupEndTime
            || (!conversionControl || !conversionControl.valid)
            || (!controlGroupControl || !controlGroupControl.valid)
        ) {
            return null;
        }

        try {
            if (areIntervalsOverlapping(
                {start: conversionStartTime, end: conversionEndTime},
                {start: controlGroupStartTime, end: controlGroupEndTime})
            ) {
                return getValidationError(FootfallRuntimeErrorCode.RuntimeOverlap);
            }
        } catch (error) {
            return getValidationError(FootfallRuntimeErrorCode.InvalidRuntime);
        }
        if (isExceedMaxTotalRange(conversionStartTime, conversionEndTime, controlGroupStartTime, controlGroupEndTime)) {
            return getValidationError(FootfallRuntimeErrorCode.ExceedMaxTotalRange);
        }
        const currentDate: Date = getTimeZonedNewDate();
        if (
            isBefore(conversionStartTime, currentDate)
            || isBefore(conversionEndTime, currentDate)
            || isBefore(controlGroupStartTime, currentDate)
            || isBefore(controlGroupEndTime, currentDate)
        ) {
            return getValidationError(FootfallRuntimeErrorCode.DateInThePast);
        }

        return null;
    }

    public hasInvalidTotalRangeError(control: AbstractControl): boolean {
        return !!control.errors
            && Object.keys(control.errors).includes(FootfallRuntimeErrorCode.ExceedMaxTotalRange);
    }
}

function getValidationError(code: FootfallRuntimeErrorCode): ValidationErrors {
    return {[code]: getTranslationForErrorCode(code)};
}

function getTranslationForErrorCode(code: FootfallRuntimeErrorCode): string {
    switch (code) {
        case FootfallRuntimeErrorCode.RuntimeOverlap:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_RUNTIME_OVERLAP_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_RUNTIME_OVERLAP_ERROR_MESSAGE`;
        case FootfallRuntimeErrorCode.DateInThePast:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_DATE_IN_THE_PAST_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_DATE_IN_THE_PAST_ERROR_MESSAGE`;
        case FootfallRuntimeErrorCode.ExceedMaxTotalRange:
            return $localize`:@@FOOTFALL_STORE_VALIDATOR_RUNTIME_EXCEEDS_MAX_RANGE_ERROR_MESSAGE:FOOTFALL_STORE_VALIDATOR_RUNTIME_EXCEEDS_MAX_RANGE_ERROR_MESSAGE ${FootfallRuntimeValidator.maxTotalRangeDays}:INTERPOLATION:`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

function isExceedMaxTotalRange(
    conversionRuntimeStartDate: Date,
    conversionRuntimeEndDate: Date,
    controlGroupRuntimeStartDate: Date,
    controlGroupRuntimeEndDate: Date,
): boolean {
    if (isBefore(controlGroupRuntimeEndDate, conversionRuntimeStartDate)) {
        const differenceInHours: number =
            differenceInHoursIgnoringDST(conversionRuntimeEndDate, controlGroupRuntimeStartDate);

        if (differenceInHours > (FootfallRuntimeValidator.maxTotalRangeDays * 24)) {
            return true;
        }
    }

    if (isBefore(conversionRuntimeEndDate, controlGroupRuntimeStartDate)) {
        const differenceInHours: number =
            differenceInHoursIgnoringDST(controlGroupRuntimeEndDate, conversionRuntimeStartDate);

        if (differenceInHours > (FootfallRuntimeValidator.maxTotalRangeDays * 24)) {
            return true;
        }
    }

    return false;
}

enum FootfallRuntimeErrorCode {
    RuntimeOverlap = '581793b8-4676-40e2-a535-140010906f77',
    ExceedMaxTotalRange = 'bfa493e4-3c89-4c76-a499-60ea3f6be2b5',
    InvalidRuntime = '99505b83-33c2-4d98-a4e6-8def2faa699a',
    DateInThePast = '0f22e80c-6b47-4317-9674-84c0f3225c4e',
}

export {FootfallRuntimeValidator};
