import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class LongitudeValidator implements Validator {
    private static readonly max: number = 180;
    private static readonly min: number = -180;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const longitude: number = control.value;

        if (longitude === undefined || longitude === null) {
            return {[LongitudeErrorCodes.Required]: this.getTranslationForErrorCode(LongitudeErrorCodes.Required)};
        }

        if (!Number.isFinite(longitude)) {
            return {[LongitudeErrorCodes.InvalidFormat]: this.getTranslationForErrorCode(LongitudeErrorCodes.InvalidFormat)};
        }

        if (longitude < LongitudeValidator.min) {
            return {[LongitudeErrorCodes.Min]: this.getTranslationForErrorCode(LongitudeErrorCodes.Min)};
        }

        if (longitude > LongitudeValidator.max) {
            return {[LongitudeErrorCodes.Max]: this.getTranslationForErrorCode(LongitudeErrorCodes.Max)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: LongitudeErrorCodes): string {
        switch (code) {
            case LongitudeErrorCodes.Required:
                return $localize`:@@FOOTFALL_LONGITUDE_VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:FOOTFALL_LONGITUDE_VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case LongitudeErrorCodes.InvalidFormat:
                return $localize`:@@FOOTFALL_LONGITUDE_VALIDATOR_INVALID_FORMAT_ERROR_MESSAGE:FOOTFALL_LONGITUDE_VALIDATOR_INVALID_FORMAT_ERROR_MESSAGE`;
            case LongitudeErrorCodes.Min:
                return $localize`:@@FOOTFALL_LONGITUDE_VALIDATOR_MIN_ERROR_MESSAGE:FOOTFALL_LONGITUDE_VALIDATOR_MIN_ERROR_MESSAGE ${this.decimalPipe.transform(LongitudeValidator.min)}:INTERPOLATION:`;
            case LongitudeErrorCodes.Max:
                return $localize`:@@FOOTFALL_LONGITUDE_VALIDATOR_MAX_ERROR_MESSAGE:FOOTFALL_LONGITUDE_VALIDATOR_MAX_ERROR_MESSAGE ${this.decimalPipe.transform(LongitudeValidator.max)}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum LongitudeErrorCodes {
    Required = 'd1c361d2-1e8c-4a2c-941c-a652844f2591',
    InvalidFormat = '3af3a187-fa90-450c-b524-f440016570a8',
    Min = '507687b4-5e06-418e-8dd9-15d2b6a8fe51',
    Max = '8e9ab190-8225-4d36-9bfc-58b6391478d0',
}

export {LongitudeValidator};
