import {OdcFilter, OdcFilterProperties} from '../odc-filter';
import {FilterTypes} from '../../types/filter.types';

type OdcContextContextualFilterProperties = OdcFilterProperties;

class OdcContextContextualFilter extends OdcFilter<FilterTypes.ODC_CONTEXT_CONTEXTUAL> {
    protected readonly _type: FilterTypes.ODC_CONTEXT_CONTEXTUAL = FilterTypes.ODC_CONTEXT_CONTEXTUAL;
    protected readonly props: OdcContextContextualFilterProperties = this.getDefaultOptions();
}

export {OdcContextContextualFilter};
