<div
    *ngIf="isNewVersionAvailable"
    class="new-version"
>
    <mat-toolbar class="new-version__toolbar">
        <commons-status-icon [status]="infoStatus"></commons-status-icon>
        <div
            class="new-version__title"
            i18n="@@LAYOUT_NEW_VERSION_AVAILABLE_MESSAGE"
        >LAYOUT_NEW_VERSION_AVAILABLE_MESSAGE</div>
        <button mat-icon-button (click)="refreshPage()" class="new-version__refresh-button">
            <mat-icon>refresh</mat-icon>
        </button>
    </mat-toolbar>
</div>
