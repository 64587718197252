import CodeMirror, {EditorConfiguration} from 'codemirror';
import {
    Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import {
    IConfigureMouseConfiguration,
    ConfigureMouseRepeatType,
} from './code-mirror-mouse-configuration';

import 'codemirror/addon/display/autorefresh.js';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/css/css.js';

@Component({
    selector: 'libs-code-mirror',
    templateUrl: './code-mirror.html',
    styleUrls: ['./code-mirror.scss'],
})
class LibsCodeMirrorComponent implements OnInit, OnChanges {
    @Input() public autoHeight: boolean = false;
    @Input() public code: string = '';
    @Input() public isReadOnly: boolean = false;
    @Input() public lineNumbers: boolean = true;
    @Input() public lineWrapping: boolean = false;
    @Input() public mode: string = 'htmlmixed';
    @Input() public cursorBlinkRate?: number;
    /**
     * https://codemirror.net/doc/manual.html#option_configureMouse
     *
     * Configuration to handle the behavior of how the code gets selected by clicked single, double or triple
     */
    @Input() private configureMouse: ConfigureMouseCallback | null = null;

    @Output() private onChange: EventEmitter<string> = new EventEmitter();
    @ViewChild('codeMirror', {static: true}) private codeMirrorElement!: ElementRef;
    private editor: CodeMirror.Editor | undefined;

    public ngOnInit(): void {
        this.editor = CodeMirror(
            this.codeMirrorElement.nativeElement,
            this.getCodeEditorOptions(),
        );

        this.editor.on('change', this.onChangeInternal.bind(this));
        this.editor.setSize('100%', '100%');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.code && changes.code.currentValue !== undefined) {
            if (this.editor && this.isReadOnly) {
                this.editor.setValue(this.code);
            }
        }

        if (changes.isReadOnly && changes.isReadOnly.currentValue !== undefined) {
            if (this.editor) {
                this.editor.setOption('readOnly', this.isReadOnly);
            }
        }
    }

    public getCodeEditorOptions(): IEditorConfiguration {
        const options: IEditorConfiguration = {
            autoRefresh: true,
            value: this.code,
            readOnly: this.isReadOnly,
            lineNumbers: this.lineNumbers,
            lineWrapping: this.lineWrapping,
            showCursorWhenSelecting: false,
            mode: this.mode,
            fixedGutter: true,
        };

        if (this.cursorBlinkRate !== undefined) {
            options.cursorBlinkRate = this.cursorBlinkRate;
        }

        if (this.configureMouse) {
            options.configureMouse = this.configureMouse;
        }

        return options;
    }

    private onChangeInternal(instance: CodeMirror.Editor): void {
        this.onChange.emit(instance.getValue());
    }
}

type ConfigureMouseCallback =
    (codeMirror: CodeMirror.Editor, repeat: ConfigureMouseRepeatType, event: MouseEvent) => IConfigureMouseConfiguration;

interface IEditorConfiguration extends EditorConfiguration, IHasConfigureMouseOverwritten {}

interface IHasConfigureMouseOverwritten extends EditorConfiguration {
    configureMouse?: ConfigureMouseCallback;
}

export {LibsCodeMirrorComponent};
