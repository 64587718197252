import {IRouterHandlerRouteConfiguration} from '@active-agent/types';
import {InjectionToken} from '@angular/core';

const ORDER_ROUTES_CONFIGURATION: InjectionToken<IOrderRoutesConfiguration> =
    new InjectionToken('order-routes-configuration');

interface IOrderRoutesConfiguration {
    settings: IRouterHandlerRouteConfiguration<IOrderSettingsRouteData>;
    campaigns: IRouterHandlerRouteConfiguration<IOrderCampaignsListRouteData>;
}

interface  IOrderSettingsRouteData {
    orderId: number;
}

interface  IOrderCampaignsListRouteData {
    orderId: number;
}

export {
    ORDER_ROUTES_CONFIGURATION,
    IOrderSettingsRouteData,
    IOrderCampaignsListRouteData,
    IOrderRoutesConfiguration,
};
