import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class BidFactorValidator {
    public static readonly maxValue: number = 100;
    public static readonly minValue: number = 0.01;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const bidFactor: number = control.value;

        if (bidFactor === undefined || bidFactor === null) {
            return null;
        }

        if (bidFactor < BidFactorValidator.minValue) {
            return {[BidFactorErrorCodes.MinValue]: this.getTranslationForErrorCode(BidFactorErrorCodes.MinValue)};
        }

        if (bidFactor > BidFactorValidator.maxValue) {
            return {[BidFactorErrorCodes.MaxValue]: this.getTranslationForErrorCode(BidFactorErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: BidFactorErrorCodes): string {
        switch (code) {
            case BidFactorErrorCodes.MinValue:
                const min: string | null = this.decimalPipe.transform(BidFactorValidator.minValue);

                return $localize`:@@VALIDATOR_BID_FACTOR_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_BID_FACTOR_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
            case BidFactorErrorCodes.MaxValue:
                const max: string | null = this.decimalPipe.transform(BidFactorValidator.maxValue);

                return $localize`:@@VALIDATOR_BID_FACTOR_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_BID_FACTOR_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum BidFactorErrorCodes {
    MinValue = '451e13e2-1a41-467c-91e3-e18091be5dda',
    MaxValue = 'b2505ba0-a155-462c-ab23-c5150872d8f5',
}

export {BidFactorValidator};
