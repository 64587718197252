import {ActivatedRouteSnapshot, BaseRouteReuseStrategy} from '@angular/router';
import {RouterDataOptions} from './router-data-options';

class LibsRouteReuseStrategy extends BaseRouteReuseStrategy {

    /**
     * By default, the router re-uses a component instance when it re-navigates to the same component
     * type without visiting a different component first.
     * For certain cases we don't want to do this. For example on navigation for global search from one state to
     * another just with a different object id. But at the same time we don't want to reload on the campaign and banner
     * page for bulk edit navigation.
     * That's why we introduce the reloadOnParamChange property on the route configuration.
     * If the reloadOnParamChange property is set to a parameter name, the router will reload the component
     * when the parameter changes (if on the same route).
     * For all other cases we keep the default angular behavior.
     */
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (future.routeConfig === curr.routeConfig && curr.routeConfig?.data
            && curr.routeConfig.data[RouterDataOptions.ReloadOnParamChange]) {
            const reloadParam: string = curr.routeConfig.data[RouterDataOptions.ReloadOnParamChange];

            return curr.params[reloadParam] === future.params[reloadParam];
        }

        return super.shouldReuseRoute(future, curr);
    }
}

export {LibsRouteReuseStrategy};
