import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';
import {Observable, of} from 'rxjs';

interface IAuctionTypeFilterProperties extends IFilterProperties {
    profile: string;
}

class AuctionTypeFilter extends Filter<FilterTypes.AUCTION_TYPE> {
    protected readonly _type: FilterTypes.AUCTION_TYPE = FilterTypes.AUCTION_TYPE;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IAuctionTypeFilterProperties = this.getDefaultOptions();

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    public isValid(): Observable<boolean> {
        return of(isAuctionTypeProfileValid(this.profile));
    }

    protected getDefaultOptions(): IAuctionTypeFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

function isAuctionTypeProfileValid(profile: string = ''): boolean {
    return !!profile.length;
}

export {AuctionTypeFilter, IAuctionTypeFilterProperties, isAuctionTypeProfileValid};
