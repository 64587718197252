import {Banner, BaseBanner} from '../base-banner';
import {BannerTypes} from '../banner-types';
import {VastEvent} from '../vast-event/vast-event';
import {ICloneable, IMergable} from '../../types';
import {IVastWrapperBanner, IVideoProperties} from '../banner';

class VastWrapperBanner extends BaseBanner implements IVastWrapperBanner, ICloneable<VastWrapperBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.VAST_WRAPPER;
    private _ssl: boolean = false;
    private _wrapperUrl: string;
    private _videoProperties: IVideoProperties | undefined;
    private _vastEvents: Array<VastEvent> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        wrapperUrl: string,
    ) {
        super(id, networkId, name, landingPage);

        this._wrapperUrl = wrapperUrl;
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get wrapperUrl(): string {
        return this._wrapperUrl;
    }

    set wrapperUrl(value: string) {
        this._wrapperUrl = value;
    }

    get videoProperties(): IVideoProperties | undefined {
        return this._videoProperties;
    }

    set videoProperties(value: IVideoProperties | undefined) {
        this._videoProperties = value;
    }

    public get vastEvents(): Array<VastEvent> {
        return this._vastEvents;
    }

    public set vastEvents(value: Array<VastEvent>) {
        this._vastEvents = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        VastWrapperBanner.fillAdditionalFields(this, source);
    }

    public clone(): VastWrapperBanner {
        const newModel: VastWrapperBanner = new VastWrapperBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.wrapperUrl,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: VastWrapperBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('wrapperUrl' in source) {
            target.wrapperUrl = source.wrapperUrl;
        }
        if ('videoProperties' in source) {
            target.videoProperties = source.videoProperties;
        }
        if ('vastEvents' in source) {
            target.vastEvents = source.vastEvents.map((vastEvent: VastEvent) => vastEvent.clone());
        }
    }
}

export {VastWrapperBanner};
