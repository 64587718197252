import {Observable, of} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface ITvTargetingGroupFilterProperties extends IFilterProperties {
    profile: string;
}

class TvTargetingGroupFilter extends Filter<FilterTypes.TV_TARGETING_GROUP> {
    protected readonly _type: FilterTypes.TV_TARGETING_GROUP = FilterTypes.TV_TARGETING_GROUP;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = false;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: ITvTargetingGroupFilterProperties = this.getDefaultOptions();

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    public isValid(): Observable<boolean> {
        return of(isTvTargetingGroupProfileValid(this.profile));
    }

    protected getDefaultOptions(): ITvTargetingGroupFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

function isTvTargetingGroupProfileValid(profile: string = ''): boolean {
    return !!profile.length;
}

export {TvTargetingGroupFilter, ITvTargetingGroupFilterProperties, isTvTargetingGroupProfileValid};
