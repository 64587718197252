import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';
import {getTimePattern} from '@active-agent/pattern';

@Injectable({
    providedIn: 'root',
})
class TimeValidator implements Validator {
    public static readonly pattern: RegExp = getTimePattern();

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<string | null>): ValidationErrors | null {
        if (!control.value) {
            return getValidationError(TimeErrorCode.Required);
        }

        if (!TimeValidator.pattern.test(control.value)) {
            return getValidationError(TimeErrorCode.Pattern);
        }

        return null;
    }
}

function getValidationError(code: TimeErrorCode): ValidationErrors {
    return {[code]: getTranslationForErrorCode(code)};
}

function getTranslationForErrorCode(code: TimeErrorCode): string {
    switch (code) {
        case TimeErrorCode.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        case TimeErrorCode.Pattern:
            return $localize`:@@VALIDATOR_TIME_PATTERN_ERROR_MESSAGE:VALIDATOR_TIME_PATTERN_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

const enum TimeErrorCode {
    Required = 'aca64e2a-614e-4e7d-a697-fa7d37a57e6c',
    Pattern = '3a59217d-5ba2-4e30-a755-1584e0ba1313',
}

export {TimeErrorCode, TimeValidator};
