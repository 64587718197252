import {LibsSlowLog} from '@active-agent/event';
import {environment} from '../../../environments/environment';

class SlowLog extends LibsSlowLog {
    constructor(
        time: number,
        url: string,
        method: string,
        params: {[key: string]: string},
    ) {
        super(time, url, method, params, environment);
    }
}

export {SlowLog};
