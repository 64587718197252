import {NgModule} from '@angular/core';
import {CommonsNewVersionToolbarComponent} from './new-version-toolbar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        MatToolbarModule,
        MatButtonModule,
        CommonsStatusIconModule,
        CommonModule,
        MatIconModule,
        MatDialogModule,
    ],
    exports: [
        CommonsNewVersionToolbarComponent,
    ],
    declarations: [
        CommonsNewVersionToolbarComponent,
    ],
})
class CommonsNewVersionToolbarModule {}

export {CommonsNewVersionToolbarModule};
