import {Banner, BaseBanner} from '../base-banner';
import {cloneDeep} from 'lodash-es';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {IBannerFile, IBannerSize, IImageBanner} from '../banner';
import {convertApiObjectToBase64, replaceAditionPlaceholders} from '../../file';

class ImageBanner extends BaseBanner implements IImageBanner, ICloneable<ImageBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.IMAGE;
    private _ssl: boolean = false;
    private _clickUrl: string;
    private _size: IBannerSize;
    private _file: IBannerFile;
    private _preHtmlCode: string = '';
    private _postHtmlCode: string = '';

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        size: IBannerSize,
        file: IBannerFile,
    ) {
        super(id, networkId, name, landingPage);

        this._clickUrl = clickUrl;
        this._size = size;
        this._file = file;
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string {
        return this._clickUrl;
    }

    set clickUrl(value: string) {
        this._clickUrl = value;
    }

    get size(): IBannerSize {
        return this._size;
    }

    set size(value: IBannerSize) {
        this._size = value;
    }

    get file(): IBannerFile {
        return this._file;
    }

    set file(value: IBannerFile) {
        this._file = value;
    }

    get preHtmlCode(): string {
        return this._preHtmlCode;
    }

    set preHtmlCode(value: string) {
        this._preHtmlCode = value;
    }

    get postHtmlCode(): string {
        return this._postHtmlCode;
    }

    set postHtmlCode(value: string) {
        this._postHtmlCode = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        ImageBanner.fillAdditionalFields(this, source);
    }

    public clone(): ImageBanner {
        const newModel: ImageBanner = new ImageBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.clickUrl,
            this.size,
            this.file,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: ImageBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('clickUrl' in source && source.clickUrl !== null) {
            target.clickUrl = source.clickUrl;
        }
        if ('size' in source) {
            target.size = cloneDeep(source.size);
        }
        if ('file' in source) {
            target.file = cloneDeep(source.file);
        }
        if ('preHtmlCode' in source) {
            target.preHtmlCode = source.preHtmlCode;
        }
        if ('postHtmlCode' in source) {
            target.postHtmlCode = source.postHtmlCode;
        }
    }

    public static getImageSource(value: IBannerFile | null): string | null {
        // Image is a URL
        if (value === null) {
            return value;
        }
        // Newly uploaded image from the input
        if (value.data && value.type) {
            return convertApiObjectToBase64(value);
        }
        // Existing Image from the API
        if (value.path) {
            return replaceAditionPlaceholders(value.path);
        }

        return null;
    }
}

export {ImageBanner};
