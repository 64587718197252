import {BehaviorSubject, Observable} from 'rxjs';
import {scan} from 'rxjs/operators';

abstract class DataService<T> {
    protected subject: BehaviorSubject<T>;
    protected data: Observable<T>;

    protected constructor(private initialValue: T) {
        this.subject = new BehaviorSubject<T>(initialValue);
        this.data = this.subject
            .pipe(scan((acc: T, curr: T) => ({...acc, ...curr}), initialValue));
    }

    public getData(): Observable<T> {
        return this.data;
    }

    public setData(data: T): void {
        this.subject.next(data);
    }

    /**
     * Can be used if you need to reset the data to the initial state
     * e.g. when switching between routes that have the same service
     */
    public clear(): void {
        this.subject.next(this.initialValue);
    }

    /**
     * @deprecated you should really not use this method.
     * It is not working as you might expect. It will not return the entire data object but only the last change
     * that was made.
     * Only use this for backwards compatibility reasons or if you really know what you are doing
     */
    protected getLastValue(): T {
        return this.subject.getValue();
    }
}

export {DataService};
