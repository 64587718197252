import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CommonsError} from '@active-agent/types';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
    selector: 'commons-status-icon',
    templateUrl: './status-icon.html',
    styleUrls: ['./status-icon.scss'],
})
class CommonsStatusIconComponent implements OnInit, OnChanges {
    @Input() public status?: Status;
    @Input() public tooltip: string | undefined;
    @Input() public tooltipPosition: TooltipPosition = 'below';
    @Input() public title: string | undefined;
    @Input() public cursor: Cursor = Cursor.Default;
    @Input() public size: IconSize = IconSize.None;
    @Input() public grayscale: boolean = false;
    @Input() public brightScale: boolean = false;
    @Input() public customIcon: string | null = null;

    public statusEnum: typeof Status = Status;
    public cursorEnum: typeof Cursor = Cursor;
    public sizeEnum: typeof IconSize = IconSize;
    public icon: string = '';

    private readonly icons: Map<Status, IStatusIcon> = new Map([
        [Status.Success, {filled: 'check_circle', outline: 'check_circle_outline'}],
        [Status.Warning, {filled: 'warning', outline: 'warning_outline'}],
        [Status.Error, {filled: 'error', outline: 'error_outline'}],
        [Status.Info, {filled: 'info', outline: 'info_outline'}],
        [Status.New, {filled: 'fiber_new', outline: 'fiber_new_outline'}],
    ]);

    public ngOnInit(): void {
        if (!this.status && !this.customIcon) {
            throw new CommonsError('status component binding has to be provided');
        }
        this.initializeIcon();
    }

    public ngOnChanges(): void {
        this.initializeIcon();
    }

    private initializeIcon(): void {
        if (this.status) {
            const icon: IStatusIcon | undefined = this.icons.get(this.status);

            if (icon) {
                this.icon = this.grayscale
                    ? icon.outline
                    : icon.filled;
            } else {
                throw new CommonsError('No status icon found', {data: {status: this.status}});
            }
        } else if (this.customIcon) {
            this.icon = this.customIcon;
        }
    }
}

interface IStatusIcon {
    filled: string;
    outline: string;
}

enum Cursor {
    Default = 'default',
    Pointer = 'pointer',
    Help = 'help',
}

enum Status {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
    New = 'new',
}

enum IconSize {
    None = 'none',
    Small = 'small',
}

export {CommonsStatusIconComponent, Status, Cursor, IconSize};
