import {AccessibleFeatures} from '../feature/accessible-features';
import {ICloneable, IMergable} from '../types';
import {DilNetwork, IDilNetwork} from './dil-network.model';

class Network implements INetwork, IMergable<Network>, ICloneable<Network> {
    // since the default atv capping segment belongs to network 16, we can't change the price for this network
    public static readonly networkIdAtvSegmentCpmDisabled: number = 16;
    private _id: number | null;
    private _adFarmId: number | undefined = undefined;
    private _billingAddress: string | null = null;
    private _companyIds: Array<number> | undefined = undefined;
    private _deleted: boolean | undefined = undefined;
    private _isDmp: boolean | undefined = undefined;
    private _lastUpdate: number | undefined = undefined;
    private _name: string | undefined = undefined;
    private _dilNetworks: Array<DilNetwork> = [];
    private _adsquareCompanyId: string | null = null;
    private _odcZoneId: string | undefined = undefined;
    private _hostingFee: number | null = null;
    private _hasHostingFee: boolean = false;
    private _isEnabledForRedirectCampaigns: boolean = false;
    private _dailyBudget: number | null = null;
    private _monthlyBudget: number | null = null;
    private _prepaidBudget: number | null = null;
    private _somThirdPartyUplift: number | null = null;
    private _thirdPartyUplift: number | undefined;
    private _accessibleFeatures: Array<AccessibleFeatures> = [];

    constructor(id: number | null = null) {
        this._id = id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get id(): number | null {
        return this._id;
    }

    get adFarmId(): number | undefined {
        return this._adFarmId;
    }

    set adFarmId(value: number | undefined) {
        this._adFarmId = value;
    }

    get companyIds(): Array<number> | undefined {
        return this._companyIds;
    }

    set companyIds(value: Array<number> | undefined) {
        this._companyIds = value;
    }

    get isDmp(): boolean | undefined {
        return this._isDmp;
    }

    set isDmp(value: boolean | undefined) {
        this._isDmp = value;
    }

    get name(): string | undefined {
        return this._name;
    }

    set name(value: string | undefined) {
        this._name = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get billingAddress(): string | null {
        return this._billingAddress;
    }

    set billingAddress(value: string | null) {
        this._billingAddress = value;
    }

    get deleted(): boolean | undefined {
        return this._deleted;
    }

    set deleted(value: boolean | undefined) {
        this._deleted = value;
    }

    get dilNetworks(): Array<DilNetwork> {
        return this._dilNetworks;
    }

    set dilNetworks(value: Array<DilNetwork>) {
        this._dilNetworks = value;
    }

    public addDilNetwork(value: DilNetwork): void {
        this._dilNetworks.push(value);
    }

    get adsquareCompanyId(): string | null {
        return this._adsquareCompanyId;
    }

    set adsquareCompanyId(value: string | null) {
        this._adsquareCompanyId = value;
    }

    public isDil(): boolean {
        return !!this._dilNetworks.length;
    }

    public get hostingFee(): number | null {
        return this._hostingFee;
    }

    public set hostingFee(value: number | null) {
        this._hostingFee = value;
    }

    get odcZoneId(): string | undefined {
        return this._odcZoneId;
    }

    set odcZoneId(value: string | undefined) {
        this._odcZoneId = value;
    }

    get hasHostingFee(): boolean {
        return this._hasHostingFee;
    }

    set hasHostingFee(value: boolean) {
        this._hasHostingFee = value;
    }

    get isEnabledForRedirectCampaigns(): boolean {
        return this._isEnabledForRedirectCampaigns;
    }

    set isEnabledForRedirectCampaigns(value: boolean) {
        this._isEnabledForRedirectCampaigns = value;
    }

    public isDilOwner(): boolean {
        return this._dilNetworks.some((dilNetwork: DilNetwork) => {
            return Object.is(dilNetwork.ownerNetworkId, this._id);
        });
    }

    public isDilUser(): boolean {
        return this._dilNetworks.some((dilNetwork: DilNetwork) => {
            return Object.is(dilNetwork.userNetworkId, this._id);
        });
    }

    public getDilUserNetworks(): Array<DilNetwork> {
        return this._dilNetworks.filter((dilNetwork: DilNetwork) => {
            return Object.is(dilNetwork.ownerNetworkId, this._id);
        });
    }

    public get dailyBudget(): number | null {
        return this._dailyBudget;
    }

    public set dailyBudget(value: number | null) {
        this._dailyBudget = value;
    }

    public get monthlyBudget(): number | null {
        return this._monthlyBudget;
    }

    public set monthlyBudget(value: number | null) {
        this._monthlyBudget = value;
    }

    get prepaidBudget(): number | null {
        return this._prepaidBudget;
    }

    set prepaidBudget(value: number | null) {
        this._prepaidBudget = value;
    }

    public get thirdPartyUplift(): number | undefined {
        return this._thirdPartyUplift;
    }

    public set thirdPartyUplift(value: number | undefined) {
        this._thirdPartyUplift = value;
    }

    public get somThirdPartyUplift(): number | null {
        return this._somThirdPartyUplift;
    }

    public set somThirdPartyUplift(value: number | null) {
        this._somThirdPartyUplift = value;
    }

    public get accessibleFeatures(): Array<AccessibleFeatures> {
        return this._accessibleFeatures;
    }

    public set accessibleFeatures(value: Array<AccessibleFeatures>) {
        this._accessibleFeatures = value;
    }

    public merge(source: Network): void {
        this.adFarmId = source.adFarmId;
        this.adsquareCompanyId = source.adsquareCompanyId;
        this.billingAddress = source.billingAddress;
        this.companyIds = source.companyIds;
        this.deleted = source.deleted;
        this.isDmp = source.isDmp;
        this.hostingFee = source.hostingFee;
        this.odcZoneId = source.odcZoneId;
        this.lastUpdate = source.lastUpdate;
        this.name = source.name;
        this.hasHostingFee = source.hasHostingFee;
        this.dailyBudget = source.dailyBudget;
        this.monthlyBudget = source.monthlyBudget;
        this.prepaidBudget = source.prepaidBudget;
        this.thirdPartyUplift = source.thirdPartyUplift;
        this.somThirdPartyUplift = source.somThirdPartyUplift;
    }

    public clone(): Network {
        const newModel: Network = new Network(this.id);
        newModel.merge(this);

        return newModel;
    }

    /**
     * @deprecated Use new instance methods. Those are just still there for backwards compatibility
     */
    public static merge(modelOne: Network, modelTwo: Network): Network {
        modelOne.adFarmId = modelTwo.adFarmId;
        modelOne.billingAddress = modelTwo.billingAddress;
        modelOne.companyIds = modelTwo.companyIds;
        modelOne.deleted = modelTwo.deleted;
        modelOne.isDmp = modelTwo.isDmp;
        modelOne.hostingFee = modelTwo.hostingFee;
        modelOne.odcZoneId = modelTwo.odcZoneId;
        modelOne.lastUpdate = modelTwo.lastUpdate;
        modelOne.name = modelTwo.name;
        modelOne.hasHostingFee = modelTwo.hasHostingFee;
        modelOne.dailyBudget = modelTwo.dailyBudget;
        modelOne.monthlyBudget = modelTwo.monthlyBudget;
        modelOne.thirdPartyUplift = modelTwo.thirdPartyUplift;
        modelOne.somThirdPartyUplift = modelTwo.somThirdPartyUplift;

        return modelOne;
    }

    /**
     * @deprecated Use new instance methods. Those are just still there for backwards compatibility
     */
    public static clone(model: Network): Network {
        const newModel: Network = new Network(model.id);
        Network.merge(newModel, model);

        return newModel;
    }
}
interface INetwork {
    id: number | null;
    adFarmId: number | undefined;
    billingAddress: string | null;
    adsquareCompanyId: string | null;
    companyIds: Array<number> | undefined;
    deleted: boolean | undefined;
    isDmp: boolean | undefined;
    lastUpdate: number | undefined;
    name: string | undefined;
    dilNetworks: Array<IDilNetwork>;
    odcZoneId: string | undefined;
    hostingFee: number | null;
    hasHostingFee: boolean;
    isEnabledForRedirectCampaigns: boolean | undefined;
    dailyBudget: number | null;
    monthlyBudget: number | null;
    thirdPartyUplift: number | undefined;
}

export {Network, INetwork};
