import {AudioAsset} from './audio-asset';
import {VideoAsset} from './video-asset';
import {IAsset} from './asset.types';
import {ICloneable, IMergable} from '../types';
import {CommonsError} from '../error';

class BaseAsset implements IAsset, IMergable<BaseAsset>, ICloneable<BaseAsset> {
    private readonly _id: number | null;
    private _networkId: number;
    private _bannerIds: Array<number> = [];
    private _vastSettingsIds: Array<number> = [];
    private _fileName: string | undefined;
    private _fileSize: number | undefined;
    private _duration: number | undefined;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
    ) {
        this._id = id;
        this._networkId = networkId;
    }

    get id(): number | null {
        return this._id;
    }

    get type(): AssetType {
        throw new CommonsError('Type property is not available on base asset');
    }

    public get networkId(): number {
        return this._networkId;
    }

    public set networkId(value: number) {
        this._networkId = value;
    }

    public get bannerIds(): Array<number> {
        return this._bannerIds;
    }

    public set bannerIds(value: Array<number>) {
        this._bannerIds = value;
    }

    public get vastSettingsIds(): Array<number> {
        return this._vastSettingsIds;
    }

    public set vastSettingsIds(value: Array<number>) {
        this._vastSettingsIds = value;
    }

    public get fileName(): string | undefined {
        return this._fileName;
    }

    public set fileName(value: string | undefined) {
        this._fileName = value;
    }

    public get fileSize(): number | undefined {
        return this._fileSize;
    }

    public set fileSize(value: number | undefined) {
        this._fileSize = value;
    }

    public get duration(): number | undefined {
        return this._duration;
    }

    public set duration(value: number | undefined) {
        this._duration = value;
    }

    public get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    public set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    public get deleted(): boolean {
        return this._deleted;
    }

    public set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: BaseAsset): void {
        BaseAsset.fillAdditionalFields(this, source);
    }

    public clone(): BaseAsset {
        const newModel: BaseAsset = new BaseAsset(
            this.id,
            this.networkId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BaseAsset, source: BaseAsset): void {
        target.bannerIds = source.bannerIds;
        target.vastSettingsIds = source.vastSettingsIds;
        target.fileName = source.fileName;
        target.fileSize = source.fileSize;
        target.duration = source.duration;
        target.lastUpdate = source.lastUpdate;
        target.deleted = source.deleted;
    }
}

enum AssetType {
    Audio = 'AUDIO',
    Video = 'VIDEO',
}

type Asset = AudioAsset | VideoAsset;

export {BaseAsset, Asset, AssetType};
