import {InjectionToken} from '@angular/core';
import {ICommonsErrorHandler} from '@active-agent/error';
import {CommonsEventLog} from './event-log';

const EVENT_LOGGER: InjectionToken<ICommonsErrorHandler> = new InjectionToken<ICommonsErrorHandler>('event.logger');

interface ICommonsEventLogger {
    log(eventLog: CommonsEventLog): Promise<void> | void;
}

export {ICommonsEventLogger, EVENT_LOGGER};
