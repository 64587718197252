import {HttpClient} from '@angular/common/http';
import {CommonsDeleteRequestBuilder, ApiResource} from '@active-agent/api';

class DeleteOrderBuilder extends CommonsDeleteRequestBuilder {
    constructor(
        baseUrl: string,
        http: HttpClient,
    ) {
        super(http, ApiResource.Orders, baseUrl);
    }
}

export {DeleteOrderBuilder};
