import {
    Component, ChangeDetectionStrategy, OnInit, DestroyRef, ChangeDetectorRef, Injector,
} from '@angular/core';
import {CommonsContentComponent, LibsCardComponent, LibsSpacerModule} from '@active-agent/ui-components';
import {MatButtonModule} from '@angular/material/button';
import {LoadingDataService} from '@active-agent/global-loading-indicator';
import {CommonsEventLog, LibsEventLoggerService} from '@active-agent/event';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {first, interval} from 'rxjs';
import {ActivatedRoute, Data} from '@angular/router';

@Component({
    selector: 'aa-not-found-page',
    templateUrl: 'not-found-page.html',
    styleUrls: ['not-found-page.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        LibsCardComponent,
        LibsSpacerModule,
        MatButtonModule,
        CommonsContentComponent,
    ],
})
class AaNotFoundPageComponent implements OnInit {
    public countdown: number = 10;
    private routeData!: INotFoundPageActivatedRouteData;

    constructor(
        private injector: Injector,
        private loadingService: LoadingDataService,
        private eventLogger: LibsEventLoggerService,
        private detectorRef: ChangeDetectorRef,
        private destroyRef: DestroyRef,
        private activatedRoute: ActivatedRoute,
    ) {}

    public ngOnInit(): void {
        this.loadingService.setData({isLoading: false});

        this.eventLogger.log(new CommonsEventLog('page.not-found.init', {pathName: window.location.pathname}));

        interval(1000).pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((): void => {
            this.countdown -= 1;

            if (this.countdown <= 0) {
                this.goToDefaultPage();
            }

            this.detectorRef.detectChanges();
        });

        this.activatedRoute.data.pipe(
            first(),
        ).subscribe((data: Data): void => {
            if (!isNotFoundPageActivatedRouteData(data)) {
                return;
            }

            this.routeData = data;
        });
    }

    public getCountdownTranslation(): string {
        return this.routeData.translation
            ? this.routeData.translation.countdown(this.countdown)
            : $localize`:@@NOT_FOUND_PAGE_COUNTDOWN_TEXT:NOT_FOUND_PAGE_COUNTDOWN_TEXT ${this.countdown}:INTERPOLATION:`;
    }

    public getButtonTranslation(): string {
        return this.routeData.translation
            ? this.routeData.translation.button
            : $localize`:@@NOT_FOUND_PAGE_DASHBOARD_BUTTON:NOT_FOUND_PAGE_DASHBOARD_BUTTON`;
    }

    public goToDefaultPage(): void {
        this.routeData.goToDefaultPageCallback(this.injector);
    }
}

function isNotFoundPageActivatedRouteData(data: Data): data is INotFoundPageActivatedRouteData {
    return 'goToDefaultPageCallback' in data;
}

interface INotFoundPageActivatedRouteData {
    goToDefaultPageCallback: (injector: Injector) => void;
    translation?: {
        countdown(countdown: number): string;
        button: string;
    };
}

export {AaNotFoundPageComponent, INotFoundPageActivatedRouteData};
