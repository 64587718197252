import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IUrlFilterProperties extends IFilterProperties {
    urls: Array<string>;
    inverted: boolean;
}

class UrlFilter extends Filter<FilterTypes.URL> {
    protected readonly _type: FilterTypes.URL = FilterTypes.URL;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = true;
    protected readonly props: IUrlFilterProperties = this.getDefaultOptions();
    private _isValid: boolean = true;

    public updateValidStatus(newStatus: boolean): void {
        this._isValid = newStatus;
    }

    public isValid(): Observable<boolean> {
        return of(this._isValid);
    }

    public setIsValid(value: boolean): void{
        this._isValid = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return this.isInverted().pipe(map((inverted: boolean) => !inverted));
    }

    public isInverted(): Observable<boolean> {
        return of(this.props.inverted);
    }

    get inverted(): boolean {
        return this.props.inverted;
    }

    set inverted(value: boolean) {
        this.props.inverted = value;
    }

    get urls(): Array<string> {
        return this.props.urls;
    }

    set urls(value: Array<string>) {
        this.props.urls = value;
    }

    protected getDefaultOptions(): IUrlFilterProperties {
        return {
            ...super.getDefaultOptions(),
            urls: [],
            inverted: false,
        };
    }
}

export {UrlFilter, IUrlFilterProperties};
