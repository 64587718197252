import {NgModule} from '@angular/core';
import {LibsAdBlockerDetectorComponent} from './ad-blocker-detector.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {LibsAdBlockDetectorService} from './ad-block-detector.service';
import {CommonModule} from '@angular/common';
import {LibsManualModule} from '@active-agent/manual';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';

@NgModule({
    imports: [
        MatToolbarModule,
        MatButtonModule,
        CommonsStatusIconModule,
        CommonModule,
        LibsManualModule,
    ],
    exports: [
        LibsAdBlockerDetectorComponent,
    ],
    declarations: [
        LibsAdBlockerDetectorComponent,
    ],
    providers: [LibsAdBlockDetectorService],
})
class LibsAdBlockerDetectorModule {}

export {LibsAdBlockerDetectorModule};
