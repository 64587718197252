import {Banner, BaseBanner} from '../base-banner';
import {cloneDeep} from 'lodash-es';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {IBannerSize, IHtml5Banner} from '../banner';

class Html5Banner extends BaseBanner implements IHtml5Banner, ICloneable<Html5Banner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.HTML5;
    private _ssl: boolean = false;
    private _clickUrl: string;
    private _size: IBannerSize;
    private _data: string | null = null;
    private _indexFilePath: string | null = null;
    private _filePaths: Array<string> = [];
    private _clickTags: Map<string, string> = new Map();
    private _params: Map<string, string> = new Map();
    private _preHtmlCode: string = '';
    private _postHtmlCode: string = '';

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        size: IBannerSize,
    ) {
        super(id, networkId, name, landingPage);

        this._clickUrl = clickUrl;
        this._size = size;
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string {
        return this._clickUrl;
    }

    set clickUrl(value: string) {
        this._clickUrl = value;
    }

    get size(): IBannerSize {
        return this._size;
    }

    set size(value: IBannerSize) {
        this._size = value;
    }

    get data(): string | null {
        return this._data;
    }

    set data(value: string | null) {
        this._data = value;
    }

    get filePaths(): Array<string> {
        return this._filePaths;
    }

    set filePaths(value: Array<string>) {
        this._filePaths = value;
    }

    get indexFilePath(): string | null {
        return this._indexFilePath;
    }

    set indexFilePath(value: string | null) {
        this._indexFilePath = value;
    }

    get clickTags(): Map<string, string> {
        return this._clickTags;
    }

    set clickTags(value: Map<string, string>) {
        this._clickTags = value;
    }

    get params(): Map<string, string> {
        return this._params;
    }

    set params(value: Map<string, string>) {
        this._params = value;
    }

    get preHtmlCode(): string {
        return this._preHtmlCode;
    }

    set preHtmlCode(value: string) {
        this._preHtmlCode = value;
    }

    get postHtmlCode(): string {
        return this._postHtmlCode;
    }

    set postHtmlCode(value: string) {
        this._postHtmlCode = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        Html5Banner.fillAdditionalFields(this, source);
    }

    public clone(): Html5Banner {
        const newModel: Html5Banner = new Html5Banner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.clickUrl,
            this.size,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: Html5Banner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('clickUrl' in source && source.clickUrl !== null) {
            target.clickUrl = source.clickUrl;
        }
        if ('size' in source) {
            target.size = cloneDeep(source.size);
        }
        if ('data' in source) {
            target.data = source.data;
        }
        if ('filePaths' in source) {
            target.filePaths = cloneDeep(source.filePaths);
        }
        if ('indexFilePath' in source) {
            target.indexFilePath = source.indexFilePath;
        }
        if ('clickTags' in source) {
            target.clickTags = new Map(source.clickTags);
        }
        if ('params' in source) {
            target.params = new Map(source.params);
        }
        if ('preHtmlCode' in source) {
            target.preHtmlCode = source.preHtmlCode;
        }
        if ('postHtmlCode' in source) {
            target.postHtmlCode = source.postHtmlCode;
        }
    }
}

export {Html5Banner};
