<div
    class="spacer"

    [class.spacer__horizontal--none]="horizontal === 'none'"
    [class.spacer__horizontal--small]="horizontal === 'small'"
    [class.spacer__horizontal--medium]="horizontal === 'medium'"

    [class.spacer__vertical--none]="vertical === 'none'"
    [class.spacer__vertical--small]="vertical === 'small'"
    [class.spacer__vertical--medium]="vertical === 'medium'"
></div>
