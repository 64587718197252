import {Banner, BaseBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {IAtvSpotBanner} from '../banner';
import {BannerTypes} from '../banner-types';
import {Ssps} from '../../ssp/ssps';

class AtvSpotBanner extends BaseBanner implements IAtvSpotBanner, ICloneable<AtvSpotBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.ATV_SPOT;
    private _ssl: boolean = false;
    private _duration: number | null = null;
    private _videoId: number | null = null;
    private _hdVideoUrl: string | null = null;
    private _sdVideoUrl: string | null = null;
    private _trackingUrls: Array<string> = [];
    private _compatibleSsps: Array<Ssps> = [];
    private _externalId: string | null = null;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
    ) {
        super(id, networkId, name, landingPage);
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get videoId(): number | null {
        return this._videoId;
    }

    set videoId(value: number | null) {
        this._videoId = value;
    }

    get hdVideoUrl(): string | null {
        return this._hdVideoUrl;
    }

    set hdVideoUrl(value: string | null) {
        this._hdVideoUrl = value;
    }

    get sdVideoUrl(): string | null {
        return this._sdVideoUrl;
    }

    set sdVideoUrl(value: string | null) {
        this._sdVideoUrl = value;
    }

    get trackingUrls(): Array<string> {
        return this._trackingUrls;
    }

    set trackingUrls(value: Array<string>) {
        this._trackingUrls = value;
    }

    get compatibleSsps(): Array<Ssps> {
        return this._compatibleSsps;
    }

    set compatibleSsps(value: Array<Ssps>) {
        this._compatibleSsps = value;
    }

    get duration(): number | null {
        return this._duration;
    }

    set duration(value: number | null) {
        this._duration = value;
    }

    get externalId(): string | null {
        return this._externalId;
    }

    set externalId(value: string | null) {
        this._externalId = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        AtvSpotBanner.fillAdditionalFields(this, source);
    }

    public clone(): AtvSpotBanner {
        const newModel: AtvSpotBanner = new AtvSpotBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: AtvSpotBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('videoId' in source) {
            target.videoId = source.videoId;
        }
        if ('hdVideoUrl' in source) {
            target.hdVideoUrl = source.hdVideoUrl;
        }
        if ('sdVideoUrl' in source) {
            target.sdVideoUrl = source.sdVideoUrl;
        }
        if ('trackingUrls' in source) {
            target.trackingUrls = source.trackingUrls;
        }
        if ('duration' in source) {
            target.duration = source.duration;
        }
        if ('externalId' in source) {
            target.externalId = source.externalId;
        }
        if ('compatibleSsps' in source && Array.isArray(source.compatibleSsps)) {
            target.compatibleSsps = source.compatibleSsps.map((ssp: Ssps) => ssp);
        }
    }
}

export {AtvSpotBanner};
