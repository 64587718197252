/** Checks if all files are in the same top level folder. */
function filePathsContainsSameTopLevelDirectory([firstPath, ...paths]: Array<string>): boolean {
    const folderName: string | undefined = firstPath?.split('/')[0];

    return paths.every((value: string) => {
        return value.split('/')[0] === folderName;
    });
}

export {filePathsContainsSameTopLevelDirectory};
