<mat-icon
    class="status-icon"
    [ngClass]="{
        'status-icon--success': status === statusEnum.Success,
        'status-icon--warning': status === statusEnum.Warning,
        'status-icon--error': status === statusEnum.Error,
        'status-icon--info': status === statusEnum.Info,
        'status-icon--new': status === statusEnum.New,

        'status-icon--cursor-default': cursor === cursorEnum.Default,
        'status-icon--cursor-pointer': cursor === cursorEnum.Pointer,
        'status-icon--cursor-help': cursor === cursorEnum.Help,

        'status-icon--small': size === sizeEnum.Small,

        'status-icon--grayscale': grayscale,
        'status-icon--bright-scale': brightScale,
    }"
    title="{{title}}"
    [matTooltip]="tooltip || ''"
    [matTooltipPosition]="tooltipPosition"
    matTooltipClass="tooltip"
    [matTooltipDisabled]="!tooltip"
>{{icon}}</mat-icon>

