import {CommonsError} from '@active-agent/types';

class TransformHelper {

    /**
     * Costs are given in cents and are transformed to Euros with 2 decimal digits.
     */
    public static centToEuro(value: number): number {
        if (!Number.isFinite(value)) {
            throw new CommonsError('The given parameter is not a number.');
        }
        const transformedValue: number = value / 100;

        return Number(transformedValue.toFixed(2));
    }

    /**
     * Costs are given in euros and are transformed to cent with 2 decimal digits.
     */
    public static euroToCent(value: number): number {
        if (!Number.isFinite(value)) {
            throw new CommonsError('The given parameter is not a number.');
        }
        const transformedValue: number = value * 100;

        return Number(transformedValue.toFixed(2));
    }

    /**
     * Costs are given in millicents and are transformed to Euros with 2 decimal digits.
     */
    public static millicentToEuro(value: number): number {
        if (!Number.isFinite(value)) {
            throw new CommonsError('The given parameter is not a number.');
        }

        return TransformHelper.centToEuro(value / 1_000);
    }

    /**
     * Costs are given in micro cents and are transformed to Euros with 2 decimal digits.
     */
    public static microCentToEuro(value: number): number {
        if (!Number.isFinite(value)) {
            throw new CommonsError('The given parameter is not a number.');
        }

        return TransformHelper.centToEuro(value / 1_000 / 1_000);
    }

    /**
     * Costs are given in cents and are transformed to Euros with 2 decimal digits.
     */
    public static millicentToCent(value: number): number {
        if (!Number.isFinite(value)) {
            throw new CommonsError('The given parameter is not a number.');
        }
        const transformedValue: number = value / 1_000;

        return Number(transformedValue.toFixed(2));
    }
}

export {TransformHelper};
