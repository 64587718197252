import {NgModule} from '@angular/core';
import {CoreCookieModule} from '@angular-clan/core';

@NgModule({
    imports: [
        CoreCookieModule,
    ],
    exports: [],
    declarations: [],
    providers: [],
})
class AaGuardsModule {}

export {AaGuardsModule};
