import {Order, IRouterHandlerData} from '@active-agent/types';
import {InjectionToken} from '@angular/core';

const FOOTFALL_ROUTES_CONFIGURATION: InjectionToken<IFootfallRoutesConfiguration>
    = new InjectionToken<IFootfallRoutesConfiguration>('footfall-routes.config');

interface IFootfallRoutesConfiguration {
    storeSettings: IFootfallRouteConfiguration<IFootfallStoreSettingsRouteData>;
}

interface IFootfallRouteConfiguration<TData> {
    getDataForLink(data: TData): IRouterHandlerData;
}

interface IFootfallStoreSettingsRouteData {
    order: Order;
}

export {
    FOOTFALL_ROUTES_CONFIGURATION,
    type IFootfallStoreSettingsRouteData,
    type IFootfallRoutesConfiguration,
    type IFootfallRouteConfiguration,
};
