/* parser generated by jison 0.3.0 */
/**
 * Returns a Parser implementing JisonParserApi and a Lexer implementing JisonLexerApi.
 */
import { JisonParser, JisonParserApi, StateType, SymbolsType, TerminalsType, ProductionsType, o } from '@ts-jison/parser';const $V0=[1,5],$V1=[1,8],$V2=[5,7,9],$V3=[1,19];

export class JisonParserParser extends JisonParser implements JisonParserApi {
    $?: any;

    constructor (yy = {}, lexer = new JisonParserLexer(yy)) {
      super(yy, lexer);
    }

    symbols_: SymbolsType = {"error":2,"exp":3,"contents":4,"EOF":5,"VIDEO_PLACEMENT_GROUP":6,"OR":7,"(":8,")":9,"VIDEO_PLACEMENT_WITH_START_POSITIONS":10,"VIDEO_PLACEMENT":11,"AND":12,"START_POSITION_GROUP":13,"VIDEO_PLACEMENT_KEY":14,"=":15,"STRING_VALUE":16,"START_POSITION":17,"START_POSITION_KEY":18,"$accept":0,"$end":1};
    terminals_: TerminalsType = {2:"error",5:"EOF",7:"OR",8:"(",9:")",12:"AND",14:"VIDEO_PLACEMENT_KEY",15:"=",16:"STRING_VALUE",18:"START_POSITION_KEY"};
    productions_: ProductionsType = [0,[3,2],[3,1],[4,1],[4,3],[6,3],[6,1],[6,1],[10,5],[10,3],[11,3],[13,1],[13,3],[17,3]];
    table: Array<StateType> = [{3:1,4:2,5:[1,3],6:4,8:$V0,10:6,11:7,14:$V1},{1:[3]},{5:[1,9]},{1:[2,2]},{5:[2,3],7:[1,10]},{6:11,8:$V0,10:6,11:7,14:$V1},o($V2,[2,6]),o($V2,[2,7],{12:[1,12]}),{15:[1,13]},{1:[2,1]},{4:14,6:4,8:$V0,10:6,11:7,14:$V1},{9:[1,15]},{8:[1,16],13:17,17:18,18:$V3},{16:[1,20]},{5:[2,4]},o($V2,[2,5]),{13:21,17:18,18:$V3},o($V2,[2,9]),o($V2,[2,11]),{15:[1,23]},o([5,7,9,12],[2,10]),{7:[1,22],9:[1,24]},{13:25,17:18,18:$V3},{16:[1,26]},o($V2,[2,8]),o($V2,[2,12]),o($V2,[2,13])];
    defaultActions: {[key:number]: any} = {3:[2,2],9:[2,1],14:[2,4]};

    performAction (yytext:string, yyleng:number, yylineno:number, yy:any, yystate:number /* action[1] */, $$:any /* vstack */, _$:any /* lstack */): any {
/* this == yyval */
          var $0 = $$.length - 1;
        switch (yystate) {
case 1:
return [...$$[$0-1]];
break;
case 2:
return [];
break;
case 4: case 12:
this.$ = $$[$0-2].concat($$[$0]);
break;
case 5:
this.$ = $$[$0-1];
break;
case 8:

            $$[$0-4][0].startPositions.push(...$$[$0-1]);
            this.$ = $$[$0-4];
        
break;
case 9:

            $$[$0-2][0].startPositions.push(...$$[$0]);
            this.$ = $$[$0-2];
        
break;
case 10:

            const videoPlacement = {
                videoPlacement: $$[$0],
                startPositions: [],
            };

            this.$ = [videoPlacement];
        
break;
case 13:
this.$ = [$$[$0]];
break;
        }
    }
}


/* generated by ts-jison-lex 0.3.0 */
import { JisonLexer, JisonLexerApi } from '@ts-jison/lexer';
export class JisonParserLexer extends JisonLexer implements JisonLexerApi {
    options: any = {"moduleName":"JisonParser"};
    constructor (yy = {}) {
        super(yy);
    }

    rules: RegExp[] = [/^(?:^("|')[a-z][a-zA-Z-]*("|'))/,/^(?:startPosition\b)/,/^(?:videoPlacement\b)/,/^(?:=)/,/^(?:[aA][nN][dD])/,/^(?:[oO][rR])/,/^(?:\()/,/^(?:\))/,/^(?:\s+)/,/^(?:$)/];
    conditions: any = {"INITIAL":{"rules":[0,1,2,3,4,5,6,7,8,9],"inclusive":true}}
    performAction (yy:any,yy_:any,$avoiding_name_collisions:any,YY_START:any): any {
          var YYSTATE=YY_START;
        switch($avoiding_name_collisions) {
    case 0: yy_.yytext = yy_.yytext.replace(/['"]+/g, '').trim(); return 16; 
      break;
    case 1: return 18; 
      break;
    case 2: return 14; 
      break;
    case 3:return 15
      break;
    case 4:return 12;
      break;
    case 5:return 7;
      break;
    case 6:return 8;
      break;
    case 7:return 9;
      break;
    case 8:/* skip whitespace */
      break;
    case 9:return 5;
      break;
        }
    }
}

