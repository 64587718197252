import {VastEvent} from '../vast-event/vast-event';
import {Banner, BaseBanner} from '../base-banner';
import {BannerTypes} from '../banner-types';
import {VideoVastSettings} from '../../vast-settings/video-vast-settings';
import {DoohVastSettings} from '../../vast-settings/dooh-vast-settings';
import {VastSettingsPreset} from '../../vast-settings/vast-settings.service';
import {VastVersion} from '../../vast-versions/vast-version';
import {IMergable} from '../../types';
import {IVideoBanner} from '../banner';

abstract class BaseVideoBanner extends BaseBanner implements IVideoBanner, IMergable<Banner> {
    public abstract vastSettings: VideoVastSettings | DoohVastSettings;
    private readonly _type: BannerTypes;
    private _ssl: boolean = false;
    private _clickUrl: string;
    private _assetId: number;
    private _vastEvents: Array<VastEvent> = [];
    private _vastXml: string | undefined;
    private _vastExtension: string | null = null;
    private _vastTemplateId: VastSettingsPreset;
    private _vastVersion: VastVersion | string;

    protected constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        assetId: number,
        vastTemplateId: VastSettingsPreset,
        type: BannerTypes,
        vastVersion: VastVersion | string,
    ) {
        super(id, networkId, name, landingPage);

        this._type = type;
        this._clickUrl = clickUrl;
        this._assetId = assetId;
        this._vastTemplateId = vastTemplateId;
        this._vastVersion = vastVersion;
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string {
        return this._clickUrl;
    }

    set clickUrl(value: string) {
        this._clickUrl = value;
    }

    public get assetId(): number {
        return this._assetId;
    }

    public set assetId(value: number) {
        this._assetId = value;
    }

    public get vastEvents(): Array<VastEvent> {
        return this._vastEvents;
    }

    public set vastEvents(value: Array<VastEvent>) {
        this._vastEvents = value;
    }

    public get vastXml(): string | undefined {
        return this._vastXml;
    }

    public set vastXml(value: string | undefined) {
        this._vastXml = value;
    }

    public get vastExtension(): string | null {
        return this._vastExtension;
    }

    public set vastExtension(value: string | null) {
        this._vastExtension = value;
    }

    public get vastTemplateId(): VastSettingsPreset {
        return this._vastTemplateId;
    }

    public set vastTemplateId(value: VastSettingsPreset) {
        this._vastTemplateId = value;
    }

    get vastVersion(): VastVersion | string {
        return this._vastVersion;
    }

    set vastVersion(value: VastVersion | string) {
        this._vastVersion = value;
    }

    public merge(source: Banner): void {
        super.merge(source);

        BaseVideoBanner.fillAdditionalFields(this, source);
    }

    public static fillAdditionalFields(target: BaseVideoBanner, source: Banner | BaseBanner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('clickUrl' in source && source.clickUrl !== null) {
            target.clickUrl = source.clickUrl;
        }
        if ('assetId' in source) {
            target.assetId = source.assetId;
        }
        if (
            'vastSettings' in source && source instanceof BaseVideoBanner
        ) {
            target.vastSettings = source.vastSettings.clone();
        }
        if ('vastEvents' in source) {
            target.vastEvents = source.vastEvents.map((vastEvent: VastEvent) => vastEvent.clone());
        }
        if ('vastXml' in source) {
            target.vastXml = source.vastXml;
        }
        if ('vastExtension' in source) {
            target.vastExtension = source.vastExtension;
        }
        if ('vastTemplateId' in source) {
            target.vastTemplateId = source.vastTemplateId;
        }
        if ('vastVersion' in source) {
            target.vastVersion = source.vastVersion;
        }
    }
}

export {BaseVideoBanner};
