import {HttpClient} from '@angular/common/http';
import {CommonsGetRequestBuilder} from './get-request-builder';
import {defaultChunkAggregator} from './dsp-api-get-request-builder';

class CommonsGetRequestBuilderWithChunk extends CommonsGetRequestBuilder {
    public static apiRequestChunkSize: number = 800;

    public constructor(
        http: HttpClient,
        networkIds: Array<number>,
        resourcePath: string,
        protected baseUrl: string,
    ) {
        super(http, resourcePath, baseUrl);

        this.addQueryParam({key: 'networkIds', value: networkIds});
        this.setChunkSize(CommonsGetRequestBuilderWithChunk.apiRequestChunkSize);
        this.setChunkAggregator(defaultChunkAggregator);
    }
}

export {CommonsGetRequestBuilderWithChunk};
