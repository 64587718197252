import {RedirectBanner} from './redirect-banner';
import {CampaignBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {IBannerSize, ICampaignBanner} from '../banner';

class CampaignRedirectBanner extends RedirectBanner implements
    ICampaignBanner,
    IMergable<CampaignBanner>,
    ICloneable<CampaignRedirectBanner> {

    private _campaignId: number;
    private _parentBannerId: number | null = null;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        redirectTag: string,
        size: IBannerSize,
        campaignId: number,
    ) {
        super(id, networkId, name, landingPage, redirectTag, size);
        this._campaignId = campaignId;
    }

    get campaignId(): number {
        return this._campaignId;
    }

    set campaignId(value: number) {
        this._campaignId = value;
    }

    get parentBannerId(): number | null {
        return this._parentBannerId;
    }

    set parentBannerId(value: number | null) {
        this._parentBannerId = value;
    }

    public merge(source: CampaignBanner): void {
        super.merge(source);
        CampaignRedirectBanner.fillAdditionalFields(this, source);
    }

    public clone(): CampaignRedirectBanner {
        const newModel: CampaignRedirectBanner = new CampaignRedirectBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.redirectTag,
            this.size,
            this.campaignId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: CampaignRedirectBanner, source: CampaignBanner): void {
        RedirectBanner.fillAdditionalFields(target, source);

        target.campaignId = source.campaignId;
        target.parentBannerId = source.parentBannerId;
    }
}

export {CampaignRedirectBanner};
