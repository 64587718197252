import {BannerPoolBanner} from '../base-banner';
import {VideoBanner} from './video-banner';
import {VideoVastSettings} from '../../vast-settings/video-vast-settings';
import {VastSettingsPreset} from '../../vast-settings/vast-settings.service';
import {VastVersion} from '../../vast-versions/vast-version';
import {ICloneable, IMergable} from '../../types';
import {IBannerPoolVideoBanner} from '../banner';

class BannerPoolVideoBanner extends VideoBanner implements
    IBannerPoolVideoBanner,
    ICloneable<BannerPoolVideoBanner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        assetId: number,
        vastSettings: VideoVastSettings,
        vastTemplateId: VastSettingsPreset,
        vastVersion: VastVersion | string,
        bannerPoolId: number,
    ) {
        super(id, networkId, name, landingPage, clickUrl, assetId, vastSettings, vastTemplateId, vastVersion);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolVideoBanner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolVideoBanner {
        const newModel: BannerPoolVideoBanner = new BannerPoolVideoBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.clickUrl,
            this.assetId,
            this.vastSettings,
            this.vastTemplateId,
            this.vastVersion,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolVideoBanner, source: BannerPoolBanner): void {
        VideoBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolVideoBanner};
