import {AudioVastSettings} from './audio-vast-settings';
import {DoohVastSettings} from './dooh-vast-settings';
import {VideoVastSettings} from './video-vast-settings';
import {CommonsError} from '../error';
import {IVastSettings} from '../banner';
import {ICloneable, IMergable} from '../types';

class BaseVastSettings implements IVastSettings, IMergable<BaseVastSettings>, ICloneable<BaseVastSettings> {
    private readonly _id: number | null;
    private _networkId: number;
    private _assetId: number;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        assetId: number,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._assetId = assetId;
    }

    get id(): number | null {
        return this._id;
    }

    get type(): VastSettingsType {
        throw new CommonsError('Type property is not available on base vast settings');
    }

    public get networkId(): number {
        return this._networkId;
    }

    public set networkId(value: number) {
        this._networkId = value;
    }

    public get assetId(): number {
        return this._assetId;
    }

    public set assetId(value: number) {
        this._assetId = value;
    }

    public get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    public set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    public get deleted(): boolean {
        return this._deleted;
    }

    public set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: BaseVastSettings): void {
        BaseVastSettings.fillAdditionalFields(this, source);
    }

    public clone(): BaseVastSettings {
        const newModel: BaseVastSettings = new BaseVastSettings(
            this.id,
            this.networkId,
            this.assetId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BaseVastSettings, source: BaseVastSettings | VastSettings): void {
        target.lastUpdate = source.lastUpdate;
        target.deleted = source.deleted;
    }
}

enum VastSettingsType {
    Audio = 'AUDIO',
    Video = 'VIDEO',
    Dooh = 'DOOH',
}

type VastSettings = AudioVastSettings | VideoVastSettings | DoohVastSettings;

export {BaseVastSettings, VastSettingsType, VastSettings};
