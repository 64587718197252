import {RedirectBanner} from './redirect-banner';
import {BannerPoolBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {IBannerPoolBanner, IBannerSize} from '../banner';

class BannerPoolRedirectBanner extends RedirectBanner implements
    IBannerPoolBanner,
    ICloneable<BannerPoolRedirectBanner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        redirectTag: string,
        size: IBannerSize,
        bannerPoolId: number,
    ) {
        super(id, networkId, name, landingPage, redirectTag, size);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolRedirectBanner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolRedirectBanner {
        const newModel: BannerPoolRedirectBanner = new BannerPoolRedirectBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.redirectTag,
            this.size,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolRedirectBanner, source: BannerPoolBanner): void {
        RedirectBanner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolRedirectBanner};
