import {ContextualFilter, IContextualFilterProperties} from '../types/contextual-filter';
import {FilterTypes} from '../types/filter.types';
import {of, Observable} from 'rxjs';
import {SegmentExpressionOperator} from '../types/targeting-segment';

type AdsquareFilterProperties = IContextualFilterProperties;

class AdsquareFilter extends ContextualFilter<FilterTypes.ADSQUARE_GEO_CONTEXTUAL> {
    public static readonly expressionOperator: SegmentExpressionOperator = 'AND';
    protected readonly _type: FilterTypes.ADSQUARE_GEO_CONTEXTUAL = FilterTypes.ADSQUARE_GEO_CONTEXTUAL;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: AdsquareFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        if (!this.expression.length) {
            return of(false);
        }

        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }
}

export {AdsquareFilter};

