import {Injectable} from '@angular/core';
import {LibsErrorHandlerService} from '@active-agent/error';
import {environment} from '../../../environments/environment';
import {NotificationService} from '@active-agent/notification';
import {Router} from '@angular/router';
import {CoreCookieService} from '@angular-clan/core';

@Injectable({
    providedIn: 'root',
})
class ErrorHandlerService extends LibsErrorHandlerService {
    constructor(
        notificationService: NotificationService,
        router: Router,
        cookieService: CoreCookieService,
    ) {
        super(notificationService, router, environment, cookieService);
    }
}

export {ErrorHandlerService};
