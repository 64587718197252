import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class LatitudeValidator implements Validator {
    private static readonly max: number = 85;
    private static readonly min: number = -85;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const latitude: number = control.value;

        if (latitude === undefined || latitude === null) {
            return {[LatitudeErrorCodes.Required]: this.getTranslationForErrorCode(LatitudeErrorCodes.Required)};
        }

        if (!Number.isFinite(latitude)) {
            return {[LatitudeErrorCodes.InvalidFormat]: this.getTranslationForErrorCode(LatitudeErrorCodes.InvalidFormat)};
        }

        if (latitude < LatitudeValidator.min) {
            return {[LatitudeErrorCodes.Min]: this.getTranslationForErrorCode(LatitudeErrorCodes.Min)};
        }

        if (latitude > LatitudeValidator.max) {
            return {[LatitudeErrorCodes.Max]: this.getTranslationForErrorCode(LatitudeErrorCodes.Max)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: LatitudeErrorCodes): string {
        switch (code) {
            case LatitudeErrorCodes.Required:
                return $localize`:@@LATITUDE_VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:LATITUDE_VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case LatitudeErrorCodes.InvalidFormat:
                return $localize`:@@LATITUDE_VALIDATOR_INVALID_FORMAT_ERROR_MESSAGE:LATITUDE_VALIDATOR_INVALID_FORMAT_ERROR_MESSAGE`;
            case LatitudeErrorCodes.Min:
                return $localize`:@@LATITUDE_VALIDATOR_MIN_ERROR_MESSAGE:LATITUDE_VALIDATOR_MIN_ERROR_MESSAGE ${this.decimalPipe.transform(LatitudeValidator.min)}:INTERPOLATION:`;
            case LatitudeErrorCodes.Max:
                return $localize`:@@LATITUDE_VALIDATOR_MAX_ERROR_MESSAGE:LATITUDE_VALIDATOR_MAX_ERROR_MESSAGE ${this.decimalPipe.transform(LatitudeValidator.max)}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum LatitudeErrorCodes {
    Required = '5e1ee50f-2b5c-4da9-b757-52c9b14b41b4',
    InvalidFormat = '46da913a-017d-4486-ab6f-e5b9dbb7ae77',
    Min = 'bc1464b4-1086-4b04-976c-03d30363c3cb',
    Max = '3115edfb-908f-4a2f-aeb9-274768e44fb6',
}

export {LatitudeValidator};
