import {ICloneable, IMergable} from '../types';
import {ExternalDealTypes} from './external-deal-types';
import {Ssps} from '../ssp/ssps';
import {IExternalDeal} from './external-deal.types';

class ExternalDeal implements IExternalDeal, IMergable<ExternalDeal>, ICloneable<ExternalDeal> {
    private readonly _id: number | null;
    private _networkId: number;
    private _name: string;
    private _dealId: string;
    private _ssp: string;
    private _type: ExternalDealTypes;
    private _description: string | null = null;
    private _maxCpm: number | null = null;
    private _companyIds: Array<number> = [];
    private _campaignDealIds: Array<number> = [];
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;
    private _isDirectlyBilled: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        dealId: string,
        ssp: string,
        type: ExternalDealTypes,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._name = name;
        this._dealId = dealId;
        this._ssp = ssp;
        this._type = type;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get dealId(): string {
        return this._dealId;
    }

    set dealId(value: string) {
        this._dealId = value;
    }

    get ssp(): string {
        return this._ssp;
    }

    set ssp(value: string) {
        this._ssp = value;
    }

    get type(): ExternalDealTypes {
        return this._type;
    }

    set type(value: ExternalDealTypes) {
        this._type = value;
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get maxCpm(): number | null {
        return this._maxCpm;
    }

    set maxCpm(value: number | null) {
        this._maxCpm = value;
    }

    get companyIds(): Array<number> {
        return this._companyIds;
    }

    set companyIds(value: Array<number>) {
        this._companyIds = value;
    }

    get campaignDealIds(): Array<number> {
        return this._campaignDealIds;
    }

    set campaignDealIds(value: Array<number>) {
        this._campaignDealIds = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get isDirectlyBilled(): boolean {
        return this._isDirectlyBilled;
    }

    set isDirectlyBilled(value: boolean) {
        this._isDirectlyBilled = value;
    }

    public clone(): ExternalDeal {
        const newModel: ExternalDeal = new ExternalDeal(
            this.id,
            this.networkId,
            this.name,
            this.dealId,
            this.ssp,
            this.type,
        );
        newModel.merge(this);

        return newModel;
    }

    public merge(source: ExternalDeal): void {
        this.networkId = source.networkId;
        this.name = source.name;
        this.dealId = source.dealId;
        this.ssp = source.ssp;
        this.type = source.type;
        this.description = source.description;
        this.maxCpm = source.maxCpm;
        this.companyIds = source.companyIds.map((id: number) => id);
        this.campaignDealIds = source.campaignDealIds.map((id: number) => id);
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
        this.isDirectlyBilled = source.isDirectlyBilled;
    }
}

/**
 * Sort deals by name
 * By that we always take the first found deal
 */
function getExternalDealNamesByDealId(deals: Array<ExternalDeal>): Map<string, IExternalDealNamesByDealIds> {
    return deals
        // We need to sort the external deals by name to use the first one when SSP option isn't selected
        .sort((a: ExternalDeal, b: ExternalDeal) => a.name.localeCompare(b.name))
        .reduce((list: Map<string, IExternalDealNamesByDealIds>, item: ExternalDeal) => {
            const entry: IExternalDealNamesByDealIds = list.get(item.dealId) || {
                firstExternalDeal: item,
                externalDealsBySsps: {},
            };

            if (!entry.externalDealsBySsps[item.ssp]) {
                entry.externalDealsBySsps[item.ssp] = item;
            }

            if (!list.has(item.dealId)) {
                list.set(item.dealId, entry);
            }

            return list;
        }, new Map());
}

interface IExternalDealNamesByDealIds {
    firstExternalDeal: ExternalDeal;
    externalDealsBySsps: {[key in Ssps]?: ExternalDeal};
}

export {ExternalDeal, getExternalDealNamesByDealId, IExternalDealNamesByDealIds};
