import {Observable, of} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IPolygonFilterProperties extends IFilterProperties {
    externalPolygonIds: Array<string>;
    polygonImportFormat: ImportFormat;
}

class PolygonFilter extends Filter<FilterTypes.POLYGON> {
    protected readonly _type: FilterTypes.POLYGON = FilterTypes.POLYGON;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IPolygonFilterProperties = this.getDefaultOptions();

    public isValid(): Observable<boolean> {
        if (!this.externalPolygonIds.length) {
            return of(false);
        }

        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get externalPolygonIds(): Array<string> {
        return this.props.externalPolygonIds;
    }

    set externalPolygonIds(value: Array<string>) {
        this.props.externalPolygonIds = value;
    }

    get polygonImportFormat(): ImportFormat {
        return this.props.polygonImportFormat;
    }

    set polygonImportFormat(value: ImportFormat) {
        this.props.polygonImportFormat = value;
    }

    protected getDefaultOptions(): IPolygonFilterProperties {
        return {
            ...super.getDefaultOptions(),
            externalPolygonIds: [],
            polygonImportFormat: ImportFormat.PLZ8,
        };
    }
}

enum ImportFormat {
    PLZ8 = 'PLZ8',
}

export {PolygonFilter, IPolygonFilterProperties, ImportFormat};
