<div
    class="widget mat-elevation-z2"
    [class.widget--has-margin]="hasMargin"
    [class.widget--small]="widgetSize === size.Small"
    [class.widget--small-medium]="widgetSize === size.SmallMedium"
    [class.widget--medium]="widgetSize === size.Medium"
    [class.widget--large]="widgetSize === size.Large"
    [class.widget--xlarge]="widgetSize === size.XLarge"
>
    <div class="widget__text-container">
        <ng-content select="[lib-widget-text]"></ng-content>
    </div>
    <div class="widget__text-container widget__text-container--small">
        <ng-content select="[lib-widget-text-small]"></ng-content>
    </div>
    <div class="widget__content-container">
        <ng-content select="[lib-widget-content]"></ng-content>
    </div>
    <div
        *ngIf="isLoading"
        class="widget__loading-container"
    >
        <mat-progress-spinner
            [mode]="spinnerConfig.mode"
            [color]="spinnerConfig.color"
            [diameter]="spinnerConfig.diameter"
        ></mat-progress-spinner>
    </div>
    <ng-container *ngIf="showTemplateRef">
        <ng-container *ngTemplateOutlet="childContent"></ng-container>
    </ng-container>
</div>
