import {Component, Inject} from '@angular/core';
import {filter, take} from 'rxjs/operators';
import {ThemePalette} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Status} from '../status-icon/status-icon.component';
import {ConfirmationStatus, IInfoBoxContent, IDialogConfirmationData} from '@active-agent/types';

@Component({
    selector: 'libs-confirmation-dialog',
    templateUrl: './confirmation-dialog.html',
    styleUrls: ['./confirmation-dialog.scss'],
})
class LibsConfirmationDialogComponent {
    public checkbox: boolean = false;
    public primaryButtonColor: ThemePalette = 'primary';
    public infoBox: IInfoBoxContent | undefined;
    public readonly infoStatus: Status = Status.Info;
    public readonly errorStatus: Status = Status.Error;
    public readonly warningStatus: Status = Status.Warning;

    constructor(
        public dialogRef: MatDialogRef<LibsConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogConfirmationData,
    ) {
        this.dialogRef.addPanelClass('dialog-container');

        if (data.status) {
            this.dialogRef.addPanelClass(`dialog-container--status-${data.status}`);

            if (this.isErrorStatus()) {
                this.primaryButtonColor = 'warn';
            }
        }

        if (data.infoBox) {
            this.infoBox = data.infoBox;
        }

        if (this.data.hasCancelButton === undefined) {
            this.data.hasCancelButton = true;
        }

        if (!this.dialogRef.disableClose) {
            this.dialogRef.backdropClick().subscribe(() => {
                if (this.data.hasCancelButton) {
                    this.close();
                } else {
                    this.dialogRef.close(this.getResultValue(true));
                }
            });

            // close on click escape
            this.dialogRef.keydownEvents()
                .pipe(
                    filter((e: KeyboardEvent) => e.code === 'Escape'),
                    take(1),
                )
                .subscribe((e: KeyboardEvent) => {
                    // Prevents that other listeners will be triggered
                    e.stopPropagation();

                    this.close();
                });
        }
    }

    public close(): void {
        this.dialogRef.close(this.getResultValue(false));
    }

    public getResultValue(value: boolean): boolean | IDialogConfirmationAdvancedResult {
        if (this.data.checkboxLabel !== undefined) {
            return {
                confirm: value,
                isCheckboxChecked: this.checkbox,
            };
        }

        return value;
    }

    public isWarningStatus(): boolean {
        return !!(this.data.status && this.data.status === ConfirmationStatus.Warning);
    }

    public isErrorStatus(): boolean {
        return !!(this.data.status && this.data.status === ConfirmationStatus.Error);
    }

    public isInfoStatus(): boolean {
        return !!(this.data.status && this.data.status === ConfirmationStatus.Info);
    }
}

/**
 * This interface is going to be returned if the dialog contains a checkbox (checkboxLabel is set)
 */
interface IDialogConfirmationAdvancedResult {
    confirm: boolean;
    isCheckboxChecked: boolean;
}

export {
    LibsConfirmationDialogComponent,
    IDialogConfirmationData,
    IDialogConfirmationAdvancedResult,
    ConfirmationStatus,
    IInfoBoxContent,
};
