import {BannerTypes} from '../../banner';
import {CommonsError} from '../../error';
import {FilterTypes} from '../types';

/**
 * Defines the available filter types for each banner type, it's only used for banners of redirect campaign.
 */
export function getAvailableTypesByBannerForRedirectCampaign(type: BannerTypes): Array<FilterTypes> {
    switch (type) {
        case BannerTypes.DOOH:
            return [
                FilterTypes.PROFILE,
                FilterTypes.TIME,
            ];
        case BannerTypes.AUDIO:
            return [
                FilterTypes.TAGGING,
                FilterTypes.CAPPING,
                FilterTypes.GRAPH_CAPPING,
                FilterTypes.PROFILE,
                FilterTypes.OS,
                FilterTypes.BROWSER,
                FilterTypes.AUDIO,
                FilterTypes.TIME,
                FilterTypes.PLATFORM,
                FilterTypes.URL,
                FilterTypes.CITY,
                FilterTypes.STATE,
                FilterTypes.COUNTRY,
                FilterTypes.LANGUAGE,
                FilterTypes.WIFI,
                FilterTypes.INTERSTITIAL,
            ];
        case BannerTypes.VIDEO:
            return [
                FilterTypes.TAGGING,
                FilterTypes.CAPPING,
                FilterTypes.GRAPH_CAPPING,
                FilterTypes.PROFILE,
                FilterTypes.OS,
                FilterTypes.BROWSER,
                FilterTypes.TIME,
                FilterTypes.PLATFORM,
                FilterTypes.URL,
                FilterTypes.CITY,
                FilterTypes.STATE,
                FilterTypes.COUNTRY,
                FilterTypes.LANGUAGE,
                FilterTypes.WIFI,
                FilterTypes.INTERSTITIAL,
                FilterTypes.VIDEO_POSITION,
                FilterTypes.VIDEO_PLAYER,
            ];
        case BannerTypes.VAST_WRAPPER:
            return [
                FilterTypes.TAGGING,
                FilterTypes.CAPPING,
                FilterTypes.GRAPH_CAPPING,
                FilterTypes.PROFILE,
                FilterTypes.OS,
                FilterTypes.BROWSER,
                FilterTypes.AUDIO,
                FilterTypes.TIME,
                FilterTypes.PLATFORM,
                FilterTypes.URL,
                FilterTypes.CITY,
                FilterTypes.STATE,
                FilterTypes.COUNTRY,
                FilterTypes.LANGUAGE,
                FilterTypes.WIFI,
                FilterTypes.INTERSTITIAL,
                FilterTypes.VIDEO_POSITION,
                FilterTypes.VIDEO_PLAYER,
            ];
        case BannerTypes.HTML5:
        case BannerTypes.IMAGE:
        case BannerTypes.REDIRECT:
        case BannerTypes.RICH_MEDIA:
        case BannerTypes.DYNAMIC:
            return [
                FilterTypes.TAGGING,
                FilterTypes.CAPPING,
                FilterTypes.GRAPH_CAPPING,
                FilterTypes.PROFILE,
                FilterTypes.OS,
                FilterTypes.BROWSER,
                FilterTypes.TIME,
                FilterTypes.PLATFORM,
                FilterTypes.URL,
                FilterTypes.CITY,
                FilterTypes.STATE,
                FilterTypes.COUNTRY,
                FilterTypes.LANGUAGE,
                FilterTypes.WIFI,
                FilterTypes.INTERSTITIAL,
            ];
        default:
            throw new CommonsError(
                'no available types by banner type found',
                {data: {type}},
            );
    }
}
