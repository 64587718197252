import {AuctionTypeFilterImportParser} from '@active-agent/jison';

enum AuctionType {
    Unknown = 0,
    FirstPrice = 1,
    SecondPrice = 2,
}

function parseAuctionTargetingImport(rawData: string): Array<AuctionType> {
    return new AuctionTypeFilterImportParser().parse(rawData);
}

export {parseAuctionTargetingImport, AuctionType};
