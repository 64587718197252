<mat-form-field class="vm-flex">
    <mat-label i18n="@@INVERTED_STATUS_LABEL">INVERTED_STATUS_LABEL</mat-label>
    <mat-select
        [formControl]="inverted"
        name="inverted"
        required
    >
        <mat-option
            [value]="option.value"
            *ngFor="let option of invertedStatusOptions"
        >{{option.name}}</mat-option>
    </mat-select>
</mat-form-field>
