import {RtbCampaign, Campaign} from './rtb-campaign';
import {CampaignTypes} from './campaign-types';
import {CpxMetric, IRadioCampaign, Optimization} from './campaign';
import {ICloneable, IMergable} from '../types';

class RadioCampaign extends RtbCampaign implements IRadioCampaign, IMergable<Campaign>, ICloneable<RadioCampaign> {

    private readonly _type: CampaignTypes = CampaignTypes.Radio;
    private _maxCpc: number | null = null;
    private _maxCpx: number | null = null;
    private _maxCpcv: number | null = null;
    private _minVtr: number | null = null;
    private _optimization: Optimization = 'NONE';
    private _cpxMetrics: Array<CpxMetric> = [];

    public get type(): CampaignTypes {
        return this._type;
    }

    get maxCpc(): number | null {
        return this._maxCpc;
    }

    set maxCpc(value: number | null) {
        this._maxCpc = value;
    }

    get maxCpx(): number | null {
        return this._maxCpx;
    }

    set maxCpx(value: number | null) {
        this._maxCpx = value;
    }

    get maxCpcv(): number | null {
        return this._maxCpcv;
    }

    set maxCpcv(value: number | null) {
        this._maxCpcv = value;
    }

    get minVtr(): number | null {
        return this._minVtr;
    }

    set minVtr(value: number | null) {
        this._minVtr = value;
    }

    get optimization(): Optimization {
        return this._optimization;
    }

    set optimization(optimization: Optimization) {
        this._optimization = optimization;
    }

    get cpxMetrics(): Array<CpxMetric> {
        return this._cpxMetrics;
    }

    set cpxMetrics(value: Array<CpxMetric>) {
        this._cpxMetrics = value;
    }

    public merge(source: Campaign): void {
        super.merge(source);
        RadioCampaign.fillAdditionalFields(this, source);
    }

    public clone(): RadioCampaign {
        const newModel: RadioCampaign = new RadioCampaign(
            this.id,
            this.orderId,
            this.networkId,
            this.biddingStrategy,
            this.dailyBudget,
            this.deliveryTechnique,
            this.maxCpm,
            this.name,
            this.totalBudget,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: RadioCampaign, source: Campaign): void {
        RtbCampaign.fillAdditionalFields(target, source);

        if ('maxCpc' in source) {
            target.maxCpc = source.maxCpc;
        }
        if ('maxCpx' in source) {
            target.maxCpx = source.maxCpx;
        }
        if ('maxCpcv' in source) {
            target.maxCpcv = source.maxCpcv;
        }
        if ('minVtr' in source) {
            target.minVtr = source.minVtr;
        }
        if ('optimization' in source) {
            target.optimization = source.optimization;
        }
        if ('cpxMetrics' in source) {
            const cpxMetrics: Array<CpxMetric> = [];
            target.cpxMetrics = cpxMetrics.concat(source.cpxMetrics);
        }
    }
}

export {RadioCampaign};
