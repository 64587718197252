import {Component, input, InputSignal} from '@angular/core';
import {LibsCardWidgetComponent} from '../card-widget/card-widget.component';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'libs-amount-widget',
    templateUrl: './amount-widget.html',
    styleUrl: './amount-widget.scss',
    standalone: true,
    imports: [
        CommonModule,
        LibsCardWidgetComponent,
    ],
})
class LibsAmountWidgetComponent {
    public title: InputSignal<string> = input.required<string>();
    public amount: InputSignal<number> = input.required<number>();
    public amountDescription: InputSignal<string> = input.required<string>();
    public isLoading: InputSignal<boolean> = input<boolean>(false);
}

export {LibsAmountWidgetComponent};
