import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class RequiredDailyBudgetValidator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0;

    constructor(private currencyPipe: CurrencyPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | null | undefined>): ValidationErrors | null {
        const dailyBudget: number | null | undefined = control.value;
        if (dailyBudget === undefined || dailyBudget === null) {
            return {[RequiredDailyBudgetErrorCodes.Required]: this.getTranslationForErrorCode(RequiredDailyBudgetErrorCodes.Required)};
        }

        if (dailyBudget < RequiredDailyBudgetValidator.minValue) {
            return {[RequiredDailyBudgetErrorCodes.MinValue]: this.getTranslationForErrorCode(RequiredDailyBudgetErrorCodes.MinValue)};
        }

        if (dailyBudget > RequiredDailyBudgetValidator.maxValue) {
            return {[RequiredDailyBudgetErrorCodes.MaxValue]: this.getTranslationForErrorCode(RequiredDailyBudgetErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: RequiredDailyBudgetErrorCodes): string {
        switch (code) {
            case RequiredDailyBudgetErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case RequiredDailyBudgetErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_DAILY_BUDGET_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_DAILY_BUDGET_MIN_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(RequiredDailyBudgetValidator.minValue, 'EUR')}:INTERPOLATION:`;
            case RequiredDailyBudgetErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_DAILY_BUDGET_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_DAILY_BUDGET_MAX_VALUE_ERROR_MESSAGE ${this.currencyPipe.transform(RequiredDailyBudgetValidator.maxValue, 'EUR')}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum RequiredDailyBudgetErrorCodes {
    Required = '0bd07b50-d28b-49ac-bbf0-4832b538d2c5',
    MinValue = '284c40d4-04fa-4959-9ed9-aa1f875c47d5',
    MaxValue = '0d533947-dd2d-4198-bff5-03ebb7e6b567',
}

export {RequiredDailyBudgetValidator, RequiredDailyBudgetErrorCodes};
