import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {getAttributeKeyPattern} from '@active-agent/pattern';
import {TaggingAttribute, DmpAttribute} from '@active-agent/types';

@Injectable({
    providedIn: 'root',
})
class AttributeKeyValidator {
    public static readonly maxLength: number = 32;
    public static readonly pattern: RegExp = getAttributeKeyPattern();
    private _attribute: TaggingAttribute | DmpAttribute | null = null;
    private _existingAttributes: Array<TaggingAttribute | DmpAttribute> = [];

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const key: string = control.value;

        if (key === undefined || key === null || key.length === 0) {
            return {[KeyErrorCodes.Required]: getTranslationForErrorCode(KeyErrorCodes.Required)};
        }

        if (key.length > AttributeKeyValidator.maxLength) {
            return {[KeyErrorCodes.MaxLength]: getTranslationForErrorCode(KeyErrorCodes.MaxLength)};
        }

        if (!AttributeKeyValidator.pattern.test(key)) {
            return {[KeyErrorCodes.Pattern]: getTranslationForErrorCode(KeyErrorCodes.Pattern)};
        }

        if (!this.isKeyUnique(key)) {
            return {[KeyErrorCodes.Unique]: getTranslationForErrorCode(KeyErrorCodes.Unique)};
        }

        return null;
    }

    public get attribute(): TaggingAttribute | DmpAttribute | null {
        return this._attribute;
    }

    public set attribute(value: TaggingAttribute | DmpAttribute | null) {
        this._attribute = value;
    }

    public get existingAttributes(): Array<TaggingAttribute | DmpAttribute> {
        return this._existingAttributes;
    }

    public set existingAttributes(value: Array<TaggingAttribute | DmpAttribute>) {
        this._existingAttributes = value;
    }

    private isKeyUnique(value: string): boolean {
        if (!this.existingAttributes.length || this.attribute === null) {
            return true;
        }

        const networkId: number =
            this.attribute && this.attribute.network && this.attribute.network.id ? this.attribute.network.id : 0;

        return this.existingAttributes
            .filter((attribute: TaggingAttribute | DmpAttribute): boolean => {
                return attribute.network !== undefined
                    && attribute.network.id === networkId;
            })
            .every((attribute: TaggingAttribute | DmpAttribute): boolean => {
                if (!attribute.key) {
                    return true;
                }

                return attribute.key.toLocaleLowerCase() !== value.toLocaleLowerCase()
                    || (!!this.attribute && this.attribute.id === attribute.id);
            });
    }
}

function getTranslationForErrorCode(code: KeyErrorCodes): string {
    switch (code) {
        case KeyErrorCodes.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        case KeyErrorCodes.MaxLength:
            return $localize`:@@VALIDATOR_KEY_MAX_LENGTH_ERROR_MESSAGE: VALIDATOR_KEY_MAX_LENGTH_ERROR_MESSAGE ${AttributeKeyValidator.maxLength}:INTERPOLATION:`;
        case KeyErrorCodes.Pattern:
            return $localize`:@@VALIDATOR_KEY_PATTERN_ERROR_MESSAGE:VALIDATOR_KEY_PATTERN_ERROR_MESSAGE`;
        case KeyErrorCodes.Unique:
            return $localize`:@@VALIDATOR_KEY_UNIQUE_ERROR_MESSAGE:VALIDATOR_KEY_UNIQUE_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum KeyErrorCodes {
    Required = 'ca816210-0901-4bed-bbbf-3164949ba2d1',
    MaxLength = 'da956f3c-9c45-4aec-b1d2-2ca06ef1b264',
    Pattern = 'ce92f382-8e5c-4771-b653-1d970cd5ecea',
    Unique = '1da395c8-8d91-4744-ab25-450528036e40',
}

export {AttributeKeyValidator};
