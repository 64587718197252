import {AbstractControl, ValidationErrors} from '@angular/forms';
import {getUrlAditionPattern} from '@active-agent/pattern';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class OptionalLandingPageValidator {
    public static readonly maxLength: number = 255;
    public static readonly pattern: RegExp = getUrlAditionPattern();

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const landingPage: string = control.value;
        if (landingPage === undefined || landingPage === null || !landingPage.length) {
            return null;
        }

        if (landingPage.length > OptionalLandingPageValidator.maxLength) {
            return {[OptionalLandingPageErrorCodes.MaxLength]: this.getTranslationForErrorCode(OptionalLandingPageErrorCodes.MaxLength)};
        }

        if (!OptionalLandingPageValidator.pattern.test(landingPage)) {
            return {[OptionalLandingPageErrorCodes.Pattern]: this.getTranslationForErrorCode(OptionalLandingPageErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: OptionalLandingPageErrorCodes): string {
        switch (code) {
            case OptionalLandingPageErrorCodes.MaxLength:
                const max: string | null = this.decimalPipe.transform(OptionalLandingPageValidator.maxLength);

                return $localize`:@@VALIDATOR_LANDING_PAGE_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_LANDING_PAGE_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            case OptionalLandingPageErrorCodes.Pattern:
                return $localize`:@@VALIDATOR_LANDING_PAGE_PATTERN_ERROR_MESSAGE:VALIDATOR_LANDING_PAGE_PATTERN_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum OptionalLandingPageErrorCodes {
    MaxLength = 'ca92c15b-929a-41e7-8c81-7d88056b2e73',
    Pattern = '4bb31c64-28b9-4b84-bb89-e84b9434aec5',
}

export {OptionalLandingPageValidator, OptionalLandingPageErrorCodes};
