enum LoginErrorCodes {
    UserNameNotProvided = 'USERNAME_NOT_PROVIDED',
    PasswordNotProvided = 'PASSWORD_NOT_PROVIDED',
    InvalidLoginData = 'INVALID_LOGIN_DATA',
    ExpiredPassword = 'EXPIRED_PASSWORD',
    TooManyFailedLoginAttempts = 'TOO_MANY_FAILED_LOGIN_ATTEMPTS',
    NoIpInRequest = 'NO_IP_IN_REQUEST',
    OutOfIpRange = 'OUT_OF_IP_RANGE',
}

const loginErrorCodeTranslations: Record<LoginErrorCodes, string> = {
    [LoginErrorCodes.UserNameNotProvided]: $localize`:@@LOGIN_ERROR_USER_NAME_NOT_PROVIDED_MESSAGE:LOGIN_ERROR_USER_NAME_NOT_PROVIDED_MESSAGE`,
    [LoginErrorCodes.PasswordNotProvided]: $localize`:@@LOGIN_ERROR_PASSWORD_NOT_PROVIDED_MESSAGE:LOGIN_ERROR_PASSWORD_NOT_PROVIDED_MESSAGE`,
    [LoginErrorCodes.InvalidLoginData]: $localize`:@@LOGIN_ERROR_INVALID_LOGIN_DATA_MESSAGE:LOGIN_ERROR_INVALID_LOGIN_DATA_MESSAGE`,
    [LoginErrorCodes.ExpiredPassword]: $localize`:@@LOGIN_ERROR_EXPIRED_PASSWORD_MESSAGE:LOGIN_ERROR_EXPIRED_PASSWORD_MESSAGE`,
    [LoginErrorCodes.TooManyFailedLoginAttempts]: $localize`:@@LOGIN_ERROR_TOO_MANY_FAILED_LOGIN_ATTEMPTS_MESSAGE:LOGIN_ERROR_TOO_MANY_FAILED_LOGIN_ATTEMPTS_MESSAGE`,
    [LoginErrorCodes.NoIpInRequest]: $localize`:@@LOGIN_ERROR_NO_IP_IN_REQUEST_MESSAGE:LOGIN_ERROR_NO_IP_IN_REQUEST_MESSAGE`,
    [LoginErrorCodes.OutOfIpRange]: $localize`:@@LOGIN_ERROR_USER_OUT_OF_ASSIGNED_NETWORK_IP_RANGE_MESSAGE:LOGIN_ERROR_USER_OUT_OF_ASSIGNED_NETWORK_IP_RANGE_MESSAGE`,
};

export {LoginErrorCodes, loginErrorCodeTranslations};
