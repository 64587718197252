import {Banner, BaseBanner} from '../base-banner';
import {BannerTypes} from '../banner-types';
import {ICloneable, IMergable} from '../../types';
import {BannerFileOrUrl, IBannerFile, ISwitchInBanner} from '../banner';
import {Ssps} from '../../ssp/ssps';

type SwitchInBannerTargetSsp = Ssps.YIELDLAB | Ssps.SMARTCLIP;

class SwitchInBanner extends BaseBanner implements ISwitchInBanner, ICloneable<SwitchInBanner>, IMergable<Banner> {
    private readonly _type: BannerTypes = BannerTypes.SWITCH_IN;
    private _ssl: boolean = false;
    private _clickUrl: string | null = null;
    private _trackingUrls: Array<string> = [];
    private _switchInFile: IBannerFile | null = null;
    private _switchInUrl: string | null = null;
    private _startBarFile: IBannerFile | null = null;
    private _startBarUrl: string | null = null;
    private _targetSsp: SwitchInBannerTargetSsp = Ssps.YIELDLAB;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        switchInFileOrUrl: BannerFileOrUrl,
    ) {
        super(id, networkId, name, landingPage);

        if (typeof switchInFileOrUrl === 'string') {
            this._switchInUrl = switchInFileOrUrl;
        } else {
            this._switchInFile = switchInFileOrUrl;
        }
    }

    public get type(): BannerTypes {
        return this._type;
    }

    get ssl(): boolean {
        return this._ssl;
    }

    set ssl(value: boolean) {
        this._ssl = value;
    }

    get clickUrl(): string | null {
        return this._clickUrl;
    }

    set clickUrl(value: string | null) {
        this._clickUrl = value;
    }

    get trackingUrls(): Array<string> {
        return this._trackingUrls;
    }

    set trackingUrls(value: Array<string>) {
        this._trackingUrls = value;
    }

    get switchInFile(): IBannerFile | null {
        return this._switchInFile;
    }

    set switchInFile(value: IBannerFile | null) {
        this._switchInFile = value;
        this._switchInUrl = null;
    }

    get switchInUrl(): string | null {
        return this._switchInUrl;
    }

    set switchInUrl(value: string | null) {
        this._switchInUrl = value;
        this._switchInFile = null;
    }

    get startBarFile(): IBannerFile | null {
        return this._startBarFile;
    }

    set startBarFile(value: IBannerFile | null) {
        this._startBarFile = value;
        this._startBarUrl = null;
    }

    get startBarUrl(): string | null {
        return this._startBarUrl;
    }

    set startBarUrl(value: string | null) {
        this._startBarUrl = value;
        this._startBarFile = null;
    }

    get targetSsp(): SwitchInBannerTargetSsp {
        return this._targetSsp;
    }

    set targetSsp(value: SwitchInBannerTargetSsp) {
        this._targetSsp = value;
    }

    public merge(source: Banner): void {
        super.merge(source);
        SwitchInBanner.fillAdditionalFields(this, source);
    }

    public clone(): SwitchInBanner {
        let switchInFileOrUrl: BannerFileOrUrl = '';
        if (this.switchInFile) {
            switchInFileOrUrl = this.switchInFile;
        } else if (this.switchInUrl) {
            switchInFileOrUrl = this.switchInUrl;
        }
        const newModel: SwitchInBanner = new SwitchInBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            switchInFileOrUrl,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: SwitchInBanner, source: Banner): void {
        BaseBanner.fillAdditionalFields(target, source);

        if ('ssl' in source) {
            target.ssl = source.ssl;
        }
        if ('clickUrl' in source) {
            target.clickUrl = source.clickUrl;
        }
        if ('trackingUrls' in source) {
            target.trackingUrls = source.trackingUrls;
        }
        if ('targetSsp' in source && source.targetSsp) {
            target.targetSsp = source.targetSsp;
        }
        if ('startBarFile' in source && source.startBarFile) {
            target.startBarFile = source.startBarFile;
        } else if ('startBarUrl' in source && source.startBarUrl) {
            target.startBarUrl = source.startBarUrl;
        }
        if ('switchInFile' in source && source.switchInFile) {
            target.switchInFile = source.switchInFile;
        } else if ('switchInUrl' in source && source.switchInUrl) {
            target.switchInUrl = source.switchInUrl;
        }
    }
}

export {SwitchInBanner, SwitchInBannerTargetSsp};
