import {FOOTFALL_ROUTES_CONFIGURATION, IFootfallRoutesConfiguration} from './footfall-routes-configuration';
import {Order, IRouterHandler, IRouterHandlerData, ROUTE_HANDLER} from '@active-agent/types';
import {inject, Injectable} from '@angular/core';

@Injectable()
class FootfallRouteHandler implements IFootfallRouteHandler {
    private readonly routeHandler: IRouterHandler = inject(ROUTE_HANDLER);
    private readonly footfallRoutesConfiguration: IFootfallRoutesConfiguration = inject(FOOTFALL_ROUTES_CONFIGURATION);

    public redirectToFootfallStoreSettings(order: Order): void {
        const {commands, queryParams, transitionOptions}: IRouterHandlerData = this.footfallRoutesConfiguration
            .storeSettings.getDataForLink({order});

        this.routeHandler.navigate(commands, queryParams, transitionOptions);
    }
}

interface IFootfallRouteHandler {
    redirectToFootfallStoreSettings(order: Order): void;
}

export {FootfallRouteHandler, type IFootfallRouteHandler};
