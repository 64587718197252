import {CommonsError} from '@active-agent/types';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef,
} from '@angular/core';
import Fuse from 'fuse.js';
import {MultiselectOption, LibsMultiselectComponent} from '../multiselect/multiselect.component';
import FuseOptionKey = Fuse.FuseOptionKey;
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
    selector: 'libs-dual-list',
    templateUrl: './dual-list.html',
    styleUrls: ['./dual-list.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        LibsMultiselectComponent,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
    ],
})
class LibsDualListComponent implements OnInit {
    @Input() public optionValueTemplate!: TemplateRef<{option: MultiselectOption}>;
    @Input() public optionsLeft: Array<MultiselectOption> = [];
    @Input() public optionsRight: Array<MultiselectOption> = [];
    @Input() public labelLeft: string = '';
    @Input() public labelRight: string = '';
    @Input() public isReadOnly: boolean = false;
    @Input() public searchFields: Array<FuseOptionKey> = ['name'];
    @Output() public onChangeSelection: EventEmitter<IOnChangeSelectionEvent> = new EventEmitter<IOnChangeSelectionEvent>();
    public preSelectedOptionsLeft: Array<MultiselectOption> = [];
    public preSelectedOptionsRight: Array<MultiselectOption> = [];

    constructor(private changeDetector: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.optionValueTemplate === undefined) {
            throw new CommonsError('optionValueTemplate component binding has to be provided');
        }
    }

    public hasPermissions(): boolean {
        return !this.isReadOnly;
    }

    public moveOptionFromLeftToRight(selectedOptions: Array<MultiselectOption>): void {
        const [optionsLeft, optionsRight]: [Array<unknown>, Array<unknown>] = moveOptionsFromAToB(
            this.optionsLeft,
            this.optionsRight,
            selectedOptions,
        );
        this.optionsLeft = optionsLeft;
        this.optionsRight = optionsRight;
        this.onChange();
    }

    public moveOptionFromRightToLeft(selectedOptions: Array<MultiselectOption>): void {
        const [optionsRight, optionsLeft]: [Array<unknown>, Array<unknown>] = moveOptionsFromAToB(
            this.optionsRight,
            this.optionsLeft,
            selectedOptions,
        );

        this.optionsLeft = optionsLeft;
        this.optionsRight = optionsRight;
        this.onChange();
    }

    public preSelectFromLeftToRight(preSelectedOptions: Array<MultiselectOption>): void {
        this.preSelectedOptionsLeft = preSelectedOptions;
        this.changeDetector.detectChanges();
    }

    public preSelectFromRightToLeft(preSelectedOptions: Array<MultiselectOption>): void {
        this.preSelectedOptionsRight = preSelectedOptions;
        this.changeDetector.detectChanges();
    }

    public onRightArrowClick(): void {
        this.moveOptionFromLeftToRight(this.preSelectedOptionsLeft);
    }

    public onLeftArrowClick(): void {
        this.moveOptionFromRightToLeft(this.preSelectedOptionsRight);
    }

    private onChange(): void {
        this.preSelectedOptionsLeft = [];
        this.preSelectedOptionsRight = [];

        this.onChangeSelection.emit({
            optionsLeft: this.optionsLeft,
            optionsRight: this.optionsRight,
        });
    }
}

function moveOptionsFromAToB(
    source: Array<MultiselectOption>,
    dest: Array<MultiselectOption>,
    selectedOptions: Array<MultiselectOption>,
): [Array<MultiselectOption>, Array<MultiselectOption>] {
    const a: Array<MultiselectOption> = [];
    const b: Array<MultiselectOption> = [...dest];

    for (const option of source) {
        if (selectedOptions.some((selectedOption: MultiselectOption) => selectedOption === option)) {
            b.push(option);
        } else {
            a.push(option);
        }
    }

    return [a, b];
}

interface IOnChangeSelectionEvent {
    optionsLeft: Array<MultiselectOption>;
    optionsRight: Array<MultiselectOption>;
}

export {LibsDualListComponent, IOnChangeSelectionEvent};
