import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {IRouterHandler, RouterHandlerCommands, RouterHandlerQueryParams} from '@active-agent/types';

@Injectable({
    providedIn: 'root',
})
class LibsRouteHandlerService implements IRouterHandler {
    public get params(): Record<string, unknown> {
        return this.route.snapshot.params;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        @Inject(LOCALE_ID) private locale: string,
    ) {}

    public navigate(commands: RouterHandlerCommands, queryParams?: RouterHandlerQueryParams): void {
        void this.router.navigate(commands, {queryParams});
    }

    public navigateBack(): void {
        this.location.back();
    }

    public transform(commands: RouterHandlerCommands, queryParams?: RouterHandlerQueryParams): string {
        return this.router.serializeUrl(this.router.createUrlTree(
            [
                ...(this.getLocalePrefix()),
                ...commands,
            ],
            {queryParams},
        ));
    }

    public transformWithQueryParameter(commands: RouterHandlerCommands, queryParams?: RouterHandlerQueryParams): string {
        return window.location.origin + this.router.serializeUrl(
            this.router.createUrlTree(
                [...(this.getLocalePrefix()), ...commands],
                {queryParams},
            ),
        );
    }

    public updateQueryParameter(commands: RouterHandlerCommands, queryParams?: RouterHandlerQueryParams): void {
        this.location.go(this.router.createUrlTree(commands, {queryParams}).toString());
    }

    public getQueryParameter(queryParam: string): string {
        return this.route.snapshot.queryParamMap.get(queryParam) || '';
    }

    public async reload(): Promise<void> {
        await this.router.navigate(['.'], {
            skipLocationChange: true,
            queryParamsHandling: 'merge',
        });
        await this.router.navigate([this.router.url], {
            skipLocationChange: true,
            queryParamsHandling: 'merge',
            onSameUrlNavigation: 'reload',
        });
    }

    public isActive(commands: RouterHandlerCommands): boolean {
        return this.location.isCurrentPathEqualTo(this.transform(commands));
    }

    private getLocalePrefix(): Array<string> {
        return window.location.pathname.startsWith(`/${this.locale}/`) ? [this.locale] : [];
    }
}

export {LibsRouteHandlerService};
