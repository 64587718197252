import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class MaxCpxValidator implements Validator {
    public static readonly maxValue: number = 100;
    public static readonly minValue: number = 1;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const maxCpx: number | undefined | null = control.value;
        if (maxCpx === undefined || maxCpx === null) {
            return {[MaxCpxErrorCodes.Required]: this.getTranslationForErrorCode(MaxCpxErrorCodes.Required)};
        }

        if (maxCpx < MaxCpxValidator.minValue) {
            return {[MaxCpxErrorCodes.MinValue]: this.getTranslationForErrorCode(MaxCpxErrorCodes.MinValue)};
        }

        if (maxCpx > MaxCpxValidator.maxValue) {
            return {[MaxCpxErrorCodes.MaxValue]: this.getTranslationForErrorCode(MaxCpxErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: MaxCpxErrorCodes): string {
        switch (code) {
            case MaxCpxErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case MaxCpxErrorCodes.MinValue:
                return $localize`:@@VALIDATOR_MAX_CPX_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_CPX_MIN_VALUE_ERROR_MESSAGE ${MaxCpxValidator.minValue}:INTERPOLATION:`;
            case MaxCpxErrorCodes.MaxValue:
                return $localize`:@@VALIDATOR_MAX_CPX_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_MAX_CPX_MAX_VALUE_ERROR_MESSAGE ${MaxCpxValidator.maxValue}:INTERPOLATION:`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum MaxCpxErrorCodes {
    Required = '16718b27-93cc-4653-9a2c-77626b5db5dc',
    MinValue = '4249d1ec-8299-4a72-a9cd-bc9478901c0f',
    MaxValue = '1cc8deac-2d54-4bda-8d8a-e25f6c6dee0d',
}

export {MaxCpxValidator, MaxCpxErrorCodes};
