<ng-template #manualLink>
    <span
        class="ad-blocker-detector__link"
        (click)="openManual()"
        i18n="@@AD_BLOCKER_INFO_MESSAGE_MANUAL_LINK"
    >AD_BLOCKER_INFO_MESSAGE_MANUAL_LINK</span>
</ng-template>
<ng-template #manualLinkMobile>
    <span
        class="ad-blocker-detector__link"
        (click)="openManual()"
        i18n="@@AD_BLOCKER_INFO_MESSAGE_MANUAL_LINK_MOBILE"
    >AD_BLOCKER_INFO_MESSAGE_MANUAL_LINK_MOBILE</span>
</ng-template>
<div *ngIf="showAdBlockerInfoMessage" class="ad-blocker-detector vm-flex">
    <mat-toolbar class="ad-blocker-detector__toolbar vm-flex-direction-row vm-layout-justify-start vm-layout-align-center">
        <commons-status-icon
            class="ad-blocker-detector__status-icon"
            [status]="infoStatus"
        ></commons-status-icon>

        <div
            class="ad-blocker-detector__title vm-hide-lt-md"
            i18n="@@AD_BLOCKER_INFO_MESSAGE"
        >AD_BLOCKER_INFO_MESSAGE <ng-container [ngTemplateOutlet]="manualLink"></ng-container></div>
        <div
            class="ad-blocker-detector__title vm-hide-gt-sm"
            i18n="@@AD_BLOCKER_INFO_MESSAGE_MOBILE"
        >AD_BLOCKER_INFO_MESSAGE_MOBILE <ng-container [ngTemplateOutlet]="manualLinkMobile"></ng-container></div>
    </mat-toolbar>
</div>
