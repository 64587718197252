import {Ssps} from '../ssp/ssps';
import {Banner, IBannerStatus} from './base-banner';
import {BumperAdBanner} from './bumper-ad/bumper-ad-banner';
import {SwitchInXxlAnimatedBanner} from './switch-in-xxl-animated/switch-in-xxl-animated-banner';
import {SwitchInXxlBanner} from './switch-in-xxl/switch-in-xxl-banner';
import {DeprecatedAtvSpotBanner} from './deprecated-atv-spot/deprecated-atv-spot-banner';

class BannerStatusHelper {
    public static getStatus(banner: Banner, placements: Array<Ssps>): BannerStatus {
        const status: IBannerStatus = banner.status || {};
        const ssps: Array<string> = getCompatibleSsps(banner, placements);

        if (isErrorBannerStatus(banner, ssps, status)) {
            return BannerStatus.ERROR;
        }

        if (isWarningBannerStatus(ssps, status)) {
            return BannerStatus.WARNING;
        }

        return BannerStatus.OK;
    }

}
/**
 * Checks if `banner` is compatible with the `ssp`. Used to filter ssps for Switch In and Bumper Ad Banners.
 * Those banners have in the API still all ssps available even if the ssp is not selected in the ssp targeting
 * and not available in compatibleSsps
 */
function isBannerCompatibleWithSsp(banner: Banner, ssp: string): boolean {
    return banner instanceof SwitchInXxlBanner
    || banner instanceof SwitchInXxlAnimatedBanner
    || banner instanceof BumperAdBanner
    || banner instanceof DeprecatedAtvSpotBanner
        ? banner.compatibleSsps.includes(ssp as Ssps)
        : true;
}

function getCompatibleSsps(banner: Banner, placements: Array<Ssps>): Array<Ssps> {
    return placements.filter((ssp: Ssps): boolean => {
        return isBannerCompatibleWithSsp(banner, ssp);
    });
}

function isErrorBannerStatus(banner: Banner, ssps: Array<string>, status: IBannerStatus): boolean {
    return !banner.valid || noSspTargetingSet(ssps) || allSspsDisapproved(ssps, status);
}

function noSspTargetingSet(ssps: Array<string>): boolean {
    return ssps.length === 0;
}

function allSspsDisapproved(ssps: Array<string>, status: IBannerStatus): boolean {
    if (ssps.length === 0) {
        return false;
    }

    return ssps.every((ssp: string) => {
        return status[ssp] === BannerSspStatus.DISAPPROVED || status[ssp] === BannerSspStatus.ERROR;
    });
}

function isWarningBannerStatus(ssps: Array<string>, status: IBannerStatus): boolean {
    return someSspHasGivenStatus(ssps, status, BannerSspStatus.NEW) ||
        someSspHasGivenStatus(ssps, status, BannerSspStatus.VERIFICATION) ||
        someSspHasGivenStatus(ssps, status, BannerSspStatus.MODIFIED) ||
        someSspHasGivenStatus(ssps, status, BannerSspStatus.DISAPPROVED) ||
        someSspHasGivenStatus(ssps, status, BannerSspStatus.ERROR);
}

function someSspHasGivenStatus(ssps: Array<string>, bannerStatus: IBannerStatus, statusToBeFound: BannerSspStatus): boolean {
    return ssps.some((ssp: string) => {
        return bannerStatus[ssp] === statusToBeFound;
    });
}

enum BannerStatus {
    OK = 'OK',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

enum BannerSspStatus {
    NEW = 'NEW',
    MODIFIED = 'MODIFIED',
    VERIFICATION = 'VERIFICATION',
    APPROVED = 'APPROVED',
    DEALS_ONLY = 'DEALS_ONLY',
    DISAPPROVED = 'DISAPPROVED',
    ERROR = 'ERROR',
}

export {BannerStatusHelper, BannerStatus, BannerSspStatus, getCompatibleSsps, isBannerCompatibleWithSsp};
