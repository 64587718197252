import {NgModule} from '@angular/core';
import {CommonsDefaultStaticInformationComponent} from './default-static-information.component';
import {CommonsStaticInformationModule} from '../static-information/static-information.module';

@NgModule({
    imports: [
        CommonsStaticInformationModule,
    ],
    exports: [
        CommonsDefaultStaticInformationComponent,
    ],
    declarations: [
        CommonsDefaultStaticInformationComponent,
    ],
})
class CommonsDefaultStaticInformationModule {}

export {CommonsDefaultStaticInformationModule};
