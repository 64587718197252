import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class FootfallCountryCodeValidator implements Validator {

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const countryCode: string | undefined = control.value;

        if (!countryCode) {
            return getValidationError(FootfallCountryCodeErrorCode.Required);
        }

        return null;
    }
}

function getValidationError(code: FootfallCountryCodeErrorCode): ValidationErrors {
    return {[code]: getTranslationForErrorCode(code)};
}

function getTranslationForErrorCode(code: FootfallCountryCodeErrorCode): string {
    switch (code) {
        case FootfallCountryCodeErrorCode.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum FootfallCountryCodeErrorCode {
    Required = '7c9caf3f-f8f1-4cb5-bbd7-0ff67e5791e9',
}

export {FootfallCountryCodeValidator};
