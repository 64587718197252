enum PeriodTypes {
    Seconds = 'seconds',
    Minutes = 'minutes',
    Hours = 'hours',
    Days = 'days',
}

interface IPeriodOption {
    id: PeriodTypes;
    name: string;
}

function getDefaultPeriodOptions(): Array<IPeriodOption> {
    return [
        {
            id: PeriodTypes.Seconds,
            name: $localize`:@@PERIOD_TYPE_SECONDS:PERIOD_TYPE_SECONDS`,
        },
        {
            id: PeriodTypes.Minutes,
            name: $localize`:@@PERIOD_TYPE_MINUTES:PERIOD_TYPE_MINUTES`,
        },
        {
            id: PeriodTypes.Hours,
            name: $localize`:@@PERIOD_TYPE_HOURS:PERIOD_TYPE_HOURS`,
        },
        {
            id: PeriodTypes.Days,
            name: $localize`:@@PERIOD_TYPE_DAYS:PERIOD_TYPE_DAYS`,
        },
    ];
}

export {PeriodTypes, IPeriodOption, getDefaultPeriodOptions};
