import {AbstractControl, ValidationErrors} from '@angular/forms';

function PasswordValidator(control: AbstractControl): ValidationErrors | null {
    const password: string = control.value;
    let isValid: boolean = true;

    // numbers
    if (!password.match(/.*\d+.*/g)) {
        isValid = false;
    }

    // lowercase
    if (!password.match(/.*[a-z]+.*/g)) {
        isValid = false;
    }

    // uppercase
    if (!password.match(/.*[A-Z]+.*/g)) {
        isValid = false;
    }

    // non word characters
    if (!password.match(/.*[\W]+.*/g)) {
        isValid = false;
    }

    if (password.length < 8) {
        isValid = false;
    }

    return isValid ? null : {syntax: true};
}

export {PasswordValidator};
