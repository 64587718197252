import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {MaxCpmErrorCodes, RequiredMaxCpmValidator} from './required-max-cpm-validator';

@Injectable({
    providedIn: 'root',
})
class OptionalMaxCpmValidator extends RequiredMaxCpmValidator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const validateResult: ValidationErrors | null = super.validate(control);

        if (validateResult && validateResult[MaxCpmErrorCodes.Required]) {
            return null;
        }

        return validateResult;
    }
}

export {OptionalMaxCpmValidator};
