import {OdcFilter, OdcFilterProperties} from '../odc-filter';
import {FilterTypes} from '../../types/filter.types';

type OdcLanguageContextualFilterProperties = OdcFilterProperties;

class OdcLanguageContextualFilter extends OdcFilter<FilterTypes.ODC_LANGUAGE_CONTEXTUAL> {
    protected readonly _type: FilterTypes.ODC_LANGUAGE_CONTEXTUAL = FilterTypes.ODC_LANGUAGE_CONTEXTUAL;
    protected readonly props: OdcLanguageContextualFilterProperties = this.getDefaultOptions();
}

export {OdcLanguageContextualFilter};
