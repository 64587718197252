import {Html5Banner} from './html5-banner';
import {BannerPoolBanner} from '../base-banner';
import {ICloneable, IMergable} from '../../types';
import {IBannerPoolBanner, IBannerSize} from '../banner';

class BannerPoolHtml5Banner extends Html5Banner implements
    IBannerPoolBanner,
    ICloneable<BannerPoolHtml5Banner>,
    IMergable<BannerPoolBanner> {

    private _bannerPoolId: number;
    private _childBannerIds: Array<number> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        size: IBannerSize,
        bannerPoolId: number,
    ) {
        super(id, networkId, name, landingPage, clickUrl, size);
        this._bannerPoolId = bannerPoolId;
    }

    get bannerPoolId(): number {
        return this._bannerPoolId;
    }

    set bannerPoolId(value: number) {
        this._bannerPoolId = value;
    }

    get childBannerIds(): Array<number> {
        return this._childBannerIds;
    }

    set childBannerIds(value: Array<number>) {
        this._childBannerIds = value;
    }

    public merge(source: BannerPoolBanner): void {
        super.merge(source);

        BannerPoolHtml5Banner.fillAdditionalFields(this, source);
    }

    public clone(): BannerPoolHtml5Banner {
        const newModel: BannerPoolHtml5Banner = new BannerPoolHtml5Banner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.clickUrl,
            this.size,
            this.bannerPoolId,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BannerPoolHtml5Banner, source: BannerPoolBanner): void {
        Html5Banner.fillAdditionalFields(target, source);

        target.bannerPoolId = source.bannerPoolId;
        target.childBannerIds = source.childBannerIds;
    }
}

export {BannerPoolHtml5Banner};
