import {CommonsError} from '../../error/error';
import {WeatherFilterImportParser} from '@active-agent/jison';

enum WeatherKey {
    Weather = 'wNow',
    Temperature = 'txToday',
}

interface IWeatherFilterImportParserResult {
    [WeatherKey.Weather]: Array<number>;
    [WeatherKey.Temperature]: Array<number>;
}

function parseWeatherFilterImport(rawData: string): IWeatherFilterImportParserResult {
    const data: IWeatherFilterImportParserResult = new WeatherFilterImportParser().parse(rawData);
    if (!Array.isArray(data.wNow) || !data.wNow.length) {
        throw new CommonsError('weatherIds has to be provided');
    }

    return data;
}

export {parseWeatherFilterImport, IWeatherFilterImportParserResult, WeatherKey};
