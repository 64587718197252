import {differenceInHours, endOfDay, isSameDay, parseISO, startOfDay} from 'date-fns';
import {findTimeZone, getUTCOffset} from 'timezone-support/dist/index-1970-2038';

enum TimeZone {
    EuropeBerlin = 'Europe/Berlin',
    EuropeLondon = 'Europe/London',
    AmericaNewYork = 'America/New_York',
}

class TimeZoneStore {

    /**
     * Do not touch this variable. Do not change it, never!
     */
    public static readonly apiTimeZone: TimeZone = TimeZone.EuropeBerlin;

    private static selectedTimeZone: TimeZone = TimeZone.EuropeBerlin;

    public static getSelected(): TimeZone {
        return this.selectedTimeZone;
    }

    /**
     * Do not use this in production right now.
     * !! Not yet tested !!
     */
    public static setTimeZone(timeZone: TimeZone): void {
        this.selectedTimeZone = timeZone;
    }

    /**
     * Can be ued to check whether the local time zone of the browser deviates from the selected timezone in the frontend
     */
    public static deviatesFromSelected(timeZone: TimeZone = this.selectedTimeZone): boolean {
        const browserTimeZoneDate: Date = new Date();
        const frontendTimeZoneDate: Date = TimeZoneStore.convertToTimeZone(browserTimeZoneDate, timeZone);

        return frontendTimeZoneDate.valueOf() !== browserTimeZoneDate.valueOf();
    }

    /**
     * Converts the given date to the respective time zone.
     *
     * However if the given timeZone does not deviates from the browser time zone we do not convert anything and instead return the
     * original date.
     * JS Date has no information regarding the time zone, so in that case we would convert twice and have a wrong value
     */
    public static convertToTimeZone(date: Date, targetTimeZone: TimeZone = this.selectedTimeZone): Date {
        const browserTimeZoneDate: Date = new Date();
        const frontendTimeZoneDate: Date = fnsConvertToTimeZone(browserTimeZoneDate, {timeZone: targetTimeZone});

        if (frontendTimeZoneDate.valueOf() === browserTimeZoneDate.valueOf()) {
            return date;
        }

        return fnsConvertToTimeZone(date, {timeZone: targetTimeZone});
    }

    /**
     * Convert the given date to the local (browser) time zone.
     * The second param timeZone is the source time zone of the given date parameter.
     *
     * However if the given timeZone does not deviates from the browser time zone we do not convert anything and instead return the
     * original date.
     * JS Date has no information regarding the time zone, so in that case we would convert twice and have a wrong value
     */
    public static convertToLocalTime(date: Date, sourceTimeZone: TimeZone = this.apiTimeZone): Date {
        if (!this.deviatesFromSelected(sourceTimeZone)) {
            return date;
        }

        return fnsConvertToLocalTime(date, {timeZone: sourceTimeZone});
    }

    /**
     * We receive dates from the api always in TimeZone.EuropeBerlin.
     *
     * JS does not handle it properly if I try to parse such a date. Therefore we just strip the timezone information from the api
     * and use the `parseFromTimeZone` to then properly parse the date into our current local browser time zone.
     *
     * From there on we then convert the date into the selected timezone
     */
    public static parseDateFromApiTimeToTargetTime(dateString: string, targetTimeZone: TimeZone = this.selectedTimeZone): Date {
        return this.convertToTimeZone(
            parseFromTimeZone(
                dateString.split('+')[0],
                {timeZone: this.apiTimeZone},
            ),
            targetTimeZone,
        );
    }

    /**
     * This method can be used to convert dates from API report entries to the proper Timezone.
     *
     * First we create a plain Date object with the values from a report entry.
     *
     * Second we convert this date to our own local time zone and since it is from the api we take Europ.Berlin as Base time zone.
     *
     * From there we convert it back into the time zone the user has currently selected (second parameter)
     */
    public static parseDateFromApiDateValuesToTargetTime(dateValues: IDateValues, targetTimeZone: TimeZone = this.selectedTimeZone): Date {
        let date: Date = new Date(
            dateValues.year,
            dateValues.month,
            dateValues.dayOfMonth,
            dateValues.hours,
            dateValues.minutes,
            dateValues.seconds,
            dateValues.milliseconds,
        );

        date = this.convertToLocalTime(date, this.apiTimeZone);

        return this.convertToTimeZone(date, targetTimeZone);
    }

    /**
     * This method is basically the inverted version of `parseDateFromApiToSelectedTime`
     *
     * We always want to send to the Api the TimeZone.EuropeBerlin.
     * That's why we need to convert it from the selected TimeZone to the ApiTimeZone
     */
    public static parseDateFromTargetTimeToApiTime(dateString: string, targetTimeZone: TimeZone = this.selectedTimeZone): Date {
        return this.convertToTimeZone(
            parseFromTimeZone(
                dateString.split('+')[0],
                {timeZone: targetTimeZone},
            ),
            this.apiTimeZone,
        );
    }
}

function convertToTimeZone(date: Date, timeZone?: TimeZone): Date {
    return TimeZoneStore.convertToTimeZone(date, timeZone);
}

function getTimeZonedNewDate(date?: number | string): Date {
    if (date !== undefined) {
        return convertToTimeZone(new Date(date));
    }

    return convertToTimeZone(new Date());
}

function getTimeZonedMaxDate(): Date {
    return TimeZoneStore.convertToTimeZone(
        TimeZoneStore.convertToLocalTime(new Date('2038-01-01T00:00:00'), TimeZoneStore.apiTimeZone),
    );
}

function getTimeZonedMinDate(): Date {
    return TimeZoneStore.convertToTimeZone(
        TimeZoneStore.convertToLocalTime(new Date('2013-07-01T00:00:00'), TimeZoneStore.apiTimeZone),
    );
}

function isTimeZonedToday(date: Date | string | number): boolean {
    if (typeof date === 'string') {
        return isSameDay(parseISO(date), getTimeZonedNewDate());
    }

    return isSameDay(date, getTimeZonedNewDate());
}

function startOfZonedToday(): Date {
    return startOfDay(getTimeZonedNewDate());
}

function endOfZonedToday(): Date {
    return endOfDay(getTimeZonedNewDate());
}

function parseDateFromApiTimeToTargetTime(dateString: string, targetTimeZone?: TimeZone): Date {
    return TimeZoneStore.parseDateFromApiTimeToTargetTime(dateString, targetTimeZone);
}

function parseDateFromApiDateValuesToTargetTime(dateValues: IDateValues, targetTimeZone?: TimeZone): Date {
    return TimeZoneStore.parseDateFromApiDateValuesToTargetTime(dateValues, targetTimeZone);
}

function parseDateFromTargetTimeToApiTime(dateString: string, targetTimeZone?: TimeZone): Date {
    return TimeZoneStore.parseDateFromTargetTimeToApiTime(dateString, targetTimeZone);
}

/**
 * When you use the regular differenceInHours method, DST timezone changes are taken into account
 * Date A: Mon Oct 21 2019 00:00:00 GMT+0200 (Central European Summer Time)
 * Date B: Thu Oct 31 2019 00:00:00 GMT+0100 (Central European Standard Time)
 * You have 10 days as difference and if you want to calculate the days you would expect 240 hours.
 * However since the DST changes the hour difference are actually 241
 *
 * This methods ignores DST changes and properly returns 240
 *
 * !! Important !!
 * Use with caution and only if you use this method for day calculations
 */
function differenceInHoursIgnoringDST(dateLeft: Date, dateRight: Date): number {
    const timeZoneOffsetDifference: number = Math.ceil((dateLeft.getTimezoneOffset() - dateRight.getTimezoneOffset()) / 60);

    return differenceInHours(dateLeft, dateRight) - timeZoneOffsetDifference;
}

/**
 * Migrated from https://github.com/prantlf/date-fns-timezone/blob/eeaeeb6b39edec021f37db5790b841055a05aa48/src/convertToTimeZone.js
 * to date-fns 2
 */
function fnsConvertToTimeZone(dateInput: DateInput, options: ITimeZoneOptions): Date {
    const date: Date = saveParseISO(dateInput);
    const timeZone: ITimeZoneInfo = findTimeZone(options.timeZone);
    let {offset}: ITimeZoneOffset = getUTCOffset(date, timeZone);
    offset -= date.getTimezoneOffset();

    return new Date(date.getTime() - offset * 60 * 1000);
}

/**
 * Migrated from https://github.com/prantlf/date-fns-timezone/blob/eeaeeb6b39edec021f37db5790b841055a05aa48/src/parseFromTimeZone.js
 * to date-fns 2
 */
function parseFromTimeZone(dateString: string, options: ITimeZoneOptions): Date {
    const {timeZone}: ITimeZoneOptions = options;
    const timeZoneInfo: ITimeZoneInfo = findTimeZone(timeZone);

    const date: Date = parseISO(dateString);
    let {offset}: ITimeZoneOffset = getUTCOffset(date, timeZoneInfo);
    offset -= date.getTimezoneOffset();

    return new Date(date.getTime() + offset * 60 * 1000);
}

/**
 * Migrated from https://github.com/prantlf/date-fns-timezone/blob/eeaeeb6b39edec021f37db5790b841055a05aa48/src/convertToLocalTime.js
 * to date-fns 2
 */
function fnsConvertToLocalTime(dateInput: DateInput, options: ITimeZoneOptions): Date {
    const date: Date = saveParseISO(dateInput);
    const timeZone: ITimeZoneInfo = findTimeZone(options.timeZone);
    let {offset}: ITimeZoneOffset = getUTCOffset(date, timeZone);
    offset = date.getTimezoneOffset() - offset;

    return new Date(date.getTime() - offset * 60 * 1000);
}

function saveParseISO(dateInput: DateInput): Date {
    return typeof dateInput === 'string'
        ? parseISO(dateInput)
        : dateInput;
}

type DateInput = string | Date;
interface ITimeZoneInfo {
    name: string;
    abbreviations: Array<string>;
    untils: Array<number>;
    offsets: Array<number>;
    population: number;
}
interface ITimeZoneOptions {
    timeZone: string;
}
interface ITimeZoneOffset {
    abbreviation?: string;
    offset: number;
}

interface IDateValues {
    year: number;
    month: number;
    dayOfMonth: number;
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds: number;
}

export {
    TimeZoneStore,
    TimeZone,
    convertToTimeZone,
    parseDateFromApiTimeToTargetTime,
    parseDateFromApiDateValuesToTargetTime,
    getTimeZonedNewDate,
    getTimeZonedMaxDate,
    getTimeZonedMinDate,
    isTimeZonedToday,
    startOfZonedToday,
    endOfZonedToday,
    IDateValues,
    parseDateFromTargetTimeToApiTime,
    differenceInHoursIgnoringDST,
    fnsConvertToTimeZone,
    saveParseISO,
};
