import {IDilPeculiarityPrice} from './peculiarity';

class DilPeculiarityPrice implements IDilPeculiarityPrice {

    private readonly _id: number | null;
    private _ownerNetworkId: number | undefined;
    private _userNetworkId: number | undefined;
    private _peculiarityId: number | undefined;
    private _cpm: number | null = null;
    private _netShare: number | null = null;

    constructor(id: number | null = null) {
        this._id = id;
    }

    get id(): number | null {
        return this._id;
    }

    get ownerNetworkId(): number | undefined {
        return this._ownerNetworkId;
    }

    set ownerNetworkId(value: number | undefined) {
        this._ownerNetworkId = value;
    }

    get userNetworkId(): number | undefined {
        return this._userNetworkId;
    }

    set userNetworkId(value: number | undefined) {
        this._userNetworkId = value;
    }

    get peculiarityId(): number | undefined {
        return this._peculiarityId;
    }

    set peculiarityId(value: number | undefined) {
        this._peculiarityId = value;
    }

    get cpm(): number | null {
        return this._cpm;
    }

    set cpm(value: number | null) {
        this._cpm = value;
    }

    get netShare(): number | null {
        return this._netShare;
    }

    set netShare(value: number | null) {
        this._netShare = value;
    }

    public static merge(modelOne: DilPeculiarityPrice, modelTwo: DilPeculiarityPrice): DilPeculiarityPrice {
        modelOne.ownerNetworkId = modelTwo.ownerNetworkId;
        modelOne.userNetworkId = modelTwo.userNetworkId;
        modelOne.peculiarityId = modelTwo.peculiarityId;
        modelOne.cpm = modelTwo.cpm;
        modelOne.netShare = modelTwo.netShare;

        return modelOne;
    }

    public static clone(model: DilPeculiarityPrice): DilPeculiarityPrice {
        const newModel: DilPeculiarityPrice = new DilPeculiarityPrice(model.id);
        DilPeculiarityPrice.merge(newModel, model);

        return newModel;
    }
}

export {DilPeculiarityPrice};
