import {Observable} from 'rxjs';
import {IDialogConfirmationData} from '../dialog';

interface ICanExit {
    canExit(): Observable<boolean> | boolean;
}

const defaultCanExitDialogData: IDialogConfirmationData = {
    title: $localize`:@@COMPONENT_HAS_DATA_TO_SAVE_CONFIRMATION_DIALOG_TITLE:COMPONENT_HAS_DATA_TO_SAVE_CONFIRMATION_DIALOG_TITLE`,
    description: $localize`:@@COMPONENT_HAS_DATA_TO_SAVE_CONFIRMATION_DIALOG_DESCRIPTION:COMPONENT_HAS_DATA_TO_SAVE_CONFIRMATION_DIALOG_DESCRIPTION`,
    confirmButtonLabel: $localize`:@@COMPONENT_HAS_DATA_TO_SAVE_CONFIRMATION_DIALOG_DISCARD_BUTTON_LABEL:COMPONENT_HAS_DATA_TO_SAVE_CONFIRMATION_DIALOG_DISCARD_BUTTON_LABEL`,
};

export {ICanExit, defaultCanExitDialogData};
