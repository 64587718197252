import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IRequestBuilderResult, Order} from '@active-agent/types';
import {catchError, map} from 'rxjs/operators';
import {GetOrderBuilder} from './builder/get-order-builder';
import {LibsOrderService} from './order.service';

@Injectable({
    providedIn: 'root',
})
class LibsOrderSearchService {
    constructor(private orderService: LibsOrderService) {}

    public searchById(term: string, networkIds: Array<number>, fetchDeleted: boolean = false): Observable<Array<Order>> {
        const searchId: number = Number(term);

        if (Number.isFinite(searchId) && searchId > 0) {
            let builder: GetOrderBuilder = this.orderService.createGetOrderBuilder(networkIds)
                .addQueryIds([searchId]);

            if (fetchDeleted) {
                builder = builder.fetchDeleted();
            }

            return builder
                .build()
                .pipe(
                    map((result: IRequestBuilderResult<Array<Order>>) => result.data as Array<Order>),
                    catchError(() => of([])),
                );
        }

        return of([]);
    }

    public searchByName(term: string, networkIds: Array<number>): Observable<Array<Order>> {
        return this.orderService.createGetOrderBuilder(networkIds)
            .addName(term)
            .fetchAll()
            .build()
            .pipe(
                map((result: IRequestBuilderResult<Array<Order>>) => result.data as Array<Order>),
            );
    }
}

export {LibsOrderSearchService};
