<ng-container [formGroup]="form">
    <div class="vm-flex-100 vm-flex-direction-row vm-flex-direction-column-lt-md vm-gap-05">
        <div class="vm-flex-50" [class.vm-flex-100]="isNoneOptimizationSelected()">
            <mat-form-field
                i18n-hintLabel="@@OPTIMIZATION_SETTINGS_OPTIMIZATION_HINT"
                hintLabel="OPTIMIZATION_SETTINGS_OPTIMIZATION_HINT"
                class="vm-flex-100 mat-form-field--auto-width mat-form-field--flex"
            >
                <mat-label i18n="@@CAMPAIGN_FORM_OPTIMIZATION_LABEL">CAMPAIGN_FORM_OPTIMIZATION_LABEL</mat-label>
                <mat-select
                    formControlName="optimization"
                    [required]="true"
                    *ngIf="!usedInTableInlineEdit"
                >
                    <mat-option *ngFor="let optimization of optimizationList" [value]="optimization.id">
                        {{optimization.name}}
                    </mat-option>
                </mat-select>
                <select
                    matNativeControl
                    formControlName="optimization"
                    [required]="true"
                    *ngIf="usedInTableInlineEdit"
                >
                    <option
                        *ngFor="let optimization of optimizationList"
                        [value]="optimization.id"
                    >
                        {{optimization.name}}
                    </option>
                </select>
            </mat-form-field>
        </div>

        <!-- CPC -->
        <div class="vm-flex-50" *ngIf="isCpcOptimizationSelected()">
            <core-number-input
                formControlName="maxCpc"
                type="currency"
                i18n-label="@@CAMPAIGN_FORM_OPTIMIZATION_MAX_CPC_LABEL"
                label="CAMPAIGN_FORM_OPTIMIZATION_MAX_CPC_LABEL"
                i18n-hint="@@CAMPAIGN_FORM_OPTIMIZATION_MAX_CPC_HINT"
                hint="CAMPAIGN_FORM_OPTIMIZATION_MAX_CPC_HINT"
                [isRequired]="true"
                [isReadOnly]="isReadOnly"
            ></core-number-input>
        </div>

        <!-- CPX -->
        <div class="vm-flex-50" *ngIf="isCpxOptimizationSelected()">
            <core-number-input
                formControlName="maxCpx"
                type="percent"
                [fraction]="0"
                i18n-label="@@OPTIMIZATION_SETTINGS_MAX_CPX_LABEL"
                label="OPTIMIZATION_SETTINGS_MAX_CPX_LABEL"
                i18n-hint="@@OPTIMIZATION_SETTINGS_MAX_CPX_HINT"
                hint="OPTIMIZATION_SETTINGS_MAX_CPX_HINT"
                [isRequired]="true"
                [isReadOnly]="isReadOnly"
            ></core-number-input>
        </div>

        <!-- CPCV -->
        <div class="vm-flex-50" *ngIf="isCpcvOptimizationSelected()">
            <core-number-input
                formControlName="maxCpcv"
                type="currency"
                i18n-label="@@OPTIMIZATION_SETTINGS_MAX_CPCV_LABEL"
                label="OPTIMIZATION_SETTINGS_MAX_CPCV_LABEL"
                i18n-hint="@@OPTIMIZATION_SETTINGS_MAX_CPCV_HINT"
                hint="OPTIMIZATION_SETTINGS_MAX_CPCV_HINT"
                [isRequired]="true"
                [isReadOnly]="isReadOnly"
            ></core-number-input>
        </div>
    </div>
    <div class="vm-flex-100">
        <!-- VTR -->
        <div class="vm-flex-50" *ngIf="isVtrOptimizationSelected()">
            <core-number-input
                formControlName="minVtr"
                type="percent"
                [fraction]="0"
                i18n-label="@@OPTIMIZATION_SETTINGS_MIN_VTR_LABEL"
                label="OPTIMIZATION_SETTINGS_MIN_VTR_LABEL"
                i18n-hint="@@OPTIMIZATION_SETTINGS_MIN_VTR_HINT"
                hint="OPTIMIZATION_SETTINGS_MIN_VTR_HINT"
                [isRequired]="true"
                [isReadOnly]="isReadOnly"
            ></core-number-input>
        </div>

        <div class="vm-flex-100" *ngIf="isCpxOptimizationSelected()">
            <libs-spacer [horizontal]="spacerSize"></libs-spacer>

            <core-info-box [level]="infoStatus">
                <ng-container core-info-box-title i18n="@@OPTIMIZATION_SETTINGS_MAX_CPX_CONDITION_TITLE">OPTIMIZATION_SETTINGS_MAX_CPX_CONDITION_TITLE</ng-container>
                <ng-container core-info-box-description>
                    <span i18n="@@OPTIMIZATION_SETTINGS_MAX_CPX_CONDITION_HINT">OPTIMIZATION_SETTINGS_MAX_CPX_CONDITION_HINT</span>
                </ng-container>
            </core-info-box>


            <libs-spacer *ngIf="!areCpxMetricsSelected()" [horizontal]="spacerSize"></libs-spacer>
            <div *ngIf="!areCpxMetricsSelected()">
                <core-info-box [level]="errorStatus">
                    <ng-container core-info-box-title i18n="@@OPTIMIZATION_SETTINGS_MAX_CPX_NO_CONDITION_ERROR_TITLE">OPTIMIZATION_SETTINGS_MAX_CPX_NO_CONDITION_ERROR_TITLE</ng-container>
                    <ng-container core-info-box-description>
                        <span i18n="@@OPTIMIZATION_SETTINGS_MAX_CPX_NO_CONDITION_ERROR_MESSAGE">OPTIMIZATION_SETTINGS_MAX_CPX_NO_CONDITION_ERROR_MESSAGE</span>
                    </ng-container>
                </core-info-box>
            </div>

            <mat-list *ngFor="let option of cpxMetricsOptions; let first = first;">
                <mat-divider *ngIf="!first"></mat-divider>
                <mat-list-item class="list-item">
                    <mat-checkbox [formControl]="option.control" color="primary">
                        <span class="list-item__title">{{option.translation}}</span>
                    </mat-checkbox>
                </mat-list-item>
            </mat-list>
        </div>

        <div *ngIf="isVtrOptimizationSelected()" class="vm-flex-100 vm-flex-direction-column">
            <libs-spacer [horizontal]="spacerSize"></libs-spacer>

            <core-info-box [level]="infoStatus">
                <ng-container core-info-box-title i18n="@@OPTIMIZATION_SETTINGS_VTR_OPTIMIZATION_TITLE">OPTIMIZATION_SETTINGS_VTR_OPTIMIZATION_TITLE</ng-container>
                <ng-container core-info-box-description>
                    <span i18n="@@OPTIMIZATION_SETTINGS_VTR_OPTIMIZATION_HINT">OPTIMIZATION_SETTINGS_VTR_OPTIMIZATION_HINT</span>
                </ng-container>
            </core-info-box>
            <libs-spacer [horizontal]="spacerSize"></libs-spacer>

            <div class="vm-flex-direction-row">
                <core-number-input
                    class="vm-flex"
                    formControlName="vtrOptimizationMaxCpcv"
                    type="currency"
                    i18n-label="@@OPTIMIZATION_SETTINGS_MAX_CPCV_LABEL"
                    label="OPTIMIZATION_SETTINGS_MAX_CPCV_LABEL"
                    i18n-hint="@@OPTIMIZATION_SETTINGS_MAX_CPCV_HINT"
                    hint="OPTIMIZATION_SETTINGS_MAX_CPCV_HINT"
                    [isRequired]="true"
                    [isReadOnly]="isReadOnly"
                ></core-number-input>
            </div>
        </div>
    </div>
</ng-container>
