/* parser generated by jison 0.3.0 */
/**
 * Returns a Parser implementing JisonParserApi and a Lexer implementing JisonLexerApi.
 */
import { JisonParser, JisonParserApi, StateType, SymbolsType, TerminalsType, ProductionsType, o } from '@ts-jison/parser';const $V0=[1,4],$V1=[1,9],$V2=[8,11],$V3=[6,8];

export class JisonParserParser extends JisonParser implements JisonParserApi {
    $?: any;

    constructor (yy = {}, lexer = new JisonParserLexer(yy)) {
      super(yy, lexer);
    }

    symbols_: SymbolsType = {"error":2,"exp":3,"[":4,"GROUP":5,"]":6,"EOF":7,",":8,"{":9,"JSONObjectProperties":10,"}":11,"JSONObjectEntry":12,"VAR":13,"JSONValue":14,"JSONOBjectKey":15,"TRUE":16,"FALSE":17,"NUMBER":18,"$accept":0,"$end":1};
    terminals_: TerminalsType = {2:"error",4:"[",6:"]",7:"EOF",8:",",9:"{",11:"}",13:"VAR",16:"TRUE",17:"FALSE",18:"NUMBER"};
    productions_: ProductionsType = [0,[3,4],[5,3],[5,3],[10,1],[10,3],[12,2],[15,1],[14,1],[14,1],[14,1]];
    table: Array<StateType> = [{3:1,4:[1,2]},{1:[3]},{5:3,9:$V0},{6:[1,5],8:[1,6]},{10:7,12:8,13:$V1},{7:[1,10]},{5:11,9:$V0},{8:[1,13],11:[1,12]},o($V2,[2,4]),{14:14,16:[1,15],17:[1,16],18:[1,17]},{1:[2,1]},o($V3,[2,2]),o($V3,[2,3]),{10:18,12:8,13:$V1},o($V2,[2,6]),o($V2,[2,8]),o($V2,[2,9]),o($V2,[2,10]),o($V2,[2,5])];
    defaultActions: {[key:number]: any} = {10:[2,1]};

    performAction (yytext:string, yyleng:number, yylineno:number, yy:any, yystate:number /* action[1] */, $$:any /* vstack */, _$:any /* lstack */): any {
/* this == yyval */
          var $0 = $$.length - 1;
        switch (yystate) {
case 1:
return $$[$0-2];
break;
case 2:
this.$ = $$[$0-2].concat(' OR ').concat($$[$0])
break;
case 3:
this.$ = '('.concat($$[$0-1]).concat(')')
break;
case 5:
this.$ = $$[$0-2].concat(' AND ').concat($$[$0])
break;
case 6:
this.$ = $$[$0-1].concat($$[$0])
break;
case 7:
this.$ = $$[$0]
break;
case 8:
this.$ = ' = 1'
break;
case 9:
this.$ = ' != 1'
break;
case 10:
this.$ = ' = '.concat($$[$0])
break;
        }
    }
}


/* generated by ts-jison-lex 0.3.0 */
import { JisonLexer, JisonLexerApi } from '@ts-jison/lexer';
export class JisonParserLexer extends JisonLexer implements JisonLexerApi {
    options: any = {"moduleName":"JisonParser"};
    constructor (yy = {}) {
        super(yy);
    }

    rules: RegExp[] = [/^(?:\{)/,/^(?:\})/,/^(?:\[)/,/^(?:\])/,/^(?:,)/,/^(?:true\b)/,/^(?:false\b)/,/^(?:\d\b)/,/^(?:"[^"]+":)/,/^(?:$)/];
    conditions: any = {"INITIAL":{"rules":[0,1,2,3,4,5,6,7,8,9],"inclusive":true}}
    performAction (yy:any,yy_:any,$avoiding_name_collisions:any,YY_START:any): any {
          var YYSTATE=YY_START;
        switch($avoiding_name_collisions) {
    case 0:return 9;
      break;
    case 1:return 11;
      break;
    case 2:return 4;
      break;
    case 3:return 6;
      break;
    case 4:return 8;
      break;
    case 5:return 16;
      break;
    case 6:return 17;
      break;
    case 7:return 18;
      break;
    case 8:yy_.yytext = yy_.yytext.slice(1,-2); return 13;
      break;
    case 9:return 7;
      break;
        }
    }
}

