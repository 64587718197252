import {IBidFactor} from './bid-factor.types';
import {ICloneable, IMergable} from '../types';

class BidFactor implements IBidFactor, IMergable<BidFactor>, ICloneable<BidFactor> {
    private readonly _id: number | null = null;
    private _campaignId: number;
    private _networkId: number;
    private _factor: number;
    private _filterId: string;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null = null,
        networkId: number,
        campaignId: number,
        factor: number,
        filterId: string,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._campaignId = campaignId;
        this._factor = factor;
        this._filterId = filterId;
    }

    get id(): number | null {
        return this._id;
    }

    get filterId(): string {
        return this._filterId;
    }

    set filterId(value: string) {
        this._filterId = value;
    }

    get factor(): number {
        return this._factor;
    }

    set factor(value: number) {
        this._factor = value;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get campaignId(): number {
        return this._campaignId;
    }

    set campaignId(value: number) {
        this._campaignId = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public merge(source: BidFactor): void {
        this.networkId = source.networkId;
        this.campaignId = source.campaignId;
        this.factor = source.factor;
        this.filterId = source.filterId;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }

    public clone(): BidFactor {
        const newModel: BidFactor = new BidFactor(
            this.id,
            this.networkId,
            this.campaignId,
            this.factor,
            this.filterId,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {BidFactor};
