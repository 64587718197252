import {ViewabilityTagType} from './viewability-tag-type';

export class ViewabilityNetworkPrice {
    private _networkId: number = 0;
    private _cpmByViewabilityTagType: Record<ViewabilityTagType, number> = {
        [ViewabilityTagType.Display]: 0,
        [ViewabilityTagType.Video]: 0,
    };

    constructor(network: number) {
        this._networkId = network;
    }

    public get networkId() {
        return this._networkId;
    }

    public set networkId(value: number) {
        this._networkId = value;
    }

    public get cpmByViewabilityTagType() {
        return this._cpmByViewabilityTagType;
    }

    public set cpmByViewabilityTagType(value: Record<ViewabilityTagType, number>) {
        this._cpmByViewabilityTagType = value;
    }

    public clone(): ViewabilityNetworkPrice {
        return Object.assign(new ViewabilityNetworkPrice(this.networkId), this);
    }
}
