import {NgModule, Type} from '@angular/core';
import {ExtraOptions, RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {AppPath} from './commons/enums';
import {AaLayoutModule} from './layout/layout.module';
import {AaLoginPageModule} from './pages/login/login.module';
import {LibsLogoutResolverFn, LibsRouteReuseStrategy} from '@active-agent/layout';

const routes: Routes = [
    {
        path: AppPath.Login,
        loadChildren: () => import ('./pages/login/login.module')
            .then((module: {AaLoginPageModule: Type<AaLoginPageModule>}) => module.AaLoginPageModule),
    },
    {
        path: AppPath.Logout,
        resolve: {
            logout: LibsLogoutResolverFn(AppPath.Login),
        },
        children: [],
    },
    {
        path: '',
        loadChildren: () => import ('./layout/layout.module')
            .then((module: {AaLayoutModule: Type<AaLayoutModule>}) => module.AaLayoutModule),
    },
];
const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    enableTracing: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top',
    /**
     * Don't set this to reload, this will cause problems for pages like bulk edit where we dynamically update the url path
     */
    onSameUrlNavigation: 'ignore',
};
@NgModule({
    imports: [
        RouterModule.forRoot(routes, routingConfiguration),
    ],
    exports: [RouterModule],
    providers: [
        {provide: RouteReuseStrategy, useClass: LibsRouteReuseStrategy},
    ],
})
class AppRoutingModule { }

export {AppRoutingModule};
