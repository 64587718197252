import {Component, Input} from '@angular/core';

@Component({
    selector: 'commons-list-item',
    templateUrl: './list-item.html',
    styleUrls: ['./list-item.scss'],
})
class CommonsListItemComponent {
    @Input() public borderStyle: CommonsBorderStyle = 'dotted';
    @Input() public hasPadding: boolean = true;
}

type CommonsBorderStyle = 'solid' | 'dotted' | 'none';

export {CommonsListItemComponent, CommonsBorderStyle};
