/**
 * Creates an array of shuffled values, using a version of the Fisher-Yates shuffle.
 *
 * https://stackoverflow.com/a/2450976/4243635
 */
function shuffle<T>(array: Array<T>): Array<T> {
    let currentIndex: number = array.length;
    let temporaryValue: T;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export {shuffle};
