import {CommonsError} from '@active-agent/types';
import {InjectionToken} from '@angular/core';

interface ICommonsErrorHandler {
    handle(
        error: CommonsError | Error,
        showToast?: boolean,
        toastMessage?: string,
        slim?: boolean,
    ): Promise<void>;
}

const ERROR_HANDLER: InjectionToken<ICommonsErrorHandler> = new InjectionToken<ICommonsErrorHandler>('error.handler');

export {ICommonsErrorHandler, ERROR_HANDLER};
