import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {getAttributeNamePattern} from '@active-agent/pattern';
import {DmpAttribute, TaggingAttribute} from '@active-agent/types';

@Injectable({
    providedIn: 'root',
})
class AttributeNameValidator {
    public static readonly maxLength: number = 64;
    public static readonly pattern: RegExp = getAttributeNamePattern();
    private _attribute: TaggingAttribute | DmpAttribute | null = null;
    private _existingAttributes: Array<TaggingAttribute | DmpAttribute> = [];

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const name: string = control.value;

        if (name === undefined || name === null || name.length === 0) {
            return {[NameErrorCodes.Required]: getTranslationForErrorCode(NameErrorCodes.Required)};
        }

        // we need to prevent attribute name starting with boolean operators,
        // because it will not correctly parse in tagging filter
        if (name.toLocaleLowerCase().startsWith('or') ||
            name.toLocaleLowerCase().startsWith('and') ||
            name.toLocaleLowerCase().startsWith('not')
        ) {
            return {[NameErrorCodes.BooleanOperator]: getTranslationForErrorCode(NameErrorCodes.BooleanOperator)};
        }

        if (name.length > AttributeNameValidator.maxLength) {
            return {[NameErrorCodes.MaxLength]: getTranslationForErrorCode(NameErrorCodes.MaxLength)};
        }

        if (!AttributeNameValidator.pattern.test(name)) {
            return {[NameErrorCodes.Pattern]: getTranslationForErrorCode(NameErrorCodes.Pattern)};
        }

        if (!this.isNameUnique(name)) {
            return {[NameErrorCodes.Unique]: getTranslationForErrorCode(NameErrorCodes.Unique)};
        }

        return null;
    }

    public get attribute(): TaggingAttribute | DmpAttribute | null {
        return this._attribute;
    }

    public set attribute(value: TaggingAttribute | DmpAttribute | null) {
        this._attribute = value;
    }

    public get existingAttributes(): Array<TaggingAttribute | DmpAttribute> {
        return this._existingAttributes;
    }

    public set existingAttributes(value: Array<TaggingAttribute | DmpAttribute>) {
        this._existingAttributes = value;
    }

    private isNameUnique(value: string): boolean {
        if (!this.existingAttributes.length || this.attribute === null) {
            return true;
        }

        const networkId: number =
            this.attribute && this.attribute.network && this.attribute.network.id ? this.attribute.network.id : 0;

        return this.existingAttributes
            .filter((attribute: TaggingAttribute | DmpAttribute): boolean => {
                return attribute.network !== undefined
                    && attribute.network.id === networkId;
            })
            .every((attribute: TaggingAttribute | DmpAttribute): boolean => {
                if (!attribute.name) {
                    return true;
                }

                return attribute.name.toLocaleLowerCase() !== value.toLocaleLowerCase()
                    || (!!this.attribute && this.attribute.id === attribute.id);
            });
    }
}

function getTranslationForErrorCode(code: NameErrorCodes): string {
    switch (code) {
        case NameErrorCodes.Required:
            return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
        case NameErrorCodes.MaxLength:
            const max: number = AttributeNameValidator.maxLength;

            return $localize`:@@VALIDATOR_NAME_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_NAME_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        case NameErrorCodes.Pattern:
            return $localize`:@@VALIDATOR_NAME_PATTERN_ERROR_MESSAGE:VALIDATOR_NAME_PATTERN_ERROR_MESSAGE`;
        case NameErrorCodes.Unique:
            return $localize`:@@VALIDATOR_NAME_UNIQUE_ERROR_MESSAGE:VALIDATOR_NAME_UNIQUE_ERROR_MESSAGE`;
        case NameErrorCodes.BooleanOperator:
            return $localize`:@@VALIDATOR_ATTRIBUTE_NAME_START_WITH_BOOLEAN_OPERATOR_ERROR_MESSAGE:VALIDATOR_ATTRIBUTE_NAME_START_WITH_BOOLEAN_OPERATOR_ERROR_MESSAGE`;
        default:
            return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
    }
}

enum NameErrorCodes {
    Required = '2aa50f88-a816-41b4-a939-b58a4199e7be',
    MaxLength = '120a20f8-5c65-4f0f-8825-00eb5be947b9',
    Pattern = '0b976daa-14df-435a-b81c-1c16981cc70b',
    Unique = '9b6ccc9a-0c56-48fa-bb0e-92bfd77c7131',
    BooleanOperator = '0ce9f37a-7940-4617-a72a-66f0cb085b2d',
}

export {AttributeNameValidator};
