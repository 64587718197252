import {NgClass} from '@angular/common';
import {Component, Signal, input} from '@angular/core';

@Component({
    selector: 'commons-content',
    templateUrl: './content.html',
    styleUrl: './content.scss',
    standalone: true,
    imports: [NgClass],
})
class CommonsContentComponent {
    public limitWidth: Signal<boolean> = input<boolean>(false);
    public fillHeight: Signal<boolean> = input<boolean>(false);
    public minHeight: Signal<number | null> = input<number | null>(null);
}

export {CommonsContentComponent};
