<div
    class="info-container mat-elevation-z2"
    [ngClass]="{'info-container--no-margin': !hasMargin}"
>
    <div
        class="info-container__content"
        [ngClass]="{
            'info-container__content--default': status === defaultStatus,
            'info-container__content--warning': status === warningStatus,
            'info-container__content--error': status === errorStatus,
            'info-container__content--success': status === successStatus,
            'info-container__content--new': status === newStatus,
            'info-container__content--info': status === infoStatus
        }"
    >
        <div
            class="info-container__title-wrapper flex-direction-row vm-layout-align-start vm-layout-justify-center vm-gap-1"
            [class.info-container__title-wrapper--no-margin]="!hasDescription() && !hasSubtitle()"
            [hidden]="!hasTitle()"
        >
            <ng-content select="[commons-info-container-title-icon]"></ng-content>
            <h1
                class="mat-headline-6 info-container__title--no-margin"
                #title
            ><ng-content select="[commons-info-container-title]"></ng-content></h1>
        </div>
        <h2
            class="mat-subtitle-1 info-container__subtitle"
            #subtitle
            [hidden]="!hasSubtitle()"
        ><ng-content select="[commons-info-container-subtitle]"></ng-content></h2>

        <div class="info-container__description" #description><ng-content select="[commons-info-container-description]"></ng-content></div>

        <!-- More info -->
        <p [hidden]="!isExpanded" #moreDescription><ng-content select="[commons-info-container-more-description]"></ng-content></p>
        <div [hidden]="!hasMoreDescription()">
            <div [ngSwitch]="isExpanded">
                <a
                    class="info-container__expand-button clickable"
                    *ngSwitchDefault
                    (click)="isExpanded = true"
                    i18n
                >INFO_CONTAINER_SHOW_MORE_BUTTON_LABEL</a>
                <a
                    class="info-container__expand-button clickable"
                    *ngSwitchCase="true"
                    (click)="isExpanded = false"
                    i18n
                >INFO_CONTAINER_SHOW_LESS_BUTTON_LABEL</a>
            </div>
        </div>

        <!-- Hint -->
        <div class="info-container__hint-container" [hidden]="!hasHint()">
            <div class="info-container__hint-title">{{hintTitle}}</div>
            <div #hint><ng-content select="[commons-info-container-hint]"></ng-content></div>
        </div>

        <!-- Rest of the content -->
        <div class="info-container__footer-description" #footerDescription [hidden]="!hasFooterDescription()">
            <ng-content select="[commons-info-container-footer-description]"></ng-content>
        </div>
    </div>
</div>
