import {ICloneable, IMergable} from '../../types';
import {CountryCode} from '../../country';

interface IPostalCode {
    id: number;
    countryCode?: CountryCode;
    postalCode?: string;
    wktString?: string;
    lastUpdate?: number;
    deleted?: boolean;
    area?: string;
    district?: string;
    state?: string;
    boundingBox?: IBoundingBox;
}

class PostalCode implements IPostalCode, IMergable<PostalCode>, ICloneable<PostalCode> {
    private readonly _id: number;
    private readonly _countryCode?: CountryCode;
    private readonly _postalCode?: string;
    private _wktString?: string;
    private _lastUpdate?: number;
    private _deleted: boolean = false;
    private _area?: string;
    private _district?: string;
    private _state?: string;
    private _boundingBox?: IBoundingBox;

    constructor(
        id: number,
        countryCode?: CountryCode,
        postalCode?: string,
        wktString?: string,
    ) {
        this._id = id;
        this._countryCode = countryCode;
        this._postalCode = postalCode;
        this._wktString = wktString;
    }

    get id(): number {
        return this._id;
    }

    get countryCode(): CountryCode | undefined {
        return this._countryCode;
    }

    get postalCode(): string | undefined {
        return this._postalCode;
    }

    get wktString(): string | undefined {
        return this._wktString;
    }

    set wktString(wktString: string | undefined) {
        this._wktString = wktString;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get area(): string | undefined {
        return this._area;
    }

    set area(value: string | undefined) {
        this._area = value;
    }

    get district(): string | undefined {
        return this._district;
    }

    set district(value: string | undefined) {
        this._district = value;
    }

    get state(): string | undefined {
        return this._state;
    }

    set state(value: string | undefined) {
        this._state = value;
    }

    get boundingBox(): IBoundingBox | undefined {
        return this._boundingBox;
    }

    set boundingBox(boundingBox: IBoundingBox | undefined) {
        this._boundingBox = boundingBox;
    }

    public clone(): PostalCode {
        const newModel: PostalCode = new PostalCode(
            this.id,
            this.countryCode,
            this.postalCode,
            this.wktString,
        );
        newModel.merge(this);

        return newModel;
    }

    public merge(source: PostalCode): void {
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
        this.area = source.area;
        this.district = source.district;
        this.state = source.state;
        this.boundingBox = this.boundingBox && {...this.boundingBox};
    }
}

interface IBoundingBox {
    minLatitude: number;
    maxLatitude: number;
    minLongitude: number;
    maxLongitude: number;
}

export {PostalCode, IBoundingBox, IPostalCode};

