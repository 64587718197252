import {ThirdPartyFeaturePriceCurrency} from './third-party-feature-price-currency';

export class ThirdPartyFeaturePrices {
    private _currency: ThirdPartyFeaturePriceCurrency = ThirdPartyFeaturePriceCurrency.USD;

    public get currency(): ThirdPartyFeaturePriceCurrency {
        return this._currency;
    }

    public set currency(value: ThirdPartyFeaturePriceCurrency) {
        this._currency = value;
    }
}
