enum DateFormat {
    ApiRequestDate = 'yyyy-MM-dd',
    ApiRequestDateTime = "yyyy-MM-dd'T'HH:mm:ss",
    FileName = 'yyyy-MM-dd_HH-mm',
    Medium = 'yyyy-MM-dd HH:mm:ss',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    ShortDate = 'yyyy-MM-dd',
    Time = 'HH:mm',
    FullDate = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
}

export {DateFormat};
