import {NgModule} from '@angular/core';
import {LibsSpacerComponent} from './spacer.component';

@NgModule({
    exports: [
        LibsSpacerComponent,
    ],
    declarations: [
        LibsSpacerComponent,
    ],
})
class LibsSpacerModule {}

export {LibsSpacerModule};
