import {Company} from '../company';
import {ICloneable, IMergable} from '../types';

class Agency extends Company implements IMergable<Agency>, ICloneable<Agency> {

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        domain: string,
    ) {
        super(id, networkId, name, domain);

        this._isAgency = true;
    }

    public clone(): Agency {
        const newModel: Agency = new Agency(
            this.id,
            this.networkId,
            this.name,
            this.domain,
        );
        newModel.merge(this);

        return newModel;
    }

    public merge(source: Agency): void {
        super.merge(source);

        this.isClient = source.isClient;
    }

    get isAgency(): boolean {
        return this._isAgency;
    }

    get isClient(): boolean {
        return this._isClient;
    }

    set isClient(value: boolean) {
        this._isClient = value;
    }

}

export {Agency};
