import {AbstractControl, ValidationErrors} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class TotalImpressionValidator {
    public static readonly maxValue: number = 1_000_000_000;
    public static readonly minValue: number = 0;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const totalImpressions: number | undefined | null = control.value;

        if (totalImpressions === undefined || totalImpressions === null) {
            return null;
        }

        if (totalImpressions < TotalImpressionValidator.minValue) {
            return {[TotalImpressionErrorCodes.MinValue]: this.getTranslationForErrorCode(TotalImpressionErrorCodes.MinValue)};
        }

        if (totalImpressions > TotalImpressionValidator.maxValue) {
            return {[TotalImpressionErrorCodes.MaxValue]: this.getTranslationForErrorCode(TotalImpressionErrorCodes.MaxValue)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: TotalImpressionErrorCodes): string {
        const min: string = this.decimalPipe.transform(TotalImpressionValidator.minValue) || '';
        const minMessage: string = $localize`:@@VALIDATOR_TOTAL_IMPRESSION_MIN_VALUE_ERROR_MESSAGE:VALIDATOR_TOTAL_IMPRESSION_MIN_VALUE_ERROR_MESSAGE ${min}:INTERPOLATION:`;
        const max: string = this.decimalPipe.transform(TotalImpressionValidator.maxValue) || '';
        const maxMessage: string = $localize`:@@VALIDATOR_TOTAL_IMPRESSION_MAX_VALUE_ERROR_MESSAGE:VALIDATOR_TOTAL_IMPRESSION_MAX_VALUE_ERROR_MESSAGE ${max}:INTERPOLATION:`;
        const defaultMessage: string = $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;

        switch (code) {
            case TotalImpressionErrorCodes.MinValue:
                return minMessage;
            case TotalImpressionErrorCodes.MaxValue:
                return maxMessage;
            default:
                return defaultMessage;
        }
    }
}

enum TotalImpressionErrorCodes {
    Required = '1df98f59-82b1-4755-bc5e-95e3d5b53165',
    MinValue = '64d6638f-3efd-4016-939e-9aa99c5ec2e6',
    MaxValue = '99c0eedb-a127-4544-860a-4f2c3a66efdf',
}

export {TotalImpressionValidator, TotalImpressionErrorCodes};
