import {Injectable} from '@angular/core';
import {DataService} from '@active-agent/app-data';

@Injectable({
    providedIn: 'root',
})
class LoadingDataService extends DataService<ILoadingData> {
    constructor() {
        super({});
    }
}

interface ILoadingData {
    isLoading?: boolean;
}

export {LoadingDataService, ILoadingData};
