import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {CommonsError} from '@active-agent/types';
import {pluralize} from './pluralize';

@Component({
    selector: 'commons-pluralize',
    template: '{{this.getTranslation()}}',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
class CommonsPluralizeComponent implements OnChanges {
    @Input() private zero: string | null = null;
    @Input() private one: string | null = null;
    @Input() private other: string | null = null;
    @Input() private count: number | null = null;

    public ngOnChanges(): void {
        if (!this.other) {
            throw new CommonsError('no other translation provided');
        }
        if (this.count === null) {
            throw new CommonsError('no count provided');
        }
    }

    public getTranslation(): string {
        if (!this.other || this.count === null) {
            return '';
        }

        return pluralize(
            {
                0: this.zero,
                1: this.one,
                other: this.other,
            },
            this.count,
        );
    }
}

export {CommonsPluralizeComponent};
