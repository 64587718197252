import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpStatusCode,
} from '@angular/common/http';
import {Location} from '@angular/common';
import {Observable, throwError} from 'rxjs';
import {CoreCookieService} from '@angular-clan/core';
import {format} from 'date-fns';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {DateFormat, IGenericObject, LogoutReason, UnauthorizedRequestError, FrontendServicesEndpoints} from '@active-agent/types';
import {parseDateFromTargetTimeToApiTime} from '@active-agent/std';
import {SlowLog} from '../event/slow-log';
import {EventLoggerService} from '../event/event-logger.service';
import {Router} from '@angular/router';
import {AppPath} from '../enums';

@Injectable({
    providedIn: 'root',
})
class ApiRequestInterceptorService implements HttpInterceptor {
    public static userLoggedOutReason: LogoutReason | null = null;

    private urlsToIntercept: Array<string> = [
        environment.apiUrl,
        `${environment.servicesUrl}/${FrontendServicesEndpoints.PartnerMappings}`,
        `${environment.servicesUrl}/${FrontendServicesEndpoints.ManualPosts}`,
        `${environment.servicesUrl}/${FrontendServicesEndpoints.ManualMenu}`,
        `${environment.servicesUrl}/${FrontendServicesEndpoints.ManualSearch}`,
    ];

    constructor(
        private location: Location,
        private cookieService: CoreCookieService,
        private eventLogger: EventLoggerService,
        private http: HttpClient,
        private router: Router,
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const interceptionAvailable: boolean = this.urlsToIntercept.some((url: string) => request.url.includes(url));
        if (!interceptionAvailable) {
            return next.handle(request);
        }

        const newRequest: HttpRequest<unknown> = this.createInterceptionRequest(request);

        const startTime: Date = new Date();

        return next.handle(newRequest)
            .pipe(
                tap((response: HttpEvent<unknown>): HttpResponse<unknown> | HttpEvent<unknown> => {
                    if (response instanceof HttpResponse && response.url?.includes(environment.apiUrl)) {
                        this.logSlowRequest(newRequest, startTime);
                    }

                    return response;
                }),
                catchError((rejection: HttpErrorResponse): Observable<never> => {
                    this.checkForMaintenanceStatus();

                    return this.handleRequestError(rejection, newRequest, startTime);
                }),
            );
    }

    // Add token to every api request if available except for login
    private getAdditionHeaders(request: HttpRequest<unknown>): IHeaders {
        const additionalHeaders: IHeaders = {};
        const token: string | null = this.cookieService.get('token');
        if (token && !request.url.includes('/auth')) {
            additionalHeaders.Authorization = token;
        }

        return additionalHeaders;
    }

    private handleRequestError(rejection: HttpErrorResponse, newRequest: HttpRequest<unknown>, startTime: Date) {
        if (!rejection.url || !rejection.url.includes(environment.apiUrl)) {
            return throwError(() => rejection);
        }
        this.logSlowRequest(newRequest, startTime);

        if (rejection.status === HttpStatusCode.Unauthorized) {
            ApiRequestInterceptorService.userLoggedOutReason = LogoutReason.Unauthorized;

            /**
             * If already on the login page and requests still failing we don't need to redirect again
             */
            if (!this.location.path().startsWith(`/${AppPath.Login}`)) {
                void this.router.navigate([AppPath.Logout], {
                    queryParams: {
                        redirectTo: this.location.path(),
                    },
                });
            }

            return throwError(() => new UnauthorizedRequestError('api request failed with unauthorized status'));

        }

        return throwError(() => rejection);
    }

    /**
     * This triggers the maintenance page if the request fails.
     * The maintenance page will return an error code and with a refresh you are guaranteed to see the maintenance page
     */
    private checkForMaintenanceStatus() {
        this.http.head('/')
            .subscribe({
                error: (response: HttpResponse<unknown>) => {
                    if (response.status.valueOf() === HttpStatusCode.ServiceUnavailable) {
                        window.location.href = '/';
                    }
                },
            });
    }

    /**
     * To guarantee that we keep the same date format which we have received
     * For that we need to check if in the date string the delimiter "T" (e.g. 2019-07-08T16:09:34) exists.
     */
    private createInterceptionRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
        const newRequest: HttpRequest<unknown> = request.clone({
            setHeaders: this.getAdditionHeaders(request),
            params: request.params,
        });

        const fromParam: string | null = request.params.get('from');
        if (fromParam && fromParam.length) {
            const dateFormat: DateFormat = fromParam.includes('T') ? DateFormat.ApiRequestDateTime : DateFormat.ApiRequestDate;
            request.params.set('from', format(parseDateFromTargetTimeToApiTime(fromParam), dateFormat));
        }

        const toParam: string | null = request.params.get('to');
        if (toParam && toParam.length) {
            const dateFormat: DateFormat = toParam.includes('T') ? DateFormat.ApiRequestDateTime : DateFormat.ApiRequestDate;
            request.params.set('to', format(parseDateFromTargetTimeToApiTime(toParam), dateFormat));
        }

        return newRequest;
    }

    private logSlowRequest(request: HttpRequest<unknown>, startTime: Date): void {
        if (!request || !request.url) {
            return;
        }
        const endTime: Date = new Date();
        const duration: number = (endTime.valueOf() - startTime.valueOf()) / 1000;

        const requestParams: IGenericObject = {};
        request.params.keys().forEach((param: string) => {
            requestParams[param] = request.params.get(param);
        });

        const slowLog: SlowLog = new SlowLog(
            duration,
            request.url,
            request.method,
            requestParams,
        );

        this.eventLogger.logSlowLog(slowLog);
    }
}

interface IHeaders {
    [name: string]: string | Array<string>;
}

export {ApiRequestInterceptorService};
