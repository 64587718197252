import {RtbCampaign, Campaign} from './rtb-campaign';
import {CampaignTypes} from './campaign-types';
import {IProgrammaticTvCampaign} from './campaign';
import {ICloneable, IMergable} from '../types';

class ProgrammaticTvCampaign extends RtbCampaign implements
    IProgrammaticTvCampaign,
    IMergable<Campaign>,
    ICloneable<ProgrammaticTvCampaign> {

    private readonly _type: CampaignTypes = CampaignTypes.ProgrammaticTv;

    public get type(): CampaignTypes {
        return this._type;
    }

    public clone(): ProgrammaticTvCampaign {
        const newModel: ProgrammaticTvCampaign = new ProgrammaticTvCampaign(
            this.id,
            this.orderId,
            this.networkId,
            this.biddingStrategy,
            this.dailyBudget,
            this.deliveryTechnique,
            this.maxCpm,
            this.name,
            this.totalBudget,
        );
        newModel.merge(this);

        return newModel;
    }
}

function isProgrammaticTvCampaign(campaign: unknown): campaign is ProgrammaticTvCampaign {
    return campaign instanceof ProgrammaticTvCampaign;
}

export {ProgrammaticTvCampaign, isProgrammaticTvCampaign};
