import {PeriodTypes} from '@active-agent/types';
import {pluralize} from '@active-agent/pluralize';

function getPeriodTranslation(period: PeriodTypes, time: number): string {
    switch (period) {
        case PeriodTypes.Days:
            return pluralize(
                {
                    1: $localize`:@@DAY_VALUE_SINGULAR:DAY_VALUE_SINGULAR ${time}:INTERPOLATION:`,
                    other: $localize`:@@DAYS_VALUE_PLURAL:DAYS_VALUE_PLURAL ${time}:INTERPOLATION:`,
                },
                time,
            );
        case PeriodTypes.Hours:
            return pluralize(
                {
                    1: $localize`:@@HOUR_VALUE_SINGULAR:HOUR_VALUE_SINGULAR ${time}:INTERPOLATION:`,
                    other: $localize`:@@HOURS_VALUE_PLURAL:HOURS_VALUE_PLURAL ${time}:INTERPOLATION:`,
                },
                time,
            );
        case PeriodTypes.Minutes:
            return pluralize(
                {
                    1: $localize`:@@MINUTE_VALUE_SINGULAR:MINUTE_VALUE_SINGULAR ${time}:INTERPOLATION:`,
                    other: $localize`:@@MINUTES_VALUE_PLURAL:MINUTES_VALUE_PLURAL ${time}:INTERPOLATION:`,
                },
                time,
            );
        default:
        case PeriodTypes.Seconds:
            return pluralize(
                {
                    1: $localize`:@@SECOND_VALUE_SINGULAR:SECOND_VALUE_SINGULAR ${time}:INTERPOLATION:`,
                    other: $localize`:@@SECONDS_VALUE_PLURAL:SECONDS_VALUE_PLURAL ${time}:INTERPOLATION:`,
                },
                time,
            );
    }
}

export {getPeriodTranslation};
