import {IDilAttribute} from './attribute.types';

class DilAttribute implements IDilAttribute {

    private readonly _id: number | null;
    private _attributeId: number | undefined;
    private _ownerNetworkId: number | undefined;
    private _userNetworkId: number | undefined;
    private _attributeType: string | undefined;

    constructor(id: number | null = null) {
        this._id = id;
    }

    get id(): number | null {
        return this._id;
    }

    get ownerNetworkId(): number | undefined {
        return this._ownerNetworkId;
    }

    set ownerNetworkId(value: number | undefined) {
        this._ownerNetworkId = value;
    }

    get userNetworkId(): number | undefined {
        return this._userNetworkId;
    }

    set userNetworkId(value: number | undefined) {
        this._userNetworkId = value;
    }

    get attributeId(): number | undefined {
        return this._attributeId;
    }

    set attributeId(value: number | undefined) {
        this._attributeId = value;
    }

    get attributeType(): string | undefined {
        return this._attributeType;
    }

    set attributeType(value: string | undefined) {
        this._attributeType = value;
    }

    public static merge(modelOne: DilAttribute, modelTwo: DilAttribute): DilAttribute {
        modelOne.ownerNetworkId = modelTwo.ownerNetworkId;
        modelOne.userNetworkId = modelTwo.userNetworkId;
        modelOne.attributeId = modelTwo.attributeId;
        modelOne.attributeType = modelTwo.attributeType;

        return modelOne;
    }

    public static clone(model: DilAttribute): DilAttribute {
        const newModel: DilAttribute = new DilAttribute(model.id);
        DilAttribute.merge(newModel, model);

        return newModel;
    }
}

export {DilAttribute};
