import {BaseVideoBanner} from './base-video-banner';
import {VideoVastSettings} from '../../vast-settings/video-vast-settings';
import {BannerTypes} from '../banner-types';
import {ICloneable} from '../../types';
import {VastVersion} from '../../vast-versions/vast-version';
import {VastSettingsPreset} from '../../vast-settings/vast-settings.service';

class VideoBanner extends BaseVideoBanner implements ICloneable<VideoBanner> {
    private _vastSettings: VideoVastSettings;

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
        clickUrl: string,
        assetId: number,
        vastSettings: VideoVastSettings,
        vastTemplateId: VastSettingsPreset,
        vastVersion: VastVersion | string,
    ) {
        super(id, networkId, name, landingPage, clickUrl, assetId, vastTemplateId, BannerTypes.VIDEO, vastVersion);

        this._vastSettings = vastSettings;
    }

    public get vastSettings(): VideoVastSettings {
        return this._vastSettings;
    }

    public set vastSettings(value: VideoVastSettings) {
        this._vastSettings = value;
    }

    public clone(): VideoBanner {
        const newModel: VideoBanner = new VideoBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
            this.clickUrl,
            this.assetId,
            this.vastSettings,
            this.vastTemplateId,
            this.vastVersion,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {VideoBanner};
